import { QETerm } from "../../../common/QETerm";
import { tokenize_and_parse, findGrammar } from "../../../common/QEGrammar";
import { QESolver, SolverStepOutput } from "../../QESolver";
import { QEValueTree } from '../../../common/QEHelper';
//import { QEQ, QEQInterval, QEQinf } from "../../../common/QE";
import { QEQ } from "../../../common/QE";
import {
//	comparisonResult,
	multiplicativeTermToList,
	listToMultiplicativeTerm,
//	createDividedTerm,
	cancelIdenticalFracTerms,
	divideDecimalsInFracTerms,
	dividePowersInFracTerm,
	factorOutCommonFracFactors,
	parseToChainModeTerm,
	additiveChainToList,
	listToAdditiveChain,
//	sortMultiplicativeTermList,
//	sortAdditiveTermList,
	getCharacterizedTermFactors,
	getUnionOfCharacterizedTermListFactors,
	getDifferenceOfFactorMaps,
	generateTermFromFactors,
	filterFactorMapToRationalFactors,
	filterFactorMapToNonRationalFactors,
	generateNonRationalBaseKeyFromFactors,
} from "../SolverUtils";


export class Simplify {
    // "simplify" comparison expressions by multiplying each sub-expression with
    // the first integer denominator we find
    static CT_simplifyComparisonFracToInts(input_value:QEValueTree, options:unknown): SolverStepOutput {
        if (input_value.type != "tree") return undefined;
        const root = input_value.value;

        if (!root.children[0].isComparatorChain()) {
            return;
        }

        const chain = root.children[0];

        // find first integer denominator fraction in comparator chain
        for (let i = 0; i < chain.children.length; i += 2) {
            let den = undefined;
            if (chain.children[i].value == "frac") {
                den = chain.children[i].children[1];
            } else {
                continue;
            }

            // ensure fraction has an integer denominator
            if (den.type != "RATIONAL" || !den.Q.isInteger()) {
                continue;
            }

            // create a new chain by cloning each term in the old chain, and multiply each non-comparator member by the denominator
            const new_chain = QETerm.create({
                type: "CHAIN",
                precedence: findGrammar("EQUAL").precedence,
            });

            const den_clone = den.clone();
            den_clone.addClass("highlight_output");
            den.addClass("highlight_input");

            for (let j = 0; j < chain.children.length; j++) {
                let new_term;
                if (j % 2) {
                    // clone comparators
                    new_term = chain.children[j].clone();
                } else {
                    // create new term from denominator multiplied by expression
                    new_term = listToMultiplicativeTerm([
                        den_clone,
                        chain.children[j],
                    ]);

                    // retain sign
                    new_term.sign = chain.children[j].sign;
                }
                new_chain.pushChild(new_term);
            }

            return {
                old_term: chain,
                new_term: new_chain,
                type: "tree",
                desc:
                    "Make a comparison with a fraction simpler by getting rid of the denominator of the fraction by multiply each term by the denominator.",
            };
        }
    }

    static simplifyComparisonDecimalsToDecimalFraction(input_value:QEValueTree, options:unknown): SolverStepOutput {
        if (input_value.type != "tree") return undefined;

        // Validate tree contains a list with at least 2 items
        if (
            input_value.value.children[0].type != "FUNCTION" ||
            input_value.value.children[0].value != "list" ||
            input_value.value.children[0].children.length != 2
        ) {
            return undefined;
        }

        const old_term = input_value.value.children[0];
        const terms = old_term.children;

        // If terms are fractions, skip this step
        if (terms[0].value == "frac" && terms[1].value == "frac") {
            return undefined;
        }

        const new_terms = [];
        for (let j = 0; j < terms.length; j++) {
            if (terms[j].attr("value_type") == "decimal") {
                new_terms.push(
                    terms[j].Q.to_term({ value_type: "decimal_fraction" }).value
                );
            } else if (terms[j].value == "frac") {
                new_terms.push(terms[j].serialize_to_text());
            } else {
                // Don't support other type
                return undefined;
            }
        }

        const new_term = tokenize_and_parse(
            "list{" + new_terms.join(",") + "}",
            { merge_sign_operators: true }
        ).tree.children[0];
        const result = {
            desc: "Convert decimal to decimal fraction",
            old_term: old_term,
            new_term: new_term,
            type: "tree",
        };
        return result;
    }

    static simplifyComparisonFractions(input_value:QEValueTree, options:unknown): SolverStepOutput{
        if (input_value.type != "tree") return undefined;

        // Validate tree contains a list with at least 2 items
        if (
            input_value.value.children[0].type != "FUNCTION" ||
            input_value.value.children[0].value != "list" ||
            input_value.value.children[0].children.length != 2
        ) {
            return undefined;
        }

        const old_term = input_value.value.children[0];
        const old_terms = input_value.value.children[0].children;

        // If denominator is the same, skip this steps
        if (
            old_terms[0].value == "frac" &&
            old_terms[1].value == "frac" &&
            old_terms[0].children[1].value == old_terms[1].children[1].value
        ) {
            return undefined;
        }

        const new_term = QESolver.solveUsing("simplify_bedmas", {
            type: "tree",
            value: old_term.clone(),
        }).value.value.children[0];
        // If output is the same, skip
        if (new_term.serialize_to_text() === old_term.serialize_to_text()) {
            return undefined;
        }

        const result = {
            desc: "Simplify fractions",
            old_term: old_term,
            new_term: new_term,
            type: "tree",
        };
        return result;
    }

    static simplifyDecimalFractions(input_value: QEValueTree, options: unknown): SolverStepOutput {
        if (input_value.type != "tree") return undefined;

        // Validate tree contains a list with at least 2 items
        if (
            input_value.value.children[0].type != "FUNCTION" ||
            input_value.value.children[0].value != "list" ||
            input_value.value.children[0].children.length != 2
        ) {
            return undefined;
        }

        const old_term = input_value.value.children[0];
        const old_terms = input_value.value.children[0].children;

        // If both are decimal, skip this step
        if (
            old_terms[0].attr("value_type") == "decimal" &&
            old_terms[1].attr("value_type") == "decimal"
        ) {
            return undefined;
        }

        const new_terms = [];
        for (const i in old_terms) {
            const term = old_terms[i];
            // convert_fraction_decimal
            if (term.attr("value_type") != "decimal") {
                const root = QETerm.create({ type: "ROOT" });
                root.pushChild(term);
                const new_term = QESolver.solveUsing(
                    "convert_fraction_decimal",
                    { type: "tree", value: root }
                ).value;
                new_terms.push(new_term.value.children[0].value);
            } else {
                new_terms.push(term.value);
            }
        }
        const new_term = tokenize_and_parse(
            "list{" + new_terms.join(",") + "}",
            { merge_sign_operators: true }
        ).tree.children[0];
        // If output is the same, skip
        if (new_term.serialize_to_text() === old_term.serialize_to_text()) {
            return undefined;
        }

        const result = {
            desc: "Converting fractions to decimals",
            old_term: old_term,
            new_term: new_term,
            type: "tree",
        };
        return result;
    }

	/**
	 * CT_removePlusSigns	Removes all PLUS signs from chain tree
	 */
	static CT_removePlusSigns(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		var old_terms = root.findAllChildren("type", "PLUS");
		if (!old_terms.length) {
			return undefined;
		}

		var clone = root.clone();

		// highlight all old PLUS terms
		old_terms.forEach(function (plus) {
			plus.addClass("strikethrough_input");
		});

		// replace PLUS terms with their child and highlight
		var new_terms = clone.findAllChildren("type", "PLUS");
		new_terms.forEach(function (plus) {
			plus.replaceWith(plus.children[0]);
		});

		return {
			old_term: root.children[0],
			new_term: clone.children[0],
			type: "tree",
			desc: "Remove any extra plus (+) signs.",
		};
	}
	/**
	 * CT_combineNestedMinusSigns	Combines MINUS sign with a MINUS child
	 */
	static CT_combineNestedMinusSigns(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find all MINUS signs with a MINUS child
		var terms = root.findAllChildren(function (node) {
			return node.type == "MINUS" && node.children[0].type == "MINUS";
		});

		for (let i = 0; i < terms.length; i++) {
			var minus = terms[i];
			var minus_child = minus.children[0];
			var clone_grandchild = minus_child.children[0].clone();

			minus.addClass("strikethrough_input");
			minus_child.addClass("strikethrough_input");
			clone_grandchild.addClass("highlight_output");

			return {
				old_term: minus,
				new_term: clone_grandchild,
				type: "tree",
				desc: "Two minus (-) signs in a row cancel each other out.",
			};
		}
	}
	/**
	 * CT_mergeMinusWithTerms	Combines MINUS sign with its child term - sets sign
	 */
	static CT_mergeMinusWithTerms(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find all MINUS signs followed by a value
		var old_terms = root.findAllChildren(function (node) {
			return (
				node.type == "MINUS" &&
				node.children[0].type != "MINUS" &&
				node.children[0].type != "PLUS"
			);
		});
		if (!old_terms.length) {
			return undefined;
		}

		var clone = root.clone();

		// replace MINUS terms with their child and set child sign to -1
		var new_terms = clone.findAllChildren(function (node) {
			return (
				node.type == "MINUS" &&
				node.children[0].type != "MINUS" &&
				node.children[0].type != "PLUS"
			);
		});
		new_terms.forEach(function (minus) {
			minus.children[0].sign = -1;
			minus.replaceWith(minus.children[0]);
		});

		return {
			old_term: root.children[0],
			new_term: clone.children[0],
			type: "tree",
			hide_step: true,
		};
	}
	/**
	 * CT_extractNegativeSign	Move a negative sign in a term of a multiplicative CHAIN, frac, or BRACKETS up to its parent, if possible
	 */
	static CT_extractNegativeSign(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find negative terms within parent CHAIN(*), frac, or BRACKETS
		// sign can not be moved up to the base of an EXPONENT since "(-3)^2" would become "-(3)^2", and if serialized the "-" would apply to the whole exponent, rather than the 3
		var terms = root.findAllChildren(function (node) {
			return (
				node.sign < 0 &&
				((node.parent.type == "CHAIN" &&
					node.parent.precedence ==
						findGrammar("MULTIPLY").precedence) ||
					node.parent.value == "frac" ||
					(node.parent.type == "BRACKETS" &&
						node.parent.parent.type != "EXPONENT" &&
						node.parent.parent.value != "pow"))
			);
		});

		for (let i = 0; i < terms.length; i++) {
			var node = terms[i];
			var parent_container = node.parent;
			var clone = parent_container.clone();
			var desc = "";

			var return_data: SolverStepOutput = {
				old_term: parent_container,
				new_term: clone,
				type: "tree",
				desc: ""
			};

			// if the parent is already negative, we'll want to show the signs cancelling
			if (node.parent.sign < 0) {
				// negative signs cancel out
				node.addClass("strikethrough_input_sign");
				parent_container.addClass("strikethrough_input_sign");

				// set node and parent_container both positive
				clone.sign = 1;
				clone.children[
					parent_container.children.indexOf(node)
				].sign = 1;

				return_data.desc =
					"Two negative signs multiplied together cancel out.";
			} else if (
				parent_container.type == "CHAIN" &&
				parent_container.children.indexOf(node) === 0
			) {
				// the term is already the first element in a CHAIN
				clone.sign = -1;
				clone.children[
					parent_container.children.indexOf(node)
				].sign = 1;

				return_data.hide_step = true;
			} else {
				// move negative sign from term to CHAIN
				node.addClass("highlight_input_sign");

				// set node positive, and parent_container negative
				clone.children[
					parent_container.children.indexOf(node)
				].sign = 1;
				clone.sign = -1;
				clone.addClass("highlight_output_sign");

				// different desc messages for moving a sign up to start of CHAIN, start of frac, or out of BRACKETS
				if (parent_container.type == "FUNCTION") {
					return_data.desc =
						"Move a negative sign from within a fraction to the start of the fraction.";
				} else if (parent_container.type == "BRACKETS") {
					return_data.desc =
						"Move a negative sign out of brackets if the brackets contain a single term.";
				} else {
					return_data.desc =
						"Move a negative sign to the start of a series of multiplied or divided terms.";
				}
			}

			return return_data;
		}
	}
	/**
	 * CT_extractNumericFractions	Extract all numeric terms from a multiplicative CHAIN, or frac, to be leading coefficents at the start of a multiplicative CHAIN
	 * E.g. frac{x,4} -> "frac{1,4}*x"
	 */
	static CT_extractNumericFractions(input_value, options) {
		if (input_value.type != "tree") return undefined;
		const root = input_value.value;

		// identify any fractions containing both numeric and non-numeric terms
		const terms = root.findAllChildren(function (node) {
			if (node.value != "frac") return false;

			// check if the numerator or denominator contains numeric/non-numeric terms
			let has_numeric = false;
			let has_non_numeric = false;
			multiplicativeTermToList(node.children[0]).concat(multiplicativeTermToList(node.children[1]))
				.forEach(function(term) {
					if (term.serialize_to_text() == "1") return; // skip "1" terms

					if (term.findAllChildren("type", "VARIABLE").length) has_non_numeric = true;
					else has_numeric = true;
			});

			return has_numeric && has_non_numeric;
		});

		for (let i = 0; i < terms.length; i++) {
			const frac = terms[i];

			// extract all numeric and non-numeric terms from the fraction numerator and denominator
			const num_terms_list = multiplicativeTermToList(frac.children[0]);
			const num_numeric_terms = [];
			const num_non_numeric_terms = [];
			num_terms_list.forEach(function(term){
				if (term.serialize_to_text() == "1") return; // skip "1" terms

				if (term.findAllChildren("type", "VARIABLE").length) num_non_numeric_terms.push(term);
				else num_numeric_terms.push(term);
			});

			const den_terms_list = multiplicativeTermToList(frac.children[1]);
			const den_numeric_terms = [];
			const den_non_numeric_terms = [];
			den_terms_list.forEach(function(term){
				if (term.serialize_to_text() == "1") return; // skip "1" terms

				if (term.findAllChildren("type", "VARIABLE").length) den_non_numeric_terms.push(term);
				else den_numeric_terms.push(term);
			});

			// create new fraction(s) for numeric and non-numeric terms
			let new_chain_terms = [];

			if (den_numeric_terms.length) {
				const new_numeric_term = QETerm.create({ type: "FUNCTION", value: "frac" });
				new_numeric_term.pushChild( listToMultiplicativeTerm(num_numeric_terms) );
				new_numeric_term.pushChild( listToMultiplicativeTerm(den_numeric_terms) );

				new_chain_terms.push(new_numeric_term);
			} else {
				new_chain_terms = new_chain_terms.concat(num_numeric_terms);
			}

			if (den_non_numeric_terms.length) {
				const new_non_numeric_term = QETerm.create({ type: "FUNCTION", value: "frac" });
				new_non_numeric_term.pushChild( listToMultiplicativeTerm(num_non_numeric_terms) );
				new_non_numeric_term.pushChild( listToMultiplicativeTerm(den_non_numeric_terms) );

				new_chain_terms.push(new_non_numeric_term);
			} else {
				new_chain_terms = new_chain_terms.concat(num_non_numeric_terms);
			}

			const desc = "Pull a numeric fraction out of the term. This is mostly useful for clarity, and separating numeric and variable terms.";

			// if the fraction is within a multiplicative chain, then clone and replace the whole chain, else just the frac
			if (frac.parent.isMultiplyChain()) {
				// convert the parent chain to a term list, exclusing the frac
				let parent_chain_terms = multiplicativeTermToList(frac.parent).filter(function(term){
					return term !== frac;
				});
				// create new chain, with the new terms prepended
				const new_chain = listToMultiplicativeTerm(new_chain_terms.concat(parent_chain_terms));

				// retain sign
				new_chain.sign = frac.parent.sign;

				return {
					old_term: frac.parent,
					new_term: new_chain,
					type: "tree",
					desc: desc,
				};
			} else {
				const new_chain = listToMultiplicativeTerm(new_chain_terms);

				// retain sign
				new_chain.sign = frac.sign;

				return {
					old_term: frac,
					new_term: new_chain,
					type: "tree",
					desc: desc,
				};
			}
		}
	}
	/**
	 * CT_combineNegativeSignsWithAddSubtract	Combines negative signs with preceding ADD/SUBTRACT operators in CHAINs
	 */
	static CT_combineNegativeSignsWithAddSubtract(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find all negative signs preceded by an ADD/SUBTRACT op
		// includes multiplicative CHAINs where first element is negative
		function matchNegativeTermPrecededByAdditiveOp(node) {
			if (
				node.sign == -1 &&
				(// term is negative and part of an addtitive CHAIN
				(node.parent.type == "CHAIN" &&
					node.parent.precedence ==
						findGrammar("ADD").precedence &&
					node.parent.children.indexOf(node) > 0) ||
					// term is negative and first element of multiplicative CHAIN
					(node.parent.type == "CHAIN" &&
						node.parent.precedence ==
							findGrammar("MULTIPLY").precedence &&
						node.parent.children.indexOf(node) == 0 &&
						// ...and part of an addtitive CHAIN
						node.parent.parent.type == "CHAIN" &&
						node.parent.parent.precedence ==
							findGrammar("ADD").precedence &&
						node.parent.parent.children.indexOf(node.parent) > 0))
			) {
				return true;
			}
			return false;
		}

		var old_terms = root.findAllChildren(
			matchNegativeTermPrecededByAdditiveOp
		);
		if (!old_terms.length) {
			return undefined;
		}

		var clone = root.clone();
		var preceding_op_index, preceding_op;

		// highlight preceding operators and signs
		old_terms.forEach(function (negative_term) {
			// check if term is directly preceded by add/subtract op, or is in a multiplicative chain
			if (
				negative_term.parent.precedence ==
				findGrammar("ADD").precedence
			) {
				preceding_op_index =
					negative_term.parent.children.indexOf(negative_term) - 1;
				preceding_op =
					negative_term.parent.children[preceding_op_index];
			} else {
				// TODO: get rid of this case by relying on CT_extractNegativeSign
				// term is in multiplicative chain
				preceding_op_index =
					negative_term.parent.parent.children.indexOf(
						negative_term.parent
					) - 1;
				preceding_op =
					negative_term.parent.parent.children[preceding_op_index];
			}
			negative_term.addClass("highlight_input_sign");
			preceding_op.addClass("highlight_input");
		});

		// replace and highlight preceding operators
		var new_terms = clone.findAllChildren(
			matchNegativeTermPrecededByAdditiveOp
		);
		new_terms.forEach(function (negative_term) {
			// check if term is directly preceded by add/subtract op, or is in a multiplicative chain
			if (
				negative_term.parent.precedence ==
				findGrammar("ADD").precedence
			) {
				preceding_op_index =
					negative_term.parent.children.indexOf(negative_term) - 1;
				preceding_op =
					negative_term.parent.children[preceding_op_index];
			} else {
				// term is in multiplicative chain
				preceding_op_index =
					negative_term.parent.parent.children.indexOf(
						negative_term.parent
					) - 1;
				preceding_op =
					negative_term.parent.parent.children[preceding_op_index];
			}

			var new_op;
			if (preceding_op.type == "SUBTRACT") {
				new_op = QETerm.create({ type: "ADD" });
			} else {
				new_op = QETerm.create({ type: "SUBTRACT" });
			}
			preceding_op.replaceWith(new_op);
			new_op.addClass("highlight_output");

			// make term positive
			negative_term.sign = 1;
		});

		return {
			old_term: root.children[0],
			new_term: clone.children[0],
			type: "tree",
			desc:
				"Combine minus signs with preceding add or subtract operations.",
		};
	}
	/**
	 * CT_extractNegativeSignFromAddChain	Move a negative sign in an additive CHAIN to its parent, if the CHAIN is sorted and the first term is negative
	 */
	static CT_extractNegativeSignFromAddChain(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find negative terms within parent CHAIN(*), frac, or BRACKETS
		// sign can not be moved up to the base of an EXPONENT since "(-3)^2" would become "-(3)^2", and if serialized the "-" would apply to the whole exponent, rather than the 3
		var terms = root.findAllChildren(function (node) {
			return (
				node.sign != -1 &&
				node.isAddChain() &&
				node.children[0].sign == -1 &&
				(node.parent.value == "frac" ||
					(node.parent.type == "BRACKETS" &&
						node.parent.parent.type != "EXPONENT" &&
						node.parent.parent.value != "pow"))
			);
		});

		// NOTE: assumes the additive CHAIN is sorted, and all terms have positive sign
		for (let i = 0; i < terms.length; i++) {
			var node = terms[i];
			var node_index = node.parent.children.indexOf(node);

			// move the negation up to the parent
			var parent_clone = node.parent.clone();
			parent_clone.sign = (parent_clone.sign || 1) * -1;

			var clone = parent_clone.children[node_index];
			parent_clone.addClass("highlight_output_sign");

			// iterate over children and flip operators and term signs
			for (var j = 0; j < clone.children.length; j += 2) {
				if (j) {
					var new_op;
					if (clone.children[j - 1].type == "ADD") {
						new_op = QETerm.create({ type: "SUBTRACT" });
					} else {
						new_op = QETerm.create({ type: "ADD" });
					}

					node.children[j - 1].addClass("highlight_input");
					new_op.addClass("highlight_output");

					clone.children[j - 1].replaceWith(new_op);
				} else {
					// flip sign of first term
					clone.children[j].sign = (clone.children[j].sign || 1) * -1;

					node.children[j].addClass("highlight_input_sign");
				}
			}

			return {
				old_term: node.parent,
				new_term: parent_clone,
				type: "tree",
				desc:
					"Pull a minus sign out of an additive expression by flipping the sign of each term.",
			};
		}
	}
	/**
	 * CT_handleDivideByZero	Aborts with "undefined" result if dividing by zero.
	 */
	static CT_handleDivideByZero(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find zero term
		var terms = root.findAllChildren(function (node) {
			return node.type == "RATIONAL" && node.value == "0";
		});

		for (let i = 0; i < terms.length; i++) {
			var zero = terms[i];
			var zero_index = zero.parent.children.indexOf(zero);

			// check parent type
			if (
				zero.parent.isMultiplyChain() &&
				zero_index &&
				zero.parent.children[zero_index - 1].type == "DIVIDE"
			) {
				// divide by zero
				zero.addClass("highlight_input");
				return {
					old_term: zero.parent,
					new_term: QETerm.create({
						type: "VARIABLE",
						value: "undefined",
					}),
					type: "tree",
					desc: "Division by zero is undefined.",
					next_solution_step: undefined,
				};
			} else if (zero.parent.value == "frac" && zero_index > 0) {
				// divide by zero
				zero.addClass("highlight_input");
				return {
					old_term: zero.parent,
					new_term: QETerm.create({
						type: "VARIABLE",
						value: "undefined",
					}),
					type: "tree",
					desc: "Division by zero is undefined.",
					next_solution_step: undefined,
				};
			} else if (zero.parent.value == "nroot" && zero_index === 0) {
				// nroot{0,x} = undefined
				zero.addClass("highlight_input");
				return {
					old_term: zero.parent,
					new_term: QETerm.create({
						type: "VARIABLE",
						value: "undefined",
					}),
					type: "tree",
					desc:
						"The zeroeth root of a value is like an exponent of 1/0, and division by zero is undefined.",
					next_solution_step: undefined,
				};
			}
		}
	}
	/**
	 * CT_handleRootOfNegative	Aborts with "undefined" result if taking sqrt of a negative number.
	 */
	static CT_handleRootOfNegative(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find zero term
		var terms = root.findAllChildren(function (node) {
			return node.type == "RATIONAL" && node.sign == -1;
		});

		for (let i = 0; i < terms.length; i++) {
			var neg_num = terms[i];

			// check parent type
			if (neg_num.parent.value == "sqrt") {
				neg_num.addClass("highlight_input");
				return {
					old_term: neg_num.parent,
					new_term: QETerm.create({
						type: "VARIABLE",
						value: "undefined",
					}),
					type: "tree",
					desc: "The square root of a negative number is undefined.",
					next_solution_step: undefined,
				};
			} else if (
				neg_num.parent.value == "nroot" &&
				neg_num.parent.children[0].type == "RATIONAL" &&
				neg_num.parent.children[0].value ==
					neg_num.parent.children[0].serialize_to_text() && // integer
				neg_num.parent.children[0].value % 2 == 0 // even
			) {
				// nth root with even integer root_index
				neg_num.addClass("highlight_input");
				return {
					old_term: neg_num.parent,
					new_term: QETerm.create({
						type: "VARIABLE",
						value: "undefined",
					}),
					type: "tree",
					desc:
						"The even-numbered-integer root of a negative number is undefined.",
					next_solution_step: undefined,
				};
			}
		}
	}
	/**
	 * CT_combineZero	Replace multiplicative zero terms, evaluate power/root zero terms, and remove additive zero terms.
	 */
	static CT_combineZero(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find zero term
		var terms = root.findAllChildren(function (node) {
			return node.type == "RATIONAL" && node.value == "0";
		});

		for (let i = 0; i < terms.length; i++) {
			var zero = terms[i];
			var zero_index = zero.parent.children.indexOf(zero);

			// check parent type
			if (zero.parent.type == "CHAIN") {
				if (zero.parent.isMultiplyChain()) {
					if (
						zero_index &&
						zero.parent.children[zero_index - 1].type == "DIVIDE"
					) {
						// divide by zero
						return {
							old_term: zero.parent,
							new_term: QETerm.create({
								type: "VARIABLE",
								value: "undefined",
							}),
							type: "tree",
							desc: "Division by zero is undefined.",
							next_solution_step: undefined,
						};
					} else {
						// replace entire CHAIN with zero
						var new_term = zero.clone();

						zero.parent.addClass("strikethrough_input");
						new_term.addClass("highlight_output");

						return {
							old_term: zero.parent,
							new_term: new_term,
							type: "tree",
							desc: "Multiplying zero by anything equals zero.",
						};
					}
				} else if (zero.parent.isAddChain()) {
					// remove zero and preceding/following op
					var clone = zero.parent.clone();

					if (zero_index > 0) {
						// zero is not first term. Safely chop it and preceding op
						clone.children.splice(zero_index - 1, 2);

						zero.parent.children[zero_index].addClass(
							"strikethrough_input"
						);
					} else {
						// Zero is first term. If following op is SUBTRACT, flip sign of following term
						if (
							zero.parent.children[zero_index + 1].type ==
							"SUBTRACT"
						) {
							// flip sign of next term
							clone.children[zero_index + 2].sign =
								(clone.children[zero_index + 2].sign || 1) * -1;
						}

						clone.children.splice(zero_index, 2);
						zero.parent.children[zero_index].addClass(
							"strikethrough_input"
						);
					}

					// single-element CHAINs are converted to single values
					clone = clone.unchainChildIfSingle();

					// replace entire CHAIN with zero
					return {
						old_term: zero.parent,
						new_term: clone,
						type: "tree",
						desc: "Adding zero does not change the value.",
					};
				}
			} else if (zero.parent.value == "frac") {
				// in denominator -> undefined
				// in numerator -> zero entire frac
				if (zero_index > 0) {
					// divide by zero
					return {
						old_term: zero.parent,
						new_term: QETerm.create({
							type: "VARIABLE",
							value: "undefined",
						}),
						type: "tree",
						desc: "Division by zero is undefined.",
						next_solution_step: undefined,
					};
				} else {
					// replace entire fraction with "0"
					const new_term = zero.clone();

					zero.parent.addClass("strikethrough_input");
					new_term.addClass("highlight_output");

					return {
						old_term: zero.parent,
						new_term: new_term,
						type: "tree",
						desc:
							"A fraction with a numerator of zero equals zero.",
					};
				}
			} else if (
				zero.parent.type == "EXPONENT" ||
				zero.parent.value == "pow"
			) {
				// 0^x = 0
				// x^0 = 1
				if (zero_index == 0) {
					// 0^x = 0
					const new_term = zero.clone();

					zero.parent.addClass("highlight_input");
					new_term.addClass("highlight_output");

					return {
						old_term: zero.parent,
						new_term: new_term,
						type: "tree",
						desc: "The exponentiation of zero is zero.",
					};
				} else {
					// x^0 = 1
					const new_term = QETerm.create({ type: "RATIONAL", value: "1" });

					zero.parent.addClass("highlight_input");
					new_term.addClass("highlight_output");

					// retain sign
					new_term.sign = zero.parent.sign;

					return {
						old_term: zero.parent,
						new_term: new_term,
						type: "tree",
						desc:
							'Any non-zero value to the power of zero equals "1".',
					};
				}
			} else if (zero.parent.value == "sqrt") {
				// sqrt{0} = 0
				const new_term = zero.clone();

				zero.parent.addClass("highlight_input");
				new_term.addClass("highlight_output");

				return {
					old_term: zero.parent,
					new_term: new_term,
					type: "tree",
					desc: "The square root of zero is zero.",
				};
			} else if (zero.parent.value == "nroot") {
				// nroot{0,x} = undefined
				// nroot{x,0} = 0
				if (zero_index == 0) {
					// nroot{0,x} = undefined
					return {
						old_term: zero.parent,
						new_term: QETerm.create({
							type: "VARIABLE",
							value: "undefined",
						}),
						type: "tree",
						desc:
							"The zeroeth root of a value is like an exponent of 1/0, and division by zero is undefined.",
						next_solution_step: undefined,
					};
				} else {
					// nroot{x,0} = 0
					var new_term = zero.clone();

					zero.parent.addClass("highlight_input");
					new_term.addClass("highlight_output");

					return {
						old_term: zero.parent,
						new_term: new_term,
						type: "tree",
						desc: "The nth root of zero is zero.",
					};
				}
			}
		}
	}
	/**
	 * CT_combineOne	Remove multiplicative one terms, simplify power/root one terms.
	 */
	static CT_combineOne(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find zero term
		var terms = root.findAllChildren(function (node) {
			return node.type == "RATIONAL" && node.value == "1";
		});

		for (let i = 0; i < terms.length; i++) {
			var one = terms[i];
			var one_index = one.parent.children.indexOf(one);

			// check parent type
			if (one.parent.isMultiplyChain()) {
				// x*1 -> x, x/1 -> x, 1*x -> x, 1/x -> no change
				if (
					one_index ||
					(!one_index &&
						one.parent.children[one_index + 1].type == "MULTIPLY")
				) {
					var clone = one.parent.clone();

					// preserve sign
					clone.sign = (clone.sign || 1) * (one.sign || 1);

					var desc = "";
					if (
						!one_index &&
						one.parent.children[one_index + 1].type == "MULTIPLY"
					) {
						// 1*x -> x
						desc = "One multiplied by any value equals that value.";

						// remove one and following op
						clone.children.splice(one_index, 2);
					} else if (
						one_index &&
						one.parent.children[one_index - 1].type == "MULTIPLY"
					) {
						// x*1 -> x
						desc = "One multiplied by any value equals that value.";

						// remove one and preceding op
						clone.children.splice(one_index - 1, 2);
					} else if (
						one_index &&
						one.parent.children[one_index - 1].type == "DIVIDE"
					) {
						// x/1 -> x
						desc = "One divided by any value equals that value.";

						// remove one and preceding op
						clone.children.splice(one_index - 1, 2);
					}

					// single-element CHAINs are converted to single values
					clone = clone.unchainChildIfSingle();

					// highlight
					one.parent.children[one_index].addClass(
						"strikethrough_input"
					);
					one.parent.addClass("highlight_input");
					clone.addClass("highlight_output");

					return {
						old_term: one.parent,
						new_term: clone,
						type: "tree",
						desc: desc,
					};
				}
			} else if (one.parent.value == "frac") {
				// in denominator -> replace term with numerator
				if (one_index > 0) {
					var clone = one.parent.children[0].clone();

					// preserve sign
					clone.sign = (clone.sign || 1) * (one.parent.sign || 1);

					one.parent.children[1].addClass("strikethrough_input");

					return {
						old_term: one.parent,
						new_term: clone,
						type: "tree",
						desc: "Simplify a fraction with 1 as the denominator.",
					};
				}
			} else if (
				one.parent.type == "EXPONENT" ||
				one.parent.value == "pow"
			) {
				// 1^x = 1
				// x^1 = x
				if (one_index == 0) {
					// 1^x = 1
					if (one.sign < 0) {
						// base is NEGATIVE one
						var exponent = one.parent.children[1];
						if (
							exponent.type == "RATIONAL" &&
							parseInt(exponent.value) == exponent.value
						) {
							// exponent is an integer
							const clone = QETerm.create({
								type: "RATIONAL",
								value: "1",
							});

							// preserve power sign
							clone.sign = (clone.sign || 1) * (one.parent.sign || 1);

							// check if it's even or odd
							let desc;
							if (parseInt(exponent.value) % 2) {
								// exponent is an odd integer - flip sign
								clone.sign = (clone.sign || 1) * -1;
								desc = "Negative one raised to an ODD integer power is negative one.";
							} else {
								// exponent is an even integer
								desc = "Negative one raised to an EVEN integer power is one.";
							}

							one.parent.addClass("highlight_input");
							clone.addClass("highlight_output");

							return {
								old_term: one.parent,
								new_term: clone,
								type: "tree",
								desc: desc,
							};
						}
					} else {
						// base is POSITIVE one
						const clone = one.parent.children[0].clone();

						// preserve power sign
						clone.sign = (clone.sign || 1) * (one.parent.sign || 1);

						one.parent.addClass("highlight_input");
						clone.addClass("highlight_output");

						return {
							old_term: one.parent,
							new_term: clone,
							type: "tree",
							desc: "Positive one raised to any power is one.",
						};
					}
				} else {
					// x^1 = x
					if (one.sign < 0) {
						// handle in CT_invertNegativeExponent
					} else {
						const clone = one.parent.children[0].clone();

						// preserve sign
						clone.sign = (clone.sign || 1) * (one.parent.sign || 1);

						one.parent.addClass("highlight_input");
						clone.addClass("highlight_output");

						return {
							old_term: one.parent,
							new_term: clone,
							type: "tree",
							desc:
								"Any value to the power of one equals itself.",
						};
					}
				}
			} else if (one.parent.value == "sqrt") {
				// sqrt{1} = 1
				// sqrt{-1} = undefined -> handle in root simplification
				if (one.sign < 0) {
					// handle in CT_simplifyRoot
				} else {
					const clone = one.parent.children[0].clone();

					// preserve sign
					clone.sign = (clone.sign || 1) * (one.parent.sign || 1);

					one.parent.addClass("highlight_input");
					clone.addClass("highlight_output");

					return {
						old_term: one.parent,
						new_term: clone,
						type: "tree",
						desc: "The root of positive one is one.",
					};
				}
			} else if (one.parent.value == "nroot") {
				// nroot{1,x} = x
				// nroot{-1,x} = 1/x -> handle in CT_invertNegativeRoot
				// nroot{x,1} = x
				// nroot{x,-1} = x -> check if x is integer
				if (one_index == 0) {
					if (one.sign < 0) {
						// nroot{-1,x} = 1/x handle in CT_invertNegativeRoot
					} else {
						// nroot{1,x} = x
						const clone = one.parent.children[1].clone();

						// preserve sign
						clone.sign = (clone.sign || 1) * (one.parent.sign || 1);

						one.parent.addClass("highlight_input");
						clone.addClass("highlight_output");

						return {
							old_term: one.parent,
							new_term: clone,
							type: "tree",
							desc: "The first root of any value equals itself.",
						};
					}
				} else {
					if (one.sign < 0) {
						// nroot{x,-1} -> value is NEGATIVE one
						var root_index = one.parent.children[0];
						if (
							root_index.type == "RATIONAL" &&
							parseInt(root_index.value) == root_index.value
						) {
							// root_index is an integer
							if (parseInt(root_index.value) % 2) {
								// ODD index root of -1
								const clone = one.parent.children[1].clone();

								// preserve sign
								clone.sign =
									(clone.sign || 1) * (one.parent.sign || 1);

								one.parent.addClass("highlight_input");
								clone.addClass("highlight_output");

								return {
									old_term: one.parent,
									new_term: clone,
									type: "tree",
									desc:
										"The ODD integer index root of negative one is negative one.",
								};
							} else {
								// EVEN index root of -1 -> handle in CT_simplifyRoot
							}
						}
					} else {
						// nroot{x,1} = x
						// value is POSITIVE one
						const clone = one.parent.children[1].clone();

						// preserve root sign
						clone.sign = (clone.sign || 1) * (one.parent.sign || 1);

						one.parent.addClass("highlight_input");
						clone.addClass("highlight_output");

						return {
							old_term: one.parent,
							new_term: clone,
							type: "tree",
							desc: "Any root of positive one is one.",
						};
					}
				}
			}
		}
	}
	/**
	 * CT_removeTermBrackets	Removes brackets from any single, positive term, or single, negative term if parent is not an exponent or postfix op - e.g. "!"
	 */
	static CT_removeTermBrackets(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find BRACKETS with non-CHAIN child
		var terms = root.findAllChildren(function (node) {
			return node.type == "BRACKETS" && node.children[0].type != "CHAIN";
		});

		for (let i = 0; i < terms.length; i++) {
			var brackets = terms[i];
			var child = brackets.children[0];

			if (child.sign < 0) {
				// let negative signs be pulled out by other steps
				continue;
			}

			const clone = child.clone();

			// preserve brackets sign
			clone.sign = (brackets.sign || 1) * (clone.sign || 1);

			brackets.addClass("highlight_input");
			clone.addClass("highlight_output");

			return {
				old_term: brackets,
				new_term: clone,
				type: "tree",
				desc: "Remove unnecessary brackets around a term.",
			};
		}
	}
	/**
	 * CT_removeChainBrackets	Removes brackets from a CHAIN if parent is not a multiplicative CHAIN, exponent or postfix op - e.g. "!"
	 * - if brackets sign is -1 and there is no preceding additive op, apply to all operands of an additive CHAIN, or first element of a multiplicative CHAIN
	 */
	static CT_removeChainBrackets(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find bracketed CHAIN with CHAIN parent of equal precedence: e.g. "1+(2+x)", "1-(2+x)", "2*(3*x)"
		//  or a CHAIN where the parent allows it, e.g. "frac{1,(x+2)}"
		//  or a negated additive CHAIN: e.g. "-(a+b)"
		var terms = root.findAllChildren(function (node) {
			if (node.type != "BRACKETS" || node.children[0].type != "CHAIN") {
				return false;
			}

			if (
				// equal precedence CHAIN
				node.parent.type == "CHAIN" &&
				node.children[0].precedence == node.parent.precedence
			) {
				return true;
			}
			if (
				// any CHAIN where parent is frac, comparator, or root
				node.parent.type == "ROOT" ||
				node.parent.type == "FUNCTION" ||
				node.parent.type == "EXPONENT" ||
				node.parent.isComparatorChain()
			) {
				return true;
			}
		});

		for (let i = 0; i < terms.length; i++) {
			var brackets = terms[i];
			var child_chain = brackets.children[0];
			var parent_chain = brackets.parent;
			var child_index = parent_chain.children.indexOf(brackets);

			if (parent_chain.type != "CHAIN") {
				// parent is ROOT, a FUNCTION, or EXPONENT
				var child_clone = child_chain.clone();
				var desc = "Remove unnecessary brackets around an expression.";
				let hide_step = false;

				if (brackets.sign < 0 && child_chain.isAddChain()) {
					// Negated additive CHAIN, e.g. "-(a+b)" -> "-a-b"
					// negate each term
					child_clone.sign = 1;

					for (var j = 0; j < child_clone.children.length; j++) {
						if (j % 2) {
							// operator
							if (child_clone.children[j].type == "ADD") {
								child_clone.children[j].replaceWith(
									QETerm.create({ type: "SUBTRACT" })
								);
							} else {
								child_clone.children[j].replaceWith(
									QETerm.create({ type: "ADD" })
								);
							}
						} else {
							// operand
							if (!j) {
								// first operand
								child_clone.children[j].sign =
									(child_clone.children[j].sign || 1) * -1;
							} else if (child_clone.children[j].sign < 0) {
								// negative term: combine sign with preceding operator
								child_clone.children[j].sign = 1;
								if (child_clone.children[j - 1].type == "ADD") {
									child_clone.children[j - 1].replaceWith(
										QETerm.create({ type: "SUBTRACT" })
									);
								} else {
									child_clone.children[j - 1].replaceWith(
										QETerm.create({ type: "ADD" })
									);
								}
							}
						}
						child_clone.children[j].addClass("highlight_output");
					}
					desc =
						"Remove brackets around a subtracted expression by negating each of the contained operations.";
				} else if (brackets.sign < 0 && child_chain.isMultiplyChain()) {
					// negative brackets around a multiply chain negate the sign of the chain, e.g. "-(2x)" -> "-2x"
					child_clone.negate();
				} else {
					// if brackets are being used in a context where they are automatically displayed (e.g. pow{x+1,2}, sin{5-y})
					//    then remove them silently
					hide_step = true;
				}

				child_chain.addClass("highlight_input");

				return {
					old_term: brackets,
					new_term: child_clone,
					type: "tree",
					desc: desc,
					hide_step: hide_step,
				};
			} else if (parent_chain.isAddChain()) {
				// ADD/ADD chains, e.g. "2+(a+b)" -> "2+a+b", "2-(a-b)" -> "2-a+b"
				var parent_clone = parent_chain.clone();
				var child_clone = child_chain.clone();

				// insert child chain in-place
				parent_clone.replaceNthChildWith(
					child_index,
					child_clone.children
				);

				// flip child chain term signs if child_chain negative, or preceded by SUBTRACT
				let desc;
				if (
					child_chain.sign < 0 ||
					(child_index &&
						parent_chain.children[child_index - 1].type ==
							"SUBTRACT")
				) {
					// chain negative or preceded by SUBTRACT. Need to flip all signs and operators
					for (var j = 0; j < child_clone.children.length; j++) {
						if (j % 2) {
							// operator
							if (
								parent_clone.children[child_index + j].type ==
								"ADD"
							) {
								parent_clone.children[
									child_index + j
								].replaceWith(QETerm.create({ type: "SUBTRACT" }));
							} else {
								parent_clone.children[
									child_index + j
								].replaceWith(QETerm.create({ type: "ADD" }));
							}
						} else {
							// operand
							if (!(child_index + j)) {
								// first operand
								parent_clone.children[child_index + j].sign =
									(parent_clone.children[child_index + j]
										.sign || 1) * -1;
							} else if (
								parent_clone.children[child_index + j].sign < 0
							) {
								// negative term: combine sign with preceding operator
								parent_clone.children[child_index + j].sign = 1;
								if (
									parent_clone.children[child_index + j - 1]
										.type == "ADD"
								) {
									parent_clone.children[
										child_index + j - 1
									].replaceWith(
										QETerm.create({ type: "SUBTRACT" })
									);
								} else {
									parent_clone.children[
										child_index + j - 1
									].replaceWith(QETerm.create({ type: "ADD" }));
								}
							}
						}
						parent_clone.children[child_index + j].addClass(
							"highlight_output"
						);
					}
					desc =
						"Remove brackets around a subtracted expression by negating each of the contained operations.";
				} else {
					// highlight inserted terms
					for (var j = 0; j < child_clone.children.length; j++) {
						parent_clone.children[child_index + j].addClass(
							"highlight_output"
						);
					}
					desc = "Remove unnecessary brackets.";
				}

				child_chain.addClass("highlight_input");

				return {
					old_term: parent_chain,
					new_term: parent_clone,
					type: "tree",
					desc: desc,
				};
			} else if (parent_chain.isMultiplyChain()) {
				// check that term is not preceded by DIVIDE -> handle in other steps
				if (
					child_index > 0 &&
					parent_chain.children[child_index - 1].type == "DIVIDE"
				) {
					continue;
				}

				// MULTIPLY/MULTIPLY chains, e.g. "2*(a*b)"-> "2*a*b"
				var parent_clone = parent_chain.clone();
				var child_clone = child_chain.clone();

				// insert child chain in-place
				parent_clone.replaceNthChildWith(
					child_index,
					child_clone.children
				);

				// preserve child chain sign -> should have already been extracted by other steps
				if (child_clone.sign < 0) {
					parent_clone.sign = (parent_clone.sign || 1) * -1;
				}

				// highlight inserted terms
				for (var j = 0; j < child_clone.children.length; j++) {
					parent_clone.children[child_index + j].addClass(
						"highlight_output"
					);
				}

				child_chain.addClass("highlight_input");

				return {
					old_term: parent_chain,
					new_term: parent_clone,
					type: "tree",
					desc: "Remove unnecessary brackets.",
				};
			}
		}
	}
	/**
	 * CT_invertNegativeExponent	Make a negative exponent positive by moving it to the denominator of a fraction
	 */
	static CT_invertNegativeExponent(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find pow|EXPONENT that has a negative exponent
		var terms = root.findAllChildren(function (node) {
			if (
				(node.type == "EXPONENT" || node.value == "pow") &&
				node.children[1].sign < 0
			) {
				return true;
			}
		});

		for (let i = 0; i < terms.length; i++) {
			var power = terms[i];
			var base = power.children[0];
			var exp = power.children[1];

			// exponent unchanged, but sign flipped
			var new_exp = exp.clone();
			new_exp.sign = 1;

			// flip a frac base, or create an inverted frac for non-frac base
			var new_frac = QETerm.create({ type: "FUNCTION", value: "frac" });
			if (base.value == "frac") {
				new_frac.pushChild(base.children[1].clone());
				new_frac.pushChild(base.children[0].clone());

				// preserve frac sign
				new_frac.sign = (new_frac.sign || 1) * (base.sign || 1);
			} else {
				new_frac.pushChild( QETerm.create({ type: "RATIONAL", value: "1" }) );
				new_frac.pushChild(base.clone());
			}

			var new_power = QETerm.create({ type: "FUNCTION", value: "pow" });
			new_power.pushChild(new_frac);
			new_power.pushChild(new_exp);

			// retain power sign
			new_power.sign = power.sign;

			// highlighting
			exp.addClass("highlight_input");
			new_exp.addClass("highlight_output");

			return {
				old_term: power,
				new_term: new_power,
				type: "tree",
				desc:
					"Make a negative exponent positive by inverting the base.",
			};
		}
	}
	/**
	 * CT_invertNegativeRoot	Make a negative root positive by moving it to the denominator of a fraction
	 */
	static CT_invertNegativeRoot(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find pow|EXPONENT that has a negative exponent
		var terms = root.findAllChildren(function (node) {
			if (node.value == "nroot" && node.children[0].sign < 0) {
				return true;
			}
		});

		for (let i = 0; i < terms.length; i++) {
			var nroot = terms[i];
			var root_index = nroot.children[0];
			var base = nroot.children[1];

			// root_index unchanged, but sign flipped
			var new_root_index = root_index.clone();
			new_root_index.sign = 1;

			// flip a frac base, or create an inverted frac for non-frac base
			var new_frac = QETerm.create({ type: "FUNCTION", value: "frac" });
			if (base.value == "frac") {
				new_frac.pushChild(base.children[1].clone());
				new_frac.pushChild(base.children[0].clone());

				// preserve frac sign
				new_frac.sign = (new_frac.sign || 1) * (base.sign || 1);
			} else {
				new_frac.pushChild(
					QETerm.create({ type: "RATIONAL", value: "1" })
				);
				new_frac.pushChild(base.clone());
			}

			var new_nroot = QETerm.create({ type: "FUNCTION", value: "nroot" });
			new_nroot.pushChild(new_root_index);
			new_nroot.pushChild(new_frac);

			// highlighting
			root_index.addClass("highlight_input");
			new_root_index.addClass("highlight_output");

			return {
				old_term: nroot,
				new_term: new_nroot,
				type: "tree",
				desc:
					"Make a negative root index root positive by inverting the base.",
			};
		}
	}
	/**
	 * CT_simplifyPower	Simplify a power by raising each base term by the exponent (a*b)^c -> a^c * b^c
	 */
	static CT_simplifyPower(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find pow|EXPONENT that has a positive integer exponent and a RATIONAL, root, or multiplicative CHAIN base
		var terms = root.findAllChildren(function (node) {
			if (
				(node.type == "EXPONENT" || node.value == "pow") &&
				!(node.children[1].sign < 0) // handle negative exponents by inverting in CT_invertNegativeExponent
			) {
				return true;
			}
		});

		if (!terms.length) {
			return undefined;
		}

		for (let i = 0; i < terms.length; i++) {
			var power = terms[i];
			var old_term = power;
			var base = power.children[0];
			var exp = power.children[1];

			// build list of base terms
			var base_terms;
			if (base.type == "BRACKETS") {
				base_terms = multiplicativeTermToList(base.children[0]);
			} else {
				base_terms = multiplicativeTermToList(base);
			}
			if (base_terms === undefined) {
				return undefined;
			}

			var extracted_terms = []; // extracted_terms here are terms that were simplified (power of rational, power, or root)
			var leftover_terms = []; // everything else

			// iterate over base_terms and apply the power
			for (var j = 0; j < base_terms.length; j++) {
				var base_term = base_terms[j];

				if (
					base_term.type == "RATIONAL" &&
					exp.type == "RATIONAL" &&
					exp.Q.den == 1
				) {
					// create new term, and retain original base_term type
					var new_Q = new QEQ(
						Math.pow(base_term.Q.num, exp.Q.num),
						Math.pow(base_term.Q.den, exp.Q.num)
					);
					var extracted_term = new_Q.to_term({
						value_type: base_term.attr("value_type"),
					});

					// handle sign
					if (base_term.sign < 0) {
						if (exp.Q.num % 2) {
							// odd integer exponent
							extracted_term.sign = -1;
						} else {
							// even integer exponent
							extracted_term.sign = 1;
						}
					}

					extracted_terms.push(extracted_term);
				} else if (
					base_term.type == "EXPONENT" ||
					base_term.value == "pow"
				) {
					// new term has same base as the base_term power base
					var new_base = base_term.children[0].clone();

					// get the exponents and check if they are both integers
					var outer_exp = exp;
					var base_term_exp;
					if (outer_exp.type == "RATIONAL" && outer_exp.Q.den == 1) {
						// integer
						outer_exp = outer_exp.Q.num;
					} else {
						outer_exp = outer_exp.children[1];
					}

					if (
						base_term.children[1].type == "RATIONAL" &&
						base_term.children[1].Q.den == 1
					) {
						// integer
						base_term_exp = base_term.children[1].Q.num;
					} else {
						base_term_exp = base_term.children[1];
					}

					// now combine term exponents
					var new_exp;
					if (
						typeof outer_exp == "number" &&
						typeof base_term_exp == "number"
					) {
						new_exp = QETerm.create({
							type: "RATIONAL",
							value: (outer_exp * base_term_exp).toString(),
						});
						new_exp.sign =
							(exp.sign || 1) * (base_term.children[1].sign || 1);
					} else {
						new_exp = QETerm.create({
							type: "CHAIN",
							precedence: findGrammar("MULTIPLY").precedence,
						});
						new_exp.pushChild(base_term.children[1].clone());
						new_exp.pushChild(
							QETerm.create({ type: "MULTIPLY", value: "" })
						);
						new_exp.pushChild(exp.clone());
					}

					var extracted_term = QETerm.create({
						type: "FUNCTION",
						value: "pow",
					});
					extracted_term.pushChild(new_base);
					extracted_term.pushChild(new_exp);

					extracted_terms.push(extracted_term);
				} else if (
					(base_term.value == "sqrt" &&
						exp.type == "RATIONAL" &&
						exp.Q.den == 1 &&
						!(exp.Q.num % 2)) ||
					(base_term.value == "nroot" &&
						base_term.children[0].type == "RATIONAL" &&
						base_term.children[0].Q.den == 1 &&
						exp.type == "RATIONAL" &&
						exp.Q.den == 1 &&
						!(exp.Q.num % base_term.children[0].Q.num))
				) {
					// base_term is a root with a root_index that divides evenly into the outer exponent
					var new_base, root_index;
					if (base_term.value == "nroot") {
						root_index = base_term.children[0].Q.num;
						new_base = base_term.children[1].clone();
					} else {
						root_index = 2;
						new_base = base_term.children[0].clone();
					}

					// new exponent after the root has been raised to the power
					var new_exp_value = exp.Q.num / root_index;

					let extracted_term;
					if (new_exp_value == 1) {
						extracted_term = new_base;
					} else {
						// result will be a power
						const new_exp = QETerm.create({
							type: "RATIONAL",
							value: new_exp_value.toString(),
						});
						if (
							base_term.value == "nroot" &&
							base_term.children[0].sign < 0
						) {
							// negative root index
							new_exp.sign = base_term.children[0].sign;
						}

						extracted_term = QETerm.create({
							type: "FUNCTION",
							value: "pow",
						});
						extracted_term.pushChild(new_base);
						extracted_term.pushChild(new_exp);
					}

					extracted_terms.push(extracted_term);
				} else {
					var leftover_term = QETerm.create({
						type: "FUNCTION",
						value: "pow",
					});
					leftover_term.pushChild(base_term.clone());
					leftover_term.pushChild(exp.clone());
					leftover_terms.push(leftover_term);
				}
			}

			if (!extracted_terms.length && !base.isMultiplyChain()) {
				// nothing was extracted/simplified, and base was not a multiplicative CHAIN
				continue;
			}

			// if base is a multiplicative CHAIN, retain sign
			if (
				base.sign < 0 &&
				(base.isMultiplyChain() || base.type == "BRACKETS") &&
				(exp.type != "RATIONAL" || exp.Q.num % 2)
			) {
				// first look for a rational extracted term
				var rational_extracted_terms = extracted_terms.filter(function (term) {
					return term.type == "RATIONAL"; // || term.value == "pow" && ...
				});

				if (rational_extracted_terms.length) {
					// apply negative sign to first rational term of extracted_terms
					rational_extracted_terms[0].sign = (rational_extracted_terms[0].sign || 1) * -1;
				} else {
					// else look for a rational-based leftover power
					var rational_base_leftover_terms = leftover_terms.filter(
						function (term) {
							return (
								term.value == "pow" &&
								term.children[0].type == "RATIONAL"
							);
						}
					);
					if (rational_base_leftover_terms.length) {
						rational_base_leftover_terms[0].children[0].sign =
							(rational_base_leftover_terms[0].children[0].sign ||
								1) * -1;
					} else {
						// no rational to apply sign to, leave behind a "-1" term instead
						var leftover_term = QETerm.create({
							type: "FUNCTION",
							value: "pow",
						});
						leftover_term.pushChild(
							QETerm.create({ type: "RATIONAL", value: "1" })
						);
						leftover_term.children[0].sign = -1;
						leftover_term.pushChild(exp.clone());
						leftover_terms.push(leftover_term);
					}
				}
			}

			// construct new chain for extracted_terms
			var new_term = QETerm.create({
				type: "CHAIN",
				precedence: findGrammar("MULTIPLY").precedence,
			});

			// retain sign of original power
			if (power.sign < 0) {
				new_term.sign = (new_term.sign || 1) * -1;
			}

			for (let j = 0; j < extracted_terms.length; j++) {
				if (j > 0) {
					new_term.pushChild(
						QETerm.create({ type: "MULTIPLY", value: "" })
					);
				}

				if (extracted_terms[j].isMultiplyChain()) {
					// extracted term already a multiplicative CHAIN. Unwind it
					for (let k = 0; k < extracted_terms[j].children.length; k++) {
						new_term.pushChild(extracted_terms[j].children[k]);
					}

					// retain sign of extracted CHAIN
					if (extracted_terms[j].sign < 0) {
						new_term.sign = (new_term.sign || 1) * -1;
					}
				} else {
					new_term.pushChild(extracted_terms[j]);
				}
			}

			if (leftover_terms.length) {
				for (let j = 0; j < leftover_terms.length; j++) {
					if (j > 0 || extracted_terms.length) {
						new_term.pushChild(
							QETerm.create({ type: "MULTIPLY", value: "" })
						);
					}
					new_term.pushChild(leftover_terms[j]);
				}
			}

			// there may have only been one item in the chain
			new_term = new_term.unchainChildIfSingle();

			// check if power was in a multiplicative CHAIN
			if (power.parent.isMultiplyChain() && new_term.isMultiplyChain()) {
				// replace parent CHAIN with a cloned CHAIN containing new_term CHAIN children
				var parent_chain = power.parent;
				var chain_index = parent_chain.children.indexOf(power);

				var new_chain = QETerm.create({
					type: "CHAIN",
					precedence: findGrammar("MULTIPLY").precedence,
				});
				for (let j = 0; j < parent_chain.children.length; j++) {
					if (j == chain_index) {
						// add new term chain terms
						for (let k = 0; k < new_term.children.length; k++) {
							new_chain.pushChild(new_term.children[k]);
						}
					} else {
						new_chain.pushChild(parent_chain.children[j].clone());
					}
				}

				new_term = new_chain;
				old_term = parent_chain;
			}

			old_term.addClass("highlight_input");
			new_term.addClass("highlight_output");

			return {
				old_term: old_term,
				new_term: new_term,
				type: "tree",
				desc:
					"Simplify a power by raising each base term by the exponent <katex>(a \\cdot b)^c = a^c \\cdot b^c</katex>",
			};
		}
	}
	/**
	 * CT_simplifyRoot - Simplify a root by pulling out common factors
	 */
	static CT_simplifyRoot(input_value, options) {
		if (input_value.type != "tree") return undefined;
		const root = input_value.value;

		// find roots
		const terms = root.findAllChildren(function (node) {
			if (node.value == "sqrt" || node.value == "nroot") {
				return true;
			}
		});

		if (!terms.length) {
			return undefined;
		}

		// characterize tree - must be performed each time, since a step may have changed a term
		root.characterizeNode();

		for (let i = 0; i < terms.length; i++) {
			const nroot = terms[i];
			let old_term = nroot;

			// check that root is not in a multiplicative CHAIN and preceded by DIVIDE - handle DIVIDEs in other steps
			if (nroot.parent.isMultiplyChain()) {
				const chain_index = nroot.parent.children.indexOf(nroot);
				if (
					chain_index > 0 &&
					nroot.parent.children[chain_index - 1].type == "DIVIDE"
				) {
					continue;
				}
			}

			// get root index
			let root_index, base;
			if (nroot.value == "nroot") {
				if (
					nroot.children[0].type == "RATIONAL" &&
					nroot.children[0].Q.den == 1 &&
					nroot.children[0].Q.num > 0 &&
					!(nroot.children[0].sign < 0)
				) {
					// nroot has positive integer root index
					root_index = nroot.children[0].Q.num;
					base = nroot.children[1];
				} else {
					continue;
				}
			} else {
				// sqrt
				root_index = 2;
				base = nroot.children[0];
			}

			// build list of base terms
			const base_terms = multiplicativeTermToList(base);
			if (base_terms === undefined) {
				return undefined;
			}

			// build lists of extracted and leftover terms. Used to construct resulting term
			const extracted_terms = [];
			const leftover_terms = [];

			// inspect base term characterizations for common factors
			for (let j = 0; j < base_terms.length; j++) {
				const base_term = base_terms[j];

				if (base_term.type == "RATIONAL") {
					const num_primes = base_term.characterization.factors.num;
					const den_primes = base_term.characterization.factors.den;

					// build extracted and leftover RATIONALs
					let new_Q_num = 1;
					let new_Q_den = 1;
					let leftover_Q_num = base_term.Q.num;
					let leftover_Q_den = base_term.Q.den;

					for (let prime in num_primes) {
						if (num_primes[prime] >= root_index) {
							let extracted_exp = Math.trunc(num_primes[prime] / root_index);
							new_Q_num *= Math.pow(Number(prime), extracted_exp);
							leftover_Q_num /= Math.pow(Number(prime), extracted_exp * root_index);
						}
					}
					for (let prime in den_primes) {
						if (den_primes[prime] >= root_index) {
							let extracted_exp = Math.trunc(den_primes[prime] / root_index);
							new_Q_den *= Math.pow(Number(prime), extracted_exp);
							leftover_Q_den /= Math.pow(Number(prime), extracted_exp * root_index);
						}
					}

					// create extracted term if anything was extracted
					if (new_Q_num != 1 || new_Q_den != 1) {
						const new_Q = new QEQ(new_Q_num, new_Q_den);
						const extracted_term = new_Q.to_term({
							value_type: base_term.attr("value_type"),
						});

						// extract negative sign if root_index odd
						if (base_term.sign < 0 && root_index % 2) {
							extracted_term.sign = base_term.sign;
						}

						extracted_term.addClass("highlight_output");
						extracted_terms.push(extracted_term);
						base_term.addClass("highlight_input");
					}

					// create leftover term if anything left
					if (
						leftover_Q_num != 1 ||
						leftover_Q_den != 1 ||
						(base_term.sign < 0 && !(root_index % 2)) // term negative, and root_index even
					) {
						// leftover factors remain
						const leftover_Q = new QEQ(leftover_Q_num, leftover_Q_den);
						const leftover_term = leftover_Q.to_term({
							value_type: base_term.attr("value_type"),
						});

						if (!(base_term.sign < 0 && root_index % 2)) {
							// sign was not extracted - preserve it on the leftover term
							leftover_term.sign = base_term.sign;
						}

						if (new_Q_num != 1 || new_Q_den != 1) {
							leftover_term.addClass("highlight_output");
						}
						leftover_terms.push(leftover_term);
					} else {
						// term extracted entirely - add strikethrough
						base_term.addClass("strikethrough_input");
					}
				} else if (
					(base_term.type == "EXPONENT" ||
						base_term.value == "pow") &&
					// check that exponent is a posiive integer and is >= root_index
					base_term.children[1].type == "RATIONAL" &&
					base_term.children[1].Q.den == 1 &&
					base_term.children[1].Q.num >= root_index &&
					!(base_term.children[1].sign < 0)
				) {
					let new_exp_value = Math.trunc(base_term.children[1].Q.num / root_index);
					let leftover_exp_value = base_term.children[1].Q.num % root_index;

					// create extracted term
					if (new_exp_value > 1) {
						const extracted_term = QETerm.create({
							type: "FUNCTION",
							value: "pow",
						});
						const new_exp = QETerm.create({
							type: "RATIONAL",
							value: new_exp_value.toString(),
						});

						extracted_term.pushChild(base_term.children[0].clone());
						extracted_term.pushChild(new_exp);

						// extract negative sign if root_index odd
						if (base_term.sign < 0 && root_index % 2) {
							extracted_term.sign = base_term.sign;
						}

						extracted_term.addClass("highlight_output");
						extracted_terms.push(extracted_term);
						base_term.addClass("highlight_input");
					} else {
						const extracted_term = base_term.children[0].clone();

						// extract negative sign if root_index odd
						if (base_term.sign < 0 && root_index % 2) {
							extracted_term.sign = base_term.sign;
						}

						extracted_term.addClass("highlight_output");
						extracted_terms.push(extracted_term);
						base_term.addClass("highlight_input");
					}

					// create leftover term if anything left
					if (leftover_exp_value > 1) {
						// leftover term is a power
						const leftover_term = QETerm.create({
							type: "FUNCTION",
							value: "pow",
						});
						const new_exp = QETerm.create({
							type: "RATIONAL",
							value: leftover_exp_value.toString(),
						});
						leftover_term.pushChild(base_term.children[0].clone());
						leftover_term.pushChild(new_exp);

						if (!(base_term.sign < 0 && root_index % 2)) {
							// sign was not extracted - preserve it on the leftover term
							leftover_term.sign = base_term.sign;
						}

						leftover_term.addClass("highlight_output");
						leftover_terms.push(leftover_term);
					} else if (leftover_exp_value == 1) {
						const leftover_term = base_term.children[0].clone();

						if (!(base_term.sign < 0 && root_index % 2)) {
							// sign was not extracted - preserve it on the leftover term
							leftover_term.sign = base_term.sign;
						}

						leftover_term.addClass("highlight_output");
						leftover_terms.push(leftover_term);
					} else if (base_term.sign < 0 && !(root_index % 2)) {
						// term was negative and root_index even, must still keep "-1"
						const leftover_term = QETerm.create({
							type: "RATIONAL",
							value: "1",
						});
						leftover_term.sign = base_term.sign;

						leftover_term.addClass("highlight_output");
						leftover_terms.push(leftover_term);
					} else {
						// term extracted entirely - add strikethrough
						base_term.addClass("strikethrough_input");
					}
				} else {
					const leftover_term = base_term.clone();
					leftover_terms.push(leftover_term);
				}
			}

			if (!extracted_terms.length) {
				continue;
			}

			// if base is a multiplicative CHAIN, retain sign - either in an extracted term, or leftover base
			if (base.sign < 0 && base.isMultiplyChain()) {
				if (root_index % 2) {
					// root_index is odd, apply sign to first extracted term
					extracted_terms[0].sign =
						(extracted_terms[0].sign || 1) * -1;
				} else {
					// root_index is even, keep sign behind with leftover terms
					if (leftover_terms.length) {
						leftover_terms[0].sign =
							(leftover_terms[0].sign || 1) * -1;
					} else {
						// no leftover terms left, leave behind a "-1" term instead
						const leftover_term = QETerm.create({
							type: "RATIONAL",
							value: "1",
						});
						leftover_term.sign = -1;
						leftover_terms.push(leftover_term);
					}
				}
			}

			// construct new chain for extracted_terms
			let new_term = QETerm.create({
				type: "CHAIN",
				precedence: findGrammar("MULTIPLY").precedence,
			});

			for (let j = 0; j < extracted_terms.length; j++) {
				if (j > 0) {
					new_term.pushChild(
						QETerm.create({ type: "MULTIPLY", value: "" })
					);
				}
				new_term.pushChild(extracted_terms[j]);
			}

			if (leftover_terms.length) {
				new_term.pushChild(QETerm.create({ type: "MULTIPLY", value: "" }));

				// construct new chain from leftover_terms
				let leftover_chain = QETerm.create({
					type: "CHAIN",
					precedence: findGrammar("MULTIPLY").precedence,
				});
				for (let j = 0; j < leftover_terms.length; j++) {
					if (j > 0) {
						leftover_chain.pushChild(
							QETerm.create({ type: "MULTIPLY", value: "" })
						);
					}
					leftover_chain.pushChild(leftover_terms[j]);
				}
				// there may have only been one item in the chain
				leftover_chain = leftover_chain.unchainChildIfSingle();

				const new_root = QETerm.create({
					type: "FUNCTION",
					value: nroot.value,
				});
				if (nroot.value == "nroot") {
					new_root.pushChild(nroot.children[0].clone());
				}
				new_root.pushChild(leftover_chain);
				new_term.pushChild(new_root);
			}

			// retain sign of entire root
			if (nroot.sign < 0) {
				new_term.sign = (new_term.sign || 1) * -1;
			}

			// there may have only been one item in the chain
			new_term = new_term.unchainChildIfSingle();

			// check if nroot was in a multiplicative CHAIN
			if (nroot.parent.isMultiplyChain() && new_term.isMultiplyChain()) {
				// replace parent CHAIN with a cloned CHAIN containing new_term CHAIN children
				const parent_chain = nroot.parent;
				const chain_index = parent_chain.children.indexOf(nroot);

				const new_chain = QETerm.create({
					type: "CHAIN",
					precedence: findGrammar("MULTIPLY").precedence,
				});
				for (let j = 0; j < parent_chain.children.length; j++) {
					if (j == chain_index) {
						// add new term chain terms
						for (let k = 0; k < new_term.children.length; k++) {
							new_chain.pushChild(new_term.children[k]);
						}
					} else {
						new_chain.pushChild(parent_chain.children[j].clone());
					}
				}

				new_term = new_chain;
				old_term = parent_chain;
			}

			return {
				old_term: old_term,
				new_term: new_term,
				type: "tree",
				desc: "Simplify a root by pulling out common factors",
			};
		}
	}
	/**
	 * CT_separateRootOfFraction	Split the root of a fraction into a fraction of two roots
	 */
	static CT_separateRootOfFraction(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find powers with a fraction base
		var terms = root.findAllChildren(function (node) {
			if (
				(node.value == "sqrt" && node.children[0].value == "frac") ||
				(node.value == "nroot" && node.children[1].value == "frac")
			) {
				return true;
			}
		});

		for (let i = 0; i < terms.length; i++) {
			var nroot = terms[i];
			var frac, root_index;
			if (nroot.value == "nroot") {
				root_index = nroot.children[0];
				frac = nroot.children[1];
			} else {
				// sqrt - no root_index
				frac = nroot.children[0];
			}
			var numerator = frac.children[0];
			var denominator = frac.children[1];

			var new_numerator = QETerm.create({
				type: "FUNCTION",
				value: nroot.value,
			});
			if (nroot.value == "nroot") {
				new_numerator.pushChild(root_index.clone());
			}
			new_numerator.pushChild(numerator.clone());

			var new_denominator = QETerm.create({
				type: "FUNCTION",
				value: nroot.value,
			});
			if (nroot.value == "nroot") {
				new_denominator.pushChild(root_index.clone());
			}
			new_denominator.pushChild(denominator.clone());

			var new_frac = QETerm.create({ type: "FUNCTION", value: "frac" });
			new_frac.pushChild(new_numerator);
			new_frac.pushChild(new_denominator);

			// highlighting
			frac.addClass("highlight_input");

			new_numerator.children[0].addClass("highlight_output");
			new_denominator.children[0].addClass("highlight_output");

			return {
				old_term: nroot,
				new_term: new_frac,
				type: "tree",
				desc: "Split a root of a fraction into a fraction of two roots",
			};
		}
	}
	/**
	 * CT_separatePowerOfFraction	Split the power of a fraction into a fraction of two powers
	 */
	static CT_separatePowerOfFraction(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find powers with a fraction base
		var terms = root.findAllChildren(function (node) {
			if (
				(node.type == "EXPONENT" || node.value == "pow") &&
				node.children[0].value == "frac"
			) {
				return true;
			}
		});

		for (let i = 0; i < terms.length; i++) {
			var power = terms[i];
			var frac = power.children[0];
			var exp = power.children[1];
			var numerator = frac.children[0];
			var denominator = frac.children[1];

			var new_numerator = QETerm.create({ type: "FUNCTION", value: "pow" });
			new_numerator.pushChild(numerator.clone());
			new_numerator.pushChild(exp.clone());

			var new_denominator = QETerm.create({
				type: "FUNCTION",
				value: "pow",
			});
			new_denominator.pushChild(denominator.clone());
			new_denominator.pushChild(exp.clone());

			var new_frac = QETerm.create({ type: "FUNCTION", value: "frac" });
			new_frac.pushChild(new_numerator);
			new_frac.pushChild(new_denominator);

			// retain power base sign
			if (frac.sign < 0 && exp.isOddInteger()) {
				new_frac.negate();
			}


			// highlighting
			exp.addClass("highlight_input");
			new_numerator.children[1].addClass("highlight_output");
			new_denominator.children[1].addClass("highlight_output");

			// retain overall power sign
			if (power.sign < 0) {
				new_frac.negate();
			}

			return {
				old_term: power,
				new_term: new_frac,
				type: "tree",
				desc: "Split a power of a fraction into a fraction of two powers",
			};
		}
	}
	/**
	 * CT_mergeFractionInFraction	Merge a fraction within a fraction with the containing fraction
	 */
	static CT_mergeFractionInFraction(input_value, options) {
		if (input_value.type != "tree") return undefined;
		let root = input_value.value;

		// find fractions with a fraction as a parent
		let terms = root.findAllChildren(function (node) {
			if (
				node.value == "frac" &&
				(node.parent.value == "frac" ||
					(node.parent.isMultiplyChain() &&
						node.parent.parent.value == "frac"))
			) {
				return true;
			}
		});

		// simplify parent fraction to numerator and denominator term lists
		for (let i = 0; i < terms.length; i++) {
			let child_frac = terms[i];

			let parent_frac = child_frac.parent;
			if (parent_frac.type == "CHAIN") {
				parent_frac = parent_frac.parent;
			}

			let numerator = parent_frac.children[0];
			let denominator = parent_frac.children[1];

			// build list of terms, so we can handle numerator and denominator chain/non-chain the same way
			let numerator_terms = multiplicativeTermToList(numerator);
			if (numerator_terms === undefined) {
				return undefined;
			}
			let denominator_terms = multiplicativeTermToList(denominator);
			if (denominator_terms === undefined) {
				return undefined;
			}

			// retain signs of parent_frac and child_frac terms
			let parent_frac_sign = parent_frac.sign || 1;

			// build a new fraction from parent_frac numerator and denominator terms
			let desc = "";
			let clone_numerator_terms = [];
			let clone_denominator_terms = [];
			for (let j = 0; j < numerator_terms.length; j++) {
				if (numerator_terms[j] === child_frac) {
					desc = "Merge a fraction within the numerator of a fraction by moving the child fraction denominator into the parent fraction denominator.";
					desc += "<katex>\\frac{(a/b)}{c} = \\frac{a}{bc}</katex>";

					// clone child_frac numerator and denominator
					let clone_child_frac_num = child_frac.children[0].clone();
					let clone_child_frac_den = child_frac.children[1].clone();
					clone_numerator_terms.push(clone_child_frac_num);
					clone_denominator_terms.push(clone_child_frac_den);

					child_frac.addClass("highlight_input");
					clone_child_frac_num.addClass("highlight_output");
					clone_child_frac_den.addClass("highlight_output");

					// retain child_frac sign
					parent_frac_sign =
						parent_frac_sign * (child_frac.sign || 1);
				} else {
					clone_numerator_terms.push(numerator_terms[j].clone());
				}
			}
			for (let j = 0; j < denominator_terms.length; j++) {
				if (denominator_terms[j] === child_frac) {
					desc =
						"Merge a fraction within the denominator of a fraction by moving the child fraction denominator into the parent fraction numerator.";
					desc += "<katex>\\frac{a}{(b/c)} = \\frac{ac}{b}</katex>";

					// clone child_frac numerator and denominator
					let clone_child_frac_num = child_frac.children[0].clone();
					let clone_child_frac_den = child_frac.children[1].clone();
					clone_denominator_terms.push(clone_child_frac_num);
					clone_numerator_terms.push(clone_child_frac_den);

					child_frac.addClass("highlight_input");
					clone_child_frac_num.addClass("highlight_output");
					clone_child_frac_den.addClass("highlight_output");

					// retain child_frac sign
					parent_frac_sign =
						parent_frac_sign * (child_frac.sign || 1);
				} else {
					clone_denominator_terms.push(denominator_terms[j].clone());
				}
			}

			// build new numerator from cloned numerator terms
			let new_numerator;
			if (clone_numerator_terms.length > 1) {
				new_numerator = QETerm.create({
					type: "CHAIN",
					precedence: findGrammar("MULTIPLY").precedence,
				});

				for (let j = 0; j < clone_numerator_terms.length; j++) {
					let clone_term = clone_numerator_terms[j];

					if (j > 0) {
						new_numerator.pushChild(
							QETerm.create({ type: "MULTIPLY", value: "" })
						);
					}

					// check if term is a CHAIN - possible if child_frac contained a CHAIN
					if (clone_term.type == "CHAIN") {
						if (clone_term.isMultiplyChain()) {
							// multiplicative CHAIN. Push children
							for (let k = 0; k < clone_term.children.length; k++) {
								new_numerator.pushChild(clone_term.children[k]);
							}
						} else if (clone_term.isAddChain()) {
							// additive CHAIN. Wrap in brackets and push
							let brackets = QETerm.create({ type: "BRACKETS" });
							brackets.pushChild(clone_term);
							new_numerator.pushChild(brackets);
						} else {
							// comparitive CHAIN. Wrap in brackets and push, and complain!
							let brackets = QETerm.create({ type: "BRACKETS" });
							brackets.pushChild(clone_term);
							new_numerator.pushChild(brackets);
							console.log(
								"Warning: merging fraction containing an EQUAL precedence CHAIN"
							);
						}
					} else {
						new_numerator.pushChild(clone_term);
					}
				}
			} else {
				// single term in numerator
				new_numerator = clone_numerator_terms[0];
			}

			// build new denominator from cloned denominator terms
			let new_denominator;
			if (clone_denominator_terms.length > 1) {
				new_denominator = QETerm.create({
					type: "CHAIN",
					precedence: findGrammar("MULTIPLY").precedence,
				});

				for (let j = 0; j < clone_denominator_terms.length; j++) {
					let clone_term = clone_denominator_terms[j];

					if (j > 0) {
						new_denominator.pushChild(
							QETerm.create({ type: "MULTIPLY", value: "" })
						);
					}

					// check if term is a CHAIN - possible if child_frac contained a CHAIN
					if (clone_term.type == "CHAIN") {
						if (clone_term.isMultiplyChain()) {
							// multiplicative CHAIN. Push children
							for (let k = 0; k < clone_term.children.length; k++) {
								new_denominator.pushChild(
									clone_term.children[k]
								);
							}
						} else if (clone_term.isAddChain()) {
							// additive CHAIN. Wrap in brackets and push
							let brackets = QETerm.create({ type: "BRACKETS" });
							brackets.pushChild(clone_term);
							new_denominator.pushChild(brackets);
						} else {
							// comparitive CHAIN. Wrap in brackets and push, and complain!
							let brackets = QETerm.create({ type: "BRACKETS" });
							brackets.pushChild(clone_term);
							new_denominator.pushChild(brackets);
							console.log(
								"Warning: merging fraction containing an EQUAL precedence CHAIN"
							);
						}
					} else {
						new_denominator.pushChild(clone_term);
					}
				}
			} else {
				// single term in denominator
				new_denominator = clone_denominator_terms[0];
			}

			let new_frac = QETerm.create({ type: "FUNCTION", value: "frac" });
			new_frac.pushChild(new_numerator);
			new_frac.pushChild(new_denominator);

			// retain sign
			new_frac.sign = parent_frac_sign;

			return {
				old_term: parent_frac,
				new_term: new_frac,
				type: "tree",
				desc: desc,
			};
		}
	}
	/**
	 * CT_removeCommonFractionFactors	Cancel out common factors in a fraction.
	 */
	static CT_removeCommonFractionFactors(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// filter to fractions
		var terms = root.findAllChildren("value", "frac");

		if (!terms.length) {
			return undefined;
		}

		// check for any decimals in fraction, and set force_rational_division if any are found
		let force_rational_division = false;
		let decimals = root
			.findAllChildren("type", "RATIONAL")
			.filter(function (term) {
				return (term.attr("value_type") == "decimal" || term.attr("value_type") == "percent");
			});
		if (decimals.length) {
			force_rational_division = true;
		}

		// reduce numerator and denominator by common factors
		for (let i = 0; i < terms.length; i++) {
			var frac = terms[i];

			// attempt to reduce by cancelling identical factors
			var reduced_by_cancellation = cancelIdenticalFracTerms(
				frac,
				options
			);
			if (reduced_by_cancellation) {
				return {
					old_term: frac,
					new_term: reduced_by_cancellation.new_frac,
					desc: reduced_by_cancellation.desc,
					type: "tree",
				};
			}

			// attempt to reduce by dividing out decimal numbers
			var reduced_by_decimal_division = divideDecimalsInFracTerms(
				frac,
				Object.assign(
					{ force_rational_division: force_rational_division },
					options
				)
			);
			if (reduced_by_decimal_division) {
				return {
					old_term: frac,
					new_term: reduced_by_decimal_division.new_frac,
					desc: reduced_by_decimal_division.desc,
					type: "tree",
				};
			}

			// attempt to combine powers with same base - dividePowersInFracTerm
			var reduced_by_power_division = dividePowersInFracTerm( frac, options );
			if (reduced_by_power_division) {
				return {
					old_term: frac,
					new_term: reduced_by_power_division.new_frac,
					desc: reduced_by_power_division.desc,
					type: "tree",
				};
			}

			// factorOutCommonFracFactors
			var factored = factorOutCommonFracFactors(frac, options);
			if (factored) {
				return {
					old_term: frac,
					new_term: factored.new_frac,
					desc: factored.desc,
					type: "tree",
					next_solution_step: {
						step_key: "CT_removeCommonFractionFactors",
					}, // TODO: use a separate CT_removeIdenticalFractionFactors step
					// next_step_options: { target_factor: common_factor }, // TODO: specify factor to cancel. Need factorOutCommonFracFactors to return it
				};
			}
		}
	}

	/**
	 * CT_invertDividedFraction	Convert division by a fraction to multiplication by flipping it and multiplying.
	 */
	static CT_invertDividedFraction(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find fractions on multiplicative CHAINs preceded by DIVIDE
		var terms = root.findAllChildren(function (node) {
			if (node.value == "frac" && node.parent.isMultiplyChain()) {
				// node is a frac in a CHAIN, now check if it is preceded by a DIVIDE
				var frac_index = node.parent.children.indexOf(node);
				if (
					frac_index > 0 &&
					node.parent.children[frac_index - 1].type == "DIVIDE"
				) {
					return true;
				}
			}
		});

		for (let i = 0; i < terms.length; i++) {
			var frac = terms[i];
			var chain = frac.parent;
			var frac_index = chain.children.indexOf(frac);

			var clone = chain.clone();

			// clone and invert fraction
			var new_frac = frac.clone();
			new_frac.children = new_frac.children
				.splice(1, 1)
				.concat(new_frac.children);

			// highlight old terms
			chain.children[frac_index - 1].addClass("highlight_input");
			chain.children[frac_index].addClass("highlight_input");

			// replace and highlight new terms
			clone.children[frac_index - 1].replaceWith(
				QETerm.create({ type: "MULTIPLY", value: "" })
			);
			clone.children[frac_index].replaceWith(new_frac);

			clone.children[frac_index - 1].addClass("highlight_output");
			clone.children[frac_index].addClass("highlight_output");

			return {
				old_term: chain,
				new_term: clone,
				type: "tree",
				desc:
					"Convert division by a fraction to multiplication by flipping it and multiplying.",
			};
		}
	}

	/**
	 * CT_combineMultiplicativeChainTerms	Combines like terms in a multiplicative CHAIN.
	 */
	static CT_combineMultiplicativeChainTerms(input_value, options) {
		if (input_value.type != "tree") return undefined;
		let root = input_value.value;

		// filter to multiplicative CHAINs
		let terms = root.findAllChildren(function (node) {
			if (node.isMultiplyChain()) {
				return true;
			}
			return false;
		});

		if (!terms.length) {
			return undefined;
		}

		// find multiplicative CHAIN with two or more like terms: rationals, roots with same root_index, or non-roots with same serialized base
		for (let i = 0; i < terms.length; i++) {
			let chain = terms[i];

			// skip if any DIVIDE ops found - should be handled in CT_convertMixedMultiplicativeChainToFraction
			if (
				chain.children.filter(function (child) {
					return child.type == "DIVIDE";
				}).length
			) {
				continue;
			}
			// skip if any fracs found - should be handled in CT_convertMixedMultiplicativeChainToFraction
			if (
				chain.children.filter(function (child) {
					return child.value == "frac";
				}).length
			) {
				continue;
			}

			// classify terms by serialized base and exponent
			let like_mult_bases = {};
			let like_roots = {};

			for (let j = 0; j < chain.children.length; j += 2) {
				let child = chain.children[j];

				if (child.type == "RATIONAL") {
					if (!like_mult_bases["1"]) {
						like_mult_bases["1"] = [];
					}
					like_mult_bases["1"].push({ term: child, exp: "1" });
				} else if (child.value == "sqrt" || child.value == "nroot") {
					let root_index;
					let root_base;

					if (child.value == "sqrt") {
						root_index = "2";
						root_base = child.children[0];
					} else {
						root_index = child.children[0].serialize_to_text();
						root_base = child.children[1];
					}

					// track by root_index
					if (!like_roots[root_index]) {
						like_roots[root_index] = [];
					}
					like_roots[root_index].push({
						term: child,
						base: root_base,
					});
				} else if (child.type == "EXPONENT" || child.value == "pow") {
					let serial_base = child.children[0].serialize_to_text();
					let serial_power = child.children[1].serialize_to_text();

					if (!like_mult_bases[serial_base]) {
						like_mult_bases[serial_base] = [];
					}
					like_mult_bases[serial_base].push({
						term: child,
						exp: serial_power,
					});
				} else {
					let serial_base = child.serialize_to_text();
					if (!like_mult_bases[serial_base]) {
						like_mult_bases[serial_base] = [];
					}
					like_mult_bases[serial_base].push({
						term: child,
						exp: "1",
					});
				}
			}

			let desc, new_term, like_terms;

			///////////////////////////////////
			// now create new_term by combining like terms

			if (like_mult_bases["1"] && like_mult_bases["1"].length > 1) {
				// multiply RATIONAL terms together
				let mult_base = "1";
				like_terms = like_mult_bases[mult_base];

				let rational_product = new QEQ(1, 1);
				let value_type = "integer";
				like_terms.forEach(function (rational_item) {
					if (rational_item.term.type != "RATIONAL") {
						// possible for terms like pow{1,x}
						return;
					}

					rational_product = rational_product.multiply(
						rational_item.term.Q
					);
					value_type = QEQ.combine_value_types(
						value_type,
						rational_item.term.attr("value_type")
					);
				});

				new_term = rational_product.to_term({ value_type: value_type });
				desc = "Simplify by multiplying numeric terms together.";
			} else if (
				Object.keys(like_mult_bases).filter(function (mult_base) {
					return like_mult_bases[mult_base].length > 1;
				}).length
			) {
				// use the first serialized base with 2 or more like terms
				let mult_base = Object.keys(like_mult_bases).filter(function (
					mult_base
				) {
					return like_mult_bases[mult_base].length > 1;
				})[0];
				like_terms = like_mult_bases[mult_base];

				// instantiate new base
				let new_term_base = parseToChainModeTerm(mult_base);

				let exponent_int = 0;
				let non_int_exp_terms = [];
				like_terms.forEach(function (term_exp_pair) {
					// check if serialized exponent is an integer
					if (parseInt(term_exp_pair.exp) == term_exp_pair.exp) {
						exponent_int += parseInt(term_exp_pair.exp);
					} else {
						// parse serialized exponent and push onto expoent terms list
						non_int_exp_terms.push(
							parseToChainModeTerm(term_exp_pair.exp)
						);
					}
				});

				// build new term exponent
				let new_term_exponent;
				if (non_int_exp_terms.length) {
					// include integer exponent, if any
					if (exponent_int != 0) {
						non_int_exp_terms.unshift(
							QETerm.create({
								type: "RATIONAL",
								value: exponent_int.toString(),
							})
						);
					}
					new_term_exponent = listToAdditiveChain(non_int_exp_terms);
				} else if (exponent_int == 1) {
					// no exponent
				} else if (exponent_int == 0) {
					// "0" exponent - replace the base term with "1"
					new_term_base = QETerm.create({
						type: "RATIONAL",
						value: "1",
					});
				} else {
					new_term_exponent = QETerm.create({
						type: "RATIONAL",
						value: exponent_int.toString(),
					});
				}

				if (new_term_exponent) {
					new_term = QETerm.create({ type: "FUNCTION", value: "pow" });
					new_term.pushChild(new_term_base);
					new_term.pushChild(new_term_exponent);
				} else {
					new_term = new_term_base;
				}

				desc = "Simplify by multiplying like terms together.";
			} else if (
				Object.keys(like_roots).filter(function (root_idx) {
					return like_roots[root_idx].length > 1;
				}).length
			) {
				// build a new root base from the base of each of the roots with the same root_index
				let root_index = Object.keys(like_roots).filter(function (
					root_idx
				) {
					return like_roots[root_idx].length > 1;
				})[0];
				like_terms = like_roots[root_index];

				let sign = 1;
				let root_base_terms = [];
				like_terms.forEach(function (root_term) {
					let root_base = root_term.base;

					// extract sign from root base
					sign *= root_base.sign || 1;

					root_base_terms = root_base_terms.concat(
						multiplicativeTermToList(root_base)
					);
				});
				let new_term_base = listToMultiplicativeTerm(root_base_terms);

				// retain sign
				new_term_base.sign = sign;

				if (root_index == "2") {
					new_term = QETerm.create({ type: "FUNCTION", value: "sqrt" });
					desc = "Simplify by multiplying two square roots together.";

					new_term.pushChild(new_term_base);
				} else {
					new_term = QETerm.create({ type: "FUNCTION", value: "nroot" });
					desc =
						"Simplify by multiplying two roots with the same root index together.";

					// technically, there's no guarantee the root_index is an integer, or even a rational, so it's safest to parse
					let new_term_root_index = parseToChainModeTerm(root_index);

					new_term.pushChild(new_term_root_index);
					new_term.pushChild(new_term_base);
				}
			} else {
				continue;
			}

			///////////////////////////////////
			// now build new chain from new term and old terms that are not component terms of new_term
			let old_chain_terms = multiplicativeTermToList(chain);
			let new_chain_terms = [];
			let has_included_new_term = false;
			for (let k = 0; k < old_chain_terms.length; k++) {
				let old_chain_term = old_chain_terms[k];

				// exclude all terms that are part of the new term
				if (
					like_terms.filter(function (x) {
						return x.term === old_chain_term;
					}).length
				) {
					old_chain_term.addClass("highlight_input");

					if (!has_included_new_term) {
						// include the new term at the position of the first of new_term's component terms
						new_term.addClass("highlight_output");
						new_chain_terms.push(new_term);
						has_included_new_term = true;
					}
				} else {
					new_chain_terms.push(old_chain_term);
				}
			}

			let new_chain = listToMultiplicativeTerm(new_chain_terms);

			// retain sign
			new_chain.sign = chain.sign;

			return {
				old_term: chain,
				new_term: new_chain,
				type: "tree",
				desc: desc,
			};
		}
	}
	/**
	 * CT_combineAdditiveChainTerms	Combines like terms in an additive CHAIN.
	 */
	static CT_combineAdditiveChainTerms(input_value, options) {
		if (input_value.type != "tree") return undefined;
		const root = input_value.value;

		// filter to addititive CHAINs
		const terms = root.findAllChildren(function(node) {
			return node.isAddChain();
		});

		if (!terms.length) {
			return undefined;
		}

		let desc = "";

		// find additive CHAIN with two like terms (RATIONALs, or non-rational with like non-RATIONAL base)
		for (let i = 0; i < terms.length; i++) {
			const chain = terms[i];
			const add_chain_terms = additiveChainToList(chain);

			// build factor map of each term in the chain
			const factor_maps = [];
			for (let j = 0; j < add_chain_terms.length; j++) {
				const add_chain_term = add_chain_terms[j];
				factor_maps.push(getCharacterizedTermFactors(add_chain_term));
			}

			// group terms by non-rational components
			const non_rational_bases = {};
			for (let j = 0; j < factor_maps.length; j++) {
				let factor_map = factor_maps[j];

				if (add_chain_terms[j].value == "frac") {
					// skip fracs: handled in separate step
					continue;
				}

				// build a string key from the non-number factors of the term
				let base_key = generateNonRationalBaseKeyFromFactors(factor_map);

				// group terms
				if (!non_rational_bases[base_key]) {
					non_rational_bases[base_key] = [];
				}
				non_rational_bases[base_key].push(j); // keep the index of the matching term
			}

			// check for a non_rational_base with 2 or more matching terms
			let matching_key = null;
			Object.keys(non_rational_bases).forEach(function(non_rational_base){
				if (matching_key === null && non_rational_bases[non_rational_base].length > 1) {
					matching_key = non_rational_base;
				}
			});
			// note: matching_key of "" indicates RATIONAL terms
			if (matching_key === null) {
				// no combinable terms
				continue;
			}

			const matching_term_indices = non_rational_bases[matching_key];

			// sum up the numeric coefficients of the matching terms
			let coeff_sum = new QEQ(0, 1);
			for (let j = 0; j < matching_term_indices.length; j++) {
				const factor_map = factor_maps[ matching_term_indices[j] ];
				const add_chain_term = add_chain_terms[ matching_term_indices[j] ];

				// get rational factors
				let coeff_num = 1;
				let coeff_den = 1;
				Object.keys(factor_map.num || {}).forEach(function(factor){
					if (!Number.isNaN(Number(factor))) {
						coeff_num *= Math.pow(Number(factor), factor_map.num[factor]);
					}
				});
				Object.keys(factor_map.den || {}).forEach(function(factor){
					if (!Number.isNaN(Number(factor))) { // 
						coeff_den *= Math.pow(Number(factor), factor_map.den[factor]);
					}
				});
				let coeff = new QEQ(coeff_num, coeff_den);

				// NOTE: alternate way to present sum is to gather coeffs into (a+b+c)*term and add in a separate step

				// use term sign to determine whether to add or subtract the value
				if (!matching_term_indices[j] && add_chain_term.sign < 0) {
					coeff_sum = coeff_sum.subtract(coeff);
				} else if (matching_term_indices[j] && add_chain_term.prev().type == "SUBTRACT") {
					coeff_sum = coeff_sum.subtract(coeff);
					add_chain_term.prev().addClass("highlight_input");
				} else {
					coeff_sum = coeff_sum.add(coeff);
				}
				add_chain_term.addClass("highlight_input");
			}

			// retain value_type: integer unless decimals being used anywhere in expression
			let coeff_value_type = "integer";
			if (chain.findAllChildren("type", "RATIONAL").
				filter(function (term) {
					return (term.attr("value_type") == "decimal" || term.attr("value_type") == "percent");
				}).length
			){
				coeff_value_type = "decimal";
			}
			const coeff_term = coeff_sum.to_term({value_type: coeff_value_type});

			// create new base term from the non-rational factors of the first matching term
			let match_term_factors = factor_maps[ matching_term_indices[0] ];
			let non_rational_factors = filterFactorMapToNonRationalFactors(match_term_factors);
			let new_base = generateTermFromFactors(non_rational_factors);

			// create new term from the summed matching terms
			let sum_term
			if (coeff_term.value == "0") {
				// terms cancel out
				for (let j = 0; j < add_chain_terms.length; j++) {
					const add_chain_term = add_chain_terms[j];
					if (matching_term_indices.indexOf(j) != -1) {
						add_chain_term.addClass("strikethrough_input");
					}
				}

				if (new_base.value == "1") {
					desc = "Simplify by adding the number terms (they cancel each other out here).";
				} else {
					desc = "Simplify by adding the like terms (they cancel each other out here).";
				}

				sum_term = QETerm.create({ type: "RATIONAL", value: "0" });
			} else {
				if (new_base.value == "1") {
					// terms are numeric - keep only the coeff
					sum_term = listToMultiplicativeTerm([coeff_term]);

					desc = "Simplify by adding the number terms.";
				} else {
					if (coeff_term.value == "1") {
						// no-coeff
						sum_term = listToMultiplicativeTerm([new_base]);
					} else {
						// coeff and term
						sum_term = listToMultiplicativeTerm([coeff_term, new_base]);
					}
					desc = "Simplify by adding the like terms.";
				}

				// set sign
				sum_term.sign = coeff_term.sign;
			}
			sum_term.addClass("highlight_output");

			// replace first matching term with summed term

			// create new add chain from new_term and any non-matching terms
			let first_matching_term_found = false;
			const new_chain_list = [];
			for (let j = 0; j < add_chain_terms.length; j++) {
				const add_chain_term = add_chain_terms[j];

				if (matching_term_indices.indexOf(j) == -1) {
					// not one of the matching terms: simply clone
					let new_term = add_chain_term.clone();

					// retain sign
					if (!j) {
						new_term.sign = add_chain_term.sign;
					} else if (add_chain_term.prev().type == "SUBTRACT") {
						new_term.sign = -1; // term preceded by subtract
					}

					new_chain_list.push(new_term);
				} else if (!first_matching_term_found) {
					// replace the first matching term with new_frac, ignore the rest
					new_chain_list.push(sum_term);
					first_matching_term_found = true;
				}
				// else ignore
			}
			let new_chain = listToAdditiveChain(new_chain_list);

			return {
				old_term: chain,
				new_term: new_chain,
				type: "tree",
				desc: desc,
			};
		}
	}
	/**
	 * CT_multiplyBracketTerms	Multiply bracketed expressions
	 */
	static CT_multiplyBracketTerms(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// filter to BRACKETS with an additive CHAIN child and a multiplicative CHAIN parent
		var terms = root.findAllChildren(function (node) {
			if (
				node.type == "BRACKETS" &&
				node.children[0].isAddChain() &&
				node.parent.isMultiplyChain()
			) {
				return true;
			}
		});

		for (let i = 0; i < terms.length; i++) {
			var brackets = terms[i];
			var parent_chain = brackets.parent;

			// ensure all operators in the parent chain are MULTIPLY
			if (
				parent_chain.children.filter(function (x, i) {
					return i % 2 && x.type != "MULTIPLY";
				}).length
			) {
				continue;
			}

			// convert chain to array of terms
			var parent_chain_term_list = multiplicativeTermToList(parent_chain);

			// get the rest of the parent CHAIN
			var index_of_brackets = parent_chain_term_list.indexOf(brackets);
			parent_chain_term_list.splice(index_of_brackets, 1);

			// handle brackets * brackets first
			var other_brackets_list = parent_chain_term_list.filter(function (
				x,
				i
			) {
				return x.type == "BRACKETS";
			});
			if (other_brackets_list.length) {
				// multiply each of the bracketed terms together
				// expand brackets using FOIL: (a + b)(c + d) = ac + ad + bc + bd
				var other_brackets = other_brackets_list[0];
				var index_of_other_brackets = parent_chain_term_list.indexOf(
					other_brackets
				);
				parent_chain_term_list.splice(index_of_other_brackets, 1);

				// convert bracketed additive CHAINs to arrays
				var bracket_terms_list = additiveChainToList(
					brackets.children[0]
				);
				var other_bracket_terms_list = additiveChainToList(
					other_brackets.children[0]
				);

				// create a new additive CHAIN by expanding out the two bracketed expressions
				var new_add_terms_list = [];
				for (var j = 0; j < bracket_terms_list.length; j++) {
					for (var k = 0; k < other_bracket_terms_list.length; k++) {
						// TODO: multiply using helper function
						var new_term = QETerm.create({
							type: "CHAIN",
							precedence: findGrammar("MULTIPLY").precedence,
						});

						// convert the two terms into lists of terms and push onto the new CHAIN
						var bracket_term_terms_list = multiplicativeTermToList(
							bracket_terms_list[j]
						);
						new_term.pushChild(bracket_term_terms_list[0].clone()); // start the chain
						for (
							var m = 1;
							m < bracket_term_terms_list.length;
							m++
						) {
							new_term.pushChild(
								QETerm.create({ type: "MULTIPLY", value: "" })
							);
							new_term.pushChild(
								bracket_term_terms_list[m].clone()
							);
						}

						var other_bracket_term_terms_list = multiplicativeTermToList(
							other_bracket_terms_list[k]
						);
						for (
							var m = 0;
							m < other_bracket_term_terms_list.length;
							m++
						) {
							new_term.pushChild(
								QETerm.create({ type: "MULTIPLY", value: "" })
							);
							new_term.pushChild(
								other_bracket_term_terms_list[m].clone()
							);
						}

						// merge in sign of terms and preceding operators
						new_term.sign =
							(new_term.sign || 1) *
							(bracket_terms_list[j].sign || 1);
						if (
							j &&
							bracket_terms_list[j].prev().type == "SUBTRACT"
						) {
							new_term.sign = (new_term.sign || 1) * -1; // TODO: negate()
						}
						new_term.sign =
							(new_term.sign || 1) *
							(other_bracket_terms_list[k].sign || 1);
						if (
							k &&
							other_bracket_terms_list[k].prev().type ==
								"SUBTRACT"
						) {
							new_term.sign = (new_term.sign || 1) * -1; // TODO: negate()
						}

						new_add_terms_list.push(new_term);
					}
				}

				// create new add CHAIN from the new terms
				var new_add_chain = QETerm.create({
					type: "CHAIN",
					precedence: findGrammar("ADD").precedence,
				});
				new_add_terms_list.forEach(function (term, index) {
					var new_term;
					if (index) {
						if (term.sign < 0) {
							// merge term sign into preceding ADD
							term.sign = 1;
							new_add_chain.pushChild(
								QETerm.create({ type: "SUBTRACT" })
							);
						} else {
							new_add_chain.pushChild(
								QETerm.create({ type: "ADD" })
							);
						}
					}
					new_add_chain.pushChild(term);
				});

				// check if there are any other terms left in parent chain
				if (parent_chain_term_list.length) {
					// create a new multiplicative CHAIN
					var new_mult_chain = QETerm.create({
						type: "CHAIN",
						precedence: findGrammar("MULTIPLY").precedence,
					});

					// preserve parent_chain sign
					new_mult_chain.sign = parent_chain.sign;

					// first include the other, non-bracketed terms
					new_mult_chain.pushChild(parent_chain_term_list[0].clone());
					for (var j = 1; j < parent_chain_term_list.length; j++) {
						new_mult_chain.pushChild(
							QETerm.create({ type: "MULTIPLY", value: "" })
						);
						new_mult_chain.pushChild(
							parent_chain_term_list[j].clone()
						);
					}

					// now include the new additive chain
					new_mult_chain.pushChild(
						QETerm.create({ type: "MULTIPLY", value: "" })
					);

					var wrapper = QETerm.create({ type: "BRACKETS" });
					wrapper.pushChild(new_add_chain);
					new_mult_chain.pushChild(wrapper);

					brackets.addClass("highlight_input");
					other_brackets.addClass("highlight_input");
					new_add_chain.addClass("highlight_output");

					return {
						old_term: parent_chain,
						new_term: new_mult_chain,
						type: "tree",
						desc:
							"Expand multiplied brackets: <katex>(a + b)(c + d) = ac + ad + bc + bd</katex>",
					};
				} else {
					// if parent_chain negative or parent_chain parent is an additive CHAIN, wrap everything in BRACKETS
					if (
						parent_chain.sign < 0 ||
						parent_chain.parent.isAddChain()
					) {
						var wrapper = QETerm.create({ type: "BRACKETS" });
						wrapper.pushChild(new_add_chain);
						new_add_chain = wrapper;

						new_add_chain.sign =
							(new_add_chain.sign || 1) *
							(parent_chain.sign || 1);
					}

					parent_chain.addClass("highlight_input");
					new_add_chain.addClass("highlight_output");

					return {
						old_term: parent_chain,
						new_term: new_add_chain,
						type: "tree",
						desc:
							"Expand multiplied brackets: <katex>(a + b)(c + d) = ac + ad + bc + bd</katex>",
					};
				}
			} else {
				// multiply non-bracketed term portion with each term of the bracketed expression
				// distributive property: a(b+c) = ab + ac
				// convert bracketed additive CHAIN to array
				var bracket_terms_list = additiveChainToList(
					brackets.children[0]
				);

				// build a list of new terms
				var new_add_terms_list = [];
				for (var j = 0; j < bracket_terms_list.length; j++) {
					// TODO: multiply using helper function
					// for each additive term item, multiply with the other parent chain terms
					var new_term = QETerm.create({
						type: "CHAIN",
						precedence: findGrammar("MULTIPLY").precedence,
					});

					// retain sign of parent chain
					new_term.sign = parent_chain.sign;

					// first include the other, non-bracketed terms
					new_term.pushChild(parent_chain_term_list[0].clone());
					for (var k = 1; k < parent_chain_term_list.length; k++) {
						new_term.pushChild(
							QETerm.create({ type: "MULTIPLY", value: "" })
						);
						new_term.pushChild(parent_chain_term_list[k].clone());
					}

					// now include the term from the additive chain
					// convert bracket_terms_list[j] to a list of terms and push each onto new_term CHAIN
					var term_list = multiplicativeTermToList(
						bracket_terms_list[j]
					);
					for (var k = 0; k < term_list.length; k++) {
						new_term.pushChild(
							QETerm.create({ type: "MULTIPLY", value: "" })
						);
						new_term.pushChild(term_list[k].clone());
					}

					// merge in sign of term and preceding operator
					new_term.sign =
						(new_term.sign || 1) *
						(bracket_terms_list[j].sign || 1);
					if (j && bracket_terms_list[j].prev().type == "SUBTRACT") {
						new_term.sign = (new_term.sign || 1) * -1; // TODO: negate()
					}

					new_add_terms_list.push(new_term);
				}

				// create new add CHAIN from the new terms
				var new_add_chain = QETerm.create({
					type: "CHAIN",
					precedence: findGrammar("ADD").precedence,
				});
				new_add_terms_list.forEach(function (term, index) {
					var new_term;
					if (index) {
						if (term.sign < 0) {
							// merge term sign into preceding ADD
							term.sign = 1;
							new_add_chain.pushChild(
								QETerm.create({ type: "SUBTRACT" })
							);
						} else {
							new_add_chain.pushChild(
								QETerm.create({ type: "ADD" })
							);
						}
					}
					new_add_chain.pushChild(term);
				});

				// if parent_chain parent is an additive CHAIN, wrap everything in BRACKETS
				if (parent_chain.parent.isAddChain()) {
					var wrapper = QETerm.create({ type: "BRACKETS" });
					wrapper.pushChild(new_add_chain);
					new_add_chain = wrapper;
				}

				parent_chain.addClass("highlight_input");
				new_add_chain.addClass("highlight_output");

				return {
					old_term: parent_chain,
					new_term: new_add_chain,
					type: "tree",
					desc:
						"Apply distributive property: <katex>a(b + c) = ab + ac</katex>",
				};
			}
		}
	}
	/**
	 * CT_combineAdditiveChainRationalFractions	Add/subtract rational fractions and terms in an additive CHAIN by finding the LCD of the terms. E.g. frac{1,2} + 1
	 */
	static CT_combineAdditiveChainRationalFractions(input_value, options) {
		if (input_value.type != "tree") return undefined;
		const root = input_value.value;

		// filter to addititive CHAINs containing >= 1 rational fractions, and >= 2 (rational fractions | rationals)
		const terms = root.findAllChildren(function (node) {
			if (node.isAddChain()) {
				if (node.children.filter(function(child){
						return child.isRationalFrac();
					}).length > 0 &&
					node.children.filter(function(child){
						return child.isRationalFrac() || child.type == "RATIONAL";
					}).length > 1
				) {
					return true;
				}
			}
		});

		if (!terms.length) {
			return undefined;
		}

		let desc = "Add/subtract fractions by finding a common denominator. a/b + c/d = (a*d + b*c) / b*d";

		// check for 2 or more rational fractions or rationals, e.g. "2 + frac{1,3}"
		for (let i = 0; i < terms.length; i++) {
			const chain = terms[i];
			const add_chain_terms = additiveChainToList(chain);

			// find the LCD of the matching terms by getting the max prime factor counts of the denominators
			const new_den_factor_maps = [];
			for (let j = 0; j < add_chain_terms.length; j++) {
				const add_chain_term = add_chain_terms[j];

				if (!add_chain_term.isRationalFrac()) {
					continue;
				}

				new_den_factor_maps.push(getCharacterizedTermFactors(add_chain_term.children[1]));
			}
			const new_den_factor_union = getUnionOfCharacterizedTermListFactors(new_den_factor_maps);
			const new_denominator = generateTermFromFactors(new_den_factor_union);

			// begin building the new numerator
			const new_numerator_list = [];
			for (let j = 0; j < add_chain_terms.length; j++) {
				const add_chain_term = add_chain_terms[j];

				let new_term;
				if (add_chain_term.type == "RATIONAL") {
					// create new multiplied term from this RATIONAL and the scaling factor (equal to new_denominator, since RATIONAL denominator is "1")
					new_term = listToMultiplicativeTerm([new_denominator, add_chain_term]);

					add_chain_term.addClass("highlight_input");
				} else if (add_chain_term.isRationalFrac()) {
					// create new multiplied term from this frac's numerator and the scaling factor (equal to factor difference between frac denominator and new_denominator)

					// get factor difference between denominator and new_denominator -> scaling factor
					let term_den_factors = getCharacterizedTermFactors(add_chain_term.children[1]);
					let factor_diff = getDifferenceOfFactorMaps(new_den_factor_union, term_den_factors);
					let scaling_factor = generateTermFromFactors(factor_diff);

					if (scaling_factor.value == "1") {
						new_term = add_chain_term.children[0].clone();
					} else {
						new_term = listToMultiplicativeTerm([scaling_factor, add_chain_term.children[0]]);
					}

					add_chain_term.addClass("highlight_input");
				} else {
					continue;
				}

				// retain sign
				if (!j) {
					new_term.sign = add_chain_term.sign;
				} else if (add_chain_term.prev().type == "SUBTRACT") {
					new_term.sign = -1; // term preceded by subtract

					add_chain_term.prev().addClass("highlight_input");
				}

				new_numerator_list.push(new_term);
			}

			// create new numerator
			let new_numerator = listToAdditiveChain(new_numerator_list);

			// replace first matching term with combined fraction
			const new_frac = QETerm.create({ type: "FUNCTION", value: "frac" });
			new_frac.pushChild(new_numerator);
			new_frac.pushChild(new_denominator);
			new_frac.addClass("highlight_output");

			// create new add chain from new_frac and any non-matching terms
			let first_rational_found = false;
			const new_chain_list = [];
			for (let j = 0; j < add_chain_terms.length; j++) {
				const add_chain_term = add_chain_terms[j];

				if (!add_chain_term.isRationalFrac() && add_chain_term.type != "RATIONAL") {
					let new_term = add_chain_term.clone();

					// retain sign
					if (!j) {
						new_term.sign = add_chain_term.sign;
					} else if (add_chain_term.prev().type == "SUBTRACT") {
						new_term.sign = -1; // term preceded by subtract
					}

					new_chain_list.push(new_term);
				} else if (!first_rational_found) {
					// replace the first rational frac / rational with new_frac, ignore the rest
					new_chain_list.push(new_frac);
					first_rational_found = true;
				}
			}
			let new_chain = listToAdditiveChain(new_chain_list);

			return {
				old_term: chain,
				new_term: new_chain,
				type: "tree",
				desc: desc,
			};
		}
	}
	/**
	 * CT_combineAdditiveChainPolyFractions	Add/subtract polynomial fractions and terms in an additive CHAIN by finding the LCD of the terms. E.g. frac{x,2} + x
	 */
	static CT_combineAdditiveChainPolyFractions(input_value, options) {
		if (input_value.type != "tree") return undefined;
		const root = input_value.value;

		// filter to addititive CHAINs containing 1 or more fractions
		const terms = root.findAllChildren(function (node) {
			if (node.isAddChain()) {
				if (node.children.filter(function (child) {
						return child.value == "frac";
					}).length > 0
				) {
					return true;
				}
			}
		});

		if (!terms.length) {
			return undefined;
		}

		let desc = "Add/subtract polynomial fractions.";

		// check for 2 or more rational fractions or rationals, e.g. "2 + frac{1,3}"
		for (let i = 0; i < terms.length; i++) {
			const chain = terms[i];
			const add_chain_terms = additiveChainToList(chain);

			// build factor map of each term in the chain
			const factor_maps = [];
			for (let j = 0; j < add_chain_terms.length; j++) {
				const add_chain_term = add_chain_terms[j];
				factor_maps.push(getCharacterizedTermFactors(add_chain_term));
			}

			// group terms by non-rational components
			const non_rational_bases = {};
			for (let j = 0; j < factor_maps.length; j++) {
				let factor_map = factor_maps[j];

				// build a string key from the non-number factors of the term
				let base_key = generateNonRationalBaseKeyFromFactors(factor_map);

				// group terms
				if (!non_rational_bases[base_key]) {
					non_rational_bases[base_key] = [];
				}
				non_rational_bases[base_key].push(j); // keep the index of the matching term
			}

			// check for a non_rational_base with 2 or more matching terms
			let matching_key = '';
			Object.keys(non_rational_bases).forEach(function(non_rational_base){
				if (!matching_key && non_rational_bases[non_rational_base].length > 1) {
					matching_key = non_rational_base;
				}
			});
			if (!matching_key) {
				// no combinable terms
				continue;
			}

			const matching_term_indices = non_rational_bases[matching_key];

			// find the LCD of the matching terms by getting the max prime factor counts of the denominators
			const new_den_factor_maps = [];
			for (let j = 0; j < matching_term_indices.length; j++) {
				const add_chain_term = add_chain_terms[ matching_term_indices[j] ];
				if (add_chain_term.value != "frac") {
					// limit to fracs
					continue;
				}

				const den_factors = getCharacterizedTermFactors(add_chain_term.children[1]);
				new_den_factor_maps.push(den_factors);
			}
			const new_den_factor_union = getUnionOfCharacterizedTermListFactors(new_den_factor_maps);
			const new_denominator = generateTermFromFactors(new_den_factor_union);

			// begin building the new numerator
			const new_numerator_list = [];
			for (let j = 0; j < matching_term_indices.length; j++) {
				const add_chain_term = add_chain_terms[ matching_term_indices[j] ];

				let new_term;
				if (add_chain_term.value == "frac") {
					// create new multiplied term from this frac's numerator and the scaling factor (equal to factor difference between frac denominator and new_denominator)

					// get factor difference between denominator and new_denominator -> scaling factor
					let term_den_factors = getCharacterizedTermFactors(add_chain_term.children[1]);
					let factor_diff = getDifferenceOfFactorMaps(new_den_factor_union, term_den_factors);
					let scaling_factor = generateTermFromFactors(factor_diff);

					if (scaling_factor.value == "1") {
						new_term = add_chain_term.children[0].clone();
					} else {
						new_term = listToMultiplicativeTerm([scaling_factor, add_chain_term.children[0]]);
					}
				} else {
					new_term = listToMultiplicativeTerm([new_denominator, add_chain_term]);
				}
				add_chain_term.addClass("highlight_input");

				// retain sign
				if (!matching_term_indices[j]) {
					new_term.sign = add_chain_term.sign;
				} else if (add_chain_term.prev().type == "SUBTRACT") {
					new_term.sign = -1; // term preceded by subtract

					add_chain_term.prev().addClass("highlight_input");
				}

				new_numerator_list.push(new_term);
			}
			const new_numerator = listToAdditiveChain(new_numerator_list);

			// replace first matching term with combined fraction
			const new_frac = QETerm.create({ type: "FUNCTION", value: "frac" });
			new_frac.pushChild(new_numerator);
			new_frac.pushChild(new_denominator);
			new_frac.addClass("highlight_output");

			// create new add chain from new_frac and any non-matching terms
			let first_matching_term_found = false;
			const new_chain_list = [];
			for (let j = 0; j < add_chain_terms.length; j++) {
				const add_chain_term = add_chain_terms[j];

				if (matching_term_indices.indexOf(j) == -1) {
					// not one of the matching terms: simply clone
					let new_term = add_chain_term.clone();

					// retain sign
					if (!j) {
						new_term.sign = add_chain_term.sign;
					} else if (add_chain_term.prev().type == "SUBTRACT") {
						new_term.sign = -1; // term preceded by subtract
					}

					new_chain_list.push(new_term);
				} else if (!first_matching_term_found) {
					// replace the first matching term with new_frac, ignore the rest
					new_chain_list.push(new_frac);
					first_matching_term_found = true;
				}
				// else ignore
			}
			let new_chain = listToAdditiveChain(new_chain_list);

			return {
				old_term: chain,
				new_term: new_chain,
				type: "tree",
				desc: desc,
			};
		}
	}
}
