/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import { TagElement } from '../common/QEWidget';
import { SvgRenderingContext } from '../common/SvgRenderingContext';

import { Arc } from './Graph/Arc';
import { Jump } from './Graph/Jump';
import { Label } from './Graph/Label';
import { Line } from './Graph/Line';
import { Point } from './Graph/Point';
import { Polygon } from './Graph/Polygon';

function showWatermark(logo, watermarkPos: string, canvas: TagElement, ctx: SvgRenderingContext) {
	// Logo dimensions 400x100
	const width = 100;
	const height = 25;
	let x = 0;
	let y = 0;

	switch (parseInt(watermarkPos)) {
		case 1:
			x = canvas.width - width;
			break;
		case 3:
			y = canvas.height - height;
			break;
		default:
			x = canvas.width - width;
			y = canvas.height - height;
	}

	// Place logo on a white bg for better clarity
	
	ctx.beginPath();
	ctx.rect(x, y, width, height);
	ctx.fillStyle = 'rgba(255,255,255,0.5)';
	// ctx.fillStyle = '#FFF';
	ctx.fill();
	ctx.closePath();

//	ctx.drawImage(logo, x, y, width, height);
	ctx.drawForeignObject(logo, x, y, width, height);
}

export class QEGraph {
	padding_left: number;
	padding_right: number;
	padding_top: number;
	padding_bottom: number;
	plot_width: number;
	plot_height: number;

	show_border: boolean;
	border_line_color: string;
	border_line_width: number;

	axis_line_color: string;
	axis_line_width: number;

	x_min: number;
	x_max: number;
	x_step_major: number;
	x_step_minor: number;

	y_min: number;
	y_max: number;
	y_step_major: number;
	y_step_minor: number;

	show_x_axis: boolean;
	show_x_axis_labels: boolean;
	x_axis_label_pos: string;

	show_y_axis: boolean;
	show_y_axis_labels: boolean;
	y_axis_label_pos: string;

	show_grid: boolean;
	gridMinorLineColor: string;
	gridMinorLineWidth: number;
	gridMajorLineColor: string;
	gridMajorLineWidth: number;

	x_max_arrow: boolean;
	x_min_arrow: boolean;
	y_max_arrow: boolean;
	y_min_arrow: boolean;

	// line
	is_number_line: boolean;

	// labels
	fontSize: number;
	background: boolean;
	backgroundColor: string;
	watermark: boolean;
	watermarkPos: string;

	canvas: TagElement;
	savedImageData: ImageData;
	datasets: any;
	logo: any;
	init_data: any;

	constructor(data) {
		const self = this;

		// store init data, so it can be used to create clone graphs when handling user input
		self.init_data = data;

		// plot config
		this.padding_left = 15;
		this.padding_right = 15;
		this.padding_top = 15;
		this.padding_bottom = 15;

		this.plot_width = 300;
		this.plot_height = 300;

		this.show_border = true;
		this.border_line_color = "#CCC";
		this.border_line_width = 1;

		// axes
		this.axis_line_color = "#333";
		this.axis_line_width = 1.5;

		this.x_min = -10;
		this.x_max = 10;
		this.x_step_major = 4;
		// this.x_step_minor = 0;
		this.y_min = -10;
		this.y_max = 10;
		this.y_step_major = 4;
		//	this.y_step_minor = 1;
		this.show_x_axis = true;
		this.show_x_axis_labels = true;
		this.x_axis_label_pos = "S";

		this.show_y_axis = true;
		this.show_y_axis_labels = true;
		this.y_axis_label_pos = "W";

		// grid
		this.show_grid = true;
		this.gridMinorLineColor = "#CCC";
		this.gridMinorLineWidth = 1;
		this.gridMajorLineColor = "#888";
		this.gridMajorLineWidth = 1;

		// line
		this.is_number_line = false;

		// labels
		this.fontSize = 16;
		this.background = false;
		this.backgroundColor = "#FFF";
		this.watermark = true;
		this.watermarkPos = "4"; // graph quadrant

		// override defaults with passed-in values
		Object.assign(this, data);

		if (this.is_number_line) {
			this.plot_width = 400;
		}

		// cast graph values to ints
		["padding_left", "padding_right", "padding_top", "padding_bottom", "plot_width", "plot_height"].map(function (x) {
			self[x] = parseInt(self[x]);
		});

		// cast axis values to floats
		["x_min", "x_max", "y_min", "y_max", "x_step_minor", "x_step_major", "y_step_minor", "y_step_major"].map(function (x) {
			self[x] = parseFloat(self[x]);
		});
	}
	fixFloat(num: undefined | number | string): undefined | number {
		if (typeof num == "undefined") {
			return num;
		} else if (typeof num == "string") {
			num = parseFloat(num);
		}
		num = num.toFixed(12);
		num = num.replace(/\.?0*$/, "");
		num = parseFloat(num);
		return num;
	}
	setFont(ctx: SvgRenderingContext, options?: { fontSize?: number; fontWeight?: number }): void {
		options = options || {};

		const fontSize = options.fontSize || this.fontSize;
		const fontFamily = "PT Mono, Courier New";
		const fontWeight = options.fontWeight || "";

		if (fontWeight) {
			ctx.font = fontWeight + " " + fontSize + "px " + fontFamily;
		} else ctx.font = fontSize + "px " + fontFamily;
	}
	init(options?: any): void {
		options = options || "";

		// Apply the new graph options
		if (options) {
			for (const prop in options) {
				this[prop] = options[prop];
			}
		}

		// validate (x|y)_(min|max)
		if (isNaN(this.x_min)) {
			this.x_min = -10;
		}
		if (isNaN(this.x_max)) {
			this.x_max = 10;
		}
		if (isNaN(this.y_min)) {
			this.y_min = -10;
		}
		if (isNaN(this.y_max)) {
			this.y_max = 10;
		}

		// ensure min/max values are in order
		if (this.x_min > this.x_max) {
			const temp = this.x_min;
			this.x_min = this.x_max;
			this.x_max = temp;
		}
		if (this.y_min > this.y_max) {
			const temp = this.y_min;
			this.y_min = this.y_max;
			this.y_max = temp;
		}

		// ensure min/max values aren't equal
		if (this.x_min == this.x_max) {
			this.x_max += 1;
		}
		if (this.y_min == this.y_max) {
			this.y_max += 1;
		}

		// validate (x|y)_step_major
		if (!this.x_step_major || isNaN(this.x_step_major)) {
			this.x_step_major = 4;
		}
		if (!this.y_step_major || isNaN(this.y_step_major)) {
			this.y_step_major = 4;
		}

		// validate (x|y)_step_minor, else default to 1/4 of (x|y)_step_major
		if ((!this.x_step_minor || isNaN(this.x_step_minor)) && !this.is_number_line) {
			this.x_step_minor = this.x_step_major / 4;
		}
		if (!this.y_step_minor || isNaN(this.y_step_minor)) {
			this.y_step_minor = this.y_step_major / 4;
		}

		// force (x|y)_steps positive
		this.x_step_major = Math.abs(this.x_step_major);
		this.x_step_minor = Math.abs(this.x_step_minor);
		this.y_step_major = Math.abs(this.y_step_major);
		this.y_step_minor = Math.abs(this.y_step_minor);

		// TODO: auto-scale major/minor step sizes if auto_scale_grid set
		// verify (x|y)MajorStep is an integer multiple of (x|y)MinorStep, else round to nearest multiple
		const x_step_ratio = this.x_step_major / this.x_step_minor;
		if ((Math.floor(x_step_ratio) != this.fixFloat(x_step_ratio) || x_step_ratio > 20) && !this.is_number_line) {
			let nearest_multiple = Math.round(x_step_ratio);

			// limit major/minor step ratio
			if (nearest_multiple < 2) {
				nearest_multiple = 2;
			}
			if (nearest_multiple > 20) {
				nearest_multiple = 20;
			}

			this.x_step_minor = this.fixFloat(this.x_step_major / nearest_multiple);
		}

		const y_step_ratio = this.y_step_major / this.y_step_minor;
		if (Math.floor(y_step_ratio) != this.fixFloat(y_step_ratio) || y_step_ratio > 20) {
			let nearest_multiple = Math.round(y_step_ratio);

			// limit major/minor step ratio
			if (nearest_multiple < 2) {
				nearest_multiple = 2;
			}
			if (nearest_multiple > 20) {
				nearest_multiple = 20;
			}

			this.y_step_minor = this.fixFloat(this.y_step_major / nearest_multiple);
		}

		// TODO: limit minimum pixel width of minor and major steps?
		/*
			// verify (x|y)MinorStep is a minimum pixel width, else set to a multiple (2x or 5x) to ensure minimum pixel width
			var minMinorStepPx = 10;
			var max_step_minorPx = 20;
	    
			var stepWidth = this.plot_width * this.x_step_minor / (this.x_max - this.x_min);
	    
			while (stepWidth < minMinorStepPx) {
				if (stepWidth * 2 >= minMinorStepPx) {
					this.x_step_major *= 2;
					this.x_step_minor *= 2;
				} else if (stepWidth * 5 >= minMinorStepPx) {
					this.x_step_major *= 5;
					this.x_step_minor *= 5;
				} else {
					this.x_step_major *= 10;
					this.x_step_minor *= 10;
				}
				stepWidth = this.plot_width * this.x_step_minor / (this.x_max - this.x_min);
			}
			while (stepWidth > max_step_minorPx) {
				if (stepWidth / 2 < max_step_minorPx) {
					this.x_step_major /= 2;
					this.x_step_minor /= 2;
				} else if (stepWidth / 5 < max_step_minorPx) {
					this.x_step_major /= 5;
					this.x_step_minor /= 5;
				} else {
					this.x_step_major /= 10;
					this.x_step_minor /= 10;
				}
				stepWidth = this.plot_width * this.x_step_minor / (this.x_max - this.x_min);
			}
	    
			var stepHeight = this.plot_height * this.y_step_minor / (this.y_max - this.y_min);
			while (stepHeight < minMinorStepPx) {
				if (stepHeight * 2 >= minMinorStepPx) {
					this.y_step_major *= 2;
					this.y_step_minor *= 2;
				} else if (stepHeight * 5 >= minMinorStepPx) {
					this.y_step_major *= 5;
					this.y_step_minor *= 5;
				} else {
					this.y_step_major *= 10;
					this.y_step_minor *= 10;
				}
				stepHeight = this.plot_height * this.y_step_minor / (this.y_max - this.y_min);
			}
		*/
		// TODO: trim values using this.fixFloat
	}
	initCanvas(): TagElement {
		// the "canvas" here is actually an svg, but all CanvasRenderingContext2D calls are emulated using SvgRenderingContext
		this.canvas = new TagElement("svg");
		this.canvas.setAttribute("xmlns", "http://www.w3.org/2000/svg");

		const canvas = this.canvas;

		// Size the canvas
		canvas.width = this.padding_left + this.plot_width + this.padding_right;
		canvas.height = this.padding_top + this.plot_height + this.padding_bottom;

		return canvas;
	}
	screenX(x: number): number {
		return this.padding_left + this.plot_width * ((x - this.x_min) / (this.x_max - this.x_min));
	}
	screenY(y: number): number {
		return this.padding_top + this.plot_height - this.plot_height * ((y - this.y_min) / (this.y_max - this.y_min));
	}
	pixelsToXWidth(px: number): number {
		return (px * (this.x_max - this.x_min)) / this.plot_width;
	}
	pixelsToYHeight(px: number): number {
		return (px * (this.y_max - this.y_min)) / this.plot_height;
	}
	xWidthToPixels(x: number): number {
		return (x * this.plot_width) / (this.x_max - this.x_min);
	}
	yHeightToPixels(y: number): number {
		return (y * this.plot_height) / (this.y_max - this.y_min);
	}
	formatIntervalValue(num: number): string {
		return this.fixFloat(num).toString();
	}
	draw(datasets) {
		const self = this;

		datasets = datasets || [];
		this.datasets = datasets;

		this.init(); // ensure all values set

		// the "canvas" here is actually an svg, but all CanvasRenderingContext2D calls are emulated using SvgRenderingContext
		const canvas = this.initCanvas();
		const ctx = canvas.getContext("2d");

		// the svg requires width and height to be tag attributes
		canvas.setAttribute("viewBox", [0, 0, (canvas.width || 0), (canvas.height || 0)].join(" "));
		canvas.setAttribute("width", "100%");
		canvas.setAttribute("height", (canvas.height || 0).toString());

		// offset by a half-pixel to avoid aliasing
		ctx.translate(0.5, 0.5);

		// add a name to the translate group so we can identify it later
		ctx.current_element.setAttribute("name", "top_group");

		if (this.show_border) {
			ctx.strokeStyle = this.border_line_color;

			const line_options = {
				lineType: "segment",
				line_color: this.border_line_color,
				line_width: this.border_line_width,
			};

			this.new("Line", Object.assign({ x1: this.x_min, y1: this.y_min, x2: this.x_max, y2: this.y_min }, line_options)).plot(this, ctx);
			this.new("Line", Object.assign({ x1: this.x_min, y1: this.y_max, x2: this.x_max, y2: this.y_max }, line_options)).plot(this, ctx);
			this.new("Line", Object.assign({ x1: this.x_min, y1: this.y_min, x2: this.x_min, y2: this.y_max }, line_options)).plot(this, ctx);
			this.new("Line", Object.assign({ x1: this.x_max, y1: this.y_min, x2: this.x_max, y2: this.y_max }, line_options)).plot(this, ctx);
		}

		// Draw the grid
		// For grid lines that fall on the axis intervals, match the axes line thickness
		if (this.show_grid && !this.is_number_line) {
			// Draw the horizontal grid lines
			let minMinorStep = Math.round(this.y_min / this.y_step_minor);
			let max_step_minor = Math.round(this.y_max / this.y_step_minor);
			let minorStepsPerMajor = Math.round(this.y_step_major / this.y_step_minor);
			for (let step = minMinorStep; step <= max_step_minor; step++) {
				if (step % minorStepsPerMajor == 0) {
					ctx.strokeStyle = this.gridMajorLineColor;
					this.new("Line", {
						lineType: "segment",
						line_color: this.gridMajorLineColor,
						line_width: this.gridMajorLineWidth,
						x1: this.x_min,
						y1: step * this.y_step_minor,
						x2: this.x_max,
						y2: step * this.y_step_minor,
					}).plot(this, ctx);
				} else {
					ctx.strokeStyle = this.gridMinorLineColor;
					this.new("Line", {
						lineType: "segment",
						line_color: this.gridMinorLineColor,
						line_width: this.gridMinorLineWidth,
						x1: this.x_min,
						y1: step * this.y_step_minor,
						x2: this.x_max,
						y2: step * this.y_step_minor,
					}).plot(this, ctx);
				}
			}

			// Draw the vertical grid lines
			minMinorStep = Math.round(this.x_min / this.x_step_minor);
			max_step_minor = Math.round(this.x_max / this.x_step_minor);
			minorStepsPerMajor = Math.round(this.x_step_major / this.x_step_minor);
			for (let step = minMinorStep; step <= max_step_minor; step++) {
				if (step % minorStepsPerMajor == 0) {
					ctx.strokeStyle = this.gridMajorLineColor;
					this.new("Line", {
						lineType: "segment",
						line_color: this.gridMajorLineColor,
						line_width: this.gridMajorLineWidth,
						x1: step * this.x_step_minor,
						y1: this.y_min,
						x2: step * this.x_step_minor,
						y2: this.y_max,
					}).plot(this, ctx);
				} else {
					ctx.strokeStyle = this.gridMinorLineColor;
					this.new("Line", {
						lineType: "segment",
						line_color: this.gridMinorLineColor,
						line_width: this.gridMinorLineWidth,
						x1: step * this.x_step_minor,
						y1: this.y_min,
						x2: step * this.x_step_minor,
						y2: this.y_max,
					}).plot(this, ctx);
				}
			}
		}

		let axis_tick_length = 6;

		// Draw the Axes
		if (this.show_y_axis) {
			// Draw the y axis line
			this.new("Line", {
				lineType: "segment",
				line_color: this.axis_line_color,
				line_width: this.axis_line_width,
				x1: 0,
				y1: this.y_min,
				x2: 0,
				y2: this.y_max,
				start_point: this.y_min_arrow ? "arrow" : "none",
				end_point: this.y_max_arrow ? "arrow" : "none",
			}).plot(this, ctx);

			const minStep = Math.round(this.y_min / this.y_step_major);
			const maxStep = Math.round(this.y_max / this.y_step_major);
			for (let step = minStep; step <= maxStep; step++) {
				// Draw the interval mark
				this.new("Line", {
					x1: -this.pixelsToXWidth(axis_tick_length),
					y1: step * this.y_step_major,
					x2: this.pixelsToXWidth(axis_tick_length),
					y2: step * this.y_step_major,
					lineType: "segment",
					line_color: this.axis_line_color,
					line_width: this.axis_line_width,
				}).plot(this, ctx);

				// Add the interval labels
				if (this.show_y_axis_labels) {
					const label_options = {
						x: 0,
						y: step * this.y_step_major,
						color: this.axis_line_color,
						value: this.formatIntervalValue(step * this.y_step_major),
						pos: this.y_axis_label_pos,
					};

					// if we're showing the x-axis, move the origin label
					if (!step && this.show_x_axis) {
						label_options.pos = "SW";
					}

					this.new("Label", label_options).plot(this, ctx);
				}
			}
		}

		if (this.show_x_axis) {
			// Draw the x axis line
			new Line({
				line_color: this.axis_line_color,
				line_width: this.axis_line_width,
				x1: this.x_min,
				y1: 0,
				x2: this.x_max,
				y2: 0,
				start_point: this.x_min_arrow ? "arrow" : "none",
				end_point: this.x_max_arrow ? "arrow" : "none",
			}).plot(this, ctx);

			axis_tick_length = this.is_number_line ? axis_tick_length * 2 : axis_tick_length;
			const show_minor_tick = this.is_number_line && this.x_step_minor != 0;
			const axis_minor_tick_length = axis_tick_length / 2;
			let min_step = Math.round(this.x_min / this.x_step_major);
			let max_step = Math.round(this.x_max / this.x_step_major);

			let minorStepsPerMajor = 1;
			let step_size = this.x_step_major;
			if (show_minor_tick) {
				min_step = Math.round(this.x_min / this.x_step_minor);
				max_step = Math.round(this.x_max / this.x_step_minor);
				minorStepsPerMajor = Math.round(this.x_step_major / this.x_step_minor);
				step_size = this.x_step_minor;
			}

			for (let step = min_step; step <= max_step; step++) {
				// TODO: check if showAxisSteps
				// Draw the interval mark
				if (step % minorStepsPerMajor == 0) {
					new Line({
						x1: step * step_size,
						y1: -this.pixelsToYHeight(axis_tick_length),
						x2: step * step_size,
						y2: this.pixelsToYHeight(axis_tick_length),
						line_color: this.axis_line_color,
						line_width: this.axis_line_width,
					}).plot(this, ctx);

					// Add the interval labels
					if (this.show_x_axis_labels) {
						const label_options = {
							x: step * step_size,
							y: 0,
							color: this.axis_line_color,
							value: this.formatIntervalValue(step * step_size),
							pos: this.x_axis_label_pos,
						};

						// show the label, unless it's the origin and has already been rendered on the y-axis
						if (step || !this.show_y_axis) {
							new Label(label_options).plot(this, ctx);
						}
					}
				} else {
					new Line({
						x1: step * step_size,
						y1: -this.pixelsToYHeight(axis_minor_tick_length),
						x2: step * step_size,
						y2: this.pixelsToYHeight(axis_minor_tick_length),
						line_color: this.axis_line_color,
						line_width: this.axis_line_width,
					}).plot(this, ctx);
				}
			}
		}

		// Draw watermark - foreignObject img tag
		if (this.watermark) {
			showWatermark('<img src="/img/logo-gray-hd-2.png"/>', this.watermarkPos, canvas, ctx);
		}

		this.drawSeries(this.datasets);

		let output = ctx.serialize();
		return output;
	}

	drawSeries(datasets): void {
		const self = this;

		datasets = datasets || [];

		// draw the graph data
		for (let i = 0; i < datasets.length; i++) {
			this.plot(datasets[i]);
		}
	}
/*
	// NOTE: these are unused
	save(): void {
		if (this.canvas) {
			const ctx = this.canvas.getContext("2d");
			this.savedImageData = ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
		}
	}
	reset(): void {
		if (this.canvas && this.savedImageData) {
			const ctx = this.canvas.getContext("2d");
			ctx.putImageData(this.savedImageData, 0, 0);
		}
	}
	refresh(queue): void {
		queue = queue || [];
		// console.log(queue);
		// Reset and run the queued calls
		this.reset();
		if (queue.length) {
			for (let i = 0; i < queue.length; i++) {
				// console.log(queue[i]);
				if (typeof queue[i] === "function") queue[i]();
			}
		}
	}
*/
	new(component, data): any {
		const defaults = {
			color: "#D00",
			label: "",
			label_pos: "N",
		};

		////////////////////////////////////////////////////////////////
		switch (component) {
			case "Arc":
				return new Arc(data);
			case "Jump":
				return new Jump(data);
			case "Label":
				return new Label(data);
			case "Line":
				return new Line(data);
			case "Point":
				return new Point(data);
			case "Polygon":
				return new Polygon(data);
		}
		return;
	}
	// Generic call?
	plot(series): void {
		const ctx = this.canvas.getContext("2d");
		const plotTypes = {
			arcs: "Arc",
			jumps: "Jump",
			labels: "Label",
			lines: "Line",
			segments: "Line",
			points: "Point",
			polygon: "Polygon",
		};

		const series_type = series.type;
		const series_values = series.values;

		for (let i = 0; i < series_values.length; i++) {
			// pass series display_options with data values
			const series_data = Object.assign({}, series_values[i]);
			Object.assign(series_data, series.display_options);

			// plot each value in the series
			this.new(plotTypes[series_type], series_data).plot(this, ctx);
		}
	}
}
