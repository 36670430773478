import { tokenize_and_parse } from '../QEGrammar';
import { QEHelper, numberToDecimal, QEValue, QEValueTree, QEValueString, QEValueJSON, QEValueMap, QEValueBoolean, QEValueWidget, QEValueAnswer } from '../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../QEWidget';
import { QEWidgetDropDown as DropDown } from './DropDown';
import { QEEqKeyboard as EqKeyboard } from 'QEEqKeyboard'; // aliased in webpack.config.js

//////////////////////////////////////////////////////////////
export class QEWidgetTable extends QEWidget {
	values: { headers: QEValue[], label_col: QEValue[], rows: QEValue[][] };
	show_header_row: Boolean;
	show_label_col: Boolean;
	display_options: DisplayOptions;

	constructor(values, display_options: DisplayOptions = {}) {
		super();

		this.values = values;
		this.display_options = display_options;
		this.show_header_row = display_options.show_header_row;
		this.show_label_col = display_options.show_label_col;
	}

	static instantiate(serialized, resolved_data, options) {
		options = options || {};

		let deserialized = JSON.parse(serialized);

		// resolve any [$name] placeholders in display_options fields
		let display_options = deserialized.display_options || {};
		for (let field_name in display_options) {
			if (!display_options.hasOwnProperty(field_name)) continue;

			let field_value = QEHelper.resolvePlaceholderToRefs(display_options[field_name], resolved_data);

			// check if there was an unresolved dependency
			if (!field_value) return null;
			display_options[field_name] = field_value.value;
		}

		// NOTE: deserialized data should contain "values" and "display_options"
		// - "values" should be a json map containing "rows", "headers", and "label_col"
		// - "rows" should be an array of array entries. Each "row" is an array containing either string/numeric values, or maps with type and value

		let show_header_row = deserialized.show_header_row;
		if (show_header_row === undefined) {
			show_header_row = true; // not set in legacy data
		}
		var show_label_col = deserialized.show_label_col || false;

		let values = deserialized.values;
		let headers = values.headers;
		let label_col = values.label_col || [];
		if (!values.label_col) {
			values.label_col = label_col;
		}

		let rows = values.rows;

		function resolveLabelCell(cell) {
			// if cell contains a data map (i.e. from regenerated export data), then use map "value" field
			if (typeof cell == 'object') {
				cell = cell.value;
			}

			// use prefixes to determine placeholder output_type
			let output_type = "tree";
			let evaluate_cell = false;

			// check if cell starts with "=", and if so evaluate the remaining expression to a float
			if (typeof cell.match === 'function' && cell.match(/^=/)) {
				cell = cell.slice(1); // strip leading '='
				evaluate_cell = true;
			} else if (typeof cell.match === 'function' && cell.match(/^"/)) {
				cell = cell.slice(1); // strip leading '"'
				cell = cell.replace(/"$/, ''); // strip trailing '"'
				output_type = "string";
			}
			// TODO: prefix to force to tree? "$"?

			let resolved_value;
			if (evaluate_cell)
				// must resolve to tree if we need to evaluate
				resolved_value = QEHelper.resolvePlaceholderToTree(cell, resolved_data);
			else if (output_type === "string")
				resolved_value = QEHelper.resolvePlaceholderToString(cell, resolved_data);
			else
				resolved_value = QEHelper.resolvePlaceholderToRefs(cell, resolved_data);

			if (!resolved_value)
				return null;

			// resolve value as display data, to capture "display_as" or other display directives
			let display_ml;
			if (evaluate_cell) {
				let value = numberToDecimal(resolved_value.value.evaluate_to_float());
				resolved_value = QEHelper.resolvePlaceholderToTree(value, {});

				display_ml = resolved_value.display();
			} else {
				let resolved_display = QEHelper.resolvePlaceholderToMarkup(cell, resolved_data);
				if (!resolved_display) return null;

				display_ml = resolved_display.value;
			}

			return { value: resolved_value, display: display_ml };
		}

		if (show_header_row) {
			// iterate over each header column and resolve
			for (let col = 0; col < headers.length; col++) {
				let resolved_cell = resolveLabelCell(headers[col]);
				if (!resolved_cell) {
					return null;
				}
				headers[col] = resolved_cell;
			}
		}

		if (show_label_col) {
			// iterate over each label col row and resolve
			for (let row = 0; row < label_col.length; row++) {
				let resolved_cell = resolveLabelCell(label_col[row]);
				if (!resolved_cell) {
					return null;
				}
				label_col[row] = resolved_cell;
			}
		}

		// row_index, col_index, and resolved cell values can be accessed in cell placeholder resolution
		let resolved_cells = {
			row_index: undefined,
			col_index: undefined,
		};

		// loop through resolution for cells until all resolved or no further resolution progress made
		let resolving = 1;
		let num_unresolved_cells = rows.length * headers.length; // all unresolved

		// track keyboard references as they are resolved, and assign combined widget key
		const input_widget_refs = [];

		while (resolving) {
			resolving = 0;

			if (!num_unresolved_cells) {
				continue;
			}

			// compare whether num_unresolved_params has changed
			let new_num_unresolved_cells = 0;

			for (let row = 0; row < rows.length; row++) {
				resolved_cells.row_index = QEHelper.resolvePlaceholderToTree(row, {});

				for (let col = 0; col < rows[row].length; col++) {
					resolved_cells.col_index = QEHelper.resolvePlaceholderToTree(col, {});

					// skip if already resolved
					if (resolved_cells['cell_' + col + '_' + row]) {
						continue;
					}

					let cell = rows[row][col];

					// if cell contains a data map (i.e. from regenerated export data), then use map "value" field
					if (typeof cell == 'object') {
						cell = cell.value;
					}

					// use prefixes to determine placeholder output_type
					let output_type = "";
					let evaluate_cell = false;

					// check if cell starts with "=", and if so evaluate the remaining expression to a float
					if (typeof cell.match === 'function' && cell.match(/^=/)) {
						cell = cell.slice(1); // strip leading '='
						evaluate_cell = true;
					} else if (typeof cell.match === 'function' && cell.match(/^"/)) {
						cell = cell.slice(1); // strip leading '"'
						cell = cell.replace(/"$/, ''); // strip trailing '"'
						output_type = "string";
					}
					// TODO: prefix to force to tree? "$"?

					let resolved_value;
					if (evaluate_cell)
						// resolve to tree if we need to evaluate
						resolved_value = QEHelper.resolvePlaceholderToTree(cell, { resolved: Object.assign(resolved_cells, resolved_data.resolved) });
					else if (output_type === "string")
						resolved_value = QEHelper.resolvePlaceholderToString(cell, { resolved: Object.assign(resolved_cells, resolved_data.resolved) });
					else
						resolved_value = QEHelper.resolvePlaceholderToRefs(cell, { resolved: Object.assign(resolved_cells, resolved_data.resolved) });

					if (!resolved_value) {
						new_num_unresolved_cells++;
						continue;
					}

					// track keyboard/dropdown instances so we can assign them individual keys
					if (resolved_value instanceof QEValueWidget &&
						(resolved_value.value instanceof EqKeyboard || resolved_value.value instanceof DropDown)
					) {
						const iw_key = resolved_value.value.name;
						resolved_value.value.input_key_index = input_widget_refs.length;

						input_widget_refs.push(iw_key);
					}

					// resolve value as display data, to capture "display_as" or other display directives
					let display_ml;
					if (evaluate_cell) {
						// check value type - if already a tree, evaluate_to_float, else first parse to tree
						let value;
						if (resolved_value.type == "string") {
							let parsed = tokenize_and_parse(resolved_value.value, {});
							if (parsed.tree == null) {
								console.log("Warning: failed to evaluate cell");
								continue;
							}
							value = numberToDecimal(parsed.tree.evaluate_to_float());
						} else {
							// TODO: check for other types - not necessarily a tree
							value = numberToDecimal(resolved_value.value.evaluate_to_float());
						}
						resolved_value = QEHelper.resolvePlaceholderToTree(value, {});

						// handle forced cell output format
						if (display_options.cell_display == "text") {
							display_ml = resolved_value.serialize_to_text();
						} else {
							display_ml = resolved_value.display();
						}
					} else {
						// handle forced cell output format
						let resolved_display;
						if (resolved_value instanceof QEValueWidget) {
							// if cell contains a Widget, ignore cell display formatting
							resolved_display = resolved_value;
						} else if (display_options.cell_display == "text") {
							resolved_display = QEHelper.resolvePlaceholderToString(cell, { resolved: Object.assign(resolved_cells, resolved_data.resolved) });
						} else if (display_options.cell_display == "tree") {
							resolved_display = QEHelper.resolvePlaceholderToTree(cell, { resolved: Object.assign(resolved_cells, resolved_data.resolved) });
						} else {
							resolved_display = QEHelper.resolvePlaceholderToMarkup(cell, { resolved: Object.assign(resolved_cells, resolved_data.resolved) });
						}
						display_ml = resolved_display.display();
					}

					rows[row][col] = { value: resolved_value, display: display_ml };

					// save cell value, for referencing by other cells
					resolved_cells['cell_' + col + '_' + row] = resolved_value;
				}
			}

			// if we're making progress, we're still resolving
			if (new_num_unresolved_cells < num_unresolved_cells) resolving = 1;
			num_unresolved_cells = new_num_unresolved_cells;
		}

		if (num_unresolved_cells) {
			// unresolved cells
			return null;
		}

		let widget = new QEWidgetTable(values, Object.assign({}, display_options, {show_header_row: show_header_row, show_label_col: show_label_col}));
		return widget;
	}

	getCell(cell_key: string, resolved_data) {
		const cell_key_parts = cell_key.split(/,/);
		if (cell_key_parts.length !== 2) {
			console.log('Error: cell_key should follow format "col,row", e.g. "0,2". ', cell_key);
			return null;
		}

		// resolve any col_key or row_key placeholders
		const col_num = QEHelper.resolveSpecifierKeyToNumber(cell_key_parts[0], resolved_data);
		if (col_num === null) return null;

		const row_num = QEHelper.resolveSpecifierKeyToNumber(cell_key_parts[1], resolved_data);
		if (row_num === null) return null;

		const rows = this.values.rows;

		// validate row and col
		if (!rows[row_num] || !rows[row_num][col_num]) {
			console.log('Error: col,row out of bounds. ', cell_key, rows);
			return null;
		}

		return rows[row_num][col_num];
	}

	getCellValue(cell_key: string, resolved_data) {
		const cell = this.getCell(cell_key, resolved_data);
		if (cell === null) return null;
		return cell.value;
	}

	getCellDisplay(cell_key: string, resolved_data) {
		const cell = this.getCell(cell_key, resolved_data);
		if (cell === null) return null;
		return cell.display;
	}

	getColList(col_key: string, resolved_data) {
		const col_num = QEHelper.resolveSpecifierKeyToNumber(col_key, resolved_data);
		if (col_num === null) return null;

		// bounds check col_num
		const rows = this.values.rows;
		if (!rows[0] || !rows[0][col_num]) {
			console.log('Error: col out of bounds. ', col_num, rows);
			return null;
		}

		// create list{...} from serialized column values
		const values = rows.map(row => { return row[col_num].value.serialize_to_text(); });
		return QEHelper.resolvePlaceholderToTree('list{'+ values.join(',') +'}', {});
	}

	getRowList(row_key: string, resolved_data) {
		const row_num = QEHelper.resolveSpecifierKeyToNumber(row_key, resolved_data);
		if (row_num === null) return null;

		// bounds check row_num
		const rows = this.values.rows;
		if (!rows[row_num]) {
			console.log('Error: row out of bounds. ', row_num, rows);
			return null;
		}

		// create list{...} from serialized row values
		const values = rows[row_num].map(col => { return col.value.serialize_to_text(); });
		return QEHelper.resolvePlaceholderToTree('list{'+ values.join(',') +'}', {});
	}

	/**
	 * Returns widget markup for inclusion in question output
	 * @returns {string} Generated display markup
	 */
	display(options) {
		options = options || {};
		// TODO: support display options, e.g. transposed

		const headers = this.values.headers;
		const label_col = this.values.label_col;
		const rows = this.values.rows;

		// column highlight classes:
		const col_classes = [];
		if (options.highlight_input_col !== undefined) {
			col_classes.push('highlight_input_col' + options.highlight_input_col);
		}
		if (options.highlight_output_col !== undefined) {
			col_classes.push('highlight_output_col' + options.highlight_output_col);
		}
		if (this.show_label_col) {
			col_classes.push('show_label_col');
		}

		let ml = '';
		ml += '<div class="content data-table' + (col_classes.length ? ' ' + col_classes.join(' ') : '') + '">';
		ml += '<table>';

		if (this.show_header_row) {
			ml += '<thead>';
			ml += '<tr name="headers">';

			if (this.show_label_col) {
				// dummy cell for label_col header
				ml += '<th style="border: none;"></th>';
			}

			for (let col = 0; col < headers.length; col++) {
				ml += '<th>';
				const cell = headers[col];
				ml += cell.display;
				ml += '</th>';
			}
			ml += '</tr>';
			ml += '</thead>';
		}

		ml += '<tbody>';
		for (let row = 0; row < rows.length; row++) {
			const row_classes = [];
			if (options.highlight_input_row !== undefined &&
				options.highlight_input_row == row) {
				row_classes.push('highlight_input_row');
			}
			if (options.highlight_output_row !== undefined &&
				options.highlight_output_row == row) {
				row_classes.push('highlight_output_row');
			}

			ml += '<tr name="row' + row + '"' + (row_classes.length ? ' class="' + row_classes.join(' ') + '"' : '') + '>';
			if (this.show_label_col) {
				ml += '<td>';
				const cell = label_col[row] || { display: "" };
				ml += cell.display;
				ml += '</td>';
			}

			for (let col = 0; col < rows[row].length; col++) {
				// cell highlight classes
				const cell_highlight_classes = [];
				if (options.highlight_input_cell !== undefined &&
					options.highlight_input_cell.row == row &&
					options.highlight_input_cell.col == col) {
					cell_highlight_classes.push('highlight_input_cell');
				}
				if (options.highlight_output_cell !== undefined &&
					options.highlight_output_cell.row == row &&
					options.highlight_output_cell.col == col) {
					cell_highlight_classes.push('highlight_output_cell');
				}

				ml += '<td' + (cell_highlight_classes.length ? ' class="' + cell_highlight_classes.join(' ') + '"' : '') + '>';

				const cell = rows[row][col];
				if (cell.value.type == 'string') {
					ml += cell.display;
				} else if (cell.value.type == 'widget') {
//					ml += '<div class="widget' + (options.disable_input ? ' disable_input' : '') + '" data-widget_key="' + cell.key_name + '" style="display: inline-block; vertical-align: middle;">' + cell.display + '</div>';
					ml += cell.display;
				} else if (cell.value.type == 'tree') {
					ml += cell.display;
				} else {
					console.log('Warning: unhandled cell type.', cell);
					ml += cell.display;
				}

				ml += '</td>';
			}
			ml += '</tr>';
		}

		ml += '</tbody>';
		ml += '</table>';
		ml += '</div>';

		return ml;
	}

	getInputValue(input_widgets?): string {
		// generate output JSON from table cells
		// same logic as exportValue, but replace any nested input widget references with the serialized referenced widget value
		const headers = this.values.headers;
		const rows = this.values.rows;
		const export_headers = [];
		const export_rows = [];

		// iterate over each header column, and each row column, and export
		for (let col = 0; col < headers.length; col++) {
			const cell = headers[col];
			export_headers.push({ value: cell.value });
		}

		// row_index, col_index, and resolved cell values can be accessed in cell placeholder resolution
		for (let row_idx = 0; row_idx < rows.length; row_idx++) {
			let export_row = [];
			for (let col_idx = 0; col_idx < rows[row_idx].length; col_idx++) {
				const cell = rows[row_idx][col_idx];
				if (typeof cell.value === "object") {
					// resolve reference to input widgets (i.e. nested keyboards)
					if (cell.key_name && input_widgets[cell.key_name]) {
						const cell_value = input_widgets[cell.key_name].getInputValue(input_widgets);
						export_row.push({ value: cell_value });
					} else {
						console.log("Error: Table cell references input widget, but resolved input_widget not found: ", cell.key_name, input_widgets);
						return;
					}
				} else {
					// push the cell value onto the current output row
					export_row.push(cell);
				}
			}
			export_rows.push(export_row);
		}

		return JSON.stringify({ headers: export_headers, rows: export_rows });
	}

	serialize_to_text() {
		// exportValue produces serialized table data
		return this.exportValue().values;
	}

	exportValue(options?){
		// iterate over rows and cols and serialize each. Table export needed when used as an input widget (with nested keyboards)
		const headers = this.values.headers;
		const rows = this.values.rows;

		const export_headers = [];
		const export_rows = [];

		// iterate over each header column, and each row column, and export
		for (let col = 0; col < headers.length; col++) {
			let cell = headers[col];
			export_headers.push({
				value: QEHelper.exportValue(cell.value),
			});
		}

		// row_index, col_index, and resolved cell values can be accessed in cell placeholder resolution
		for (let row_idx = 0; row_idx < rows.length; row_idx++) {
			let export_row = [];
			for (let col_idx = 0; col_idx < rows[row_idx].length; col_idx++) {
				const cell = rows[row_idx][col_idx];
				export_row.push({
					value: QEHelper.exportValue(cell.value),
				});
			}
			export_rows.push(export_row);
		}

		let export_value = {
			type: 'table',
			values: JSON.stringify({ headers: export_headers, rows: export_rows }),
		};
		return export_value;
	}

	bindEventHandlers(widget_container) {
		// Table input events should be bound directly to any nested KB inputs
		return;
	}
}

