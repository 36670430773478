import { QETerm } from "../../../common/QETerm";
import { GrammarType, tokenize_and_parse, findGrammar } from "../../../common/QEGrammar";
import { QEValConstants } from "../../../common/QEValConstants";
import { QEHelper, QEValue, QEValueMap, QEValueTree } from "../../../common/QEHelper";
import { QEQ } from "../../../common/QE";
import { arrayEquals } from "../Utils";
import { QESolverStep } from '../QESolverStep';
import { comparisonResult } from "../SolverUtils";
import { QESolver, SolverStepOutput } from "QESolver";

export class Evaluate {
	static ComputeOnValues = {
		ADD: function (l, r) {
			return parseInt(l) + parseInt(r);
		},
		SUBTRACT: function (l, r) {
			return parseInt(l) - parseInt(r);
		},
		MULTIPLY: function (l, r) {
			return parseInt(l) * parseInt(r);
		},
		DIVIDE: function (l, r) {
			return parseInt(l) / parseInt(r);
		},
		EXPONENT: function (l, r) {
			l = parseInt(l);
			r = parseInt(r);
			let x = 1;
			for (let i = 0; i < r; i++) {
				x *= l;
			}
			return x;
		}
	}

	static getPrimeFactorList(num: number): number[] {
		if (num <= 1) return [];

		const prime_factors: number[] = [];
		let max = Math.sqrt(num);
		let i = 2;
		while (i <= num && i <= max) {
			if (num % i == 0) {
				prime_factors.push(i);
				num /= i;
				max = Math.sqrt(num);
			} else {
				i++;
			}
		}
		if (i > max) prime_factors.push(num);
		return prime_factors;
	}

	// eg:   getPrimeFactorDecomposition(60) --> [[2,2], [3,1], [5,1]]
	// returns [1,1] factor if called with argument 1
	// never returns 1 otherwise
	static getPrimeFactorDecomposition(num: number): undefined | [number, number][] {
		return QEHelper.getPrimeFactorDecomposition(num);
	}

	static getCountingNumbers(initial_number: number, counting: number): number[] {
		if (counting < 1) return [];

		let i = 1;
		const initial = initial_number;
		const counting_numbers = [];

		while (i <= counting) {
			counting_numbers.push(initial + i);
			i++;
		}

		return counting_numbers;
	}

	static getFactorList(num: number): number[] {
		let is_negative;
		if (num < 0) {
			is_negative = true;
			num = Math.abs(num);
		}

		let factors: number[] = [];
		const max = Math.sqrt(num);
		for (let i = 1; i <= max; i++) {
			if (num % i == 0) {
				if (i == num / i) {
					// num is a square of this factor, so only include the factor once
					factors.splice(factors.length / 2, 0, i);
				} else {
					factors.splice(factors.length / 2, 0, i, num / i);
				}
			}
		}

		if (is_negative) {
			const neg_factors = [];
			for (let i = factors.length - 1; i >= 0; i--) {
				neg_factors.push(-factors[i]);
			}
			factors = neg_factors.concat(factors);
		}

		return factors;
	}

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	static getGCF(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a list with at least 2 items
		if (input_value.value.children[0].type != "FUNCTION" || input_value.value.children[0].value != "list" || input_value.value.children[0].children.length < 2) {
			return undefined;
		}
		const list = input_value.value.children[0];

		// validate each list item is an integer
		const values = [];
		for (let i = 0; i < list.children.length; i++) {
			const list_item = list.children[i];
			const serialized = list_item.serialize_to_text();
			if ( isNaN(parseInt(serialized)) ) return undefined;
			values.push(parseInt(serialized));
		}

		let desc = "";
		desc +=
			"To find the Greatest Common Factor of a set of numbers, first find the factors of each, then find the greatest factor that is common across all of them.<br>";

		// find factors of each, and factor frequency
		const factor_lists = [];
		const factor_counts = {};
		for (let i = 0; i < values.length; i++) {
			const factors = Evaluate.getFactorList(values[i]);
			factor_lists.push(factors);
			factors.map(function (x) {
				if (!factor_counts[x]) factor_counts[x] = 0;
				factor_counts[x]++;
			});
		}

		// find the greatest factor present in all
		let max_factor = 0;
		for (const factor in factor_counts) {
			if (!factor_counts.hasOwnProperty(factor)) continue;

			if (factor_counts[factor] == list.children.length && parseInt(factor) > max_factor) {
				max_factor = parseInt(factor);
			}
		}

		// now display
		for (let i = 0; i < values.length; i++) {
			desc +=
				"<br>The factors of " +
				values[i] +
				" are: " +
				factor_lists[i]
					.map(function (x) {
						if (x == max_factor) return '<span style="font-weight: bold;">' + x + "</span>";
						else return x;
					})
					.join(", ");
		}
		desc += '<br><br>The greatest common factor is: <span style="font-weight: bold;">' + max_factor + "</span>";

		const result = {
			desc: desc,
			old_term: list,
			new_term: QETerm.create({ type: "RATIONAL", value: max_factor.toString() }),
			type: "tree",
			hide_transformation: true,
		};
		return result;
	}
	static getFactors(input_value: QEValueTree, options: any): SolverStepOutput | undefined {
		if (input_value.type != "tree") return undefined;

		// validate value is an integer
		const term_value = input_value.value.children[0];
		const serialized = term_value.serialize_to_text();
		if (!Number.isInteger(Number(serialized))) return undefined;

		let desc = "";

		// find the factors
		const factors = Evaluate.getFactorList(parseInt(serialized));

		// now display
		desc += "The factors of " + serialized + " are: " + factors.join(", ");

		// generate tree with list term
		const sorted_list = tokenize_and_parse("list{" + factors.join(",") + "}", { merge_sign_operators: true }).tree.children[0];

		const result = {
			desc: desc,
			old_term: term_value,
			new_term: sorted_list,
			hide_transformation: true,
			type: "tree",
		};
		return result;
	}
	static getForwardsNumbers(input_value: QEValueMap, options: any): any {
		if (input_value.type != "map") return undefined;
		const params = input_value.value;

		if (!params["number"] || !params["counting"]) {
			return undefined;
		}

		const counting = parseInt(params["counting"].serialize_to_text());
		const number = parseInt(params["number"].serialize_to_text());

		// find the number
		const factors = Evaluate.getCountingNumbers(number, counting);

		// now display
		let desc = "";
		if (!factors.length) {
			desc += number + " is not an integer &gt; 1.";
		} else {
			desc += "The " + counting + " counting forwards numbers for " + number + " are: " + factors.join(", ");
		}

		// generate tree with list term
		const sorted_list = tokenize_and_parse("list{" + factors.join(",") + "}", { merge_sign_operators: true }).tree.children[0];

		const result = {
			desc: desc,
			value: sorted_list,
			type: "tree",
		};

		return result;
	}
	static getAdditionStatement(input_value: QEValueTree, options: any): any {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a non-empty list
		if (input_value.value.children[0].type != "FUNCTION" || input_value.value.children[0].value != "list" || input_value.value.children[0].children.length != 3) {
			return undefined;
		}
		const list = input_value.value.children[0];

		const start = parseInt(list.children[0].serialize_to_text());
		const diff = parseInt(list.children[1].serialize_to_text());
		const last = parseInt(list.children[2].serialize_to_text());

		let desc = "";
		desc += "How to find the difference between " + start + "</span> and " + last + "?";
		desc += '<div style="width:40%; text-align: right;>';
		desc += '<br> Start: <span style="font-weight: 600;">' + start + "</span>";
		// now display
		for (let i = 1; i < diff; i++) {
			desc += "<br> Count " + i + ': <span style="font-weight: 500;">' + (start + i) + "</span>";
		}
		desc += '<br> Count <span style="font-weight: 600; color: #3a0;">' + diff + '</span>: <span style="font-weight: 600;">' + last + "</span>";
		desc += "</div>";
		desc += 'The answer is <span style="font-weight: 600;">' + diff + "</span>";

		const result = {
			desc: desc,
			value: diff.toString(),
			type: "string",
		};

		return result;
	}
	static getPrimeFactors(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;

		// validate value is an integer
		const term_value = input_value.value.children[0];
		const serialized = input_value.value.serialize_to_text();
		if (!Number.isInteger(Number(serialized))) return undefined;

		let desc = "";

		// find the factors
		const factors = Evaluate.getPrimeFactorList(parseInt(serialized));

		// now display
		if (!factors.length) {
			desc += serialized + " is not an integer &gt; 1.";
		} else {
			desc += "The prime factors of " + serialized + " are: " + factors.join(", ");
		}

		// generate tree with list term
		const sorted_list = tokenize_and_parse("list{" + factors.join(",") + "}", { merge_sign_operators: true }).tree.children[0];

		const result = {
			desc: desc,
			old_term: term_value,
			new_term: sorted_list,
			hide_transformation: true,
			type: "tree",
		};
		return result;
	}
	static getCommonFactors(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a list with at least 2 items
		if (input_value.value.children[0].type != "FUNCTION" || input_value.value.children[0].value != "list" || input_value.value.children[0].children.length < 2) {
			return undefined;
		}
		const list = input_value.value.children[0];

		// validate each list item is an integer
		const values = [];
		for (let i = 0; i < list.children.length; i++) {
			const list_item = list.children[i];
			const serialized = list_item.serialize_to_text();
			values.push(parseInt(serialized));
		}

		let desc = "";
		desc += "To find the common factors of a set of numbers, first find the factors of each, then find the factor that are common across all of them.<br>";

		// find factors of each, and factor frequency
		const factor_lists = [];
		const factor_counts = {};
		for (let i = 0; i < values.length; i++) {
			const factors = Evaluate.getFactorList(values[i]);
			factor_lists.push(factors);
			factors.map(function (x) {
				if (!factor_counts[x]) factor_counts[x] = 0;
				factor_counts[x]++;
			});
		}

		// find the factors present in all
		const common_factors = [];
		for (const factor in factor_counts) {
			if (!factor_counts.hasOwnProperty(factor)) continue;

			if (factor_counts[factor] == list.children.length) {
				common_factors.push(parseInt(factor));
			}
		}

		// now display
		for (let i = 0; i < values.length; i++) {
			desc += "<br>The factors of " + values[i] + " are: " + factor_lists[i].join(", ");
		}
		desc += '<br><br>The common factors are: <span style="font-weight: bold;">' + common_factors.join(", ") + "</span>";

		// generate tree with list term
		const common_list = tokenize_and_parse("list{" + common_factors.join(",") + "}", { merge_sign_operators: true }).tree.children[0];

		const result = {
			desc: desc,
			old_term: list,
			new_term: common_list,
			type: "tree",
			final_step: true,
		};
		return result;
	}

	/*
	Long multiplication
	- Support decimal multiplication
	- Support negative multiplication
	*/
	static getStandardMultiplication(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a multiply chain with 2 numeric arguments
		if (
			!input_value.value.children[0].isMultiplyChain() ||
			input_value.value.children[0].children[1].type != "MULTIPLY" ||
			input_value.value.children[0].children.length != 3
		) {
			return undefined;
		}
		const placeMap = QEValConstants.string_maps["number"]["place"];

		const chain = input_value.value.children[0];

		const originalValues = [];
		const tenBasedQ = [];
		const nums = [];
		for (let i = 0; i < chain.children.length; i += 2) {
			const chain_item = chain.children[i];
			const serialized = chain_item.serialize_to_text();

			originalValues.push(serialized);
			const q = QEQ.from_string(serialized).serialize_to_decimal_tenBased();
			tenBasedQ.push(q);
			nums.push(q.num);
		}
		const den = tenBasedQ[0].den * tenBasedQ[1].den;

		const newQ = tenBasedQ[0].multiply(tenBasedQ[1]);
		const answer = newQ.num / newQ.den;

		const isTwoNegative = tenBasedQ[0].num < 0 && tenBasedQ[1].num < 0;

		const isNegativeAnswer = answer < 0;
		// Sort value
		const postiveNums = nums.map((x) => Math.abs(x));
		const sortedNums = [...postiveNums].sort(function (a, b) {
			return b - a;
		});

		let desc = "";
		desc += '<table class="rules">';
		desc += "<tbody>";
		desc += '<tr><td></td><td style="text-align: right;">  ' + originalValues[0] + " x " + originalValues[1] + "</td><td></td></tr>";
		if (den != 1) {
			desc += '<tr><td></td><td style="text-align: left;"> Convert decimal to fraction</td><td></td></tr>';
			desc += '<tr><td></td><td style="text-align: right">';
			desc += tenBasedQ[0].den != 1 ? "(" + tenBasedQ[0].num + " / " + tenBasedQ[0].den + ")" : tenBasedQ[0].num;
			desc += " x ";
			desc += tenBasedQ[1].den != 1 ? "(" + tenBasedQ[1].num + " / " + tenBasedQ[1].den + ")" : tenBasedQ[1].num;
			desc += "</td><td></td></tr>";
		}

		if (den != 1) {
			desc += '<tr><td></td><td style="text-align: left"> Move divisor to last </td><td></td></tr>';
			desc += '<tr><td></td><td style="text-align: right">' + nums[0] + " x " + nums[1] + " / " + den + "</td><td></td></tr>";
		}

		if (isNegativeAnswer) {
			desc += '<tr><td></td><td style="text-align: left"> * Will multiply <b>-1</b> later * </td><td></td></tr>';
			desc += '<tr><td></td><td style="text-align: right">' + Math.abs(nums[0]) + " x " + nums[1];
			desc += den != 1 ? " / " + den : "";
			desc += "</td><td></td></tr>";
		} else if (isTwoNegative) {
			desc += '<tr><td></td><td style="text-align: left"> Multiplying two negatives makes positive</td><td></td></tr>';
			desc += '<tr><td></td><td style="text-align: right">' + postiveNums[0] + " x " + postiveNums[1];
			desc += den != 1 ? " / " + den : "";
			desc += "</td><td></td></tr>";
		}

		if (!arrayEquals(sortedNums, postiveNums)) {
			desc += '<tr><td></td><td style="text-align: left"> Move bigger number to front </td><td></td></tr>';
			desc += '<tr><td></td><td style="text-align: right">' + sortedNums[0] + " x " + sortedNums[1];
			desc += den != 1 ? " / " + den : "";
			desc += "</td><td></td></tr>";
		}

		if (den != 1) {
			desc += '<tr><td></td><td style="text-align: left"> ** Will divide <b>' + den + "</b> later **</td><td></td></tr>";
			desc += '<tr><td></td><td style="text-align: right">' + sortedNums[0] + " x " + sortedNums[1] + "</td><td></td></tr>";
		}
		desc += '<tr><td></td><td style="text-align: right">Long multiplication</td><td></td></tr>';

		desc += '<tr><td></td><td style="text-align: right">' + sortedNums[0] + "</td><td></td></tr>";
		desc += '<tr><td>x</td><td style="text-align: right">' + sortedNums[1] + "</td></tr>";
		desc += '<tr><td style="border-bottom:1px solid black"></td><td style="border-bottom:1px solid black"></td><td></td></tr>';

		const str = sortedNums[1].toString();
		const numbers = str.split("").filter((c) => parseInt(c) != 0);

		// handle [1-9]0, [1-9]00, [1-9]000 cases
		if (numbers.length == 1) {
			desc += '<tr><td style="border-bottom:1px solid black"></td><td style="border-bottom:1px solid black"></td><td></td></tr>';
		} else if (sortedNums[1] > 10) {
			const len = str.length;
			let place = 0; // ones
			for (let i = len - 1; i >= 0; i--) {
				const t = parseInt(str[i]);
				if (t == 0) {
					place++;
					continue;
				}
				const placement = placeMap[Math.pow(10, place).toString()];
				if (i == 0) {
					desc +=
						'<tr><td>+</td><td style="text-align: right">' +
						sortedNums[0] * t * Math.pow(10, place) +
						"</td><td><- " +
						sortedNums[0] +
						"(top) x " +
						t * Math.pow(10, place) +
						" (" +
						placement +
						" bottom) </td></tr>";
				} else {
					desc +=
						'<tr><td></td><td style="text-align: right">' +
						sortedNums[0] * t * Math.pow(10, place) +
						"</td><td><- " +
						sortedNums[0] +
						"(top) x " +
						t * Math.pow(10, place) +
						" (" +
						placement +
						" bottom) </td></tr>";
				}

				place++;
			}
			desc += '<tr><td style="border-bottom:1px solid black"></td><td style="border-bottom:1px solid black"></td><td></td></tr>';
		}

		if (isNegativeAnswer || den != 1) {
			desc += '<tr><td></td><td style="text-align: right">' + sortedNums[0] * sortedNums[1] + "</td></tr>";
			if (isNegativeAnswer) {
				desc += '<tr><td></td><td style="text-align: left"> * Multiply <b>-1</b> * </td><td></td></tr>';
				desc += '<tr><td></td><td style="text-align: right"> ' + sortedNums[0] * sortedNums[1] * -1 + "</td></tr>";
			}
			if (den != 1) {
				desc += '<tr><td></td><td style="text-align: left"> ** Divide by <b>' + den + "</b> ** </td><td></td></tr>";
				desc += '<tr><td></td><td style="text-align: right">' + answer + "</td></tr>";
			}
		} else {
			desc += '<tr><td></td><td style="text-align: right">' + answer + "</td></tr>";
		}

		desc += "</tbody>";
		desc += "</table>";
		desc += "</br>";

		const result = {
			desc: desc,
			old_term: chain,
			new_term: QETerm.create({ type: "RATIONAL", value: answer.toString() }),
			type: "tree",
		};
		return result;
	}

	/*
	Division using place value
	- TODO: Support decimal dividend
	- TODO: Support negative dividend
	*/
	static getDividingUsingPlaceValue(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a multiply(divide) chain with 2 numeric arguments
		if (
			!input_value.value.children[0].isMultiplyChain() ||
			input_value.value.children[0].children[1].type != "DIVIDE" ||
			input_value.value.children[0].children.length != 3
		) {
			return undefined;
		}
		const chain = input_value.value.children[0];

		// validate each chain item is an integer
		const values = [];
		for (let i = 0; i < chain.children.length; i += 2) {
			const chain_item = chain.children[i];
			const serialized = chain_item.serialize_to_text();
			if ( isNaN(parseInt(serialized)) ) return undefined;
			values.push(parseInt(serialized));
		}

		const dividend = values[0];
		const divisor = values[1];
		const answer = dividend / divisor;

		let desc = "";
		desc += '<table class="rules">';
		desc += "<tbody>";
		desc += "<tr><td>" + dividend + " ÷ " + divisor + "</td></tr>";

		const str = dividend.toString();
		const numbers = str.split("").filter((c) => parseInt(c) != 0);
		if (numbers.length == 1) {
			desc += "<tr><td>= " + answer + "</td></tr>";
		} else if (dividend > 10) {
			const len = str.length;
			let remainder = "";
			const dividendParts = [];
			const dividendDisplay = [];
			const partsQuotients = [];
			// Find dividendParts
			for (let i = 0; i < len; i++) {
				remainder = remainder.concat(str[i]);
				const t = parseInt(remainder);
				if (Math.floor(t / divisor) > 0 && t % divisor == 0) {
					const v = t * Math.pow(10, len - i - 1);
					dividendParts.push(v);
					dividendDisplay.push("( " + v + " ÷ " + divisor + " )");
					partsQuotients.push(v / divisor);
					remainder = "";
				}
			}
			if (dividendParts.length > 1) {
				// Step 1 (20+4) ÷ 4
				desc += "<tr><td>= ( " + dividendParts.join(" + ") + " ) ÷ " + divisor + "</td></tr>";
				// Step 2 (20÷4) + (4÷4)
				desc += "<tr><td>= " + dividendDisplay.join(" + ") + "</td></tr>";
				// Step 3 5+1
				desc += "<tr><td>= " + partsQuotients.join(" + ") + "</td></tr>";
			}
			// Step 4
			desc += "<tr><td>= " + answer + "</td></tr>";
		}
		desc += "</tbody>";
		desc += "</table>";
		desc += "</br>";

		const result = {
			desc: desc,
			old_term: chain,
			new_term: QETerm.create({ type: "RATIONAL", value: answer.toString() }),
			type: "tree",
		};
		return result;
	}

	/*
	Long Division
	- TODO: Support decimal dividend
	- TODO: Support negative dividend
	*/
	static getDividingUsingLongDivision(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a multiply(divide) chain with 2 numeric arguments
		if (
			!input_value.value.children[0].isMultiplyChain() ||
			input_value.value.children[0].children[1].type != "DIVIDE" ||
			input_value.value.children[0].children.length != 3
		) {
			return undefined;
		}
		const chain = input_value.value.children[0];

		const values = [];
		const originalValues = [];
		const tenBasedQ = [];
		const nums = [];
		for (let i = 0; i < chain.children.length; i += 2) {
			const chain_item = chain.children[i];
			const serialized = chain_item.serialize_to_text();
			// Divisor must be integer
			if (i == 2 && !Number.isInteger(Number(serialized))) {
				console.log('Error: getDividingUsingLongDivision called with non-integer divisor');
				return undefined;
			}
			originalValues.push(serialized);
			const q = QEQ.from_string(serialized).serialize_to_decimal_tenBased();
			tenBasedQ.push(q);
			nums.push(q.num);

			values.push(parseInt(serialized));
		}
		// den = 1, integer divide by integer. den != 1, decimal divide by integer
		let den = tenBasedQ[0].den * tenBasedQ[1].den;
		let originalDividend = originalValues[0];
		const originalDivsor = originalValues[1];

		const newQ = tenBasedQ[0].divide(tenBasedQ[1]);
		const quotient = newQ.num / newQ.den; // quotient
		const quotientQ = QEQ.from_string(quotient.toString()).serialize_to_decimal_tenBased();

		// Decimal divide by integer. Check dividend needs to append zero or not.
		if (den != 1 && quotientQ.den.toString() != tenBasedQ[0].den.toString()) {
			const times = quotientQ.den / tenBasedQ[0].den;
			tenBasedQ[0].den *= times;
			tenBasedQ[0].num *= times;
			den = tenBasedQ[0].den * tenBasedQ[1].den;
			// Append zero
			originalDividend += times.toString().replace("1", "");
		}

		// Convert decimal to integer, then do the long division
		const calculableDividend = Math.abs(tenBasedQ[0].num);
		const calculableDivisor = Math.abs(tenBasedQ[1].num);

		let remainder = 0;
		if (den == 1) {
			remainder = calculableDividend % calculableDivisor;
		}

		let hasReminderDuringDivisionProcess = false;
		let hasReminderAtTheEnd = false;

		const katexEmptySpace = "\\enspace";

		let longDivisionDesc = "";
		longDivisionDesc += '<table class="rules">';
		longDivisionDesc += "<tbody>";
		longDivisionDesc += '<tr><td style="text-align: right"><katex>' + quotient + "</katex></td><td></td></tr>";
		longDivisionDesc +=
			'<tr><td style="text-align: right"><katex>' + originalDivsor + "\\overline{\\smash{)}" + originalDividend + '}</katex></td><td span="45"></td></tr>';

		let stepsDesc = "";

		const str = calculableDividend.toString();
		const len = str.length;
		let partialRemainder = "";
		const dividendParts = [];
		const stepsDisplay = [];
		const longDivisionDisplay = [];
		// Find dividendParts
		for (let i = 0; i < len; i++) {
			partialRemainder = partialRemainder.concat(str[i]);
			const tmpValue = parseInt(partialRemainder);
			const extraSpace = len - i - 1 > 0 ? katexEmptySpace.repeat(len - i - 1) : "";
			if (longDivisionDisplay.length > 0) {
				longDivisionDisplay.push("<katex>" + tmpValue + extraSpace + "</katex>");
			}
			const q = Math.floor(tmpValue / calculableDivisor);
			const r = tmpValue % calculableDivisor;
			if (q > 0) {
				if (r != 0) {
					partialRemainder = r.toString();
					hasReminderDuringDivisionProcess = true;
					stepsDisplay.push("<span>Divide: </span><katex>" + tmpValue + " ÷ " + calculableDivisor + "</katex>");
					stepsDisplay.push("<span>Multiply: Use multiplication to find close value to </span><katex>" + tmpValue + "</katex>");
					stepsDisplay.push("<katex>" + calculableDivisor + " * \\textcolor{#3a0}{" + q + "} = " + calculableDivisor * q + "</katex>");
					stepsDisplay.push("<katex>" + calculableDivisor + " * " + (q + 1) + " = " + calculableDivisor * (q + 1) + "</katex><br />");
					// Last remainder
					if (i == len - 1 && r != 0) {
						stepsDisplay.push("<span>Remainder: </span><katex>" + tmpValue + " - " + q * calculableDivisor + " = \\textcolor{#3a0}{" + r + "}</katex><br />");
					}
				} else {
					stepsDisplay.push("<span>Divide: </span><katex>" + tmpValue + " ÷ " + calculableDivisor + " = \\textcolor{#3a0}{" + q + "}</katex><br />");
					partialRemainder = "";
				}
				dividendParts.push(tmpValue);
				longDivisionDisplay.push("<katex>\\underline{" + q * calculableDivisor + extraSpace + "}</katex>");
			}

			// Special case, 481/6, one place can't divid by divisor
			else if (i == len - 1 && q == 0 && r != 0) {
				hasReminderAtTheEnd = true;
				stepsDisplay.push("<span>Remainder: </span><katex> \\textcolor{#3a0}{" + r + "}</katex><br />");
			}
		}

		if (hasReminderDuringDivisionProcess || hasReminderAtTheEnd) {
			longDivisionDisplay.forEach(function (item, index, array) {
				longDivisionDesc += '<tr><td style="text-align: right">' + item + "</td></tr>";
			});
			if (hasReminderDuringDivisionProcess && longDivisionDisplay.length > 0) {
				longDivisionDesc += '<tr><td style="text-align: right"><katex>' + remainder + "</katex></td></tr>";
			}
		}
		longDivisionDesc += "</tbody>";
		longDivisionDesc += "</table>";
		longDivisionDesc += "</br>";

		stepsDisplay.forEach(function (item) {
			stepsDesc += item + "<br />";
		});

		let desc = "";
		if (den != 1) {
			desc += "<div>";
			desc += "To divide decimals and integers using the standard algorithm for long division:<br/>";
			desc += "1) Determine whether your answer will be positive or negative<br />";
			desc += "2) Line up the decimal place in the dividend and the quotient<br/>";
			desc += "3) Decide how many decimal places there will be in your answer";
			desc += "<br /><br /></div>";
		}
		desc += '<div class="container-fluid">';
		desc += '<div class="row">';
		desc += '<div class="col-md-2">';
		desc += longDivisionDesc;
		desc += "</div>";
		desc += '<div class="col-md-10">';
		desc += stepsDesc;
		desc += "</div>";
		desc += "</div>";
		desc += "</div>";

		const result = {
			desc: desc,
			old_term: chain,
			new_term: QETerm.create({ type: "RATIONAL", value: quotient.toString() }),
			type: "tree",
		};
		return result;
	}
	///////////////////////////////////////////////
	static getMultiples(input_value: QEValueMap, options: any): any {
		if (input_value.type != "map") return undefined;
		const params = input_value.value;

		if (!params["base"] || !params["length"]) {
			return undefined;
		}

		const base = params["base"].serialize_to_text();
		const length = params["length"].serialize_to_text();

		// validate arguments are integers
		if (!Number.isInteger(Number(base))) return undefined;
		if (!Number.isInteger(Number(length))) return undefined;

		let desc = "";

		// find the multiples
		const multiples = [];
		for (let i = 1; i <= Number(length); i++) {
			multiples.push(parseInt(base) * i);
		}

		const list = tokenize_and_parse("list{" + multiples.join(",") + "}", { merge_sign_operators: true }).tree;

		// now display
		desc += "The first " + length + " multiples of " + base + " are: " + multiples.join(", ");

		const result = {
			desc: desc,
			value: list,
			type: "tree",
		};
		return result;
	}
	static get_LCM_by_GCF(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a list with 2 items
		if (input_value.value.children[0].type != "FUNCTION" || input_value.value.children[0].value != "list" || input_value.value.children[0].children.length != 2) {
			return undefined;
		}
		const list = input_value.value.children[0];

		// validate each list item is an integer
		const values = [];
		for (let i = 0; i < list.children.length; i++) {
			const list_item = list.children[i];
			const serialized = list_item.serialize_to_text();
			if ( isNaN(parseInt(serialized)) ) return undefined;
			values.push(parseInt(serialized));
		}

		let desc = "";

		// NOTE: can be done less expensively for 2 numbers, but... meh, generic
		// find factors of each, and factor frequency
		const factor_lists = [];
		const factor_counts = {};
		for (let i = 0; i < values.length; i++) {
			const factors = Evaluate.getFactorList(values[i]);
			factor_lists.push(factors);
			factors.map(function (x) {
				if (!factor_counts[x]) factor_counts[x] = 0;
				factor_counts[x]++;
			});
		}
		// find the greatest factor present in all
		let gcf = 0;
		for (const factor in factor_counts) {
			if (!factor_counts.hasOwnProperty(factor)) continue;

			if (factor_counts[factor] == list.children.length && parseInt(factor) > gcf) gcf = parseInt(factor);
		}

		desc += "To find the Lowest Common Multiple of two numbers, first find the Greatest Common Factor (GCF)";
		desc += "<br>For two numbers, the LCM is equal to the product of the numbers, divided by the GCF: a * b / GCF";
		desc += "<br><br>The GCF of " + values[0] + " and " + values[1] + ' is <span style="font-weight: bold;">' + gcf + "</span>";

		const lcm = (values[0] * values[1]) / gcf;

		// now display
		desc += "<br><br>The lowest common multiple is: " + values[0] + " * " + values[1] + " / " + gcf + ' = <span style="font-weight: bold;">' + lcm + "</span>";

		const result = {
			desc: desc,
			old_term: list,
			new_term: QETerm.create({ type: "RATIONAL", value: lcm.toString() }),
			type: "tree",
			hide_transformation: true,
		};
		return result;
	}
	///////////////////////////////////////////////
	static getListMean(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a non-empty set
		if (input_value.value.children[0].type != "FUNCTION" || input_value.value.children[0].value != "list" || input_value.value.children[0].children.length == 0) {
			return undefined;
		}
		const list = input_value.value.children[0];

		const value_list = [];
		for (let i = 0; i < list.children.length; i++) {
			const value = list.children[i].evaluate_to_float();
			if (isNaN(value)) {
				console.log("Error. Set contains non-numeric value");
				return undefined;
			}
			// keep original item, and evaluated value, in case any items are fractions
			value_list.push({ item: list.children[i], value: value });
		}

		// now display
		let desc = "";
		desc += "The mean of a list of numbers is equal to the sum of the numbers, divided by how many numbers are in the list.";

		const avg_expression = tokenize_and_parse(
			"frac{" +
				value_list
					.map(function (x) {
						return x.item.serialize_to_text();
					})
					.join("+") +
				"," +
				value_list.length +
				"}",
			{ merge_sign_operators: true }
		).tree;

		// simplify avg_expression
		let mean = QESolver.solveUsing("simplify_bedmas", {
			type: "tree",
			value: avg_expression.clone(),
		}).value.value.children[0];
		mean = QETerm.create({
			type: "RATIONAL",
			value: mean.evaluate_to_float().toString(),
		});

		desc += "<br><br>" + avg_expression.display() + " = " + mean.display();

		const result = {
			desc: desc,
			old_term: list,
			new_term: mean,
			hide_transformation: true,
			type: "tree",
		};
		return result;
	}
	static getListMedian(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a non-empty list
		if (input_value.value.children[0].type != "FUNCTION" || input_value.value.children[0].value != "list" || input_value.value.children[0].children.length == 0) {
			return undefined;
		}
		const list = input_value.value.children[0];

		const value_list = [];
		for (let i = 0; i < list.children.length; i++) {
			const value = list.children[i].evaluate_to_float();
			if (isNaN(value)) {
				console.log("Error. Set contains non-numeric value");
				return undefined;
			}
			// keep original item, and evaluated value, in case any items are fractions
			value_list.push({ item: list.children[i], value: value });
		}

		// now display
		let desc = "";
		desc += "To find the median of a list of numbers, first sort the list.";

		const sorted_values = value_list.sort(function (a, b) {
			return a.value > b.value ? 1 : b.value > a.value ? -1 : 0;
		});
		const sorted_list = tokenize_and_parse(
			"list{" +
				sorted_values
					.map(function (x) {
						return x.item.serialize_to_text();
					})
					.join(",") +
				"}",
			{ merge_sign_operators: true }
		).tree;
		desc += "<br><br>" + list.display() + " becomes " + sorted_list.display();

		let median;
		if (value_list.length % 2) {
			// odd-length list
			median = tokenize_and_parse(sorted_values[Math.trunc(sorted_values.length / 2)].item.serialize_to_text(), { merge_sign_operators: true }).tree;
			desc += "<br><br>The median is the value in the middle of a sorted list: " + median.display();
		} else {
			const median_frac = tokenize_and_parse(
				"frac{" + sorted_values[sorted_values.length / 2 - 1].item.serialize_to_text() + "+" + sorted_values[sorted_values.length / 2].item.serialize_to_text() + ",2}",
				{}
			).tree;

			// convert to decimal number
			median = QESolver.solveUsing("simplify_bedmas", {
				type: "tree",
				value: median_frac.clone(),
			}).value.value.children[0];
			median = QETerm.create({
				type: "RATIONAL",
				value: median.evaluate_to_float().toString(),
			});
			desc += "<br><br>In a list with an even number of items, the median is the average of the middle two values:<br>";
			desc += median_frac.display() + " = " + median.display();
		}

		const result = {
			desc: desc,
			old_term: list,
			new_term: median,
			hide_transformation: true,
			type: "tree",
		};
		return result;
	}
	static getListMode(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a non-empty list
		if (input_value.value.children[0].type != "FUNCTION" || input_value.value.children[0].value != "list" || input_value.value.children[0].children.length == 0) {
			return undefined;
		}
		const list = input_value.value.children[0];

		const value_list = [];
		const value_counts = {};
		for (let i = 0; i < list.children.length; i++) {
			const value = list.children[i].evaluate_to_float();
			if (isNaN(value)) {
				console.log("Error. Set contains non-numeric value");
				return undefined;
			}
			// keep original item, and evaluated value, in case any items are fractions
			value_list.push({ item: list.children[i], value: value });

			// keep frequency counts of each value
			if (!value_counts[value]) {
				value_counts[value] = 0;
			}
			value_counts[value]++;
		}

		// now display
		let desc = "";
		desc += "The mode of a list of numbers is the number that appears most frequently. To spot the mode, first sort the list.";

		const sorted_values = value_list.sort(function (a, b) {
			return a.value > b.value ? 1 : b.value > a.value ? -1 : 0;
		});
		const sorted_list = tokenize_and_parse(
			"list{" +
				sorted_values
					.map(function (x) {
						return x.item.serialize_to_text();
					})
					.join(",") +
				"}",
			{ merge_sign_operators: true }
		).tree;
		desc += "<br><br>" + list.display() + " becomes " + sorted_list.display();

		// find number(s) with highest frequency
		let highest_freq = 0;
		let freq_vals = [];
		for (const value in value_counts) {
			if (value_counts.hasOwnProperty(value)) {
				if (value_counts[value] > highest_freq) {
					highest_freq = value_counts[value];
					freq_vals = [value];
				} else if (value_counts[value] == highest_freq) {
					freq_vals.push(value);
				}
			}
		}

		let result_type = "tree";
		let mode;
		if (freq_vals.length > 1 && highest_freq > 1) {
			// multi-modal
			mode = tokenize_and_parse("list{" + freq_vals.join(",") + "}", { merge_sign_operators: true }).tree;
			desc += "<br><br>The list of numbers is multi-modal. The modes are: " + mode.display();
		} else if (freq_vals.length > 1 && highest_freq == 1) {
			// no modal
			result_type = "string";
			mode = "none";
			desc += "<br><br>There is no mode.";
		} else if (freq_vals.length == 1) {
			// single modal
			mode = tokenize_and_parse(freq_vals[0], { merge_sign_operators: true }).tree;
			desc += "<br><br>The mode is: " + mode.display();
		}

		const result = {
			desc: desc,
			old_term: list,
			new_term: mode,
			hide_transformation: true,
			type: result_type,
		};
		return result;
	}
	static getListRange(input_value: QEValueTree, options: any): SolverStepOutput {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a non-empty list
		if (input_value.value.children[0].type != "FUNCTION" || input_value.value.children[0].value != "list" || input_value.value.children[0].children.length < 1) {
			return undefined;
		}
		const list = input_value.value.children[0];

		const value_list = [];
		for (let i = 0; i < list.children.length; i++) {
			const value = list.children[i].evaluate_to_float();
			if (isNaN(value)) {
				console.log("Error. Set contains non-numeric value");
				return undefined;
			}
			// keep original item, and evaluated value, in case any items are fractions
			value_list.push({ item: list.children[i], value: value });
		}

		// now display
		let desc = "";
		desc += "The range of a list of numbers is the difference of the greatest and lowest number in the list. To find the range, it helps to first sort the list.";

		const sorted_values = value_list.sort(function (a, b) {
			return a.value > b.value ? 1 : b.value > a.value ? -1 : 0;
		});
		const sorted_list = tokenize_and_parse(
			"list{" +
				sorted_values
					.map(function (x) {
						return x.item.serialize_to_text();
					})
					.join(",") +
				"}",
			{ merge_sign_operators: true }
		).tree;
		desc += "<br><br>" + list.display() + " becomes " + sorted_list.display();

		const range_eq = tokenize_and_parse(
			sorted_values[sorted_values.length - 1].item.serialize_to_text() + "-" + sorted_values[0].item.serialize_to_text(),
			{ merge_sign_operators: true }
		).tree;

		const range = QESolver.solveUsing("simplify_bedmas", {
			type: "tree",
			value: range_eq.clone(),
		}).value.value.children[0];
		desc += "<br><br>To get the range, subtract the lowest value in the list from the greatest value:<br>";
		desc += range_eq.display() + " = " + range.display();

		const result = {
			desc: desc,
			old_term: list,
			new_term: range,
			hide_transformation: true,
			type: "tree",
		};
		return result;
	}

	// given a list of 2 numbers, return a 'string' of the appropriate comparator
	// eg: given [ "-1", "42" ]  ==> "<"
	// Only handles integers
	static MakeIntComparison(input_value, options) {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a non-empty list
		if (
			input_value.value.children[0].type != "FUNCTION" ||
			input_value.value.children[0].value != "list" ||
			input_value.value.children[0].children.length != 2
		) {
			return undefined;
		}
		var list = input_value.value.children[0];

		// validate each list item is an integer
		var values = [];
		for (var i = 0; i < list.children.length; i++) {
			var list_item = list.children[i];
			var serialized = list_item.serialize_to_text();
			if (parseInt(serialized) != serialized) return undefined;
			values.push(parseInt(serialized));
		}

		if (values[0] === values[1]) {
			return {
				desc:
					'The first integer is <span style="font-weight: bold;">equal</span> to the other one.',
				value: "=",
				type: "string",
			};
		} else if (values[0] < values[1]) {
			return {
				desc:
					'The first integer is <span style="font-weight: bold;">less</span> than the other one.',
				value: "<",
				type: "string",
			};
		} else if (values[0] > values[1]) {
			return {
				desc:
					'The first integer is <span style="font-weight: bold;">greater</span> than the other one.',
				value: ">",
				type: "string",
			};
		}
	}

	// Given a list of 2 fractions, return a 'string' of the appropriate comparator
	// eg: given [ "-1", "42" ]  ==> "<"
	// Handles same input numbers as QESolverStep.FracComparisonEvaluate
	static MakeFracComparison(input_value, options) {
		if (input_value.type != "tree") return undefined;

		// validate tree contains a non-empty list
		if (
			input_value.value.children[0].type != "FUNCTION" ||
			input_value.value.children[0].value != "list" ||
			input_value.value.children[0].children.length != 2
		) {
			return undefined;
		}
		var list = input_value.value.children[0];

		// create a new tree with the values and a comparator, then evaluate
		var root = QETerm.create({ type: "ROOT" });
		var new_chain = QETerm.create({
			type: "CHAIN",
			precedence: findGrammar("EQUAL").precedence,
		});
		root.pushChild(new_chain);

		var comparator = QETerm.create({ type: "LESS" });
		new_chain.pushChild(list.children[0].clone()); // discard "ROOT"
		new_chain.pushChild(comparator);
		new_chain.pushChild(list.children[1].clone());

		var result = QESolverStep.FracComparisonEvaluate({
			type: "tree",
			value: root,
		});
		if (!result) {
			// we can't evaluate a comparison with the given input
			return;
		} else if (result.value === true) {
			return {
				desc:
					'The first value is <span style="font-weight: bold;">less</span> than the other one.',
				value: "<",
				type: "string",
			};
		}

		// change the comparator type and re-evaluate
		comparator.type = "GREATER";
		comparator.value = ">";
		result = QESolverStep.FracComparisonEvaluate({
			type: "tree",
			value: root,
		});
		if (result.value === true) {
			return {
				desc:
					'The first value is <span style="font-weight: bold;">greater</span> than the other one.',
				value: ">",
				type: "string",
			};
		} else {
			comparator.type = "EQUAL";
			comparator.value = "=";
			return {
				desc:
					'The first value is <span style="font-weight: bold;">equal</span> to the other one.',
				value: "=",
				type: "string",
			};
		}
	}
	// Evaluate a comparison (eg: "1<2=frac{4,1+1}")
	// Can compare that which can be reduced to a fraction.
	// Only evaluate an 'ordered' chain of comparators: "a<b<=c=d", not "a>b<c"
	// If we can evaluate it, returns either true or false
	static FracComparisonEvaluate(input_value, options?) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		if (!root.children[0].isComparatorChain()) {
			return;
		}

		var chain = root.children[0];

		// We want a chain of EQUAL, LESS, and LESS_OR_EQUAL;
		// OR a chain of EQUAL, GREATER, and GREATER_OR_EQUAL.
		var direction_from_type = {
			LESS_OR_EQUAL: -1,
			LESS: -1,
			GREATER_OR_EQUAL: 1,
			GREATER: 1,
			EQUAL: 0,
		};
		var direction = 0;
		for (var i = 1; i < chain.children.length; i += 2) {
			var dir = direction_from_type[chain.children[i].type];
			if (dir === 0) {
				// we can always add an EQUAL
				continue;
			} else if (direction === 0) {
				// first comparator that is not an "EQUAL"
				direction = dir;
				continue;
			} else if (dir === direction) {
				// same direction, eg: "a < b <= c"
				continue;
			} else {
				// opposing comparators, eg: "a > b < c"
				return;
			}
		}

		// We want to avoid dealing with float issues as much as possible,
		// eg: no guarantee that "2/7" will be evaluated to the same value twice
		// Simplify the expression under the hood to compare integers
		var result = QESolver.solveUsing(
			"frac_comparison_simplify_to_int",
			{ type: "tree", value: root.clone() }
		).value;
		let simplified_chain = result.value.children[0];

		// The chain or comparison is well-formed. eg: not "a > b < c"
		// Check each comparison 1 by 1
		var lval = simplified_chain.children[0].evaluate_to_float();
		for (var i = 2; i < simplified_chain.children.length; i += 2) {
			var rval = simplified_chain.children[i].evaluate_to_float();

			if (isNaN(lval) || isNaN(rval)) {
				console.log(
					"QESolverStep.IntFracComparison: an argument was evaluated to NaN"
				);
				return;
			}

			var ordered = false;
			switch (chain.children[i - 1].type) {
				case "LESS_OR_EQUAL":
					ordered = lval <= rval;
					break;
				case "LESS":
					ordered = lval < rval;
					break;
				case "GREATER_OR_EQUAL":
					ordered = lval >= rval;
					break;
				case "GREATER":
					ordered = lval > rval;
					break;
				case "EQUAL":
					ordered = lval == rval;
					break;
			}
			if (ordered) {
				lval = rval;
				continue;
			} else {
				return {
					desc:
						"FALSE comparison: " +
						chain.children[i - 2].serialize_to_text() +
						" " +
						chain.children[i - 1].value +
						" " +
						chain.children[i].serialize_to_text(),
					value: false,
					type: "boolean",
				};
			}
		}

		return {
			desc: "TRUE comparison",
			value: true,
			type: "boolean",
		};
	}

	/**
	 * CT_evaluateFactorial	Compute the value of a positive integer factorial
	 */
	static CT_evaluateFactorial(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var root = input_value.value;

		// find pow|EXPONENT that has a RATIONAL base and positive integer exponent
		var terms = root.findAllChildren(function (node) {
			if (
				node.type == "FACTORIAL" &&
				node.children[0].type == "RATIONAL" &&
				node.children[0].Q.den == 1 &&
				node.children[0].Q.num <= 20 // NOTE: capping factorial to 20 to prevent Q simplification from exploding
			) {
				return true;
			}
		});

		for (let i = 0; i < terms.length; i++) {
			var factorial = terms[i];
			var n = factorial.children[0].Q.num;

			var final_value = 1;
			for (; n > 0; n--) {
				final_value *= n;
			}

			var new_term = QETerm.create({
				type: "RATIONAL",
				value: final_value.toString(),
			});

			// retain sign of original term
			new_term.sign = (new_term.sign || 1) * (factorial.sign || 1);

			// highlighting
			factorial.addClass("highlight_input");
			new_term.addClass("highlight_output");

			return {
				old_term: factorial,
				new_term: new_term,
				type: "tree",
				desc:
					"Compute the value of a factorial by multiplying the series: n! = n * (n-1) * (n-2) * ... * 2 * 1",
			};
		}
	}

	static compareFractionsWithSameDenominator(input_value, options) {
		if (input_value.type != "tree") return undefined;

		// Validate tree contains a list with at least 2 items
		if (
			input_value.value.children[0].type != "FUNCTION" ||
			input_value.value.children[0].value != "list" ||
			input_value.value.children[0].children.length != 2
		) {
			return undefined;
		}

		var old_term = input_value.value.children[0];
		let vars = old_term.findAllChildren("type", "VARIABLE");
		// Don't support fraction variable comparison
		if (vars.length > 0) {
			return undefined;
		}

		var firstNumerator = undefined;
		var secondNumerator = undefined;
		if (old_term.children[0].value == "frac") {
			firstNumerator = parseInt(old_term.children[0].children[0].value);
		} else if (old_term.children[0].type == "RATIONAL") {
			firstNumerator = parseInt(old_term.children[0].value);
		}

		if (old_term.children[1].value == "frac") {
			secondNumerator = parseInt(old_term.children[1].children[0].value);
		} else if (old_term.children[1].type == "RATIONAL") {
			secondNumerator = parseInt(old_term.children[1].value);
		}

		return comparisonResult(
			firstNumerator,
			secondNumerator,
			old_term.children[0],
			old_term.children[1],
			old_term
		);
	}
	static compareDecimals(input_value, options) {
		if (input_value.type != "tree") return undefined;

		// Validate tree contains a list with at least 2 items
		if (
			input_value.value.children[0].type != "FUNCTION" ||
			input_value.value.children[0].value != "list" ||
			input_value.value.children[0].children.length != 2
		) {
			return undefined;
		}

		var old_term = input_value.value.children[0];
		let vars = old_term.findAllChildren("type", "VARIABLE");
		// Don't support fraction variable comparison
		if (vars.length > 0) {
			return undefined;
		}

		return comparisonResult(
			old_term.children[0].value,
			old_term.children[1].value,
			old_term.children[0],
			old_term.children[1],
			old_term
		);
	}
	static extractComparisonSign(input_value, options) {
		if (input_value.value.children == undefined) {
			return undefined;
		}

		if (
			input_value.value.children[0].type != "LESS" &&
			input_value.value.children[0].type != "GREATER" &&
			input_value.value.children[0].type != "EQUAL"
		) {
			return undefined;
		}

		var result = {
			desc:
				"The correct answer is  <b>" +
				input_value.value.children[0].value +
				"</b>",
			value: input_value.value.children[0].value,
			type: "string",
		};
		return result;
	}
}
