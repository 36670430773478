import { tokenize_and_parse } from '../QEGrammar';
import { QEHelper } from '../QEHelper';
import { QETerm, Token } from '../QETerm';
import { QEWidget } from '../QEWidget';

export class QEEqKeyboard extends QEWidget {

	type: string;
	name: string;
	value: string;
	kb_rows: string[][];
	key_aliases: { [key: string]: string };
	input_key_index: number;
	cursor_index: number;
	keys: { [key: string]: { val: string | string[], type: string, subtype?: string, label?: string, display?: string, take_neighbouring?: boolean } };
	display_options: any;
	tree: QETerm;
	token_array: Token[];
	original_string: string;
	implied_string: string;
	output_str: string;

	static instantiate(serialized: string, resolved_data, options: { name: string }){
		resolved_data = resolved_data || {};
	
		let deserialized = JSON.parse(serialized);
	
		// build map and resolve any [$name] placeholders in display_options
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);
	
		// check if there was an unresolved dependency
		if (!display_options) return null;
	
		let widget = new QEEqKeyboard('', {type: deserialized.type, name: options.name, display_options: display_options});
		return widget;
	}

	constructor(str: string, options: { type: string, name?: string, input_key_index?: number, display_options: any }) {
		super();

		//console.log('EqKeyboard: "'+ str +'" ', options);
		if (typeof str == 'undefined')
			str = '';

		this.value = str;

		// TODO: validate type
		this.type = options.type;
		this.kb_rows = QEEqKeyboard.kb_map[this.type].kb_rows;
		this.key_aliases = QEEqKeyboard.kb_map[this.type].key_aliases || {};

		if (options.name)
			this.name = options.name;
		if (options.input_key_index !== undefined)
			this.input_key_index = options.input_key_index;

		this.display_options = Object.assign({}, options.display_options);
		this.display_options = Object.assign(this.display_options, QEEqKeyboard.kb_map[this.type].display_options);

		this.cursor_index = null;

		// parse equation and get cursor position info
		this.setEquation(str);

		this.keys = {
			'1': { val: '1', type: 'Insert', subtype: 'Integer' },
			'2': { val: '2', type: 'Insert', subtype: 'Integer' },
			'3': { val: '3', type: 'Insert', subtype: 'Integer' },
			'4': { val: '4', type: 'Insert', subtype: 'Integer' },
			'5': { val: '5', type: 'Insert', subtype: 'Integer' },
			'6': { val: '6', type: 'Insert', subtype: 'Integer' },
			'7': { val: '7', type: 'Insert', subtype: 'Integer' },
			'8': { val: '8', type: 'Insert', subtype: 'Integer' },
			'9': { val: '9', type: 'Insert', subtype: 'Integer' },
			'0': { val: '0', type: 'Insert', subtype: 'Integer' },
			'x': { val: 'x', display: '<span style="font-family: serif; font-style: italic;">x</span>', type: 'Insert', subtype: 'Variable' },
			'y': { val: 'y', display: '<span style="font-family: serif; font-style: italic;">y</span>', type: 'Insert', subtype: 'Variable' },
			'z': { val: 'z', display: '<span style="font-family: serif; font-style: italic;">z</span>', type: 'Insert', subtype: 'Variable' },
			// TODO: symbols:  pi and friends
			'.': { val: '.', type: 'Insert', subtype: 'Decimal' },
			'+': { val: '+', display: '&plus;', type: 'Insert', subtype: 'Add' },
			'/': { val: '/', display: '&divide;', type: 'Insert', subtype: 'Divide' },
			'divide': { val: '/', display: '&divide;', type: 'Insert', subtype: 'Divide' },
			'*': { val: '*', display: '&bull;', type: 'Insert', subtype: 'Multiply' },
			'-': { val: '-', display: '&minus;', type: 'Insert', subtype: 'Subtract' },
			'=': { val: '=', type: 'Insert', subtype: 'Equal' },
			'<=': { val: '<=', display: '&le;', type: 'Insert', subtype: 'LessThanEqual' },
			'>=': { val: '>=', display: '&ge;', type: 'Insert', subtype: 'GreaterThanEqual' },
			'<': { val: '<', display: '&lt;', type: 'Insert', subtype: 'LessThan' },
			'>': { val: '>', display: '&gt;', type: 'Insert', subtype: 'GreaterThan' },
			'^': { val:  '^', display: '<span style="font-size: 14px;"><katex>\\colorbox{#ffcccc}{$\\textcolor{#ffcccc}{1}$}\\hspace{0.1em}\\raisebox{0.75em}{$\\colorbox{#ffcccc}{$\\textcolor{#ffcccc}{\\tiny 1}$}$}</katex></span>', type: 'Insert', subtype: 'Exponent', label: 'Exponent' },
			'!': { val: '!', type: 'Insert', subtype: 'Factorial' },
			'(': { val: '(', type: 'Insert', subtype: 'LeftParen' },
			')': { val: ')', type: 'Insert', subtype: 'RightParen' },
			'[': { val: '[', type: 'Insert', subtype: 'LeftSquare' },
			']': { val: ']', type: 'Insert', subtype: 'RightSquare' },
			'{': { val: '\\{', type: 'Insert', subtype: 'LeftCurly' },
			'}': { val: '\\}', type: 'Insert', subtype: 'RightCurly' },
			',': { val: ',', type: 'Insert', subtype: 'Comma' },
			'plus_minus': { val: 'plus_minus', display: '&plusmn;', type: 'Modify', subtype: 'PlusMinus' },

			// functions
			'frac': { val: ['\\frac{', ',', '}'], display: '<span style="font-size: 12px; display: inline-block; position: relative; top: -4px;"><katex>\\frac{\\raisebox{0.4em}{$\\colorbox{#ffcccc}{$\\textcolor{#ffcccc}{,}$}$}}{\\raisebox{0.25em}{$\\colorbox{#ffcccc}{$\\textcolor{#ffcccc}{ ,}$}$}}</katex></span>', type: 'Insert', subtype: 'Fraction', label: 'Fraction', take_neighbouring: true },
			'mfrac': { val: ['\\mfrac{', ',', ',', '}'], display: '<span style="font-size: 12px; display: inline-block; position: relative; top: -4px;"><katex>\\colorbox{#ffcccc}{$\\textcolor{#ffcccc}{l}$} \\frac{\\raisebox{0.4em}{$\\colorbox{#ffcccc}{$\\textcolor{#ffcccc}{,}$}$}}{\\raisebox{0.25em}{$\\colorbox{#ffcccc}{$\\textcolor{#ffcccc}{ ,}$}$}}</katex></span>', type: 'Insert', subtype: 'Fraction', label: 'Mixed fraction' },
			'list': { val: ['\\list{', '}'], display: '<span style="font-size: 14px;"><katex>\\{{\\colorbox{#ffcccc}{$\\textcolor{#ffcccc}{l}$}},{\\colorbox{#ffcccc}{$\\textcolor{#ffcccc}{l}$}}\\}</katex></span>', type: 'Insert', subtype: 'List', label: 'List of values' },
			'sin': { val: '\\sin', display: 'sin', type: 'Insert', subtype: 'trig' },
			'cos': { val: '\\cos', display: 'cos', type: 'Insert', subtype: 'trig' },
			'tan': { val: '\\tan', display: 'tan', type: 'Insert', subtype: 'trig' },
			'sqrt': { val: ['\\sqrt{', '}'], display: '<span style="font-size: 14px; display: inline-block; position: relative; top: -1px;"><katex>\\sqrt{\\colorbox{#ffcccc}{$\\textcolor{#ffcccc}{1}$}}</katex></span>', type: 'Insert', subtype: 'SqRt', label: 'Square root' },
			'nroot': { val: ['\\nroot{', ',', '}'], display: '<span style="font-size: 14px; display: inline-block; position: relative; top: -1px;"><katex>\\sqrt[\\raisebox{0.4em}{$\\colorbox{#ffcccc}{$\\textcolor{#ffcccc}{\\tiny 1}$}$}]{\\colorbox{#ffcccc}{$\\textcolor{#ffcccc}{1}$}}</katex></span>', type: 'Insert', subtype: 'NRoot', label: 'N-th root', take_neighbouring: true },
			'pi': { val: '\\pi', display: '<span style="font-family: serif;">&pi;</span>', type: 'Insert', subtype: 'Constant' },
			// cursor control
			'Backspace': { val: '&#9003;', type: 'Backspace' },
			'Delete': { val: '&#8998;', type: 'Delete' },
			'ArrowLeft': { val: '&larr;', type: 'CursorLeft' },
			'ArrowRight': { val: '&rarr;', type: 'CursorRight' },
			'ArrowUp': { val: '&uarr;', type: 'CursorUp' },
			'ArrowDown': { val: '&darr;', type: 'CursorDown' },
			'Home': { val: 'Home', type: 'Home' },
			'End': { val: 'End', type: 'End' },
			'FullClear': { val: 'FullClear', type: 'FullClear' },
			'Enter': { val: 'Enter', type: 'Enter' },
			// TODO: PgUp, PgDn
			// placeholders
			'Input': { val: '[?kb1]', display: '<span style="font-size: 70%;">?input</span>', type: 'Insert', subtype: 'Input' },
			'Param': { val: '[$p1]', display: '<span style="font-size: 70%;">$param</span>', type: 'Insert', subtype: 'Param' },
		};

		// TODO: add vars a-z to this.keys via loop
	}

	setEquation(str) {
		// add any prefix/postfix tokens to str, if not already present - these are included in the parsed tree
		if (this.display_options.prefix && this.display_options.postfix) {
			var esc_prefix = this.display_options.prefix.replace(/([.*+?^${}()|\[\]\/\\])/g, "\\$1");
			if (!str.match(new RegExp('^' + esc_prefix))) {
				str = this.display_options.prefix + str;
			}
			var esc_postfix = this.display_options.postfix.replace(/([.*+?^${}()|\[\]\/\\])/g, "\\$1");
			if (!str.match(new RegExp(esc_postfix + '$'))) {
				str = str + this.display_options.postfix;
			}
		}

		// parse equation and get cursor info
		var editor_info = tokenize_and_parse(str, {});
		this.tree = editor_info.tree;
		this.token_array = editor_info.token_array;
		this.original_string = editor_info.original_string;
		this.implied_string = this.token_array.map(function (x) { return x.value; }).join('');
		this.output_str = this.original_string;

		// now remove any prefix/postfix tokens from token_array and implied_string - they should be ignored by the editor
		if (this.display_options.prefix && this.token_array[0].value == this.display_options.prefix) {
			// if prefix matches first token, then strip token from token_array and implied_string
			this.token_array.shift();

			// strip from this.implied_string
			// eslint-disable-next-line no-redeclare
			var esc_prefix = this.display_options.prefix.replace(/([.*+?^${}()|\[\]\/\\])/g, "\\$1");
			this.implied_string = this.implied_string.replace(new RegExp('^' + esc_prefix), '');
		}
		if (this.display_options.postfix && this.token_array[this.token_array.length - 1].value == this.display_options.postfix) {
			// if postfix matches last token, then strip token from token_array and implied_string
			this.token_array.pop();

			// strip from this.implied_string
			// eslint-disable-next-line no-redeclare
			var esc_postfix = this.display_options.postfix.replace(/([.*+?^${}()|\[\]\/\\])/g, "\\$1");
			this.implied_string = this.implied_string.replace(new RegExp(esc_postfix + '$'), '');
		}

		if (this.cursor_index === null)
			this.cursor_index = this.implied_string.length;
	}

	/**
	 * Returns widget markup for inclusion in question output
	 * @param {Object} value
	 * @param {Object} options
	 * @returns {string} Generated display markup
	 */
	display() {
		var value = this.value;

		var ml = '';
		ml += '<span ' +
			(this.name ? ' name="' + this.name + '"' : '') +
			(this.input_key_index !== undefined ? ' data-input_key_index="' + this.input_key_index + '"' : '') +
			' class="input_box answer">';
		if (!value.length)
			ml += '<span style="color:transparent;">??</span>'; // TODO: latex phantom "??"
		else {
			ml += this.tree.display(Object.assign({
				show_implied_faded: 1
			}, this.display_options));
		}
		ml += '</span>';

		return ml;
	}

	static shift_keycodes = {
		48: ')',
		49: '!',
		50: '@',
		51: '#',
		52: '$',
		53: '%',
		54: '^',
		55: '&',
		56: '*',
		57: '(',

		186: ':',
		187: '+',
		188: '<',
		189: '_',
		190: '>',
		191: '?',
		219: '{',
		221: '}',
	};

	static keycodes = {
		8: 'Backspace',
		9: 'Tab',
		35: 'End',
		36: 'Home',
		13: 'Enter',
		37: 'ArrowLeft',
		38: 'ArrowUp',
		39: 'ArrowRight',
		40: 'ArrowDown',
		46: 'Delete',

		48: '0',
		49: '1',
		50: '2',
		51: '3',
		52: '4',
		53: '5',
		54: '6',
		55: '7',
		56: '8',
		57: '9',

		186: ';',
		187: '=',
		188: ',',
		189: '-',
		190: '.',
		191: '/',
		219: '[',
		221: ']',
	};

	static kb_map = {
		editor: {
			kb_rows: [
				[ 7, 8, 9, 'x', '+', '-', '*', 'divide', 'sqrt'],
				[ 4, 5, 6, 'y', '<', '>', '<=', '>=', 'nroot'],
				[ 1, 2, 3, 'z', '=', '(', ')', '!', 'pi' ],
				[ 'Backspace', 0, '.', '^', 'frac', 'mfrac', 'list', ',' ],
				[ 'Delete', 'ArrowLeft', 'ArrowRight', 'sin', 'cos', 'tan', 'Input', 'Param' ],
			],
			key_aliases: {'/': 'frac'},
		},
		basic_integer: {
			kb_rows: [
				[ 7, 8, 9 ],
				[ 4, 5, 6 ],
				[ 1, 2, 3 ],
				[ '-', 0, ],
				[ 'Backspace', 'ArrowLeft', 'ArrowRight' ],
			],
		},
		basic_decimal: {
			kb_rows: [
				[ 7, 8, 9 ],
				[ 4, 5, 6 ],
				[ 1, 2, 3 ],
				[ '-', 0, '.' ],
				[ 'Backspace', 'ArrowLeft', 'ArrowRight' ],
			],
		},
		basic_operations: {
			kb_rows: [
				[ 7, 8, 9, '+', '=' ],
				[ 4, 5, 6, '-', '<' ],
				[ 1, 2, 3, '*', '>' ],
				[ '-', 0, '.', 'divide' ],
				[ 'Backspace', 'ArrowLeft', 'ArrowRight' ],
			],
			key_aliases: {'/': 'divide'},
		},
		basic_var_operations: {
			kb_rows: [
				[ 7, 8, 9, '+', '=', 'x' ],
				[ 4, 5, 6, '-', '<', 'y' ],
				[ 1, 2, 3, '*', '>', 'z' ],
				[ '-', 0, '.', 'divide' ],
				[ 'Backspace', 'ArrowLeft', 'ArrowRight' ],
			],
			key_aliases: {'/': 'divide'},
		},
		basic_integer_fraction: {
			kb_rows: [
				[ 7, 8, 9 ],
				[ 4, 5, 6 ],
				[ 1, 2, 3 ],
				[ '-', 0, 'frac' ],
				[ 'Backspace', 'ArrowLeft', 'ArrowRight' ],
			],
			key_aliases: {'/': 'frac'},
		},
		basic_integer_exponent: {
			kb_rows: [
				[ 7, 8, 9 ],
				[ 4, 5, 6 ],
				[ 1, 2, 3 ],
				[ '-', 0, '^' ],
				[ 'Backspace', 'ArrowLeft', 'ArrowRight' ],
			],
		},
		basic_integer_sqrt: {
			kb_rows: [
				[ 7, 8, 9 ],
				[ 4, 5, 6 ],
				[ 1, 2, 3 ],
				[ '-', 0, 'sqrt' ],
				[ 'Backspace', 'ArrowLeft', 'ArrowRight' ],
			],
		},
		basic_integer_roots: {
			kb_rows: [
				[ 7, 8, 9, 'sqrt' ],
				[ 4, 5, 6, 'nroot' ],
				[ 1, 2, 3, 'frac' ],
				[ '-', 0 ],
				[ 'Backspace', 'ArrowLeft', 'ArrowRight' ],
			],
		},
		basic_mixed_fraction: {
			kb_rows: [
				[ 7, 8, 9 ],
				[ 4, 5, 6 ],
				[ 1, 2, 3 ],
				[ 'frac', 0, 'mfrac' ],
				[ 'Backspace', 'ArrowLeft', 'ArrowRight' ],
			],
			key_aliases: {'/': 'frac'},
		},
		basic_integer_list: {
			kb_rows: [
				[ 7, 8, 9 ],
				[ 4, 5, 6 ],
				[ 1, 2, 3 ],
				[ '-', 0, ',' ],
				[ 'Backspace', 'ArrowLeft', 'ArrowRight' ],
			],
			display_options: {hide_set_brackets: 1, prefix: 'list{', postfix: '}'},
		},
		basic_polynomial: {
			kb_rows: [
				[ 7, 8, 9, 'x', 'frac' ],
				[ 4, 5, 6, 'y', '(' ],
				[ 1, 2, 3, 'z', ')' ],
				[ '-', 0, '+', '^' ],
				[ 'Backspace', 'ArrowLeft', 'ArrowRight' ],
			],
		},
	};
	static kb_list = [
		'basic_integer',
		'basic_decimal',
		'basic_operations',
		'basic_var_operations',
		'basic_integer_fraction',
		'basic_integer_exponent',
		'basic_integer_sqrt',
		'basic_integer_roots',
		'basic_mixed_fraction',
		'basic_integer_list',
		'basic_polynomial',
	];

	exportValue(options?) {
		return {
			type: 'kb',
			name: this.name,
			value: '',
			kb_type: this.type,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}
