import * as katex from 'katex';
import { tokenize_and_parse } from './QEGrammar';
import { QETerm } from './QETerm';
import { QEWidget, Eq, ImgSet, Tally, StringLookup, FormatSelector, WidgetList, DecimalGrid, PlaceBlocks } from './QEWidget';
import { QEWidgetTable as Table } from './Widget/Table';
import { QEWidgetFractionSet as FractionSet } from './Widget/FractionSet';
import { QEWidgetFractionShape as FractionShape } from './Widget/FractionShape';
import { QEWidgetMC as MC } from './Widget/QEWidgetMC';
import { QEWidgetMultiSelect as MultiSelect } from './Widget/MultiSelect';
import { QEWidgetMultiInput as MultiInput } from './Widget/MultiInput';
import { QEWidgetDropDown as DropDown } from './Widget/DropDown';
import { QEEqKeyboard as EqKeyboard } from 'QEEqKeyboard'; // aliased in webpack.config.js
import { QEWidgetGraph as Graph } from 'QEWidgetGraph'; // aliased in webpack.config.js
import { QESolver } from 'QESolver';

interface DisplayOptions {
	[key: string]: any;
}

export interface ExplanationStep {
	desc: string;
	widget_data?: unknown;
	substeps?: ExplanationStep[];
	value: QEValue;
}

export interface Solution {
	value: QEValue;
	steps: ExplanationStep[];
}

export class QEValue {
	value: any;
	type?: string; // always
	subtype?: string; // only for Widget
	key_name?: string;

	constructor(data: { [key: string]: any }) {
		Object.assign(this, data);
	}
	static create(data: { [key: string]: any }): QEValue {
		// the static factory method is defined outside of QEValue,
		//   since it needs to reference subclasses that extend QEValue
		return createQEValue(data);
	}
	applyResolutionSpecifier(specifier: string, resolved_data) {
		if (specifier.match(/^display_as\([^\)]*\)/)) {
			// [$value.display_as(widget_key)] or [$value.display_as(widget_key, key1, val1, ...)]
			// calls referenced widget with .display({ value: this, key1: val1, ... })

			const display_options_str = specifier.match(/^display_as\(([^\)]*)\)/)[1];
			const display_widget_key = display_options_str.split(/,/)[0];

			// get referenced display object for subsequent rendering
			const display_widget = resolved_data.resolved[display_widget_key];
			if (!display_widget) return null; // unresolved dependency

			// build map of any display options - strip display_widget_key from start of display option string
			const display_options: DisplayOptions = QEHelper.resolveOptionsString(display_options_str.replace(/^[^,]*,?/, ""), resolved_data);
			if (!display_options) return null; // unresolved dependency

			// special handling for MC: display_widget must be passed to MC so it can be used for displaying each choice
			if (this.subtype == "mc") {
				display_options.display_as = display_widget;
				return new QEValueString({ value: this.value.display(display_options) });
			} else {
				display_options.value = this;
				return new QEValueString({ value: display_widget.value.display(display_options) });
			}
		} else if (specifier.match(/^display\([^\)]*\)/)) {
			const display_options_str = specifier.match(/^display\(([^\)]*)\)/)[1];

			// build map of any display options
			const display_options: DisplayOptions = QEHelper.resolveOptionsString(display_options_str, resolved_data);
			if (!display_options) {
				// there was an unresolved dependency
				return null;
			}

			// pass display_options to current widget
			const markup = this.display(Object.assign({ resolved_data: resolved_data }, display_options));
			return new QEValueString({ value: markup });
		} else if (specifier.match(/^to_text\(\)/)) {
			return new QEValueString({ value: this.serialize_to_text() });
		} else if (specifier.match(/^apply_solver_step\([^)]*\)/)) {
			const step_key = specifier.match(/apply_solver_step\(([^)]*)\)/)[1];

			// call the specified solver step
			const solved = QESolver.applySolverStep(step_key, this);
			if (!solved) {
				console.log("Error: applySolverStep returned undef for step_key: ", step_key, this);
				return null;
			}
			return solved.value;
		} else if (specifier.match(/^solve_using\([^)]*\)/)) {
			const solver_key = specifier.match(/solve_using\(([^)]*)\)/)[1];

			// call the specified solver
			const solved = QESolver.solveUsing(solver_key, this);
			if (!solved) {
				console.log("Error: solveUsing returned undef for solver_key: ", solver_key, this);
				return null;
			}
			return solved.value;
		}

		console.log("applyResolutionSpecifier: '" + specifier + "' not handled for QEValue: ", this);
		return null;
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		console.log("Error: calling serialize_to_text on base QEValue object");
		return null;
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		console.log("Error: calling display on base QEValue object");
		return null;
	}
	exportValue(options: { allow_private?: boolean } = {}) {
		console.log("Error: calling exportValue on base QEValue object");
		return null;
	}
}

export class QEValueTree extends QEValue {
	value: QETerm;

	constructor(data: { value: QETerm }) {
		super(Object.assign({ type: "tree" }, data));
		this.value = data.value;

		// check if tree begins with a ROOT. If not, wrap it
		if (this.value.type != "ROOT") {
			const new_root = QETerm.create({ type: "ROOT" });
			new_root.pushChild(this.value);
			this.value = new_root;
		}
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		return this.value.serialize_to_text(options);
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return this.value.display(options);
	}
	exportValue(options: { allow_private?: boolean } = {}) {
		return this.value.serialize_to_text();
	}

	static applySpecifierEvaluateToFloat(tree) {
		// check that first child of ROOT is not a comparator CHAIN
		if (tree.children[0].isComparatorChain()) {
			console.log("Error: evaluate_to_float specifier called on comparator tree: ", tree.serialize_to_text());
			return new QEValueTree({ value: tree });
		}

		const val = tree.evaluate_to_float();
		if (Number.isNaN(val)) {
			console.log("Error: evaluate_to_float resulted in NaN");
			return new QEValueTree({ value: tree });
		}
		const val_term = QETerm.create({ type: "RATIONAL", value: val.toString() });
		return new QEValueTree({ value: val_term });
	}
	static applySpecifierLHS(tree, resolved_data) {
		// check that first child of ROOT is a comparator CHAIN, or a binary mode comparator: EQUAL, LESS, etc.
		if (tree.children[0].isComparatorChain())
			return new QEValueTree({ value: tree.children[0].children[0]});
		else
			console.log("Error: lhs specifier called on non-comparator tree: ", tree.serialize_to_text());
		return new QEValueTree({ value: tree });
	}
	static applySpecifierRHS(tree, resolved_data) {
		// check that first child of ROOT is a comparator CHAIN, or a binary mode comparator: EQUAL, LESS, etc.
		if (tree.children[0].isComparatorChain())
			return new QEValueTree({ value: tree.children[0].children[2]});
		else
			console.log("Error: rhs specifier called on non-comparator tree: ", tree.serialize_to_text());
		return new QEValueTree({ value: tree });
	}
	static applySpecifierRoundTo(specifier, tree, resolved_data) {
		// get rounding place - default to 1
		let precision_key = specifier.match(/round_to\(([^)]*)\)/)[1];
		if (!precision_key) {
			precision_key = "1";
		}

		// placeholder resolution of precision
		let precision_num;
		if (precision_key.match(/^\$/)) {
			const resolved_precision_key = QEHelper.resolvePlaceholderToString('['+ precision_key +']', resolved_data);
			if (resolved_precision_key === null)
				return null;
			precision_num = Number(resolved_precision_key.serialize_to_text());
		} else {
			precision_num = Number(precision_key);
		}
		if (isNaN(precision_num)) {
			console.log("Error: round_to() specifier called with NaN precision: ", precision_key);
			return null;
		}

		// confirm tree is a rational number
		if (tree.children[0].type != "RATIONAL") {
			console.log("Error: round_to() specifier called on non-rational tree: ", tree.serialize_to_text());
			return null;
		}

		const log10_exp = Math.round(Math.log10(precision_num));
		const scale10 = Math.pow(10, -log10_exp);

		const value = tree.evaluate_to_float();
		const rounded_value = Math.round(value * scale10) / scale10;

		const rounded = QETerm.create({ type: "RATIONAL", value: rounded_value.toString() });
		return new QEValueTree({ value: rounded });
	}
	static applySpecifierListGet(specifier, tree, resolved_data) {
		const index_key = specifier.match(/list_get\(([^)]*)\)/)[1];

		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			console.log('Error: .list_get specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// placeholder resolution of list index
		let index_num;
		if (index_key.match(/^\$/)) {
			const resolved_index_key = QEHelper.resolvePlaceholderToString('['+ index_key +']', resolved_data);
			if (resolved_index_key === null)
				return null;
			index_num = Number(resolved_index_key.serialize_to_text());
		} else {
			index_num = Number(index_key);
		}

		if (Number.isNaN(index_num) || index_num < 0 || index_num >= tree.children[0].children.length) {
			console.log('Error: list_get index out of bounds: ', index_num);
			return null;
		}

		return new QEValueTree({ value: tree.children[0].children[index_num]});
	}
	static applySpecifierListLength(tree) {
		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			console.log('Error: .list_length specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		const len = tree.children[0].children.length.toString();
		const len_tree = tokenize_and_parse(len, {}).tree;
		return new QEValueTree({ value: len_tree});
	}
	static applySpecifierListMin(tree) {
		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list" || !tree.children[0].children.length) {
			console.log('Error: .list_min() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// note: if the first item is NaN, subsequent items will not be < NaN, so that item will remain the "min"
		const list = tree.children[0];
		let min = list.children[0];
		let min_val = min.evaluate_to_float();
		for (let i = 1; i < list.children.length; i++){
			let val = list.children[i].evaluate_to_float();
			if (val < min_val) {
				min_val = val;
				min = list.children[i];
			}
		}
		return new QEValueTree({ value: min });
	}
	static applySpecifierListMax(tree) {
		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list" || !tree.children[0].children.length) {
			console.log('Error: .list_max() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// note: if the first item is NaN, subsequent items will not be > NaN, so that item will remain the "max"
		const list = tree.children[0];
		let max = list.children[0];
		let max_val = max.evaluate_to_float();
		for (let i = 1; i < list.children.length; i++){
			let val = list.children[i].evaluate_to_float();
			if (val > max_val) {
				max_val = val;
				max = list.children[i];
			}
		}
		return new QEValueTree({ value: max });
	}
	static applySpecifierListSort(tree) {
		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			console.log('Error: .list_sort() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// sort list children
		const clone = tree.clone();
		const list = clone.children[0];
		list.children.sort(function(a_term, b_term){
			const a = a_term.evaluate_to_float();
			const b = b_term.evaluate_to_float();

			// sort NaN values to the end of the list
			return !Number.isNaN(a) && Number.isNaN(b) ? -1 : Number.isNaN(a) && !Number.isNaN(b) ? 1 : Number.isNaN(a) && Number.isNaN(b) ? 0 : a < b ? -1 : a > b ? 1 : 0;
		});

		return new QEValueTree({ value: clone });
	}
	static applySpecifierListSortDesc(tree) {
		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			console.log('Error: .list_sortdesc() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// sort list children
		const clone = tree.clone();
		const list = clone.children[0];
		list.children.sort(function(b_term, a_term){ // NOTE: arg order flipped, so sort order is descending
			const a = a_term.evaluate_to_float();
			const b = b_term.evaluate_to_float();

			// sort NaN values to the end of the list
			return !Number.isNaN(a) && Number.isNaN(b) ? -1 : Number.isNaN(a) && !Number.isNaN(b) ? 1 : Number.isNaN(a) && Number.isNaN(b) ? 0 : a < b ? -1 : a > b ? 1 : 0;
		});

		return new QEValueTree({ value: clone });
	}
	static applySpecifierListShuffle(tree) {
		// verify tree.children[0] is a list{}
		if (tree.children[0].value !== "list") {
			console.log('Error: .list_shuffle() specifier called on non-list tree: ', tree.serialize_to_text());
			return null;
		}

		// shuffle list children
		const clone = tree.clone();
		shuffle(clone.children[0].children);

		return new QEValueTree({ value: clone });
	}
	static applySpecifierFracToMixed(tree, resolved_data) {
		// convert any fracs to mfracs
		const clone = tree.clone();
		const terms = clone.findAllChildren("value", "frac");
		for (let i = 0; i < terms.length; i++) {
			const frac = terms[i];
			const num = frac.children[0];
			const den = frac.children[1];
			if (num.type !== "RATIONAL" || !Number.isInteger(Number(num.value)) ||
				den.type !== "RATIONAL" || !Number.isInteger(Number(den.value)) ||
				Number(den.value) == 0) {
				continue; // not a valid frac
			}

			// create a new mfrac
			const new_whole = Math.trunc(Number(num.value) / Number(den.value));
			const new_num = Number(num.value) % Number(den.value);
			const new_den = Number(den.value);

			if (!new_num) {
				// no remaining frac: integer instead of mfrac
				const new_integer = QETerm.create({ type: "RATIONAL", value: new_whole.toString() });

				// replace the frac
				frac.replaceWith(new_integer);
			} else if (new_whole) {
				const new_mfrac = QETerm.create({ type: "FUNCTION", value: "mfrac" });
				new_mfrac.pushChild(QETerm.create({ type: "RATIONAL", value: new_whole.toString() }));
				new_mfrac.pushChild(QETerm.create({ type: "RATIONAL", value: new_num.toString() }));
				new_mfrac.pushChild(QETerm.create({ type: "RATIONAL", value: new_den.toString() }));

				// replace the frac
				frac.replaceWith(new_mfrac);
			}
			// else zero whole part and zero numerator; can skip
		}

		return new QEValueTree({ value: clone });
	}
	static applySpecifierMixedToFrac(tree, resolved_data) {
		// convert any mfracs to fracs
		const clone = tree.clone();
		const terms = clone.findAllChildren("value", "mfrac");
		for (let i = 0; i < terms.length; i++) {
			const mfrac = terms[i];
			const whole = mfrac.children[0];
			const num = mfrac.children[1];
			const den = mfrac.children[2];
			if (whole.type !== "RATIONAL" || !Number.isInteger(Number(whole.value)) ||
				num.type !== "RATIONAL" || !Number.isInteger(Number(num.value)) ||
				den.type !== "RATIONAL" || !Number.isInteger(Number(den.value))) {
				continue; // not a valid mfrac
			}

			// create a new fraction
			const new_num = Number(whole.value) * Number(den.value) + Number(num.value);
			const new_den = Number(den.value);

			const new_frac = QETerm.create({ type: "FUNCTION", value: "frac" });
			new_frac.pushChild(QETerm.create({ type: "RATIONAL", value: new_num.toString() }));
			new_frac.pushChild(QETerm.create({ type: "RATIONAL", value: new_den.toString() }));

			// replace the mfrac
			mfrac.replaceWith(new_frac);
		}

		return new QEValueTree({ value: clone });
	}
	static applySpecifierSortAddTerms(value, resolved_data) {
		// apply CT_sortAdditiveChainTerms solver step
		const solved = QESolver.applySolverStep("CT_sortAdditiveChainTerms", value);
		if (!solved) {
			console.log("Error: applySolverStep returned undef for step_key: ", "CT_sortAdditiveChainTerms", value);
			return null;
		}
		return solved.value;
	}
	static applySpecifierSortMultiplyTerms(value, resolved_data) {
		// apply CT_sortAdditiveChainTerms solver step
		const solved = QESolver.applySolverStep("CT_sortMultiplicativeChainTerms", value);
		if (!solved) {
			console.log("Error: applySolverStep returned undef for step_key: ", "CT_sortMultiplicativeChainTerms", value);
			return null;
		}
		return solved.value;
	}
	static applySpecifierTimeHours(tree) {
		// verify tree.children[0] is time_hm{} or time_hms
		if (["time_hm", "time_hms"].indexOf(tree.children[0].value) == -1) {
			console.log('Error: .hours specifier called on non-time tree: ', tree.serialize_to_text());
			return null;
		}
		return new QEValueTree({ value: tree.children[0].children[0] });
	}
	static applySpecifierTimeMinutes(tree) {
		// verify tree.children[0] is time_hm{} or time_hms
		if (["time_hm", "time_hms"].indexOf(tree.children[0].value) == -1) {
			console.log('Error: .hours specifier called on non-time tree: ', tree.serialize_to_text());
			return null;
		}
		return new QEValueTree({ value: tree.children[0].children[1] });
	}
	static applySpecifierTimeSeconds(tree) {
		// verify tree.children[0] is time_hm{} or time_hms
		if (["time_hm", "time_hms"].indexOf(tree.children[0].value) == -1) {
			console.log('Error: .hours specifier called on non-time tree: ', tree.serialize_to_text());
			return null;
		}
		if (tree.children[0].value == "time_hms") {
			return new QEValueTree({ value: tree.children[0].children[2] });
		} else {
			return new QEValueTree({ value: QETerm.create({ type: "RATIONAL", value: "00" }) });
		}
	}
	applyResolutionSpecifier(specifier: string, resolved_data) {
		if (specifier.match(/^evaluate_to_float\(\)/)) {
			return QEValueTree.applySpecifierEvaluateToFloat(this.value);
		} else if (specifier == 'lhs') {
			return QEValueTree.applySpecifierLHS(this.value, resolved_data);
		} else if (specifier == 'rhs') {
			return QEValueTree.applySpecifierRHS(this.value, resolved_data);
		} else if (specifier.match(/^round_to\([^)]*\)/)) {
			return QEValueTree.applySpecifierRoundTo(specifier, this.value, resolved_data);
		} else if (specifier.match(/^list_get\([^)]*\)/)) {
			return QEValueTree.applySpecifierListGet(specifier, this.value, resolved_data);
		} else if (specifier == "list_length") {
			return QEValueTree.applySpecifierListLength(this.value);
		} else if (specifier.match(/^list_min\(\)/)) {
			return QEValueTree.applySpecifierListMin(this.value);
		} else if (specifier.match(/^list_max\(\)/)) {
			return QEValueTree.applySpecifierListMax(this.value);
		} else if (specifier.match(/^list_sort\(\)/)) {
			return QEValueTree.applySpecifierListSort(this.value);
		} else if (specifier.match(/^list_sortdesc\(\)/)) {
			return QEValueTree.applySpecifierListSortDesc(this.value);
		} else if (specifier.match(/^list_shuffle\(\)/)) {
			return QEValueTree.applySpecifierListShuffle(this.value);
		} else if (specifier.match(/^frac_to_mixed\(\)/)) {
			return QEValueTree.applySpecifierFracToMixed(this.value, resolved_data);
		} else if (specifier.match(/^mixed_to_frac\(\)/)) {
			return QEValueTree.applySpecifierMixedToFrac(this.value, resolved_data);
		} else if (specifier.match(/^sort_add_terms\(\)/)) {
			return QEValueTree.applySpecifierSortAddTerms(this, resolved_data); // passes full QEValue to solver
		} else if (specifier.match(/^sort_multiply_terms\(\)/)) {
			return QEValueTree.applySpecifierSortMultiplyTerms(this, resolved_data); // passes full QEValue to solver
		} else if (specifier == "hours") {
			return QEValueTree.applySpecifierTimeHours(this.value);
		} else if (specifier == "minutes") {
			return QEValueTree.applySpecifierTimeMinutes(this.value);
		} else if (specifier == "seconds") {
			return QEValueTree.applySpecifierTimeSeconds(this.value);
		}

		// fallback
		return super.applyResolutionSpecifier(specifier, resolved_data);
	}
}

export class QEValueString extends QEValue {
	constructor(data: { value: string | number | boolean }) {
		// cast boolean and number values to string
		if (typeof data.value == "boolean" || typeof data.value == "number") {
			data.value = data.value.toString();
		}

		super(Object.assign({type: "string"}, data));
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		return this.value;
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return this.value;
	}
	exportValue(options: { allow_private?: boolean } = {}) {
		return this.value;
	}
	applyResolutionSpecifier(specifier: string, resolved_data) {
		if (specifier.match(/^to_tree\(\)/)) {
			// parse and check output for error
			const parsed = tokenize_and_parse(this.value, {parameter_map: resolved_data});
			if (parsed.tree == null)
				return null;

			return new QEValueTree({ value: parsed.tree });
		} else if (specifier.match(/^pad_left\([^,)]+,[^,)]+\)/)) {
			const pad_opts_str = specifier.match(/^pad_left\(([^,)]+,[^,)]+)\)/)[1];
			const pad_opts = pad_opts_str.split(/,/);
			const len = parseInt(pad_opts[0]) || 0;
			const pad_char = pad_opts[1];

			let str = this.value;
			while (str.length < len) {
				str = pad_char + str;
			}
			return new QEValueString({ value: str });
		} else if (specifier.match(/^pad_right\([^,)]+,[^,)]+\)/)) {
			const pad_opts_str = specifier.match(/^pad_right\(([^,)]+,[^,)]+)\)/)[1];
			const pad_opts = pad_opts_str.split(/,/);
			const len = parseInt(pad_opts[0]) || 0;
			const pad_char = pad_opts[1];

			let str = this.value;
			while (str.length < len) {
				str += pad_char;
			}
			return new QEValueString({ value: str });
		}

		// fallback
		return super.applyResolutionSpecifier(specifier, resolved_data);
	}
}

export class QEValueJSON extends QEValue {
	value: object;

	constructor(data: { value: object }) {

		// check that data contains only arrays, object maps, and primitives - reject complex objects like QEValues or QE.Terms.
		function containsComplexObjects(val){
			if (val instanceof Array) {
				for (let i = 0; i < val.length; i++) {
					if (containsComplexObjects(val[i])) {
						return true;
					}
				}
			} else if (val instanceof Object) {
				if (val.constructor !== Object) {
					console.log('Complex object found in JSON data: ', val);
					return true;
				}
				const keys = Object.keys(val);
				for (let i = 0; i < keys.length; i++) {
					if (containsComplexObjects(val[keys[i]])) {
						return true;
					}
				}
			}
			return false;
		}

		if (containsComplexObjects(data)) {
			console.log('ERROR. QEValueJSON instantiated with non-primitive key value.', data);
			debugger;
		}

		super(Object.assign({type: "json"}, data));
		this.value = data.value;
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		return JSON.stringify(this.value);
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return JSON.stringify(this.value);
	}
	exportValue(options: { allow_private?: boolean } = {}) {
		return JSON.stringify(this.value);
	}
	applyResolutionSpecifier(specifier: string, resolved_data) {
		if (specifier.match(/^list_get\([^)]*\)/)) {
			if (this.value instanceof Array) {
				const index_key = specifier.match(/list_get\(([^)]*)\)/)[1];

				// placeholder resolution of list index
				let index_num;
				if (index_key.match(/^\$/)) {
					const resolved_index_key = QEHelper.resolvePlaceholderToString('['+ index_key +']', resolved_data);
					if (resolved_index_key === null)
						return null;
					index_num = Number(resolved_index_key.serialize_to_text());
				} else {
					index_num = Number(index_key);
				}

				if (Number.isNaN(index_num) || index_num < 0 || index_num >= this.value.length) {
					console.log('Error: list_get index out of bounds: ', index_num);
					return null;
				}

				// determine if referenced item is an Object, or string
				const item = this.value[index_num];
				if (item instanceof Object) {
					return new QEValueJSON({ value: item });
				} else {
					return new QEValueString({ value: item });
				}
			} else {
				console.log('Error: .list_get(index) specifier called on non-list JSON: ', JSON.stringify(this.value));
				return null;
			}
		} else if (specifier.match(/^map_get\([^)]*\)/)) {
			if (this.value instanceof Object && !(this.value instanceof Array)) {
				const key_str = specifier.match(/map_get\(([^)]*)\)/)[1];

				// placeholder resolution of key_str
				let key;
				if (key_str.match(/^\$/)) {
					const resolved_key_str = QEHelper.resolvePlaceholderToString('['+ key_str +']', resolved_data);
					if (resolved_key_str === null)
						return null;
					key = resolved_key_str.serialize_to_text();
				} else {
					key = key_str;
				}

				// determine if referenced item is an Object, or string
				const item = this.value[key];
				if (item === undefined) {
					console.log('Error: map_get key not found: ', key_str, key);
					return null;
				}

				if (item instanceof Object) {
					return new QEValueJSON({ value: item });
				} else {
					return new QEValueString({ value: item });
				}
			} else {
				console.log('Error: .map_get(key) specifier called on non-map JSON: ', JSON.stringify(this.value));
				return null;
			}
		} else if (specifier == "list_length") {
			if (this.value instanceof Array) {
				// return length as tree
				const len = this.value.length.toString();
				const len_tree = tokenize_and_parse(len, {}).tree;
				return new QEValueTree({ value: len_tree});
			} else {
				console.log('Error: .list_length specifier called on non-list JSON: ', JSON.stringify(this.value));
				return null;
			}
		}

		// fallback
		return super.applyResolutionSpecifier(specifier, resolved_data);
	}
}

// QEValueMap is similar to QEValueJSON but is a one-level deep map AND the values may be other QEValue objects
export class QEValueMap extends QEValue {
	value: object;

	constructor(data: { value: object }) {
		super(Object.assign({type: "map"}, data));
		this.value = data.value;
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		const self = this;

		// serialize each value in the map
		const serialized = {};
		Object.keys(self.value).forEach(x => { serialized[x] = self.value[x].serialize_to_text(options); });
		return JSON.stringify(serialized);

/*
		return '{' + Object.keys(self.value).map(function (x) {
			return x + ':"' + self.value[x].serialize_to_text() + '"';
		}).join(',') + '}';
*/
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return this.serialize_to_text(options);
	}
//	exportValue(options: { allow_private?: boolean } = {}) {
//		return JSON.stringify(this.value);
//	}
}

export class QEValueBoolean extends QEValue {
	value: boolean;

	constructor(data: { value: boolean }) {
		super(Object.assign({type: "boolean"}, data));
		this.value = data.value;
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		return JSON.stringify(this.value);
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return JSON.stringify(this.value);
	}
	exportValue(options: { allow_private?: boolean } = {}) {
		return this.value;
	}
}

export class QEValueWidget extends QEValue {
	value: QEWidget;

	constructor(data: { value: QEWidget, subtype: string, key_name?: string }) {
		super(Object.assign({type: "widget"}, data));
		this.value = data.value;
	}
	applyResolutionSpecifier(specifier: string, resolved_data) {
		if (this.value instanceof Eq) {
			// pass-through tree to QEValueTree static specifier handlers
			if (specifier.match(/^evaluate_to_float\(\)/)) {
				return QEValueTree.applySpecifierEvaluateToFloat(this.value.value);
			} else if (specifier == 'lhs') {
				return QEValueTree.applySpecifierLHS(this.value.value, resolved_data);
			} else if (specifier == 'rhs') {
				return QEValueTree.applySpecifierRHS(this.value.value, resolved_data);
			} else if (specifier.match(/^round_to\([^)]*\)/)) {
				return QEValueTree.applySpecifierRoundTo(specifier, this.value.value, resolved_data);
			} else if (specifier.match(/^list_get\([^)]*\)/)) {
				return QEValueTree.applySpecifierListGet(specifier, this.value.value, resolved_data);
			} else if (specifier == "list_length") {
				return QEValueTree.applySpecifierListLength(this.value.value);
			} else if (specifier.match(/^list_min\(\)/)) {
				return QEValueTree.applySpecifierListMin(this.value.value);
			} else if (specifier.match(/^list_max\(\)/)) {
				return QEValueTree.applySpecifierListMax(this.value.value);
			} else if (specifier.match(/^list_sort\(\)/)) {
				return QEValueTree.applySpecifierListSort(this.value.value);
			} else if (specifier.match(/^list_sortdesc\(\)/)) {
				return QEValueTree.applySpecifierListSortDesc(this.value.value);
			} else if (specifier.match(/^list_shuffle\(\)/)) {
				return QEValueTree.applySpecifierListShuffle(this.value.value);
			} else if (specifier.match(/^frac_to_mixed\(\)/)) {
				return QEValueTree.applySpecifierFracToMixed(this.value.value, resolved_data);
			} else if (specifier.match(/^mixed_to_frac\(\)/)) {
				return QEValueTree.applySpecifierMixedToFrac(this.value.value, resolved_data);
			} else if (specifier.match(/^sort_add_terms\(\)/)) {
				return QEValueTree.applySpecifierSortAddTerms(this, resolved_data); // passes full QEValue to solver
			} else if (specifier.match(/^sort_multiply_terms\(\)/)) {
				return QEValueTree.applySpecifierSortMultiplyTerms(this, resolved_data); // passes full QEValue to solver
			} else if (specifier == "hours") {
				return QEValueTree.applySpecifierTimeHours(this.value.value);
			} else if (specifier == "minutes") {
				return QEValueTree.applySpecifierTimeMinutes(this.value.value);
			} else if (specifier == "seconds") {
				return QEValueTree.applySpecifierTimeSeconds(this.value.value);
			}
		} else if (this.value instanceof MC) {
			if (specifier == 'correct_target') {
				const correct_data = this.value.getCorrectChoice();
				if (correct_data) {
					// check that choice is an answer, and if so resolve to answer target
					if (correct_data.value.type == 'answer') {
						return correct_data.value.target;
					} else {
						console.log('Error: correct_target for mc not set: ', this);
						return null;
					}
				} else {
					console.log('Error: correct choice for mc not set: ', this);
					return null;
				}
			} else if (specifier == 'correct_value') {
				const correct_data = this.value.getCorrectChoice();
				if (correct_data) {
					return correct_data.value;
				} else {
					console.log('Error: correct choice for mc not set: ', this);
					return null;
				}
			} else if (specifier == 'correct_display') {
				const correct_data = this.value.getCorrectChoice();
				if (correct_data) {
					return new QEValueString({ value: correct_data.display });
				} else {
					console.log('Error: correct choice for mc not set: ', this);
					return null;
				}
			} else if (specifier == 'user_value') {
				const choice_data = this.value.user_value;
				if (choice_data) {
					return choice_data;
				} else {
					// no value set yet for user_value - likely not yet submitted
					return null;
				}
			}
		} else if (this.value instanceof StringLookup) {
			if (specifier.match(/^lookup\([^)]*\)/)) {
				let string_key = specifier.match(/lookup\(([^)]*)\)/)[1];
				if (string_key.match(/^\$/)) {
					const lookup_value_key = string_key.slice(1);

					// get referenced placeholder object
					const lookup_value = resolved_data.resolved[lookup_value_key];
					if (!lookup_value)
						return null;

					// serialize lookup_value
					string_key = lookup_value.serialize_to_text();
				}

				return new QEValueString({ value: this.value.lookup(string_key) });
			}
		} else if (this.value instanceof Table) {
			if (specifier.match(/^cell\([^)]*\)/)) {
				// getCell handles placeholder resolution of $col,$row
				const cell_key = specifier.match(/cell\(([^)]*)\)/)[1];
				return this.value.getCellValue(cell_key, resolved_data);
			} else if (specifier.match(/^col\([^)]*\)/)) {
				// getCol handles placeholder resolution of $col
				const col_key = specifier.match(/col\(([^)]*)\)/)[1];
				return this.value.getColList(col_key, resolved_data);
			} else if (specifier.match(/^row\([^)]*\)/)) {
				// getRow handles placeholder resolution of $row
				const row_key = specifier.match(/row\(([^)]*)\)/)[1];
				return this.value.getRowList(row_key, resolved_data);
			}
		}
		// TODO: other widget-type-specific specifier handlers

		return super.applyResolutionSpecifier(specifier, resolved_data);
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		if (this.value instanceof Eq) {
			return this.value.value.serialize_to_text(options);
		} else if (this.value instanceof MC) {
			// TODO: get user_value
			if (options.correct_value) {
				const correct_data = this.value.getCorrectChoice();
				if (correct_data) {
					return correct_data.value.serialize_to_text(options);
				} else {
					console.log('Error: correct choice for mc not set: ', this);
				}
			} else if (options.user_value) {
				const choice_data = this.value.user_value;
				if (choice_data) {
					return choice_data.value.serialize_to_text(options);
				} else {
					console.log('Error: user choice for mc not set: ', this);
				}
			} else {
				// default
				console.log('ERROR: serializing MC needs option flag: ', this);
			}
		} else if (this.value instanceof Graph) {
			// TODO: serialize display config and data series
		} else if (this.value instanceof ImgSet) {
			// TODO: serialize display config
		} else if (this.value instanceof Table) {
			return this.value.serialize_to_text();
		} else {
			console.log('Error: attempting to serialize widget with unsupported subtype.', this);
		}

		return null;
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return this.value.display(options);
	}
	exportValue(options: { allow_private?: boolean } = {}) {
		return this.value.exportValue(options);
	}
}

export class QEValueAnswer extends QEValue {
	value: Solution;
	target: QEValue;
	solution_key: string;
	target_key: string;

	constructor(data: { value: Solution, target: QEValue, solution_key: string, target_key: string }) {
		super(Object.assign({type: "answer"}, data));
		this.value = data.value;
	}
	serialize_to_text(options: { [key: string]: string | boolean } = {}): string {
		return this.value.value.serialize_to_text(options);
	}
	display(options: { [key: string]: string | boolean } = {}): string {
		return this.value.value.display(options);
	}
	applyResolutionSpecifier(specifier: string, resolved_data) {
		if (specifier == 'target') {
			return this.target;
		} else if (specifier == 'value') {
			return this.value.value; // Solution value
		}

		// pass-through to Solution value
		console.log('Warning: unhandled specifier on QEValueAnswer. Passing through to this.value. Should always specify ".target" or ".value" for QEValueAnswer.', specifier, this);
		return this.value.value.applyResolutionSpecifier(specifier, resolved_data);
	}
}

// static factory to instantiate QEValue subclass
function createQEValue(data: { [key: string]: any }): QEValue {
	switch (data.type) {
		case "tree":
			return new QEValueTree({value: data.value});
		case "string":
			return new QEValueString({value: data.value});
		case "json":
			return new QEValueJSON({value: data.value});
		case "map":
			return new QEValueMap({value: data.value});
		case "boolean":
			return new QEValueBoolean({value: data.value});
		case "widget":
			return new QEValueWidget({subtype: data.subtype, value: data.value});
		case "answer":
			return new QEValueAnswer({value: data.value, target: data.target, solution_key: data.solution_key, target_key: data.target_key});
	}

	console.log("Error: unhundled QEValue subclass type: ", data);
	return null;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class QEHelper {
	// helper function to parse a "key1,val1,key2,val2,..." string into a map AND to resolve placeholders in map values
	// - placeholders are resolved to QEValue objects
	static resolveOptionsString(options_str = "", resolved_data) {
		// build map from options string
		const options = {};

		// first attempt JSON parse, in case options string is JSON instead of comma-delimited text
		let opt_pairs;
		if (typeof options_str == "object") {
			// already parsed
			opt_pairs = Object.keys(options_str).map(function (field_name) { return [field_name, options_str[field_name]]; });
		} else {
			try {
				const deserialized = JSON.parse(options_str);
				opt_pairs = Object.keys(deserialized).map(function (field_name) { return [field_name, deserialized[field_name]]; });
			} catch (err) {
				// could not parse as JSON, treat as comma-separated pairs instead
				opt_pairs = options_str.split(/,/).filter(function (opt_pair) {
					// only keep the "pair" if it is a colon-delimited pair
					return opt_pair.split(/:/).length == 2;
				}).map(function (pair) { return pair.split(/:/); });
			}
		}

		// resolve placeholder in value
		opt_pairs.forEach(function (pair) {
			const pair_key = pair[0];
			let pair_val = pair[1];

			// NOTE: for option string $placeholder pair_val specified in a template (e.g. the "$data" in "[$graph.display(value:$data)]"),
			//    there can't be [] brackets, since otherwise the [$placeholder] would resolve and immediately serialize to string.

			if (typeof pair_val == "string" && pair_val.match(new RegExp('^\\[\\$[^\\[\\]]*\\]$'))) {
				// resolve "[$key]" pair_val
				const value_param = QEHelper.resolvePlaceholderToRefs(pair_val, resolved_data);
				if (!value_param) {
					// unresolved dependency
					return null;
				} else {
					pair_val = value_param;
				}
			} else if (typeof pair_val == "string" && pair_val.match(new RegExp('^\\$[^\.()]*$'))) {
				// resolve "$key" pair_val - auto-wrap in [] brackets for resolve
				const value_param = QEHelper.resolvePlaceholderToRefs('['+ pair_val +']', resolved_data);
				if (!value_param) {
					// unresolved dependency
					return null;
				} else {
					pair_val = value_param;
				}
			}

			options[pair_key] = pair_val;
		});

		return options;
	}

	static getResolutionSpecifierList(str) {
		if (!str.match(/\./))
			return [str];

		// split into list of specifiers on ".", but avoid splitting on specifiers containing ".", such as ".display(style_opactity:0.5)"
		const ref_list = [];
		let temp_str = str;
		while (temp_str.match(/\./)){
			if (temp_str.match(/^[^\.]*\([^\)]*\)\.?/)) {
				ref_list.push(temp_str.match(/^([^\.]*\([^\)]*\))\.?/)[1]);
				temp_str = temp_str.replace(/^[^\.]*\([^\)]*\)\.?/, '');
			} else {
				ref_list.push(temp_str.match(/^([^\.]*)\./)[1]);
				temp_str = temp_str.replace(/^[^\.]*\./, '');
			}
		}
		if (temp_str.length)
			ref_list.push(temp_str);

		return ref_list;
	}

	static applyPlaceholderSpecifiers(ref_key: string, resolved_data): QEValue {
		const ref_list = QEHelper.getResolutionSpecifierList(ref_key);
		const current_widget_key = ref_list.shift();

		let current_widget;
		if (current_widget_key.match(/^".+"$/)) {
			// if current_widget_key is enclosed by '"' tokens, convert enclosed string to QEValueString
			let enclosed = current_widget_key.match(/^"(.+)"$/)[1];
			current_widget = new QEValueString({value: enclosed});
		} else {
			current_widget = resolved_data.resolved[current_widget_key];
		}
		if (!current_widget) return null;

		// handling for resolution specifiers (e.g. lhs, rhs)
		for (let i = 0; i  < ref_list.length; i++) {
			const ref_token = ref_list[i];

			current_widget = current_widget.applyResolutionSpecifier(ref_token, resolved_data);
			if (current_widget === null)
				return null;
		}

		return current_widget;
	}

	// resolvePlaceholderToRefs:
	// - for single ref placeholders (e.g. "[$p1]", or "[$eq1.lhs]"), returns the QEValue(Tree|Widget|etc.) of the placeholder, with applied specifiers
	// - for mixed or no placeholders (e.g. "[$p1]+[$p2]", or "123"), serializes placeholders to text and returns a QEValueString
	// - NOTE: applied specifiers such as "display()" or "display_as(...)" may result in markup
	static resolvePlaceholderToRefs(input_str: string | number | boolean, resolved_data, options: {[key: string]: string} = {}): QEValue {
		if (typeof input_str == "boolean")
			return new QEValueBoolean({ value: input_str });

		if (typeof input_str == "number")
			input_str = input_str.toString();

		// init if empty
		resolved_data.resolved = resolved_data.resolved || {};

		if (input_str.match(new RegExp('^\\[\\$[^\\[\\]]*\\]$'))) {
			// Case 1: input_str is a single reference
			// - apply specifiers
			const match_list = input_str.match(new RegExp('\\[\\$([^\\[\\]]*)\\]'));
			const ref_key = match_list[1];

			return QEHelper.applyPlaceholderSpecifiers(ref_key, resolved_data);
		} else {
			// Case 2: input_str is mixed or has no placeholders (e.g. "[$p1]+[$p2]", or "123")
			// - serialize each placeholder to string and replace in input_str
			while (input_str.match(new RegExp('\\[\\$[^\\[\\]]*\\]'))) {
				const match_list = input_str.match(new RegExp('\\[\\$([^\\[\\]]*)\\]'));
				let ref_key = match_list[1];

				const specified_value = QEHelper.applyPlaceholderSpecifiers(ref_key, resolved_data);
				if (specified_value === null)
					return null;

				// escape non-regex-safe characters to prevent infinite loops
				ref_key = ref_key.replace(/([.*+?^${}()|\[\]\/\\])/g, "\\$1");

				// replace placeholder with serialized string
				const output_str = specified_value.serialize_to_text();
				input_str = input_str.replace(new RegExp('\\[\\$'+ ref_key +'\\]', 'g'), output_str);
			}
			return new QEValueString({value: input_str});
		}
	}

	static resolvePlaceholderToTree(input_str: string | number, resolved_data, options: {[key: string]: string} = {}): QEValueTree {
		const resolved_ref = QEHelper.resolvePlaceholderToRefs(input_str, resolved_data, options);
		if (resolved_ref === null)
			return null;

		if (resolved_ref.type === "string") {
			// parse and check output for error
			const parsed = tokenize_and_parse(resolved_ref.value, {parameter_map: resolved_data});
			if (parsed.tree == null)
				return null;

			return new QEValueTree({ value: parsed.tree });
		} else if (resolved_ref.type === "tree") {
			return resolved_ref;
		} else if (resolved_ref instanceof QEValueWidget && resolved_ref.value instanceof Eq) {
			// TODO: use generic QEValue "export_to_tree()"?
			return new QEValueTree({ value: resolved_ref.value.value });
		}

		console.log("Error: expected placeholder to resolve to tree, but got: ", resolved_ref, input_str);
		return null;
	}

	static resolvePlaceholderToString(input_str: string | number, resolved_data, options: {[key: string]: string} = {}): QEValueString {
		const resolved_ref = QEHelper.resolvePlaceholderToRefs(input_str, resolved_data, options);
		if (resolved_ref === null)
			return null;

		if (resolved_ref.type === "string")
			return resolved_ref;

		const serialized = resolved_ref.serialize_to_text();
		if (serialized === null)
			return null;

		return new QEValueString({ value: serialized });
	}

	static resolvePlaceholderToJSON(input_str: string, resolved_data, options: {[key: string]: string} = {}): QEValueJSON {
		let resolved_ref = QEHelper.resolvePlaceholderToRefs(input_str, resolved_data, options);
		if (resolved_ref === null)
			return null;

		if (resolved_ref instanceof QEValueJSON)
			return resolved_ref;

		let serialized;
		if (resolved_ref instanceof QEValueWidget) {
			if (resolved_ref.value instanceof Graph)
				serialized = resolved_ref.value.exportValue().series;

			if (resolved_ref.value instanceof Table)
				serialized = resolved_ref.value.exportValue().values;
		}

		if (resolved_ref instanceof QEValueString)
			serialized = resolved_ref.value;

		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			console.log("Error: failed to parse JSON: ", serialized, err);
			return null;
		}

		return new QEValueJSON({ value: deserialized });
	}

	static resolvePlaceholderToMarkup(input_str: string | number, resolved_data, options: {[key: string]: string} = {}): QEValueString {
		// NOTE: mostly behaves like QEHelper.resolvePlaceholderToRefs, but serializes with "display()" instead of "serialize_to_text()". Could combine?

		if (typeof input_str == "number")
			input_str = input_str.toString();

		// init if empty
		resolved_data.resolved = resolved_data.resolved || {};

		if (input_str.match(new RegExp('^\\[\\$[^\\[\\]]*\\]$'))) {
			// Case 1: input_str is a single reference
			// - apply specifiers
			const match_list = input_str.match(new RegExp('\\[\\$([^\\[\\]]*)\\]'));
			const ref_key = match_list[1];

			const specifiedValue = QEHelper.applyPlaceholderSpecifiers(ref_key, resolved_data);
			if (specifiedValue === null)
				return null;

			// nested dropdowns in Eq trees must be rendered here, since they contain inline content that must be included in the tree markup
			if (specifiedValue instanceof QEValueWidget && specifiedValue.value instanceof Eq) {
				const eq_widget = specifiedValue.value;
				const tree = eq_widget.value;

				tree.findAllChildren('type', 'INPUT').forEach(function(node){
					const input_key_match = node.value.match(/\[\?(.*)\]/);
					if (!input_key_match) {
						return
					}

					// get the associated nested input widget
					const input_key = input_key_match[1];
					if (!resolved_data.resolved[input_key]) {
						console.log("ERROR: nested input widget not included in exported data");
						return;
					}

					const nested_input_widget = resolved_data.resolved[input_key].value;
					if (nested_input_widget instanceof DropDown) {
						// render DropDown, and set node.attr content_markup
						let iw_ml = nested_input_widget.display();
						node.attr('content_markup', iw_ml);
					}
				});
			}

			return new QEValueString({ value: specifiedValue.display(options) });
		} else {
			// Case 2: input_str is mixed or has no placeholders (e.g. "[$p1]+[$p2]", or "123")
			// - serialize each placeholder to string and replace in input_str
			while (input_str.match(new RegExp('\\[\\$[^\\[\\]]*\\]'))) {
				const match_list = input_str.match(new RegExp('\\[\\$([^\\[\\]]*)\\]'));
				let ref_key = match_list[1];

				const specifiedValue = QEHelper.applyPlaceholderSpecifiers(ref_key, resolved_data);
				if (specifiedValue === null)
					return null;

				// escape non-regex-safe characters to prevent infinite loops
				ref_key = ref_key.replace(/([.*+?^${}()|\[\]\/\\])/g, "\\$1");

				// replace placeholder with serialized string
				const output_str = specifiedValue.display(options);
				input_str = input_str.replace(new RegExp('\\[\\$'+ ref_key +'\\]', 'g'), output_str);
			}
			return new QEValueString({ value: input_str });
		}
	}

	static resolveToNumber(value: string, resolved_data): number {
		let resolved = QEHelper.resolvePlaceholderToTree(value, resolved_data);
		if (!resolved) return null;

		return resolved.value.evaluate_to_float();
	}

	static resolveSpecifierKeyToNumber(key_value: string, resolved_data): number {
		// helper for resolving placeholder keys in placeholder ".specifiers(...)"
		if (key_value.match(/^\$/)) {
			return QEHelper.resolveToNumber('['+ key_value +']', resolved_data);
		}

		return Number(key_value);
	}

	static resolvePlotDataset(input_str: string, resolved_data, options: {[key: string]: string} = {}) {
		const deserialized = JSON.parse(input_str);

		// resolve any references in value
		let dataset;
		if (deserialized.dataset_from_ref) {
			let resolved = QEHelper.resolvePlaceholderToRefs(deserialized.dataset, resolved_data);

			if (resolved instanceof QEValueWidget) {
				if (resolved.value instanceof Table) {
					// JSON exported from Table: { headers: [{value: string}, ..], rows: [[ {value: string}, ...], ...] }
					let table_rows = resolved.value.values.rows;

					// validate table rows have >= 2 columns. col1 -> label, col2 -> value
					if (table_rows.length && table_rows[0] instanceof Array && table_rows[0].length >= 2) {
						dataset = [];
						for (let i = 0; i < table_rows.length; i++) {
							let row = table_rows[i];
							let point = {
								label: row[0].value.serialize_to_text(),
								value: row[1].value.serialize_to_text()
							};
							dataset.push(point);
						}
					} else {
						console.log('Error: resolvePlotDataset called on Table, but must have >= 1 row and >= 2 columns: ', resolved.value);
					}
				} else if (resolved.value instanceof Graph) {
					// TODO: support JSON exported from Graph: [{ type: string, values: [{x: string, y: string}, ...] }, ...]
					// TODO: expouse Graph first data series
					console.log('Graph: ', resolved.value);
				} else {
					console.log('Error: resolvePlotDataset called on widget ref, but widget is not Table or Graph: ', resolved.value);
				}
			} else if (resolved instanceof QEValueJSON) {
				dataset = resolved.value;
			} else {
				console.log('Warning: resolvePlotDataset called on non-json data reference: ', resolved);
				dataset = resolved.value;
			}

		} else {
			// JSON values specified in tool UI
			try {
				dataset = JSON.parse(deserialized.dataset);
			} catch (err) {
				console.log('Error: unable to parse dataset values: ', deserialized.dataset, err);
				return null;
			}
		}

		if (!(dataset instanceof Array)) {
			console.log('Error: dataset must be an array of {label, value} maps. ', dataset);
			return null;
		}

		// resolve placeholders in dataset labels and values
		for (let i = 0; i < dataset.length; i++) {
			let resolved = QEHelper.resolvePlaceholderToString(dataset[i].label, resolved_data);
			if (resolved !== null)
				dataset[i].label = resolved.value;

			let value = QEHelper.resolveToNumber(dataset[i].value, resolved_data);
			if (!Number.isInteger(value)) {
				console.log('Error: non-integer dataset value');
				return null;
			}

			dataset[i].value = value;
		}

		return dataset;
	}

	static populateTemplate(template: string, dest_data, options: {[key: string]: any} = {}): string {
		// regex replace template placeholders with resolved parameters
		const display_options = Object.assign({ display: 1, output_type: "markup" }, options);

		let replace_counter = 100;

		// inline_string_placeholder allows arbitrary string values to be used with placeholder specifiers
		// processing of it is performed last, so named value placeholders can be included in the arbitrary string
		const inline_string_placeholder = '"[^"]*"';
		var widget_keys = Object.keys(dest_data.resolved).concat(inline_string_placeholder);

		// insert widgets in output
		for (let i = 0 ; i < widget_keys.length; i++) {
			const widget_key = widget_keys[i];
			const dest_widget = dest_data.resolved[widget_key];

			// loop over template, and for each match, run it through QEValGen.resolvePlaceholderToMarkup to get back the rendered content
			while (replace_counter && (
				template.match(new RegExp('\\[\\$' + widget_key + '\\.[^\\]]*\\]')) ||
				template.match(new RegExp('\\[\\$' + widget_key + '\\]'))
			)) {
				replace_counter--;
				if (!replace_counter)
					console.log('ERROR: infinite loop on populateTemplate replace.');

				// widget_ref may contain specifiers
				let widget_ref;
				if (template.match(new RegExp('\\[\\$' + widget_key + '\\.[^\\]]*\\]')))
					widget_ref = template.match(new RegExp('(\\[\\$' + widget_key + '\\.[^\\]]*\\])'))[1];
				else
					widget_ref = template.match(new RegExp('(\\[\\$' + widget_key + '\\])'))[1];

				const resolved_markup = QEHelper.resolvePlaceholderToMarkup(widget_ref, dest_data, display_options);
				if (!resolved_markup) {
					console.log("Warning unresolved dependency for: ", widget_ref);
					return null; // unresolved dependency
				}

				let widget_ml = resolved_markup.value;
				if (widget_ml === null && options.require_dependencies)
					return null; // abort

				// check if the placeholder references a widget
				const non_display_ref = QEHelper.resolvePlaceholderToRefs(widget_ref, dest_data);

				// wrap widget values in div so client can attach handler logic
				if (dest_widget) {
					// but don't wrap widget_ml if the resolved target is a simple string
					if (dest_widget.value instanceof Graph && non_display_ref.value instanceof Graph) {
						// fix for Safari bug: svg in inline-block does not display
						widget_ml = '<div class="widget' + (options.disable_input ? ' disable_input' : '') + '" data-widget_key="' + widget_key + '">' + widget_ml + '</div>';
					} else if (dest_widget.value instanceof QEWidget && non_display_ref.value instanceof QEWidget) {
						widget_ml = '<div class="widget' + (options.disable_input ? ' disable_input' : '') + '" data-widget_key="' + widget_key + '" style="display: inline-block; vertical-align: middle;">' + widget_ml + '</div>';
					}
				}

				// escape non-regex-safe characters to prevent infinite loops
				widget_ref = widget_ref.replace(/([.*+?^${}()|\[\]\/\\])/g, "\\$1");

				template = template.replace(new RegExp(widget_ref, 'g'), widget_ml);
			}
		}

		if ((
			template.match(new RegExp('\\[\\$[^\\]]*\\.[^\\[\\]]*\\]')) ||
			template.match(new RegExp('\\[\\$[^\\]]*\\]'))
		) && options.require_dependencies) {
			// unresolved dependency in template
			return null;
		}

		// check string for '<katex>' and '</katex>' -> if found, latex render
		if (template.indexOf('<katex>') !== -1) {
			template = template.replace(/<katex>(.*?)<\/katex>/g, function (full, inner) {
				return katex.renderToString(inner, { displayMode: true });
			});
		}

		return template;
	}

	// eg:   getPrimeFactorDecomposition(60) --> [[2,2], [3,1], [5,1]]
	// returns [1,1] factor if called with argument 1
	// never returns 1
    static getPrimeFactorDecomposition(num: number): undefined | [number, number][] {
		if (num < 1)
			return undefined;

		if (num === 1) {
			return [[1, 1]];
		}

		const prime_factors = [];
		let max = Math.sqrt(num);
		let i = 2;
		while (i <= max) {
			if (num % i !== 0) {
				i++;
				continue;
			}
			// i is a prime factor of num
			const back = prime_factors.length - 1;
			if (back >= 0 && prime_factors[back][0] === i) {
				prime_factors[back][1]++;
			} else {
				prime_factors.push([i, 1]);
			}
			num /= i;
			max = Math.sqrt(num);
		}

		const back = prime_factors.length - 1;
		if (back >= 0 && prime_factors[back][0] === num) {
			prime_factors[back][1]++;
		} else {
			prime_factors.push([num, 1]);
		}

		return prime_factors;
	}

	static testKatex() {
		return katex.renderToString("Test katex", { displayMode: true });
	}

	static getWidgetTag(widget_key, options) {
		options = options || {};
		return '<div class="widget' + (options.disable_input ? ' disable_input' : '') + '" data-widget_key="' + widget_key + '" style="display: inline-block; vertical-align: middle;"></div>';
	}

	// serialization helper function
	static exportValue(value, options: { allow_private?: boolean } = {}) {
		// TODO: type value: QEValue. Needs fix to engine/server/Solver/Steps/GraphSolver.ts

		if (typeof value != 'object' || !value.type) {
			console.log('Error: attempting to export non-value type: ', value);
			return null;
		}

		if (value instanceof QEValueWidget) {
			return value.value.exportValue(options);
        } else if (value.type == 'tree') {
            return value.serialize_to_text();
        } else if (value.type == 'string') {
            return value.value;
        } else if (value.type == 'boolean') {
            return value.value;
        } else if (value.type == 'json') {
            // serialize
            return JSON.stringify(value.value);
        }

        console.log('Error. Attempting to export unhandled value type: ', value);
        return '';
    }

	static deserializeInputWidget(resolved_widgets, widget_key, user_input_configs, input_widget_configs) {
		const widget_data = input_widget_configs[widget_key];
		if (!widget_data) {
			// config missing
			return;
		}
		if (resolved_widgets[widget_key]) {
			// already deserialized
			return;
		}

		// deserialize based on widget type
		if (widget_data.type == 'eq') {
			const display_options = JSON.parse(widget_data.display_options || '{}');
			const tree = tokenize_and_parse(widget_data.value).tree;

			// if Eq widget contains input nodes (keyboard inputs), assign each input node an index_id attribute so we can distinguish between multiple inputs in an equation
			let input_key_index = 0;
			tree.findAllChildren('type', 'INPUT').forEach(function(node){
				const input_key_match = node.value.match(/\[\?(.*)\]/);
				if (input_key_match) {
					node.attr('input_key_index', input_key_index);

					// deserialize the associated KB widget
					const input_key = input_key_match[1];
					if (!input_widget_configs[input_key]) {
						console.log("ERROR: nested input info not included in exported data");
						return;
					}

					const combined_kb_key = widget_key +'__'+ input_key +'__'+ input_key_index;
					const iw_data = input_widget_configs[input_key];
					const display_options = JSON.parse(iw_data.display_options || '{}');

					// check if it is a keyboard or a dropdown
					if (iw_data.type == "kb") {
						const keyboard_type = iw_data.kb_type;
						const input_content = iw_data.value;

						resolved_widgets[combined_kb_key] = new EqKeyboard(input_content, {
							type: keyboard_type,
							name: input_key,
							input_key_index: input_key_index,
							display_options: display_options
						});
					} else if (iw_data.type == "dropdown") {
						const dataset = JSON.parse(iw_data.dataset);
						resolved_widgets[combined_kb_key] = new DropDown(dataset, {
							name: input_key,
							input_key_index: input_key_index,
							display_options: display_options,
						});

						// init node.content
						if (dataset.length) {
							node.content = QEHelper.resolvePlaceholderToTree(dataset[0].value, {}).value;
						}

						// render DropDown, and set node.attr content_markup
						let iw_ml = resolved_widgets[combined_kb_key].display();
						node.attr('content_markup', iw_ml);
					}

					// if the "answer" input widget key points to this keyboard input_key, then update to point to this combined_kb_key
					for (let i = 0; i < user_input_configs.length; i++) {
						if (user_input_configs[i].widget_key == input_key) {
							user_input_configs[i].widget_key = combined_kb_key;
						}
					}

					input_key_index++;
				}
			});

			resolved_widgets[widget_key] = new Eq(tree, display_options);
		} else if (widget_data.type == 'table') {
			const display_options = JSON.parse(widget_data.display_options || '{}');
			const values = JSON.parse(widget_data.values);

			// if Table widget contains keyboard inputs, assign each kb input an index_id attribute so we can distinguish between multiple inputs
			let input_key_index = 0;

			// if the input widget is a Table it should contain one or more kb widget references
			let included_input_keys = [];
			let rows = values.rows;
			for (let j = 0; j < rows.length; j++) {
				let row = rows[j];
				for (let k = 0; k < row.length; k++) {
					let cell = row[k];

					if (typeof cell.value === "object" &&
						(cell.value.type === "kb" || cell.value.type === "dropdown")
					) {
						// deserialize the associated input widget
						const input_key = cell.value.name;
						if (!input_widget_configs[input_key]) {
							console.log("ERROR: nested input info not included in exported data");
							return;
						}

						const combined_iw_key = widget_key +'__'+ input_key +'__'+ input_key_index;
						const iw_data = input_widget_configs[input_key];
						const display_options = JSON.parse(iw_data.display_options || '{}');

						// check if it is a keyboard or a dropdown
						if (iw_data.type == "kb") {
							const keyboard_type = iw_data.kb_type;
							const input_content = iw_data.value;

							resolved_widgets[combined_iw_key] = new EqKeyboard(input_content, {
								type: keyboard_type,
								name: input_key,
								input_key_index: input_key_index,
								display_options: display_options
							});
						} else if (iw_data.type == "dropdown") {
							const dataset = JSON.parse(iw_data.dataset);
							resolved_widgets[combined_iw_key] = new DropDown(dataset, {
								name: input_key,
								input_key_index: input_key_index,
								display_options: display_options,
							});
						}

						// replace cell value with instantiated keyboard
						cell.value = resolved_widgets[combined_iw_key];

						// store the combined_iw_key so the cell can wrap it in a widget container
						cell.key_name = combined_iw_key;

						// if the "answer" input widget key points to this keyboard input_key, then update to point to this combined_iw_key
						for (let i = 0; i < user_input_configs.length; i++) {
							if (user_input_configs[i].widget_key == input_key) {
								user_input_configs[i].widget_key = combined_iw_key;
							}
						}

						input_key_index++;
					}
				}
			}

			resolved_widgets[widget_key] = new Table(values, display_options);
		} else if (widget_data.type == 'kb') {
			const keyboard_type = widget_data.kb_type;
			const input_content = widget_data.value;
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new EqKeyboard(input_content, { type: keyboard_type, name: widget_key, input_key_index: 0, display_options: display_options });
		} else if (widget_data.type == 'mc') {
			resolved_widgets[widget_key] = new MC({choices: widget_data.choices}, {});
		} else if (widget_data.type == 'multi_select') {
			const dataset: { label: string, key: string }[] = JSON.parse(widget_data.dataset);
			resolved_widgets[widget_key] = new MultiSelect(dataset, {});
		} else if (widget_data.type == 'multi_input') {
			const dataset: { label: string, key: string }[] = JSON.parse(widget_data.dataset);
			resolved_widgets[widget_key] = new MultiInput(dataset, {});
		} else if (widget_data.type == 'dropdown') {
			const dataset: { label: string, value: string }[] = JSON.parse(widget_data.dataset);
			resolved_widgets[widget_key] = new DropDown(dataset, {});
		} else if (widget_data.type == 'gr') {
			// NOTE: graph inputs are rendered after question template markup setup
			const series_data = JSON.parse(widget_data.series || '[]');
			const display_options = JSON.parse(widget_data.display_options || '{}');
			const input_options = JSON.parse(widget_data.input_options || '{}');
			resolved_widgets[widget_key] = new Graph(series_data, display_options, input_options);
		} else if (widget_data.type == 'format_selector') {
			// deserialize nested format widgets
			const format_widget_keys = JSON.parse(widget_data.formats);
			const format_widgets = [];
			for (let i = 0; i < format_widget_keys.length; i++) {
				const format_widget_key = format_widget_keys[i];

				// deserialize format_widget if not yet deserialized
				if (!resolved_widgets[format_widget_key]) {
					QEHelper.deserializeInputWidget(resolved_widgets, format_widget_key, user_input_configs, input_widget_configs);
				}
				format_widgets.push(resolved_widgets[format_widget_key]);
			}
			resolved_widgets[widget_key] = new FormatSelector(format_widget_keys, format_widgets);
		} else if (widget_data.type == 'widget_list') {
			// deserialize nested sub widgets
			const sub_widget_keys = JSON.parse(widget_data.sub_widget_keys);
			const sub_widgets = [];
			for (let i = 0; i < sub_widget_keys.length; i++) {
				const sub_widget_key = sub_widget_keys[i];

				// deserialize sub_widget if not yet deserialized
				if (!resolved_widgets[sub_widget_key]) {
					QEHelper.deserializeInputWidget(resolved_widgets, sub_widget_key, user_input_configs, input_widget_configs);
				}
				sub_widgets.push(resolved_widgets[sub_widget_key]);
			}
			const active_index = widget_data.active_index;
			resolved_widgets[widget_key] = new WidgetList(sub_widget_keys, sub_widgets, active_index);
		} else if (widget_data.type == 'decimal_grid') {
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new DecimalGrid(widget_data.value, display_options);
		} else if (widget_data.type == 'fraction_set') {
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new FractionSet(widget_data.value, widget_data.wanted_equal_groups, widget_data.num_equal_groups, display_options);
		} else if (widget_data.type == 'fraction_shape') {
			const display_options = JSON.parse(widget_data.display_options || '{}');
			resolved_widgets[widget_key] = new FractionShape(widget_data.value, display_options);
		} else {
			console.log('ERROR. Unknown widget type: ', widget_data);
		}
	}
}

export function numberToDecimal(number){
	if (typeof number == 'undefined') {
		return number;
	} else if (typeof number == 'string') {
		number = parseFloat(number);
	}

	number = number.toFixed(12);
	number = number.replace(/\.?0*$/, '');
	number = parseFloat(number);
	return number;
}

// shuffle helper function
export function shuffle(arr) {
	let counter = arr.length, idx, temp;
	while (counter > 0) {
		idx = Math.floor(Math.random() * counter);
		counter--;

		temp = arr[counter];
		arr[counter] = arr[idx];
		arr[idx] = temp;
	}
	return arr;
}
