import { QETerm } from '../QETerm';
import { QEHelper, QEValueTree } from '../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../QEWidget';

export class QEWidgetAnalogClock extends QEWidget {
	display_options: { [key: string]: any };
	hours: number;
	minutes: number;
	seconds: number;

	active_colour: string;
	clock_colour: string;
	hands_colour: string;
	major_hours_only: boolean;
	major_labels_only: boolean;
	minute_marks: boolean;
	padding: number;
	seconds_colour: string;
	show_labels: boolean;
	show_seconds: boolean;
	size: string;

	static default_style = {
		active_colour: '#05a7cf',
		clock_colour: '#000000',
		hands_colour: '#454545',
		major_hours_only: false,
		major_labels_only: false,
		minute_marks: true,
		padding: 5,
		seconds_colour: '#d00000',
		show_labels: true,
		show_seconds: true,
		size: '200',
	};

	constructor(hours: number, minutes: number, seconds: number, display_options: DisplayOptions = {}) {
		super();

		this.hours = hours;
		this.minutes = minutes;
		this.seconds = seconds;

		this.display_options = display_options;

		// apply default style
		Object.assign(this, QEWidgetAnalogClock.default_style);

		// apply side length, angle settings, and any style overrides
		Object.assign(this, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		// TODO: value field with \time_hm{} or \time_hms{}

		let value = QEHelper.resolvePlaceholderToTree(deserialized.value, resolved_data);
		if (!value || !(value instanceof QEValueTree)) {
			console.log("Error: failed to parse AnalogClock to tree: ", deserialized.value);
			return null;
		}

		const values: {hours: number, minutes: number, seconds: number} = { hours: 0, minutes: 0, seconds: 0 };
		let time_func = value.value.children[0];
		if (time_func.value == "time_hms") {
			values.hours = Number(time_func.children[0].serialize_to_text());
			values.minutes = Number(time_func.children[1].serialize_to_text());
			values.seconds = Number(time_func.children[2].serialize_to_text());
		} else if (time_func.value == "time_hm") {
			values.hours = Number(time_func.children[0].serialize_to_text());
			values.minutes = Number(time_func.children[1].serialize_to_text());
		} else {
			console.log("Error: AnalogClock value not time_hm or time_hms: ", time_func);
			return null;
		}

		// validate each time unit
		const time_units = ['hours', 'minutes', 'seconds'];
		for (let i = 0; i < 3; i++) {
			if (!Number.isInteger(values[time_units[i]])) {
				console.log('Non-integer '+ time_units[i] +': ', deserialized.value);
				return null;
			}
		}

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetAnalogClock(values.hours, values.minutes, values.seconds, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		var degrees = [];
		var r = Number(this.size)/2;
		var cx = r + this.padding;
		var cy = r + this.padding;
		var width = (this.padding * 2 + Number(this.size)).toString();
		var viewBox = [0, 0, width, width];

		degrees[0] = (360 / 12 * (this.hours + this.minutes / 60));
		degrees[1] = (360 / 60 * this.minutes);
		degrees[2] = (360 / 60 * this.seconds);

		const svg = new TagElement("svg");
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
		svg.setAttribute('viewBox', viewBox.join(' '));
		svg.setAttribute('width', width);

		// SET STYLES
		var styles = new TagElement("style");
		styles.innerHTML += 'text { -webkit-user-select: none; user-select: none; }';
		styles.innerHTML += '.analog-clock__center { fill:'+ this.clock_colour +'; }';
		styles.innerHTML += '.analog-clock__hand { fill:'+ this.hands_colour +'; stroke:'+ this.hands_colour +'; stroke-width: 4; }';
		styles.innerHTML += '.analog-clock__hand:hover { opacity: 0.5; }';
		styles.innerHTML += '.analog-clock__hand[href="#sec-hand"] { stroke:'+ this.seconds_colour +'; stroke-width: 2; }';
		styles.innerHTML += '.analog-clock__label { fill:'+ this.clock_colour + '; font-family: Lato, sans-serif; font-size: 14; font-weight: bold; }';
		styles.innerHTML += '.analog-clock__mark { stroke:'+ this.clock_colour +'; }';
		styles.innerHTML += '.analog-clock__ring { fill: none; stroke:'+ this.clock_colour +'; stroke-width: 3; }';

		svg.innerHTML += styles.outerHTML();

		// SET DEFINITIONS
		var defs = new TagElement('defs');
		var g = new TagElement('g');
		var y1, y2;

		function getArrowheadPoints(x, y) {
			var dx = 4, dy = 10;
			var pts = [];

			pts[0] = x + ',' + y;
			pts[1] = (x - dx) + ',' + (y + dy);
			pts[2] = (x + dx) + ',' + (y + dy);

			return pts.join(' ');
		}

		// Define the hours hand
		y1 = 60;
		g.id = 'hour-hand';
		g.innerHTML = '<line x1="'+ cx +'" y1="'+ y1 +'" x2="'+ cx +'" y2="'+ cy +'"/>';
		g.innerHTML += '<polygon points="'+ getArrowheadPoints(cx, y1) +'" />';
		defs.innerHTML += g.outerHTML();

		// Define the minutes hand
		y1 = 30;
		g.id = 'min-hand';
		g.innerHTML = '<line x1="'+ cx +'" y1="'+ y1 +'" x2="'+ cx +'" y2="'+ cy +'"/>';
		g.innerHTML += '<polygon points="'+ getArrowheadPoints(cx, y1) +'" />';
		defs.innerHTML += g.outerHTML();

		// Define the seconds hand
		g.id = 'sec-hand';
		g.innerHTML = '<line x1="'+ cx +'" y1="35" x2="'+ cx +'" y2="'+ cy +'"/>';
		defs.innerHTML += g.outerHTML();

		// Define the hours mark
		y1 = this.padding;
		y2 = y1 + 20; // mark height
		defs.innerHTML += '<line id="hour-mark" x1="'+ cx +'" y1="'+ y1 +'" x2="'+ cx +'" y2="'+ y2 +'"/>';

		// Define the minutes mark
		y2 = y1 + 10; // mark height
		defs.innerHTML += '<line id="min-mark" x1="'+ cx +'" y1="'+ y1 +'" x2="'+ cx +'" y2="'+ y2 +'"/>';

		svg.innerHTML += defs.outerHTML();

		// BUILD THE CLOCK
		var hours_placement = [
			{anchor: 'middle', baseline: 'hanging'},// 12
			{anchor: 'end', baseline: 'hanging'},   // 1
			{anchor: 'end', baseline: 'middle'},    // 2
			{anchor: 'end', baseline: 'middle'},    // 3
			{anchor: 'end', baseline: 'middle'},    // 4
			{anchor: 'end', baseline: 'bottom'},    // 5
			{anchor: 'middle', baseline: 'bottom'}, // 6
			{anchor: 'start', baseline: 'bottom'},  // 7
			{anchor: 'start', baseline: 'middle'},  // 8
			{anchor: 'start', baseline: 'middle'},  // 9
			{anchor: 'start', baseline: 'hanging'}, // 10
			{anchor: 'start', baseline: 'hanging'}, // 11
		];

		var markup = '';

		// Generate the minute marks
		if (this.minute_marks) {
			markup += '<g class="analog-clock__min-marks">';
			for (var i = 0; i < 60; i++) {
				var arr = [360 / 60 * i, cx, cy];
				var transform = 'rotate('+ arr.toString() +')';
				markup += '<use class="analog-clock__mark" href="#min-mark" x="0" y="0" stroke-width="2" transform="'+ transform +'"/>';
			}
			markup += '</g>';
		}

		// Generate the hour marks
		markup += '<g class="analog-clock__hour-marks">';
		for (var i = 0; i < 12; i++) {
			var rot = 360 / 12 * i;
			var arr = [rot, cx, cy];
			var transform = 'rotate('+ arr.toString() +')';
			if (!this.major_hours_only || (this.major_hours_only && !(i % 3))) {
				markup += '<use class="analog-clock__mark" href="#hour-mark" x="0" y="0" stroke-width="3" transform="'+ transform +'"/>';
			}
			// Add the hour labels
			var a = -rot * (Math.PI/180);
			var p = hours_placement[i];
			var x = 0 * Math.cos(a) - 0.72 * Math.sin(a);
			var y = 0.72 * Math.cos(a) + 0 * Math.sin(a);
			x = Math.ceil(x * r + r) + this.padding;
			y = Math.ceil(r - (y * r)) + this.padding;
			if (this.show_labels) {
				if (this.major_labels_only && (i % 3)) continue;
				markup += '<text class="analog-clock__label" x="'+ x +'" y="'+ y +'" alignment-baseline="'+ p.baseline +'" text-anchor="'+ p.anchor +'">'+ (i ? i : 12) +'</text>';
			}
		}
		markup += '</g>';

		// Generate the clock center and outer ring
		markup += '<circle class="analog-clock__ring" cx="'+ cx +'" cy="'+ cy +'" r="'+ r +'" fill="none" stroke="#000" stroke-width="#000"/>';

		// Generate the clock hands
		markup += '<g class="analog-clock__hands">';
		markup += '<use class="analog-clock__hand" href="#hour-hand" x="0" y="0" transform="rotate(' + degrees[0] +','+ cx +','+ cy +')"/>';
		markup += '<use class="analog-clock__hand" href="#min-hand" x="0" y="0" transform="rotate(' + degrees[1] +','+ cx +','+ cy +')"/>';
		if (this.show_seconds) {
			markup += '<use class="analog-clock__hand" href="#sec-hand" x="0" y="0" transform="rotate(' + degrees[2] +','+ cx +','+ cy +')"/>';
		}
		markup += '</g>';

		markup += '<circle class="analog-clock__center" cx="'+ cx +'" cy="'+ cy +'" r="5"/>';

		svg.innerHTML += markup;

		return svg.outerHTML();
	}

	exportValue(options?){
		return {
			type: 'analog_clock',
			hours: this.hours,
			minutes: this.minutes,
			seconds: this.seconds,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

