import { tokenize_and_parse } from './QEGrammar';
import { QETerm } from './QETerm';
import { QEHelper, numberToDecimal, QEValue, QEValueTree, QEValueString, QEValueJSON, QEValueMap, QEValueBoolean, QEValueWidget, QEValueAnswer } from './QEHelper';
import { QEWidgetMC } from './Widget/QEWidgetMC';
import { QEValConstants } from './QEValConstants';
import { QEQ } from './QE';
import { SvgRenderingContext } from './SvgRenderingContext';
import * as jQuery from 'jquery';

//////////////////////////////////////////////////////////////
export interface DisplayOptions {
	[key: string]: any;
}

export class TagElement {
	name: string;
	innerHTML: string;
	attributes: { [key: string]: string };
	dataset: { [key: string]: string };
	style: { [key: string]: string };
	id: string;
	children: TagElement[];
	parent_element: TagElement;
	ctx: SvgRenderingContext; // only used to mirror canvas element
	width: number;
	height: number;

	constructor(name: string) {
		this.name = name;
		this.innerHTML = "";
		this.attributes = {};
		this.dataset = {};
		this.style = {};
		this.id = "";
		this.children = [];
	}

	setAttribute(attr: string, value: string){
		this.attributes[attr] = value;
	}
	getAttribute(attr: string): string{
		return this.attributes[attr];
	}
	append(child: TagElement) {
		child.parent_element = this;
		this.children.push(child);
	}
	removeChild(child: TagElement) {
		let child_index = -1;
		for (let i = 0; i < this.children.length; i++) {
			if (this.children[i] === child) {
				child_index = i;
			}
		}

		if (child_index == -1) {
			throw "Error: called removeChild but specified element is not a child of current element.";
		} else {
			// detach
			this.children.splice(child_index, 1);
			child.parent_element = null;
		}
	}
	findChildrenWithId(id: string) {
		const matching_elements = [];
		for (let i = 0; i < this.children.length; i++) {
			if (this.children[i].id === id) {
				matching_elements.push(this.children[i]);
			}
		}
		return matching_elements;
	}
	findChildrenWithAttribute(attr: string, value: string) {
		const matching_elements = [];
		for (let i = 0; i < this.children.length; i++) {
			if (this.children[i].getAttribute(attr) === value) {
				matching_elements.push(this.children[i]);
			}
		}
		return matching_elements;
	}
	outerHTML(): string {
		const self = this;

		let attr_str = Object.keys(self.attributes).map(key => { return key +'="'+ self.attributes[key] +'"' }).join(' ');
		let dataset_str = Object.keys(self.dataset).map(key => { return 'data-'+ key +'="'+ self.dataset[key] +'"' }).join(' ');
		let style_str = Object.keys(self.style).map(key => { return key +':'+ self.style[key] +';' }).join(' ');

		let ml = '<'+ this.name;
		ml += this.id ? ' id="'+ this.id +'"' : '';
		ml += attr_str.length ? ' '+ attr_str : '';
		ml += dataset_str.length ? ' '+ dataset_str : '';
		ml += style_str.length ? ' style="'+ style_str +'"' : '';
		ml += '>';
		ml += 	self.innerHTML;
		for (let i = 0; i < this.children.length; i++) {
			ml += this.children[i].outerHTML();
		}
		ml += '</'+ self.name +'>'; // closing tag

		return ml;
	}

	getContext(contextType: string) {
		// validate this tag is an svg
		if (this.name !== "svg") {
			console.log("Error: getContext() called on non-svg tag.");
			return;
		}

		if (!this.ctx) {
			this.ctx = new SvgRenderingContext();
			this.ctx.setRootElement(this);
		}
		return this.ctx;
	}
}

function escapeHtml(unsafe) {
	if (typeof unsafe == 'string') return unsafe.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
	else return unsafe;
}

export class QEWidget {
	series?: unknown;
	display_options?: unknown;

	constructor(){}

	display(options?){
		console.log("Warning: display() called on base QEWidget");
		return null;
	}

	getInputValue(input_widgets?): string {
		// base getInputValue call
		// input_widgets map may be passed to allow widget access to nested input widgets (i.e. keyboards)
		return null;
	}

	exportValue(options?){
		console.log("Warning: exportValue() called on base QEWidget");
		return null;
	}

	bindEventHandlers(widget_container) {
		console.log("Warning: bindEventHandlers() called on base QEWidget");
		return;
	}
}

// config value to support overriding image location
const img_path = '../img/qe/';
export class ImgSet extends QEWidget {
	image_map_key: string;
	image_map: { key_parts: string[], type: string[], size: string[], color?: string[], cw?: string[] };
	display_options: DisplayOptions;

	// TODO: image and style information should be moved out of this code and managed completely server-side
	constructor(image_map_key: string, image_map, display_options: DisplayOptions) {
		super();

		display_options = display_options || {};

		this.image_map_key = image_map_key;
		this.image_map = image_map;
		this.display_options = display_options;
	}

	/**
	 * Instantiates and returns an ImgSet widget from serialized data
	 * @param {string} serialized - serialized string containing value and display config
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options?) {
		let deserialized = JSON.parse(serialized);

		let image_map_key_qev: QEValueString = QEHelper.resolvePlaceholderToString(deserialized.image_map, resolved_data);
		if (!image_map_key_qev) return null;
		let image_map_key = image_map_key_qev.value;

		let image_map = QEValConstants.image_maps[image_map_key];
		if (!image_map) return null;

		// build map and resolve any [$name] placeholders in display_options
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new ImgSet(image_map_key, image_map, display_options);
		return widget;
	}


	/**
	 * Returns widget markup for inclusion in question output
	 * @param {Object} value
	 * @param {Object} options
	 * @param {Object} options.<type|color|size|...>
	 * @param {Object} options.value_as_<type|color|size|...>
	 * @param {Object} options.random_<type|color|size|...>
	 * @param {Object} options.value_as_quantity
	 * @param {string} options.style
	 * @param {Object} options.bg
	 * @param {Object} options.tiling_pattern
	 * @returns {string} Generated display markup
	 */
	display(options) {
		// use this.display_options, then override with passed options
		var display_options = Object.assign({}, this.display_options);
		display_options = Object.assign(display_options, options);

		// TODO: support value list - needed for displaying counting solutions

		var value = display_options.value;

		// construct image key from specified parts
		var image_map_key = this.image_map_key;
		var image_map = this.image_map;
		var key_parts = image_map.key_parts;

		// handle key_part-specific display options - applied in order they are encountered, so one option can override another
		key_parts.map(function (key_part) {
			if (display_options['random_' + key_part]) {
				// choose a random value from the specified list
				display_options[key_part] = image_map[key_part][Math.trunc(Math.random() * image_map[key_part].length)];
			} else if (display_options['value_as_' + key_part]) {
				// serialize based on value
				if (typeof value === 'string') {
					display_options[key_part] = value;
				} else if (value instanceof QEValueString) {
					display_options[key_part] = value.value;
				} else if (value instanceof QEValueTree) {
					display_options[key_part] = value.serialize_to_text();
				} else {
					console.log('Error: value_as_'+ key_part +' specified but value not a string or tree.', value);
				}
			} else if (display_options[key_part] && display_options[key_part] instanceof QEValue) {
				// serialize based on value type
				display_options[key_part] = display_options[key_part].serialize_to_text();
			}
		});

		// handle style-specific display options
		var supported_style_keys = ['opacity'];
		var style_options = [];
		supported_style_keys.map(function (style_key) {
			if (display_options['style_' + style_key]) {
				style_options.push(style_key + ':' + display_options['style_' + style_key]);
			}
		});

		var image_key_parts = [image_map_key];
		for (var i = 0; i < key_parts.length; i++) {
			// if key part value not specified, default to first item of the list for that key part
			var key_part = key_parts[i];
			var part_list = image_map[key_part];

			var specified_value = display_options[key_part];
			if (typeof specified_value == 'undefined') {
				image_key_parts.push(part_list[0]);
			} else if (part_list.indexOf(specified_value) == -1) {
				// invalid value was specified
				console.log('Error: invalid value specified for image key part: ', key_part, specified_value);
				return 'ERR';
			} else {
				image_key_parts.push(specified_value);
			}
		}
		var image_key = img_path + image_key_parts.join('_') + '.png';

		var ml = '';

		var quantity = 1;
		if (display_options.value_as_quantity) {
			// serialize based on value type
			if (typeof value != 'undefined') {
				var serialized = value.serialize_to_text();
				quantity = Math.trunc(serialized);
			}
		}

		// TODO: support grouping and tiling

		if (display_options.bg) {
			// use background image
			var bg_ml = display_options.bg.value.display();

			ml += '<div class="img_set" style="display: inline-block;">';
			ml += '<div style="display: inline-block; position: relative;">'; // composite image container
			ml += bg_ml;
			ml += '<div style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;">'; // table sizing container
			ml += '<div style="width: 100%; height: 100%; display: table; line-height: 0;">'; // composite content table container
			ml += '<div style="position: relative; width: 100%; height: 100%; text-align: center; display: table-cell; vertical-align: middle;">'; // composite content centering container
			ml += '<img style="' + style_options.join('; ') + '" alt_text="TODO" src="' + image_key + '">';
			ml += '</div>'; // table cell, centering container
			ml += '</div>'; // table container
			ml += '</div>'; // table sizing container
			ml += '</div>'; // composite image container
			ml += '</div>'; // img_set

		} else {
			for (var i = 0; i < quantity; i++) {
				ml += '<img style="' + style_options.join('; ') + '" alt_text="TODO" src="' + image_key + '">';
			}
		}

		return ml;
	}

	exportValue(options?){
		return {
			type: 'image_set',
			value: '',
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

export class Eq extends QEWidget {
	value: QETerm;
	display_options: DisplayOptions;
	input_values: {};

	constructor(value, display_options) {
		super();

		this.value = value;
		this.display_options = display_options;
		this.input_values = {};
	}

	/**
	 * Instantiates and returns an Eq widget from serialized data
	 * @param {string} serialized - serialized string containing value
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 * @param {bool} [options.editor_mode] - flag indicating parser should return a map containing the parsed equation, cursor position info, and token arrays with and without the cursor
	*/
	static instantiate(serialized, resolved_data, options) {
		resolved_data = resolved_data || {};
		options = options || {};

		// coerce legacy QE.Widget.Eq data to current format -> { value: '...', display_options: '...' }
		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			deserialized = {
				value: serialized,
				display_options: '',
			};
		}

		// resolve any references in value
		let resolved = QEHelper.resolvePlaceholderToTree(deserialized.value, resolved_data);
		if (!resolved) return null;

		// build map and resolve any [$name] placeholders in display_options
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new Eq(resolved.value, display_options);
		return widget;
	}

	/**
	 * Returns widget markup for inclusion in question output
	 * @returns {string} Generated display markup
	 */
	display(options) {
		let ml = '';
		if (this.display_options && this.display_options['display_as']) {
			// pass value to designated display widget
			let display_widget = this.display_options['display_as'];
			if (typeof display_widget == "object") {
				ml += display_widget.display({ value: this.value });
			} else {
				ml += "Error: display_as widget unresolved: " + display_widget;
			}
		} else if (!this.value) {
			console.log('ERROR: attempting to display unresolved value.');
			ml += '<div style="color:#f00;">ERR</div>';
		} else {
			// assign input_key_index to individual input fields so they can separately have focus and values
			let input_key_index = 0;
			this.value.findAllChildren('type', 'INPUT').forEach(function (node: QETerm) {
				let input_key_match = node.value.match(/\[\?(.*)\]/);
				if (input_key_match) {
					node.attr('input_key_index', input_key_index);
					input_key_index++;
				}
			});

			ml += this.value.display(Object.assign(this.display_options, options));
		}
		return ml;
	}

	/**
	 * Sets the widget value for the specified input key
	 * @param {string} input_key
	 * @param {string} value
	 */
	setInputValue(input_key, value) { return this.input_values[input_key] = value; }

	/**
	 * Gets the widget value for the specified input key
	 * @param {string} input_key
	 */
	getInputValue(input_widgets?): string {
		// ensure all input nodes contain content
		var missing_content = false;
		this.value.findAllChildren('type', 'INPUT').forEach(function (node) {
			if (!node.content) {
				missing_content = true;
			}
		});
		if (missing_content) {
			return;
		}

		var serialized = this.value.serialize_to_text({ include_input_content: true });
		return serialized;
	}

	exportValue(options?){
		return {
			type: 'eq',
			value: this.value.serialize_to_text(),
			display_options: JSON.stringify(this.display_options || {}),
		};
	}

	bindEventHandlers(widget_container) {
		// client-side fix for radical tails obscuring input box focus: swap element order
		// included here for first-render. Subsequently performed in calling code setKeyboardCB
		widget_container.find('.hide-tail').parent().each(function(){
			var tail = jQuery(this);
			if (tail.prev().hasClass('svg-align')){
				tail.insertBefore(tail.prev());
			}
		});

		// Eq input events should be bound directly to any nested KB inputs
		return;
	}
}

export class Tally extends QEWidget {
	value: QETerm;
	input_values: unknown;

	constructor(value) {
		super();

		this.value = value;
		this.input_values = {};
	}

	/**
	 * Instantiates and returns a Tally widget from serialized data
	 * @param {string} serialized - serialized string containing value and display config
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options) {
		resolved_data = resolved_data || {};
		options = options || {};

		// coerce legacy Eq data to current format -> { value: '...', display_options: '...' }
		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			deserialized = {
				value: serialized,
				display_options: '',
			};
		}

		// resolve any references in value
		let resolved = QEHelper.resolvePlaceholderToTree(deserialized.value, resolved_data);
		if (!resolved) return null;

		let widget = new Tally(resolved.value);
		return widget;
	}


	display(options) {
		let obj = options.value;
		if (obj === null || obj.value == null) {
			return;
		}
		var counting = parseInt(obj.value.serialize_to_text());

		var ml = '<ol class="tally">';
		for (var i = 0; i < counting; i++) {
			ml += '<li></li>';
		}
		ml += '</ol>'

		return ml;
	}

	exportValue(options?){
		return null; // no exportable data
	}
}

export class FormatSelector extends QEWidget {
	format_widget_keys: string[];
	format_widgets: QEWidget[];
	current_active_index: number;

	constructor(format_widget_keys, format_widgets) {
		super();

		this.format_widget_keys = format_widget_keys;
		this.format_widgets = format_widgets;
		this.current_active_index = 0;
	}

	/**
	 * Instantiates and returns a FormatSelector widget from serialized data
	 * @param {string} serialized - serialized string containing format_widget_keys
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options) {
		resolved_data = resolved_data || {};
		options = options || {};

		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			deserialized = {
				widget_keys: [],
			};
		}

		// resolve any references in each format_widget_keys entry
		let format_widget_keys = deserialized.format_widget_keys;
		let format_widgets = [];

		for (let i = 0; i < format_widget_keys.length; i++) {
			let widget_ref = '[$'+ format_widget_keys[i] +']';
			let resolved = QEHelper.resolvePlaceholderToRefs(widget_ref, resolved_data);
			if (!resolved) return null;

			format_widgets.push(resolved.value);
		}

		let widget = new FormatSelector(format_widget_keys, format_widgets);
		return widget;
	}

	display(options) {
		// selector box with format widgets rendered and hidden (except current_active)
		let default_index = 0;


		let ml = '<div class="format_selector">';
		ml += '<div class="format_widgets">';
		for (let i = 0; i < this.format_widgets.length; i++) {
			ml += '<div class="format_widget'+ (i == default_index ? ' current_active' : '') +'">';
			ml += 	'<div class="widget" data-widget_key="'+ this.format_widget_keys[i] +'" style="display: inline-block; vertical-align: middle;">';
			ml += 		this.format_widgets[i].display();
			ml += 	'</div>';
			ml += '</div>';
		}
		ml += '</div>';

		ml += '<div class="expander fa fa-caret-down"></div>';
		ml += '</div>';

		return ml;
	}

	setCurrentActiveIndex(index) {
		this.current_active_index = index;
	}

	bindEventHandlers(widget_container) {
		const self = this;

		widget_container.on('click', '.format_selector .expander', function(e){
			if (widget_container.is('.disable_input')) return; // skip if disabled

			var event_item = jQuery(this);
			event_item.parent().toggleClass('expanded');
		}).on('click', '.format_selector.expanded .format_widget', function(e){
			if (widget_container.is('.disable_input')) return; // skip if disabled

			// if clicked format_widget is not current_active, set it to be active
			var event_item = jQuery(this);
			if (!event_item.hasClass('current_active')) {
				// update format selector current widget index so submission can choose the selected child widget
				var format_widget_index = event_item.index();
				self.setCurrentActiveIndex(format_widget_index)

				event_item.parent().find('> .format_widget').removeClass('current_active');
				event_item.addClass('current_active');
			}

			event_item.closest('.format_selector').toggleClass('expanded');
		});
	}

	getInputValue(input_widgets?): string {
		let current_active_widget = this.format_widgets[this.current_active_index];
		return current_active_widget.getInputValue(input_widgets);
	}

	exportValue(options?){
		return {
			type: 'format_selector',
			formats: JSON.stringify(this.format_widget_keys),
		};
	}
}

export class WidgetList extends QEWidget {
	widget_keys: string[];
	sub_widgets: QEWidget[];
	active_index: number;
	active_widget_key: string;

	constructor(widget_keys, sub_widgets, active_index) {
		super();

		this.widget_keys = widget_keys;
		this.sub_widgets = sub_widgets;
		this.active_index = active_index;
		this.active_widget_key = this.widget_keys[active_index] || '';
	}

	/**
	 * Instantiates and returns a WidgetList widget from serialized data
	 * @param {string} serialized - serialized string containing widget_keys
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options) {
		resolved_data = resolved_data || {};
		options = options || {};

		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			deserialized = {
				widget_keys: [],
			};
		}

		// resolve the index of the widget to display
		let index = QEHelper.resolvePlaceholderToString(deserialized.index, resolved_data);
		if (!index) return null;
		let active_index = Number(index.value);

		// resolve any references in each widget_keys entry
		let widget_keys = deserialized.widget_keys;
		let sub_widgets = [];

		for (let i = 0; i < widget_keys.length; i++) {
			let widget_ref = '[$'+ widget_keys[i] +']';
			let resolved = QEHelper.resolvePlaceholderToRefs(widget_ref, resolved_data);
			if (!resolved) return null;

			sub_widgets.push(resolved.value);
		}

		let widget = new WidgetList(widget_keys, sub_widgets, active_index);
		return widget;
	}

	display(options) {
		let ml = '<div class="widget" data-widget_key="'+ this.widget_keys[this.active_index] +'" style="display: inline-block; vertical-align: middle;">';
		ml += 		this.sub_widgets[this.active_index].display(options);
		ml += 	'</div>';
		return ml;
	}

	bindEventHandlers(widget_container) {}

	getInputValue(input_widgets?): string {
		let active_widget = this.sub_widgets[this.active_index];
		return active_widget.getInputValue(input_widgets);
	}

	exportValue(options?){
		return {
			type: 'widget_list',
			active_index: this.active_index,
			sub_widget_keys: JSON.stringify(this.widget_keys),
		};
	}
}

export class StringLookup extends QEWidget {
	string_map: { [key: string]: { [key: string]: string } };
	theme_key: string;

	constructor(string_map, theme_key) {
		super();

		this.string_map = string_map;
		this.theme_key = theme_key;
	}

	/**
	 * Instantiates and returns a StringLookup widget from serialized data
	 * @param {string} serialized - serialized string containing value and display config
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options?) {
		let deserialized = JSON.parse(serialized);

		let value = QEHelper.resolvePlaceholderToString(deserialized.value, resolved_data);
		if (!value) return null;
		let theme_key = value.value;

		let string_map_key = QEHelper.resolvePlaceholderToString(deserialized.string_map, resolved_data, {});
		if (!string_map_key) return null;

		let string_map = QEValConstants.string_maps[string_map_key.value];
		if (!string_map) return null;

		let widget = new StringLookup(string_map, theme_key);
		return widget;
	}

	lookup(string_key: string, options?): string {
		return this.display(Object.assign({ value: string_key, options }));
	}

	display(options: { theme_key?: string, value_test?: string, value?: string | QEValue } = {}): string {
		// TODO: value should only be a string - serialization should occur outside of this call
		var value = options.value;

		// check if string_key is an object requiring serialization, or a simple string
		var string_key;
		if (typeof value != 'object') {
			string_key = value;
		} else if (value.type == 'widget') {
			// TODO: check subtype
			string_key = value.value.value.serialize_to_text();
		} else if (value.type == 'tree') {
			string_key = value.value.serialize_to_text();
		} else if (value.type == 'answer') {
			// TODO: support answer output types. Type is currently always "tree", but some solutions will need to evaluate to true/false, or sets of values
			string_key = value.value.serialize_to_text();
		} else if (value.type == 'string') {
			string_key = value.value;
		} else if (value.type == 'boolean') {
			string_key = value.value;
		} else {
			console.log('Error: attempting to serialize unsupported type: ', value);
			string_key = "";
		}

		// support passed string_map and theme_key
		const string_map = this.string_map;
		let theme_key = options.theme_key || this.theme_key;

		const theme_string_map = string_map[theme_key];
		if (!theme_string_map) {
			console.log('Error: specified theme string map not found: ', theme_key, string_map);
			return 'ERR1';
		}

		// TODO: display options
		if (options.value_test == 'plurality') {
			// check plurality of value
			if (parseInt(string_key) == 1) string_key = 'one';
			else string_key = 'many';
		}

		var string = theme_string_map[string_key];

		if (typeof string == 'undefined') {
			console.log('Error: specified string key not found in theme string map: ', string_key, theme_key, string_map);
			return 'ERR2';
		}

		return string;
	}

	exportValue(options?){
		return null; // no exportable data
	}
}

export class DecimalGrid extends QEWidget {
	value: string;
	user_value: string;
	display_options: DisplayOptions;

	constructor(value: string, display_options: DisplayOptions) {
		super();

		this.value = value;
		this.display_options = display_options;
	}

	/**
	 * Instantiates and returns a DecimalGrid widget from serialized data
	 * @param {string} serialized - serialized string containing value and display config
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options) {
		resolved_data = resolved_data || {};
		options = options || {};

		// coerce legacy Eq data to current format -> { value: '...', display_options: '...' }
		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			deserialized = {
				value: serialized,
				display_options: '',
			};
		}

		// resolve references in value, default to "0" for empty value
		let value = QEHelper.resolveToNumber(deserialized.value || "0", resolved_data);
		if (value === null || Number.isNaN(value))
			return null;

		// deserialize display_options string
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options, {});

		let widget = new DecimalGrid(value.toString(), display_options);
		return widget;
	}

	display(options) {
		let value = this.value;

		if (options.value !== undefined) {
			value = options.value.serialize_to_text();
		}
		if (value === undefined) return null;

		let padding = 5;
		let margin = 0;
		let start_x = 1.5;
		let start_y = 1.5;
		let col_width = 8;
		let row_height = 8;

		let outer_stroke_width = 2;
		let inner_stroke_width = 1;
		let fill_color = '#cff0fc';
		let empty_fill_color = '#ffffff';
		let stroke_color = '#0ea2d8';

		function genWholeSvg(num){
			let ml = '<div style="width: 94px; height: 94px; padding: 5px; display: inline-block;">';
			ml += 	'<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">';
			if (num)
				ml += 		'<rect x="'+ (outer_stroke_width / 2) +'" y="'+ (outer_stroke_width / 2) +'" width="81" height="81" fill="'+ fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ outer_stroke_width +'"/>';
			else
				ml += 		'<rect x="'+ (outer_stroke_width / 2) +'" y="'+ (outer_stroke_width / 2) +'" width="81" height="81" fill="'+ empty_fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ outer_stroke_width +'"/>';

			ml += 	'</svg>';
			ml += '</div>';
			return ml;
		}

		function genTenthsSvg(num){
			let ml = '<div style="width: 94px; height: 94px; padding: 5px; display: inline-block;">';
			ml += 	'<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">';
			ml += 		'<rect x="1" y="1" width="81" height="81" fill="'+ empty_fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ outer_stroke_width +'"/>';

			// filled columns
			for (let i = 0; i < num; i++) {
				ml += '<rect data-col="'+ (i+1) +'" x="'+ (start_x + i * col_width) +'" y="'+ start_y +'" width="'+ col_width +'" height="80" fill="'+ fill_color +'" stroke="'+ stroke_color +'" stroke-width="1"/>';
			}
			// empty columns
			for (let i = num; i < 10; i++) {
				ml += '<rect data-col="'+ (i+1) +'" x="'+ (start_x + i * col_width) +'" y="'+ start_y +'" width="'+ col_width +'" height="80" fill="'+ empty_fill_color +'" stroke="'+ stroke_color +'" stroke-width="1"/>';
			}
			ml += 	'</svg>';
			ml += '</div>';
			return ml;
		}

		function genHundredthsSvg(num){
			let ml = '<div style="width: 94px; height: 94px; padding: 5px; display: inline-block;">';
			ml += 	'<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">';
			ml += 		'<rect x="1" y="1" width="81" height="81" fill="'+ empty_fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ outer_stroke_width +'"/>';

			// filled cells
			for (let i = 0; i < num; i++) {
				ml += '<rect data-cell="'+ (i+1) +'" x="'+ (start_x + Math.trunc(i/10) * col_width) +'" y="'+ (start_y + Math.trunc(i%10) * row_height) +'" width="'+ col_width +'" height="'+ row_height +'" fill="'+ fill_color +'" stroke="'+ stroke_color +'" stroke-width="1"/>';
			}

			// empty cells
			for (let i = num; i < 100; i++) {
				ml += '<rect data-cell="'+ (i+1) +'" x="'+ (start_x + Math.trunc(i/10) * col_width) +'" y="'+ (start_y + Math.trunc(i%10) * row_height) +'" width="'+ col_width +'" height="'+ row_height +'" fill="'+ empty_fill_color +'" stroke="'+ stroke_color +'" stroke-width="1"/>';
			}
			ml += 	'</svg>';
			ml += '</div>';
			return ml;
		}

		var Q = QEQ.from_string(value);
		var parts = Q.serialize_to_decimal_parts();
		var integer = Number(parts[0]);
		var decimal = parts[1];

		var tenths = Math.trunc(Number(decimal[0]) || 0);
		var hundredths = Math.trunc(Number(decimal[1]) || 0);

		// default drawing to "largest units", i.e. 2 uses ones, 0.2 uses tenths, and 0.02 uses hundredths
		// TODO: support display options for forcing rendering to tenths or hundredths

		// default drawing to "column-oriented"
		// TODO: support display options for row-oriented

		let ml = '<div style="display: inline-block; vertical-align: middle;">';

		// handle the "0" case
		if (Q.num === 0) {
			if (this.display_options.display_unit == "hundredths") ml += genHundredthsSvg(0);
			else if (this.display_options.display_unit == "tenths") ml += genTenthsSvg(0);
			else ml += genWholeSvg(0);
		}

		// display ones first
		for (let i = 0; i < integer; i++) {
			if (this.display_options.display_unit == "hundredths") {
				ml += genHundredthsSvg(100);
			} else if (this.display_options.display_unit == "tenths") {
				ml += genTenthsSvg(10);
			} else {
				ml += genWholeSvg(1);
			}
		}

		// now the decimal portion
		if (hundredths || (tenths && this.display_options.display_unit == "hundredths")) {
			ml += genHundredthsSvg(tenths * 10 + hundredths);
		} else if (tenths) {
			ml += genTenthsSvg(tenths);
		}

		ml += '</div>';

		return ml;
	}

	bindEventHandlers(widget_container) {
		const self = this;

		widget_container.on('click', 'svg rect', function(){
			if (widget_container.is('.disable_input')) return; // skip if disabled

			var event_item = jQuery(this);
			if (event_item.is('[data-cell]')) {
				const grid_val = event_item.attr('data-cell');
				self.setInputValue((Number(grid_val) / 100).toString());
			} else if (event_item.is('[data-col]')) {
				const grid_val = event_item.attr('data-col');
				self.setInputValue((Number(grid_val) / 10).toString());
			} else return;

			// re-draw
			widget_container.html(self.display({}));
		});
	}

	setInputValue(value) {
		// special case: clear value if it is already equal to the current value
		if (value == this.value) {
			value = "0";
		}

		this.user_value = value;
		this.value = value;
	}

	getInputValue(input_widgets?): string { return this.user_value; }

	exportValue(options?) {
		return {
			type: 'decimal_grid',
			value: this.value,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

export class PlaceBlocks extends QEWidget {
	value: QETerm;

	constructor(value) {
		super();

		this.value = value;
	}

	/**
	 * Instantiates and returns a PlaceBlocks widget from serialized data
	 * @param {string} serialized - serialized string containing value and display config
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options) {
		resolved_data = resolved_data || {};
		options = options || {};

		// coerce legacy Eq data to current format -> { value: '...', display_options: '...' }
		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			deserialized = {
				value: serialized,
				display_options: '',
			};
		}

		// resolve any references in value
		let resolved = QEHelper.resolvePlaceholderToTree(deserialized.value, resolved_data);
		if (!resolved) return null;

		let widget = new PlaceBlocks(resolved.value);
		return widget;
	}

	display(options) {
		let obj = options.value;
		if (obj === null || obj.value == null) {
			return;
		}

		let stroke_width = 1;
		let fill_color = '#cff0fc';
		let empty_fill_color = '#ffffff';
		let shade_fill_color = '#41c3f3';
		let stroke_color = '#0ea2d8';

		let aspect_y = 3;
		let aspect_x = 4;

		let start_x = stroke_width / 2;
		let start_y = stroke_width / 2;
		let col_width = 8;
		let row_height = 8;

		function genBlocksSvg(w: number, h: number, d: number, style_options?: { [key: string]: string } ){
			let style = {
				'margin-left': '0px',
				'margin-right': '0px',
				'margin-top': '0px',
				'margin-bottom': '0px',
				'display': 'inline-block'
			};
			style = Object.assign(style, style_options);

			let ml = '<div style="width: '+ (stroke_width + w * col_width + d * aspect_x) +'px; height: '+ (stroke_width + h * row_height + d * aspect_y) +'px; '+
				Object.keys(style).map(function(field){ return field +': '+ style[field] }).join('; ') +'">';
			ml += 	'<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">';

			// faces
			for (let i = 0; i < w * h; i++) {
				ml += '<rect x="'+
					(start_x + (i % w) * col_width) +'" y="'+
					(start_y + Math.trunc(i / w) * row_height + d * aspect_y) +
					'" width="'+ col_width +'" height="'+ row_height +'" fill="'+ fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ stroke_width +'"/>';
			}

			// upper edge aspect
			for (let i = 0; i < w * d; i++) {
				ml += '<polygon points="'+
					(start_x + (i % w) * col_width + Math.trunc(i/w) * aspect_x                       ) +','+ (start_y + (d - Math.trunc(i/w)) * aspect_y) +' '+
					(start_x + (i % w) * col_width + Math.trunc(i/w) * aspect_x + aspect_x            ) +','+ (start_y + (d - Math.trunc(i/w)) * aspect_y - aspect_y) +' '+
					(start_x + (i % w) * col_width + Math.trunc(i/w) * aspect_x + aspect_x + col_width) +','+ (start_y + (d - Math.trunc(i/w)) * aspect_y - aspect_y) +' '+
					(start_x + (i % w) * col_width + Math.trunc(i/w) * aspect_x + col_width           ) +','+ (start_y + (d - Math.trunc(i/w)) * aspect_y) +
					'" fill="'+ shade_fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ stroke_width +'"/>'
			}

			// right edge aspects
			for (let i = 0; i < d * h; i++) {
				ml += '<polygon points="'+
					(start_x + w * col_width + (i % d) * aspect_x           ) +','+ (start_y + Math.trunc(i/d) * row_height + (d - i % d) * aspect_y) +' '+
					(start_x + w * col_width + (i % d) * aspect_x + aspect_x) +','+ (start_y + Math.trunc(i/d) * row_height + (d - i % d) * aspect_y - aspect_y) +' '+
					(start_x + w * col_width + (i % d) * aspect_x + aspect_x) +','+ (start_y + Math.trunc(i/d) * row_height + (d - i % d) * aspect_y - aspect_y + row_height) +' '+
					(start_x + w * col_width + (i % d) * aspect_x           ) +','+ (start_y + Math.trunc(i/d) * row_height + (d - i % d) * aspect_y + row_height) +
					'" fill="'+ shade_fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ stroke_width +'"/>'
			}

			ml += 	'</svg>';
			ml += '</div>';
			return ml;
		}


		var integer  = Math.trunc(obj.value.serialize_to_text());
		var thousands = Math.trunc(integer / 1000);
		var hundreds = Math.trunc((integer % 1000) / 100);
		var tens = Math.trunc((integer % 100) / 10);
		var ones = Math.trunc(integer % 10);

		let ml = '<div style="display: inline-block; vertical-align: middle;">';

		for (let i = 0; i < thousands ; i++) {
			ml += genBlocksSvg(10, 10, 10, { 'margin-right': '5px' });
		}

		for (let i = 0; i < hundreds ; i++) {
			// horizontal stacking: apply negative margin-left to each slice of 100 after the first so they overlap
			if (i) {
				ml += genBlocksSvg(1, 10, 10, { 'margin-left': '-30px' });
			} else {
				ml += genBlocksSvg(1, 10, 10);
			}
		}

		for (let i = 0; i < tens ; i++) {
			ml += genBlocksSvg(1, 10, 1, { 'margin-right': '5px' });
		}

		if (ones) {
			// stacking container for ones
			ml += '<div style="display: inline-block; max-width: 40px; font-size: 0;">';
			for (let i = 0; i < ones ; i++ ) {
				ml += genBlocksSvg(1, 1, 1, { 'margin-bottom': '3px', 'margin-right': '3px' });
			}
			ml += '</div>';
		}

		ml += '</div>';

		return ml;
	}

	exportValue(options?){
		return null; // no exportable data
	}
}
