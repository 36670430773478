import * as katex from 'katex';
import { QEAssert } from './QEAssert.js';
import { QEQ } from './QE';
import { QEHelper } from './QEHelper';
import { GrammarType, ArityType, findGrammar, findGrammarType } from "./QEGrammar";

export interface Token {
	type: GrammarType;
	value?: string;
	precedence?: number;
	term?: QETerm;
	offset?: number;
	implied?: boolean;
	separable?: boolean;
}

interface SerializeToTextOptions {
	hide_implied_brackets?: boolean;
	include_input_content?: boolean;
}

interface SerializeToLatexOptions {
	cursor_pos?: number;
	show_cursor?: boolean;
	show_placeholders?: boolean;
	show_implied_faded?: boolean;
	hide_set_brackets?: boolean;
	frac_as_ratio?: boolean;
	display_input_as_correct?: boolean;
	vertical_format?: boolean;
	skip_vertical_align_env?: boolean;
	zero_pad_hms?: boolean;
	max_child_integer_digits?: number;
	max_child_decimal_digits?: number;
	multiply_symbol?: string;
	toFixed?: number;
	digit_group_delim?: string;
	digit_group_char?: string;
	pad_start?: [number, string];
}

interface CursorOptions {
	cursor_pos?: number;
	token_array?: Token[];
}

interface TermCharacterization {
	add_term_info?: {
		has_rationals: boolean;
		serial_non_poly: string;
		vars: {
			[key: string]: number;
		};
	};
	factors?: {
		num: unknown;
		den: unknown;
	};
	coeffs?: QETerm[];
	base_add?: string;
	base_mult?: string;
	power_base?: string;
	power_type?: string;
	power_value?: string;
	serialized?: string;
}

const cursor = '\\htmlClass{cursor}{\\vert}';

export class QETerm {
	parent: QETerm;
	children: QETerm[];
	type: GrammarType;
	value: string;
	prefix: string;
	precedence: number;
	arity: ArityType;
	classes: string[];
	attributes: { [key: string]: string | number };
	token: Token;
	tokens: Token[];
	Q: QEQ;
	sign: number;
	preceding_sign?: number;
	preceding_operator?: QETerm;
	content: QETerm;
	characterization: TermCharacterization;
	open_state: boolean;

	constructor(token: Token) {
		this.children = [];
		this.type = token.type;
		this.value = token.value;

		const grammar = findGrammar(findGrammarType(token.type));
		// if no token value specified, use default value
		if (token.value === undefined) {
			this.value = grammar.value;
		}

		// trim leading "\\" from FUNCTIONs and store as prefix
		if (["FUNCTION", "FUNCTION_OPEN"].indexOf(this.type) != -1 && this.value.match(/^\\/)) {
			this.value = this.value.slice(1);
			this.prefix = "\\"; // TODO: use prefix in serialization, or just discard and always prefix with "\\"
		}

		// trim opening bracket from FUNCTIONs
		if (this.type == "FUNCTION_OPEN") this.value = this.value.slice(0, -1);

		this.precedence = grammar.precedence;
		this.arity = grammar.arity;
		this.classes = []; // meta-data, mainly display info?
		this.attributes = {}; // meta-data
		this.token = token;
		this.tokens = [token];
		token.term = this; // give token a reference back to the term to make it possible for keyboard to see the association
	}

	static create(token: Token): QETerm {
		// the static factory method is defined outside of QETerm,
		//   since it needs to reference subclasses that extend QETerm
		return createQETerm(token);
	}

	validate(): boolean {
		// check arity is correct
		if (this.arity == "VALUE") {
			QEAssert(this.children.length === 0);
		} else if (this.type == "CHAIN") {
			QEAssert(this.children.length >= 2);
		} else if (this.type == "EXPONENT") {
			QEAssert(this.children.length === 2);
		} else if (this.arity == "BINARY" && this.parent.type == "CHAIN") {
			QEAssert(this.children.length === 0);
		} else if (this.arity == "BINARY") {
			QEAssert(this.children.length === 2);
		} else if (this.arity == "POSTFIX") {
			QEAssert(this.children.length === 1);
		} else {
			QEAssert(this.arity == "PREFIX");
			if (this.type != "FUNCTION") {
				QEAssert(this.children.length === 1);
			}
		}
		// check parent/children are consistent
		for (let i = 0; i < this.children.length; i++) {
			QEAssert(this.children[i].parent === this);
			this.children[i].validate();
		}
		return true;
	}
	cloneNode(): QETerm {
		const clone: QETerm = QETerm.create({ type: this.type, value: this.value, precedence: this.precedence });
		clone.parent = this.parent;
		clone.classes = this.classes.slice(0);
		clone.attributes = Object.assign({}, this.attributes);
		clone.token = this.token;
		clone.tokens = this.tokens;

		if (this.sign !== undefined) {
			clone.sign = this.sign;
		}
		if (this.preceding_sign !== undefined) {
			clone.preceding_sign = this.preceding_sign;
		}
		if (this.preceding_operator !== undefined) {
			clone.preceding_operator = this.preceding_operator;
		}

		return clone;
	}
	clone(): QETerm {
		// Note about cloning: if clone() is called on a non-ROOT node, it will indeed clone that sub-tree,
		//    BUT the parent of the sub-tree (including its children[] references) will not be affected.
		//    This means that the cloned sub-tree will reference a parent that doesn't recognize the sub-tree as a child.
		const clone: QETerm = this.cloneNode();
		for (let i = 0; i < this.children.length; i++) {
			clone.pushChild(this.children[i].clone());
		}

		if (this.type == "ROOT") {
			QEAssert(
				clone.serialize_to_polish_notation() == this.serialize_to_polish_notation(),
				clone.serialize_to_polish_notation() + " != " + this.serialize_to_polish_notation()
			);
			QEAssert(clone.serialize_to_text() == this.serialize_to_text(), clone.serialize_to_text() + " != " + this.serialize_to_text());
			QEAssert(clone.serialize_to_latex() == this.serialize_to_latex(), clone.serialize_to_latex() + " != " + this.serialize_to_latex());
		}

		return clone;
	}
	pushChild(new_child: QETerm): void {
		QEAssert(
			(this.arity == "PREFIX" && this.children.length == 0) ||
				(this.arity == "POSTFIX" && this.children.length == 0) ||
				(this.arity == "BINARY" && this.children.length <= 1) ||
				this.type == "FUNCTION" ||
				this.type == "CHAIN"
		);
		QEAssert(new_child !== undefined);
		this.children.push(new_child);
		new_child.parent = this;
	}
	replaceWith(new_node: QETerm): void {
		QEAssert(this.type != "ROOT");
		const parent: QETerm = this.parent;
		const index: number = parent.children.indexOf(this);
		QEAssert(index !== -1);

		// check new_node is an array of nodes
		if (new_node instanceof Array) {
			// set parent for all new nodes
			new_node.forEach(function (node) {
				node.parent = parent;
			});

			// replace the element with the new nodes
			[].splice.apply(parent.children, [index, 1].concat(new_node));
		} else if (parent.isMultiplyChain() && new_node.isMultiplyChain()) {
			// replacing a child of a multiply chain with a multiply chain -> flatten

			// set parent for all new nodes
			new_node.children.forEach(function (node) {
				node.parent = parent;
			});

			// replace the element with the children of the new node
			parent.children.splice(index, 1);
			new_node.children.forEach(function(new_child){ parent.children.splice(index, 0, new_child); });
		} else {
			new_node.parent = parent;
			parent.children[index] = new_node;
		}
	}
	replaceNthChildWith(child_index: number, new_node: QETerm): void {
		QEAssert(this.children.length > child_index);
		QEAssert(this !== new_node);
		const self = this;

		// check new_node is an array of nodes
		if (new_node instanceof Array) {
			// set parent for all new nodes
			new_node.forEach(function (node) {
				node.parent = self;
			});

			// replace the element with the new nodes
			[].splice.apply(self.children, [child_index, 1].concat(new_node));
		} else {
			new_node.parent = this;
			this.children[child_index] = new_node;
		}
	}
	findParent(member: string | { (a: QETerm): boolean }, value?: string): QETerm {
		let node: QETerm = this;

		while (node) {
			if ((typeof member == "function" && member(node)) || (typeof member == "string" && node[member] === value)) {
				return node;
			} else {
				node = node.parent;
			}
		}

		return undefined;
	}
	findFirstChild(member: string | { (a: QETerm): boolean }, value?: string): QETerm {
		if ((typeof member == "function" && member(this)) || (typeof member == "string" && this[member] === value)) {
			return this;
		} else {
			for (let i = 0; i < this.children.length; i++) {
				const found: QETerm = this.children[i].findFirstChild(member, value);
				if (found != undefined) {
					return found;
				}
			}
		}

		return undefined;
	}
	prev(): QETerm {
		// return this child's previous sibling - primarily for CHAINs or lists
		if (this.parent) {
			const index: number = this.parent.children.indexOf(this);
			if (index > 0) {
				return this.parent.children[index - 1];
			}
		}
		return undefined;
	}
	next(): QETerm {
		// return this child's next sibling - primarily for CHAINs or lists
		if (this.parent) {
			const index: number = this.parent.children.indexOf(this);
			if (index < this.parent.children.length - 1) {
				return this.parent.children[index + 1];
			}
		}
		return undefined;
	}
	negate(): void {
		this.sign ||= 1; // init if not set
		this.sign *= -1; // flip sign

		this.preceding_sign ||= 1; // init if not set
		this.preceding_sign *= -1; // flip sign
	}
	isAddChain(): boolean {
		return false;
	}
	isMultiplyChain(): boolean {
		return false;
	}
	isComparatorChain(): boolean {
		return false;
	}
	isInteger(): boolean {
		return false;
	}
	isEvenInteger(): boolean {
		return false;
	}
	isOddInteger(): boolean {
		return false;
	}
	isRationalFrac(): boolean {
		return false;
	}
	// returns an array of all nodes in sub-tree (node and descendants) that meet matching criteria
	findAllChildren(member: string | { (a: QETerm): boolean }, value?: string): QETerm[] {
		let array: QETerm[] = [];
		if (typeof member == "function") {
			// matching functions can use arbitrary/complex matching criteria
			if (member(this)) {
				array.push(this);
			}
		} else {
			if (member in this && this[member] === value) {
				array.push(this);
			}
		}
		this.children.forEach(function (child) {
			array = array.concat(child.findAllChildren(member, value));
		});
		return array;
	}
	// returns an array of immediate children of a node that meet matching criteria
	findImmediateChildren(member: string | { (a: QETerm): boolean }, value?: string): QETerm[] {
		const array: QETerm[] = [];
		this.children.forEach(function (child) {
			if (typeof member == "function") {
				// matching functions can use arbitrary/complex matching criteria
				if (member(child)) {
					array.push(child);
				}
			} else {
				if (member in child && child[member] == value) {
					array.push(child);
				}
			}
		});
		return array;
	}
	// Try to evaluate a tree or sub-tree to a float
	// return NaN if that cannot be done (undefined, 1/0, variable, etc...)
	evaluate_to_float(): number {
		console.log("Error: unhandled evaluate_to_float() type: ", this.type);
		return NaN;
	}

	evaluate_to_Q(): QEQ | number {
		return NaN;
	}

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// CHAIN nodes
	////////////////
	convertBinaryOpsToChains(): QETerm {
		// recursively iterate over children and convertToChains
		if (
			this.arity == "BINARY" &&
			(this.precedence == findGrammar('ADD').precedence ||
				this.precedence == findGrammar('MULTIPLY').precedence ||
				this.precedence == findGrammar('EQUAL').precedence) &&
			this.children.length == 2 // binary op has not already been incorporated into chain
		) {
			// BINARY operators are converted to chains
			// NOTE: can't shift() children out yet - need to be referenced by parent for replaceWith to work
			const lhs = this.children[0].convertBinaryOpsToChains();
			const rhs = this.children[1].convertBinaryOpsToChains();

			if (lhs.type == "CHAIN" && lhs.precedence == this.precedence) {
				// if left-hand-side is a CHAIN of same precedence, append this and rhs
				this.replaceWith(lhs);
				lhs.pushChild(this);
				lhs.pushChild(rhs);
				this.children.splice(0); // remove children
				return lhs;
			} else {
				// else create new CHAIN
				const chain = QETerm.create({ type: "CHAIN", precedence: this.precedence });
				this.replaceWith(chain);
				chain.pushChild(lhs);
				chain.pushChild(this);
				chain.pushChild(rhs);
				this.children.splice(0); // remove children
				return chain;
			}
		} else {
			for (let i = 0; i < this.children.length; i++) {
				this.children[i] = this.children[i].convertBinaryOpsToChains();
			}
			return this;
		}
	}
	unchainChildIfSingle(): QETerm {
		// CHAIN nodes should always have at least three children. If we're down to a single child, replace the CHAIN
		if (this.type == "CHAIN" && this.children.length === 1) {
			if (this.sign < 0) {
				if (this.children[0].sign < 0) {
					// if chain and child negative, signs cancel out
					this.children[0].sign = 1;
				} else {
					// else preserve negative by setting on child
					this.children[0].sign = -1;
				}
			}

			return this.children[0];
		}
		return this;
	}
	convertExponentsToPowers(): QETerm {
		// convert all exponent nodes into pow{} nodes
		// recursively iterate over children and convertExponentsToPowers
		if (this.type == "EXPONENT") {
			// perform conversion on sub-tree
			const lhs = this.children[0].convertExponentsToPowers();
			const rhs = this.children[1].convertExponentsToPowers();

			const pow = QETerm.create({ type: "FUNCTION", value: "pow" });
			this.replaceWith(pow);
			pow.pushChild(lhs);
			pow.pushChild(rhs);
			return pow;
		} else {
			for (let i = 0; i < this.children.length; i++) {
				this.children[i] = this.children[i].convertExponentsToPowers();
			}
			return this;
		}
	}

	convertUnarySignOperatorsToTermSign(): QETerm {
		// replace PLUS ops with their child
		const plus_terms = this.findAllChildren("type", "PLUS");
		plus_terms.forEach(function (plus) {
			plus.replaceWith(plus.children[0]);
		});

		// merge each MINUS op with its child term
		let minus_ops = this.findAllChildren(function (node){
			// find all MINUS signs followed by a value
			return node.type == "MINUS" && node.children[0].type != "MINUS";
		});
		while (minus_ops.length) {
			minus_ops.forEach(function (minus) {
				const minus_child = minus.children[0];

				// invert sign, then replace the MINUS
				minus_child.negate();
				minus.replaceWith(minus_child);
			});

			// look for more MINUS ops
			minus_ops = this.findAllChildren(function (node){
				return node.type == "MINUS" && node.children[0].type != "MINUS";
			});
		}

		return this;
	}
	addClass(class_name: string): void {
		const class_index = this.classes.indexOf(class_name);
		if (class_index < 0) {
			this.classes.push(class_name);
		}
	}
	removeClass(class_name: string): void {
		const class_index = this.classes.indexOf(class_name);
		if (class_index >= 0) {
			this.classes.splice(class_index, 1);
		}
	}
	getClasses(): string[] {
		return this.classes;
	}
	addClassToAll(class_name: string): void {
		this.addClass(class_name);
		for (let i = 0; i < this.children.length; i++) {
			this.children[i].addClassToAll(class_name);
		}
	}
	removeClassToAll(class_name: string): void {
		this.removeClass(class_name);
		for (let i = 0; i < this.children.length; i++) {
			this.children[i].removeClassToAll(class_name);
		}
	}
	attr(attribute_name: string, value?: string | number): void | string | number {
		if (typeof value === "undefined") {
			return this.attributes[attribute_name];
		}
		this.attributes[attribute_name] = value;
	}
	getAttributes(): string[] {
		const attributes = [];
		for (const attr in this.attributes) {
			if (this.attributes.hasOwnProperty(attr)) {
				attributes.push({ name: attr, value: this.attributes[attr] });
			}
		}
		return attributes;
	}
	serialize_to_polish_notation(): string {
		if (this.type == "ROOT") {
			return this.children[0].serialize_to_polish_notation();
		}

		if (this.arity == "VALUE") {
			return this.value;
		} else {
			return (
				this.value +
				"[" +
				this.children
					.map(function (x) {
						return x.serialize_to_polish_notation();
					})
					.join(",") +
				"]"
			);
		}
	}

	serialize_to_text(options: SerializeToTextOptions = {}): string {
		return (this.sign < 0 ? "-" : "") + this.value;
	}

	// Note on cursor rendering: the term that the cursor is at the start of should render the cursor
	//	...unless cursor is at the end of the string, in which case the preceding term should display it
	isCursorAtStart(options: CursorOptions): boolean {
		if (typeof options.cursor_pos == "undefined") return false;

		// check if previous token is zero-length, e.g. "_*4" - in this case the cursor should be rendered by the previous token
		if (this.arity == "BINARY" || this.arity == "POSTFIX") {
			if (options.token_array[options.token_array.indexOf(this.token) - 1].value.length == 0) return false;
		}

		return this.token && options.cursor_pos === this.token.offset;
	}
	isCursorWithin(options: CursorOptions): boolean {
		if (typeof options.cursor_pos == "undefined") return false;
		return this.token && this.token.separable && options.cursor_pos > this.token.offset && options.cursor_pos < this.token.offset + this.token.value.length;
	}
	isCursorAtEnd(options: CursorOptions): boolean {
		if (typeof options.cursor_pos == "undefined") return false;
		if (!this.token) return false;
		if (!options.token_array) return false;

		// compare cursor_pos to the end of the last token for this node (needed for brackets and functions)
		const end_token = this.tokens[this.tokens.length - 1];
		if (options.cursor_pos != end_token.offset + end_token.value.length) return false;

		// we've confirmed the cursor is at the end of this term. Now we need to check whether the cursor should be rendered by a following term
		const cursor_pos = options.cursor_pos;
		const token_array = options.token_array;

		// find index of the last token of this term in token_array
		const last_token = this.tokens[this.tokens.length - 1];
		let token_index = -1;
		for (let i = 0; i < token_array.length; i++) {
			if (token_array[i] === last_token) token_index = i;
		}

		// check if following token is for a node that cannot render cursor (e.g. due to being removed, or being a function separator)
		if (
			token_index == token_array.length - 1 || // always show cursor at end of last token
			token_array[token_index + 1].type == "COMMA" ||
			token_array[token_index + 1].type == "FUNCTION_CLOSE" ||
			token_array[token_index + 1].type == "CLOSING_BRACKET"
		) {
			return true;
		}

		return false;
	}
	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		console.log("Error: serialize_to_latex called on unhandled node type: ", this);
		return "";
	}

	display(options?: SerializeToLatexOptions): string {
		options = options || {};

		if (options.vertical_format) {
			// find max number of integer digits and decimal digits amongst child numbers - needed for digit alignment padding
			let length_pairs = getIntegerDecimalDigitLengths(this);

			// if tree contains an INPUT node, also include any RATIONAL nodes from INPUT content in max size calc
			this.findAllChildren("type", "INPUT").forEach((node) => {
				if (node.content) {
					const input_length_pairs = getIntegerDecimalDigitLengths(node.content);
					length_pairs = length_pairs.concat(input_length_pairs);
				}
			});

			options.max_child_integer_digits = Math.max.apply(
				null,
				length_pairs.map((x) => x[0])
			);
			options.max_child_decimal_digits = Math.max.apply(
				null,
				length_pairs.map((x) => x[1])
			);
		}

		const serialized = this.serialize_to_latex(options);
		let ml = katex.renderToString(serialized, { displayMode: true, trust: true, strict: false });

		// strip empty span.vlist elements that obscure input box hitbox - introduced with katex html class support (likely v0.12+)
		if (!options.vertical_format) {
			// the exception is when rendering an equation in vertical_format mode, where the extra vlist is needed for column display
			ml = ml.replace(/<span class="vlist"( style="[^"]*")?><span><\/span><\/span>/g, '');
		}

		// implied brackets: replace placeholder delimiter with semi-transparent bracket
		// \lang
		ml = ml.replace(/<span( class="[^"]*")?( style="[^"]*")?>[\u27e8]</g, function (match, class_match, style_match) {
			if (!class_match) class_match = "";
			if (style_match) style_match = style_match.slice(0, -1) + 'opacity:0.25;"';
			else style_match = ' style="opacity:0.25;"';
			return "<span" + class_match + style_match + ">(<";
		});
		// \rang
		ml = ml.replace(/<span( class="[^"]*")?( style="[^"]*")?>[\u27e9]</g, function (match, class_match, style_match) {
			if (!class_match) class_match = "";
			if (style_match) style_match = style_match.slice(0, -1) + 'opacity:0.25;"';
			else style_match = ' style="opacity:0.25;"';
			return "<span" + class_match + style_match + ">)<";
		});

		if (options.vertical_format) {
			// in vertical_format mode, nested keyboards lack container markup, since we can't wrap a "row" in a column-oriented environment
			// - find nested keyboard name/index and apply to the parent equation markup
			const included_inputs = [];
			this.findAllChildren("type", "INPUT").forEach(function (node) {
				const input_key = node.value.match(/\[\?(.*)\]/)[1];
				const input_key_index = node.attr("input_key_index");
				included_inputs.push({ input_key: input_key, input_key_index: input_key_index });
			});

			if (included_inputs.length > 1) {
				console.log("Error: nesting more than one kb input in an equation in vertical mode.");
			}

			if (included_inputs.length == 1) {
				let wrapped = '<span class="equation vertical input_eq">';
				wrapped += '<span data-name="' + included_inputs[0].input_key + '"';
				if (included_inputs[0].input_key_index !== undefined) {
					wrapped += ' data-input_key_index="' + included_inputs[0].input_key_index + '"';
				}
				wrapped += ">";
				wrapped += ml;
				wrapped += "</span>"; // name and input_key_index
				wrapped += "</span>"; // equation vertical input_eq

				ml = wrapped;
			} else {
				ml = '<span class="equation vertical">' + ml + "</span>";
			}
		} else {
			// if tree contains an INPUT node, check if it contains content_markup
			this.findAllChildren("type", "INPUT").forEach((node) => {
				if (node.attr('content_markup')) {
					// for nested dropdowns, identify the input box and regex-replace the contents with the dropdown content_markup
					const data_name = node.value.match(/^\[\?([^\]]*)\]$/)[1];
					const input_key_index = node.attr('input_key_index');
					const content_markup = node.attr('content_markup');

					// replace the nested content of the input box tag with the dropdown content_markup
					let match_tag_start = '<span class="enclosing" data-input_key_index="'+ input_key_index +'" data-name="'+ data_name +'">';

					let match_regexp = new RegExp(match_tag_start + '.*?<\/span>.*?<\/span>.*?<\/span>', 'g');
					ml = ml.replace(match_regexp, match_tag_start + content_markup + '</span>');
				}
			});

			ml = '<span class="equation">' + ml + "</span>";
		}

		return ml;
	}

	// NOTE: the goal of characterizing nodes is to make it simple for solver steps to identify compatible terms to operate on
	//  This includes adding/subtracting, multiplying/dividing, applying exponts, simplifying roots, and sorting multiplicative terms and additive expressions
	// TODO: there are some things I'm thinking of changing:
	//  1. This function characterizes the entire subtree, which is wasteful when we don't need to characterize a node's children
	//  2. This function performs all types of characterization, rahter than for a specified operation type.
	characterizeNodeChildren(): void {
		for (let i = 0; i < this.children.length; i++) {
			this.children[i].characterizeNode();
		}
	}
	characterizeNode(): void {
		const current_node = this;

		// recursively characterize children first
		this.characterizeNodeChildren();

		// NOTE:
		// 1. Nodes can be terms, operators, or additive/comparative chains
		// 2. Terms all fall into one of the following basic types:
		// 2.a. rational value
		// 2.b. non-rational value (this includes brackets and functions)
		// 2.c. multiplicative chain
		// 3. Terms all have an exponent, either explicitly ("x^2" has exponent "2") or implied ("x" has exponent "1"), and possibly fractional ("sqrt{5}" has exponent "1/5")
		// 4. Terms need classification for the following ops: add/subtract, multiply/divide, apply exponent/root
		// 4.a. Add/subtract:
		// 4.b. Multiply/divide:
		// 4.c. Apply exponent/root:
		// - brackets and functions can be treated as powers with exponent "1"

		console.log("No special handling for node type " + current_node.type, current_node);
	}
}

//////////////////////////////
// QETerm subclasses
//////////////////////////////
export class QETermRoot extends QETerm {
	evaluate_to_float(): number {
		return this.children[0].evaluate_to_float();
	}

	evaluate_to_Q(): QEQ | number {
		return this.children[0].evaluate_to_Q();
	}

	serialize_to_text(options: SerializeToTextOptions = {}): string {
		return this.children[0].serialize_to_text(options);
	}

	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		if (options.vertical_format && !options.skip_vertical_align_env) {
			let ml = '';
			ml += '\\begin{alignat*}{20}'; // start "aligned" environment
			ml += '\\hline'; // top grid line
			ml += this.children[0].serialize_to_latex(options);
			ml += '\\\\ \\hline'; // bottom grid line
			ml += '\\end{alignat*}';
			return ml;
		} else {
			return this.children[0].serialize_to_latex(options);
		}
	}

	characterizeNode(): void {
		this.characterizeNodeChildren();

		// NOTE: could aggregate various info about children
		this.characterization = {
			// NOTE: "serialized", is not used, but it can be handy for debug purposes
			serialized: this.serialize_to_text(),
		};
	}
}

export class QETermInput extends QETerm {
	serialize_to_text(options: SerializeToTextOptions = {}): string {
		if (options.include_input_content && this.content) {
			return this.content.serialize_to_text(options);
		}
		return this.value;
	}

	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		const value = this.value.match(/^\[\?([^\]]*)\]$/)[1];
		let ml = '';

		if (this.isCursorAtStart(options))
			ml += cursor;

		if (options.show_placeholders) {
			ml += '\\boxed{?' + value + '}';
		} else if (options.display_input_as_correct) {
			// display content as coloured content instead of box
			const serial_content = this.content ? this.content.serialize_to_text() : '';
			ml += '\\textcolor{#00a000}{' + (serial_content.length ? this.content.serialize_to_latex(options) : '\\phantom{??}') + '}';
		} else {
			const input_key_index = this.attr('input_key_index');
			const serial_content = this.content ? this.content.serialize_to_text() : '';

			// TODO: display flag to control displaying as empty box or "?"

			if (options.vertical_format) {
				// render input box content with grid columns, but skip double rendering "alignat*" environment tags
				if (serial_content.length) {
					ml += this.content.serialize_to_latex(Object.assign({skip_vertical_align_env: true}, options));
				} else {
					// empty content, still show grid lines
					ml += QETerm.create({ type: "EMPTY" }).serialize_to_latex(Object.assign({skip_vertical_align_env: true}, options));
				}
				return ml;
			} else {
				if (input_key_index !== undefined) {
					ml += '\\:\\htmlClass{input_box answer}{\\htmlData{input_key_index=' + input_key_index + ', name='+ value +'}{' + (serial_content.length ? this.content.serialize_to_latex(options) : '\\phantom{??}') + '}}';
				} else {
					ml += '\\:\\htmlClass{input_box answer}{\\htmlData{name='+ value +'}{' + (serial_content.length ? this.content.serialize_to_latex(options) : '\\phantom{??}') + '}}';
				}
			}
		}
		if (this.isCursorAtEnd(options))
			ml += cursor;

		return ml;
	}
}

export class QETermParameter extends QETerm {
	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		const value = this.value.match(/^\[\$([^\]]*)\]$/)[1];
		let ml = '';

		if (this.isCursorAtStart(options))
			ml += cursor;
		ml += '\\boxed{\\$' + value + '}';
		if (this.isCursorAtEnd(options))
			ml += cursor;

		return ml;
	}
}

////////
export class QETermValue extends QETerm {
	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		const value = this.value;
		let ml = '';

		if (this.isCursorAtStart(options)) {
			ml += cursor;
			ml += this.value;
		} else if (this.isCursorWithin(options)) {
			const cursor_sub_index = options.cursor_pos - this.token.offset;
			ml += value.substr(0, cursor_sub_index) + cursor + value.substr(cursor_sub_index);
		} else if (this.isCursorAtEnd(options)) {
			ml += this.value + cursor;
		} else {
			ml += addHighlights(this, this.value);
		}
		return renderTermSign(this) + ml;
	}
}

export class QETermEmpty extends QETermValue {
	serialize_to_text(options: SerializeToTextOptions = {}): string {
		return (this.sign < 0 ? '-' : '') + "";
	}

	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		let ml = '';
		if (options.vertical_format) {
			// input box should span the full width, using grid columns
			const num_cols = (options.max_child_integer_digits || 0) + (options.max_child_decimal_digits || 0);

			let val = '';
			for (let i = num_cols; i > 0; i--) {
				val = '&'+ val;
			}
			ml += val;
		} else {
			if (this.isCursorAtStart(options)) {
				ml += cursor;
			}

			// display placeholder unless we're showing a cursor with an empty string (empty node with parent ROOT)
			if (options.show_cursor && this.parent.type != 'ROOT' || !options.show_cursor) {
				ml += '\\htmlStyle{background-color: #ffcccc; color: #ffffff}{?}';
			}
		}
		return ml;
	}
}

export class QETermConstant extends QETermValue {
	evaluate_to_float(): number {
		if (this.value === "\\pi") {
			return (this.sign < 0 ? -1 : 1) * Math.PI;
		}

		console.log("Unknown constant value in evaluate_to_float for: ", this.value);
		return NaN;
	}
}

export class QETermRational extends QETermValue {
	constructor(token: Token) {
		super(token);

		// determine value_type of RATIONALs from this.value
		this.value = this.value.toString(); // force to string

		// strip preceding "-" signs and put in sign field
		if (this.value.match(/^-/)) {
			this.value = this.value.replace(/^-/, '');
			this.negate();
		}

		// TODO: update SolverSteps to use directly, rather than converting back-and-forth
		this.Q = QEQ.from_string(this.value);

		if (this.value.indexOf('%') === this.value.length - 1) {
			this.attr('value_type', 'percent');
		} else if (this.value.indexOf('...') != -1) {
			// TODO: should we use an extra 'repeating' attribute? Not needed for QEQ
			this.attr('value_type', 'decimal');
		} else if (this.value.indexOf('.') != -1) {
			this.attr('value_type', 'decimal');
		} else if (this.value.indexOf('mfrac') != -1) {
			this.attr('value_type', 'mixed');
		} else if (this.value.indexOf('frac') != -1) {
			this.attr('value_type', 'fraction');
		} else {
			this.attr('value_type', 'integer');
		}

		// TODO: use same matching as the lexer:
		/*
			/^[0-9]*[.][0-9]+[eE][+-]?[0-9]+%$/
			/^[0-9]*[.][0-9]+[eE][+-]?[0-9]+$/
			/^[0-9]*[.][0-9]+[.][.][.]%$/
			/^[0-9]*[.][0-9]+[.][.][.]$/
			/^[0-9]*[.][0-9]*%$/
			/^[0-9]*[.][0-9]*$/
			/^[0-9]+%$/
			/^[0-9]+$/
		*/
	}

	evaluate_to_float(): number {
		return (this.sign < 0 ? -1 : 1) * this.Q.num / this.Q.den;
	}

	evaluate_to_Q(): QEQ | number { return this.Q; }

	isInteger(): boolean {
		return this.Q.den == 1;
	}

	isEvenInteger(): boolean {
		return this.Q.den == 1 && !(this.Q.num % 2);
	}

	isOddInteger(): boolean {
		return this.Q.den == 1 && this.Q.num % 2 == 1;
	}

	serialize_to_text(options: SerializeToTextOptions = {}): string {
		if (this.value === "0") {
			return this.value; // "0" can not be negatative
		}
		return (this.sign < 0 ? '-' : '') + this.value;
	}

	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		let ml = '';

		// set digit grouping character
		const digit_group_delim = options.digit_group_delim;
		let digit_group_char = "";
		if (digit_group_delim == "space") {
			digit_group_char = "\\medspace";
		} else if (digit_group_delim == "comma") {
			digit_group_char = ",";
		} else if (digit_group_delim == "period") {
			digit_group_char = ".";
		}

		if (options.show_cursor) {
			let value = this.value;

			// apply digit grouping
			const dec_pos = value.indexOf(".");
			const chars = value.split('');
			for (let char_idx = dec_pos != -1 ? dec_pos - 1 : chars.length - 1; char_idx >= 3; char_idx -= 3) {
				// append grouping separator
				chars[char_idx - 3] += digit_group_char;
			}

			if (this.isCursorAtStart(options)) {
				value = cursor + chars.join('');
			} else if (this.isCursorWithin(options)) {
				const cursor_sub_index = options.cursor_pos - this.token.offset;
				value = chars.slice(0, cursor_sub_index).join('') + cursor + chars.slice(cursor_sub_index).join('');
			} else {
				value = chars.join('');
			}

			// prepend implied leading zero
			if (this.value.match(/^\./)) {
				value = '\\htmlStyle{opacity: 0.4}{0}' + value;
			}

			if (this.isCursorAtEnd(options))
				value += cursor;

			// append implied trailing zero
			if (this.value.match(/\.$/) && !this.value.match(/\.\.\.$/)) {
				value += '\\htmlStyle{opacity: 0.4}{0}';
			}

			ml += value;
		} else if (this.attr('value_type') == 'fraction') {
			// NOTE: fraction RATIONAL terms are a bit of a pain, since the "value" is the text-serialized value, which differs from the latex-serialized value
			if (this.value.indexOf('\\frac') != -1) {
				ml += this.value.replace(/^\\frac{(.*?),(.*?)}/, function (full, num, den) {
					// if numerator begins with "-", pull it out in front of the fraction
					if (num.slice(0, 1) == '-') {
						return '-\\frac{' + num.slice(1) + '}{' + den + '}';
					} else {
						return '\\frac{' + num + '}{' + den + '}';
					}
				});
			} else {
				ml += this.value;
			}
		} else if (this.attr('value_type') == 'mixed') {

			if (this.value.indexOf('\\mfrac') != -1) {
				// e.g. "mfrac{-2,1,3}" -> "-2\\frac{1}{3}"
				ml += this.value.replace(/^\\mfrac{(.*?),(.*?),(.*?)}/, function (full, whole, num, den) {
					return whole + '\\frac{' + num + '}{' + den + '}';
				});
			} else if (this.value.indexOf('\\frac') != -1) {
				// e.g. "frac{-5,3}" -> "-1\\frac{2}{3}"
				ml += this.value.replace(/^\\frac{(.*?),(.*?)}/, function (full, num, den) {
					return mixed_frac_katex(num, den);
				});
			} else {
				ml += this.value;
			}

		} else if (this.attr('value_type') == 'integer' && this.value.indexOf('/') !== -1) {
			// "5/2" -> "5\\div 2"
			ml += this.value.replace(/\//, ' \\div');
		} else if (this.attr('value_type') == 'percent' || this.attr('value_type') == 'decimal') {
			// serialize_to_decimal_parts and show overline on repeating portion
			let Q = this.Q;
			if (this.attr('value_type') == 'percent') {
				// upshift percent
				Q = new QEQ(Q.num * 100, Q.den);
			}

			// column alignment grid in vertical stack mode
			if (options.vertical_format) {
				return renderTermSign(this) + columnAlignDigits(this.value, Q, Object.assign({}, options, {digit_group_char: digit_group_char}));
			}

			const parts = Q.serialize_to_decimal_parts();
			let integer = parts[0];
			let decimal = parts[1];
			const repeating = parts[2];
			const ellipsis = parts[3];

			// handle "empty" integer portion
			if (integer == '0' && this.value.match(/^\./)) {
				integer = '';
			}

			// handle trailing zeros in decimal
			if (this.value.match(/\./) && this.value.match(/0$/)) {
				decimal += this.value.match(/0*$/)[0]; // re-attach stripped zeros
			}

			// integer portion
			var val = integer;

			// apply digit grouping: split every 3 digits, then merge back together with grouping char
			val = integer.split(/(?=(?:...)*$)/).join(digit_group_char);

			// decimal portion
			if (decimal || repeating
				|| this.value.match(/\./)) { // trailing "."
				val += '.';
			}
			val += decimal;

			// repeating portion
			if (repeating) {
				// NOTE: currently not supporting user entry of repeating decimals
				val += '\\overline{' + repeating + '}';
			} else if (ellipsis) {
				val += '...';
			}

			// formatting options
			if (options.toFixed !== undefined) {
				val = parseFloat(val).toFixed(options.toFixed);
			}

			if (this.attr('value_type') == 'percent') {
				val += '\\%';
			}

			ml += val;
		} else {
			// default value_type -> usually integer

			// column alignment grid in vertical stack mode
			if (options.vertical_format) {
				return renderTermSign(this) + columnAlignDigits(this.value, this.Q, Object.assign({}, options, {digit_group_char: digit_group_char}));
			}

			// formatting options
			let val = this.value;
			if (options.toFixed !== undefined) {
				val = parseFloat(val).toFixed(options.toFixed);
			}

			if (options.pad_start) {
				val = val.padStart(options.pad_start[0], options.pad_start[1]);
			}

			// TODO: support using a different decimal char (e.g. "," in Europe)

			// apply digit grouping: split every 3 digits, then merge back together with grouping char
			if (val.indexOf(".") != -1) {
				// apply to digits to the left of the decimal point
				const integer = val.substr(0, val.indexOf("."));
				const dec = val.substr(val.indexOf(".")+1);
				val = integer.split(/(?=(?:...)*$)/).join(digit_group_char) + '.' + dec;
			} else {
				val = val.split(/(?=(?:...)*$)/).join(digit_group_char);
			}

			ml += val;
		}
		return renderTermSign(this) + addHighlights(this, ml);
	}

	characterizeNode(): void {
		const current_node = this;

		current_node.characterization = {
			base_mult: current_node.value,
			base_add: current_node.value,
			power_type: "power",
			power_value: "1",
			power_base: current_node.value,
			factors: { num: {}, den: {} },
			add_term_info: { has_rationals: true, vars: {}, serial_non_poly: "" },
		};

		// TODO: pull prime factorization out and only perform as needed
		// getPrimeFactorDecomposition return undefined when called on 0 or a negative value, and a single [1,1] when called on 1
		const num_primes = QEHelper.getPrimeFactorDecomposition(Math.abs(current_node.Q.num));
		const den_primes = QEHelper.getPrimeFactorDecomposition(current_node.Q.den);

		// convert prime factors to factor maps
		if (num_primes && typeof num_primes[0] == "object") {
			num_primes.forEach(function (factor_pair) {
				current_node.characterization.factors.num[factor_pair[0]] = factor_pair[1];
			});
		}
		if (den_primes && typeof den_primes[0] == "object") {
			den_primes.forEach(function (factor_pair) {
				current_node.characterization.factors.den[factor_pair[0]] = factor_pair[1];
			});
		}
	}
}

export class QETermVariable extends QETermValue {
	characterizeNode(): void {
		this.characterization = {
			base_mult: this.value,
			base_add: this.value,
			power_type: "power",
			power_value: "1",
			power_base: this.value,
			add_term_info: { has_rationals: false, vars: {}, serial_non_poly: "" },
		};
		this.characterization.add_term_info.vars[this.value] = 1;
	}
}

////////
export class QETermBinaryOperator extends QETerm {
	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		// horizontal row grid lines in vertical stack mode
		if (options.vertical_format && this.type == "EQUAL") {
			// equal symbol not needed - handled as a styled hline
			return '\\\\ \\hline';
		}

		// alias operator value if needed
		let value = this.value;
		if (this.type == 'DIVIDE')
			value = '\\div ';
		else if (this.type == 'MULTIPLY') {
			if (value == '*') {
				value = options.multiply_symbol ? options.multiply_symbol +' ' : '\\cdot ';
			} else if (value == '') {
				const prev = this.prev();
				const next = this.next();

				// inspect neighbouring terms and explicitly render if the following term could be misinterpreted or merged without a separating "*"
				if (next.type == "RATIONAL" ||
					next.type == "EXPONENT" && next.children[0].type == "RATIONAL" ||
					next.value == "pow" && next.children[0].type == "RATIONAL" ||
					next.value == "frac" ||
					next.sign < 0
					// other cases?
				) {
					if (options.show_implied_faded)
						value = '\\htmlStyle{opacity: 0.4}{'+ (options.multiply_symbol ? options.multiply_symbol +' ' : '\\cdot') +'} ';
					else
						value = options.multiply_symbol ? options.multiply_symbol +' ' : '\\cdot ';
				}
			}
		} else if (this.type == 'GREATER_OR_EQUAL') {
			value = '\\geq ';
		} else if (this.type == 'LESS_OR_EQUAL') {
			value = '\\leq ';
		}

		value = addHighlights(this, value);

		if (options.vertical_format) {
			value = '\\\\ \\hline' + value;
		}

		let ml = '';
		if (this.isCursorAtStart(options) && this.value.length) {
			ml += cursor;
		}

		ml += value;

		if (this.isCursorAtEnd(options)) {
			ml += cursor;
		}
		return addHighlights(this, ml);
	}

	characterizeNode(): void {
		this.characterization = {
			serialized: this.serialize_to_text()
		};
	}
}

////////

export class QETermOperator extends QETermBinaryOperator {
}

export class QETermAdditiveOperator extends QETermOperator {
}

export class QETermMultiplicativeOperator extends QETermOperator {
}

export class QETermAdd extends QETermAdditiveOperator {
}

export class QETermSubtract extends QETermAdditiveOperator {
}

export class QETermMultiply extends QETermMultiplicativeOperator {
	serialize_to_text(options: SerializeToTextOptions = {}): string {
		if (this.parent && this.parent.type == "CHAIN" && this.value == '') {
			// inspect neighbouring terms and explicitly render if the following term could be misinterpreted or merged without a separating "*"
			const prev = this.prev();
			const next = this.next();
			if (prev.type == "RATIONAL" && next.type == "RATIONAL" ||
				next.sign < 0 ||
				prev.type == "RATIONAL" && next.value == "pow" && next.children[0].type == "RATIONAL"
				// other cases?
			) {
				return "*";
			}
		}
		return this.value
	}
}

export class QETermDivide extends QETermMultiplicativeOperator {
}

////////

export class QETermComparator extends QETermBinaryOperator {
}

export class QETermEqual extends QETermComparator {
}

export class QETermLess extends QETermComparator {
}

export class QETermLessOrEqual extends QETermComparator {
}

export class QETermGreater extends QETermComparator {
}

export class QETermGreaterOrEqual extends QETermComparator {
}

////////

export class QETermFunction extends QETerm {
	constructor(token: Token) {
		super(token);

		this.type = "FUNCTION";

		if (token.type == "FUNCTION_OPEN") {
			this.open_state = true;
		}
	}

	evaluate_to_float(): number {
		if (this.value === "frac") {
			const num: number = this.children[0].evaluate_to_float();
			const den: number = this.children[1].evaluate_to_float();
			if (den !== 0) {
				return (this.sign < 0 ? -1 : 1) * num / den;
			}
		} else if (this.value === "mfrac") {
			// handle negative signs in mixed fraction fields
			let sign = 1;
			if (this.children[0].serialize_to_text().indexOf('-') === 0) {
				sign *= -1;
			}
			if (this.children[1].serialize_to_text().indexOf('-') === 0) {
				sign *= -1;
			}
			if (this.children[2].serialize_to_text().indexOf('-') === 0) {
				sign *= -1;
			}

			const whole = Math.abs(this.children[0].evaluate_to_float());
			const num: number = Math.abs(this.children[1].evaluate_to_float());
			const den: number = Math.abs(this.children[2].evaluate_to_float());
			if (den !== 0) {
				return (this.sign < 0 ? -1 : 1) * sign * (whole * den + num) / den;
			}
		} else if (this.value === "pow") {
			const base: number = this.children[0].evaluate_to_float();
			const exp: number = this.children[1].evaluate_to_float();

			if (!isNaN(base) && !isNaN(exp)) {
				return (this.sign < 0 ? -1 : 1) * Math.pow(base, exp);
			}
			return NaN;
		} else if (this.value === "min") {
			const vals: number[] = [];
			for (let i = 0; i < this.children.length; i++) {
				const val: number = this.children[i].evaluate_to_float();
				if (isNaN(val)) {
					return NaN;
				} else {
					vals.push(val);
				}
			}
			return (this.sign < 0 ? -1 : 1) * Math.min.apply(this, vals);
		} else if (this.value === "max") {
			const vals: number[] = [];
			for (let i = 0; i < this.children.length; i++) {
				const val: number = this.children[i].evaluate_to_float();
				if (isNaN(val)) {
					return NaN;
				} else {
					vals.push(val);
				}
			}
			return (this.sign < 0 ? -1 : 1) * Math.max.apply(this, vals);
		} else if (this.value === "abs") {
			return (this.sign < 0 ? -1 : 1) * Math.abs(this.children[0].evaluate_to_float());
		} else if (this.value === "ceil") {
			return (this.sign < 0 ? -1 : 1) * Math.ceil(this.children[0].evaluate_to_float());
		} else if (this.value === "floor") {
			return (this.sign < 0 ? -1 : 1) * Math.floor(this.children[0].evaluate_to_float());
		} else if (this.value === "round") {
			const number: number = this.children[0].evaluate_to_float();
			let place = 0;
			if (this.children.length === 2) {
				place = Math.floor(this.children[1].evaluate_to_float());
			}

			if (place > 0) {
				return (this.sign < 0 ? -1 : 1) * parseFloat(number.toFixed(place));
			} else if (place < 0) {
				return (this.sign < 0 ? -1 : 1) * Math.round(number * Math.pow(10, place)) / Math.pow(10, place);
			} else {
				return (this.sign < 0 ? -1 : 1) * Math.round(number);
			}
		} else if (this.value === "random") {
			return (this.sign < 0 ? -1 : 1) * Math.random();
		} else if (this.value === "sign") {
			return (this.sign < 0 ? -1 : 1) * Math.sign(this.children[0].evaluate_to_float());
		} else if (this.value === "nroot") {
			const root_index: number = this.children[0].evaluate_to_float();
			if (isNaN(root_index) || !root_index) {
				return NaN;
			}
			return (this.sign < 0 ? -1 : 1) * Math.pow(1 / root_index, this.children[1].evaluate_to_float());
		} else if (this.value === "sqrt") {
			return (this.sign < 0 ? -1 : 1) * Math.sqrt(this.children[0].evaluate_to_float());
		} else if (this.value === "sin") {
			return (this.sign < 0 ? -1 : 1) * Math.sin(this.children[0].evaluate_to_float());
		} else if (this.value === "cos") {
			return (this.sign < 0 ? -1 : 1) * Math.cos(this.children[0].evaluate_to_float());
		} else if (this.value === "tan") {
			return (this.sign < 0 ? -1 : 1) * Math.tan(this.children[0].evaluate_to_float());
		} else {
			console.log('Warning: unknown evaluate_to_float() FUNCTION type: ', this.value);
		}
		return NaN;
	}

	evaluate_to_Q(): QEQ | number {
		// evaluate based on type of function
		if (this.value === "frac") {
			const num: QEQ | number = this.children[0].evaluate_to_Q();
			if (!(num instanceof QEQ)) return NaN;

			const den: QEQ | number = this.children[1].evaluate_to_Q();
			if (!(den instanceof QEQ)) return NaN;

			if (!den.equal(0)) {
				return num.divide(den);
			}
		} else if (this.value === "mfrac") {
			// handle negative signs: mfrac{-1,1,2} should be -3/2, not "-1 + 1/2 -> -1/2"
			const whole: QEQ | number = this.children[0].evaluate_to_Q();
			if (!(whole instanceof QEQ)) return NaN;

			const num: QEQ | number = this.children[1].evaluate_to_Q();
			if (!(num instanceof QEQ)) return NaN;

			const den: QEQ | number = this.children[2].evaluate_to_Q();
			if (!(den instanceof QEQ)) return NaN;

			if (!den.equal(0)) {
				if (this.children[0].serialize_to_text().indexOf('-') === 0) {
					return whole.subtract(num.divide(den));
				}
				return whole.add(num.divide(den));
			}
		} else if (this.value === "min") {
		} else if (this.value === "max") {
		} else if (this.value === "abs") {
		} else if (this.value === "ceil") {
		} else if (this.value === "floor") {
		} else if (this.value === "round") {
		} else {
			console.log('Warning: unknown evaluate_to_float() FUNCTION type: ', this.value);
		}
		return NaN;
	}

	isRationalFrac(): boolean {
		if (this.value != "frac") {
			return false;
		}

		return this.children[0] instanceof QETermRational &&
			this.children[1] instanceof QETermRational;
	}

	serialize_to_text(options: SerializeToTextOptions = {}): string {
		// check number of children. Single-argument functions don't require closing tokens, but may have them
		if (this.children.length == 1 && this.tokens.length == 2) {
			// single-argument function with brackets
			// if opening token already has a "\" prefix, don't double-add it
			if (this.tokens[0].value.indexOf("\\") == 0) {
				return (this.sign < 0 ? '-' : '') + this.tokens[0].value + this.children[0].serialize_to_text(options) + this.tokens[1].value;
			} else {
				return (this.sign < 0 ? '-' : '') + '\\' + this.tokens[0].value + this.children[0].serialize_to_text(options) + this.tokens[1].value;
			}
		} else if (this.children.length == 1) {
			// NOTE: single-argument function -> but no guarantee that it was not created without "{" and "}" tokens by a solution, so let's add them
			return (this.sign < 0 ? '-' : '') + '\\' + this.value + "{" + this.children[0].serialize_to_text(options) + "}";
		} else {
			return (this.sign < 0 ? '-' : '') + '\\' + this.value + "{" + this.children.map(function (x) {
				return x.serialize_to_text(options);
			}).join(",") + "}";
		}
	}

	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		let ml = '';

		if (this.value == 'frac') {
			if (this.isCursorAtStart(options))
				ml += cursor;

			// TODO: support as node class as well - options applies to all nodes, whereas class is applied to a single node
			if (options.frac_as_ratio) {
				ml += this.children[0].serialize_to_latex(options);
				ml += ':';
				ml += this.children[1].serialize_to_latex(options);
			} else {
				ml += '\\frac{';
				ml += this.children[0].serialize_to_latex(options);
				ml += '}{';
				ml += this.children[1].serialize_to_latex(options);
				ml += '}';
			}
			if (this.isCursorAtEnd(options))
				ml += cursor;
		} else if (this.value == 'mfrac') {
			if (this.isCursorAtStart(options))
				ml += cursor;

			ml += this.children[0].serialize_to_latex(options);
			ml += '\\frac{';
			ml += this.children[1].serialize_to_latex(options);
			ml += '}{';
			ml += this.children[2].serialize_to_latex(options);
			ml += '}';
			if (this.isCursorAtEnd(options))
				ml += cursor;
		} else if (this.value == 'list' || this.value == 'set') {
			if (this.isCursorAtStart(options))
				ml += cursor;

			if (options.hide_set_brackets) {
				ml += this.children.map(function (x) { return x.serialize_to_latex(options); }).join(',');
			} else {
				ml += '\\left\\{' + this.children.map(function (x) { return x.serialize_to_latex(options); }).join(',') + '\\right\\}';
			}

			if (this.isCursorAtEnd(options))
				ml += cursor;
		} else if (this.value == 'pow') {
			// if base sign is negative, wrap in brackets. E.g. "pow{-1,x}" -> "(-1)^x"
			if (this.children[0].sign < 0 || this.children[0].type == "MINUS" ||
				this.children[0].type == "CHAIN" ||
				this.children[0].type == "FUNCTION" ||
				this.children[0].arity == "BINARY") {
				ml += '\\left\\lparen ';
				if (this.isCursorAtStart(options))
					ml += cursor;
				ml += this.children[0].serialize_to_latex(options);
				ml += '\\right\\rparen ';
			} else {
				ml += '{';
				if (this.isCursorAtStart(options))
					ml += cursor;
				ml += this.children[0].serialize_to_latex(options);
				ml += '}';
			}
			ml += '^{';
			ml += this.children[1].serialize_to_latex(options);
			ml += '}';
			if (this.isCursorAtEnd(options))
				ml += cursor;

		} else if (this.value == 'abs') {
			if (this.isCursorAtStart(options))
				ml += cursor;
			ml += '\\left\\vert ' + this.children[0].serialize_to_latex(options) + '\\right\\vert ';
			if (this.isCursorAtEnd(options))
				ml += cursor;
		} else if (this.value == 'ceil') {
			if (this.isCursorAtStart(options))
				ml += cursor;
			ml += '\\left\\lceil ' + this.children[0].serialize_to_latex(options) + '\\right\\rceil ';
			if (this.isCursorAtEnd(options))
				ml += cursor;
		} else if (this.value == 'floor') {
			if (this.isCursorAtStart(options))
				ml += cursor;
			ml += '\\left\\lfloor ' + this.children[0].serialize_to_latex(options) + '\\right\\rfloor ';
			if (this.isCursorAtEnd(options))
				ml += cursor;
		} else if (this.value == 'nroot') {
			if (this.isCursorAtStart(options))
				ml += cursor;

			// TODO: cursor pos within children - like EXPONENT, pow, list, etc.
			ml += '\\sqrt[' + this.children[0].serialize_to_latex(options) + ']{' + this.children[1].serialize_to_latex(options) + '}';
			if (this.isCursorAtEnd(options))
				ml += cursor;
		} else if (this.value == 'sqrt') {
			if (this.isCursorAtStart(options))
				ml += cursor;
			ml += '\\sqrt{' + this.children[0].serialize_to_latex(options) + '}';
			if (this.isCursorAtEnd(options))
				ml += cursor;
		} else if (this.value == 'time_hm') {
			ml += this.children[0].serialize_to_latex(options);
			ml += '\\!:\\!';
			if (options.zero_pad_hms) { // zero-pad minutes
				ml += this.children[1].serialize_to_latex(Object.assign({}, options, {pad_start: [2, "0"]}));
			} else {
				ml += this.children[1].serialize_to_latex(options);
			}

			// AM/PM display - extra argument
			if (this.children.length == 3) {
				// check if argument is "0" or "1" - if so, treat as flag for AM/PM display, else render
				const am_pm = this.children[2].serialize_to_text();
				if (am_pm === "0") {
					ml += '\\;\\text{AM}';
				} else if (am_pm === "1") {
					ml += '\\;\\text{PM}';
				} else {
					ml += '\\;'+ this.children[2].serialize_to_latex(options);
				}
			}
		} else if (this.value == 'time_hms') {
			ml += this.children[0].serialize_to_latex(options);
			ml += '\\!:\\!';
			if (options.zero_pad_hms) { // zero-pad minutes
				ml += this.children[1].serialize_to_latex(Object.assign({}, options, {pad_start: [2, "0"]}));
			} else {
				ml += this.children[1].serialize_to_latex(options);
			}
			ml += '\\!:\\!';
			if (options.zero_pad_hms) { // zero-pad seconds
				ml += this.children[2].serialize_to_latex(Object.assign({}, options, {pad_start: [2, "0"]}));
			} else {
				ml += this.children[2].serialize_to_latex(options);
			}

			// AM/PM display - extra argument
			if (this.children.length == 4) {
				// check if argument is "0" or "1" - if so, treat as flag for AM/PM display, else render
				const am_pm = this.children[3].serialize_to_text();
				if (am_pm === "0") {
					ml += '\\;\\text{AM}';
				} else if (am_pm === "1") {
					ml += '\\;\\text{PM}';
				} else {
					ml += '\\;'+ this.children[3].serialize_to_latex(options);
				}
			}
		} else {
			// standard "name(...)" delimiters
			const display_alias = {
				sin: '\\sin ',
				cos: '\\cos ',
				tan: '\\tan ',
			};

			if (this.isCursorAtStart(options))
				ml += cursor;

			if (display_alias[this.value])
				ml += display_alias[this.value];
			else
				ml += this.value;

			ml += '\\left\\lparen ' + this.children.map(function (x) { return x.serialize_to_latex(options); }).join(',') + '\\right\\rparen ';
			if (this.isCursorAtEnd(options))
				ml += cursor;
		}
		return renderTermSign(this) + addHighlights(this, ml);
	}

	characterizeNode(): void {
		const current_node = this;

		current_node.characterizeNodeChildren();

		if (current_node.value == "nroot") {
			current_node.characterization = {
				base_mult: current_node.serialize_to_text(),
				base_add: current_node.serialize_to_text(),
				power_type: "radical",
				power_value: current_node.children[0].serialize_to_text(),
				power_base: current_node.children[1].serialize_to_text(),
				add_term_info: { has_rationals: false, vars: {}, serial_non_poly: current_node.serialize_to_text() },
			};
		} else if (current_node.value == "sqrt") {
			current_node.characterization = {
				base_mult: current_node.serialize_to_text(),
				base_add: current_node.serialize_to_text(),
				power_type: "radical",
				power_value: "2",
				power_base: current_node.children[0].serialize_to_text(),
				add_term_info: { has_rationals: false, vars: {}, serial_non_poly: current_node.serialize_to_text() },
			};
		} else if (current_node.value == 'frac') {
			// NOTE: no base_mult, since we never multiply by fraction directly ???
			// TODO: do we want this? "frac{1,2} + frac{1,2}" -> "2*frac{1,2}"

			// combine numerator and denominator add_term characterizations
			const add_term_info = { has_rationals: false, vars: {}, serial_non_poly: "" };
			const num_add_term_info = current_node.children[0].characterization.add_term_info;
			const den_add_term_info = current_node.children[1].characterization.add_term_info;

			Object.keys(num_add_term_info.vars).forEach(function (var_name) {
				add_term_info.vars[var_name] = num_add_term_info.vars[var_name];
			});
			Object.keys(den_add_term_info.vars).forEach(function (var_name) {
				// invert denominator exponents
				const den_var_exp = -den_add_term_info.vars[var_name];

				if (add_term_info.vars[var_name] !== undefined) {
					add_term_info.vars[var_name] = Math.max(
						add_term_info.vars[var_name],
						den_var_exp
					);
				} else {
					add_term_info.vars[var_name] = den_var_exp;
				}
			});
			// append serial_non_poly
			add_term_info.serial_non_poly = num_add_term_info.serial_non_poly;
			if (den_add_term_info.serial_non_poly.length) {
				if (add_term_info.serial_non_poly.length) {
					add_term_info.serial_non_poly += '/';
				} else {
					add_term_info.serial_non_poly = '1/';
				}
				add_term_info.serial_non_poly += den_add_term_info.serial_non_poly;
			}
			// merge has_rationals
			add_term_info.has_rationals = num_add_term_info.has_rationals || den_add_term_info.has_rationals;

			current_node.characterization = {
				base_add: current_node.serialize_to_text(),
				// fractions should be treated as a "power"
				power_type: "power",
				power_value: "1",
				power_base: current_node.serialize_to_text(),
				add_term_info: add_term_info,
			};
		} else if (current_node.value == 'pow') {
			// TODO: \pow{} uses the same logic as EXPONENT. The duplicated code could be pulled into a helper function,
			//  but I'd like to instead have QETermExponent inherit from QETermFunctionPow, but calling code in
			//  the solvers would first need to be updated to check instance type instead of type field value

			// base and exponent of a power are already characterized. No need to do so further here
			const base = current_node.children[0];

			// for power_base, check if children[0] is BRACKETS and positive sign, and if so, use its child
			let power_base;
			if (current_node.children[0].type == "BRACKETS" && current_node.children[0].sign != -1) {
				power_base = current_node.children[0].characterization.power_base;
			} else {
				power_base = current_node.children[0].serialize_to_text();
			}

			current_node.characterization = {
				base_mult: base.serialize_to_text(),
				base_add: current_node.serialize_to_text(),
				power_type: "power",
				power_value: current_node.children[1].serialize_to_text(),
				power_base: power_base,
				add_term_info: { has_rationals: false, vars: {}, serial_non_poly: "" },
			};

			// set add_term_info, using power add_term_info
			if (current_node.children[1].type == "RATIONAL" &&
				/^-?(0|[1-9]\d*)$/.test(current_node.children[1].value)) { // integer test
				const base_add_term_info = current_node.children[0].characterization.add_term_info;
				if (Object.keys(base_add_term_info.vars).length) {
					// having variables is the primary sorting criterium, so we can ignore serial_non_poly and has_rationals
					Object.keys(base_add_term_info.vars).forEach(function (var_name) {
						// combine exponents
						current_node.characterization.add_term_info.vars[var_name] = base_add_term_info.vars[var_name] * parseInt(current_node.children[1].value);
					});
				} else if (base_add_term_info.serial_non_poly.length) {
					// non-poly base. Serialize the whole thing
					current_node.characterization.add_term_info.serial_non_poly = current_node.serialize_to_text();
				} else {
					// TODO: should be rational - confirm that!
					current_node.characterization.add_term_info.has_rationals = true;
				}
			} else {
				// non-integer exponent. Serialize the whole thing
				current_node.characterization.add_term_info.serial_non_poly = current_node.serialize_to_text();
			}
		} else {
			current_node.characterization = {
				base_mult: current_node.serialize_to_text(),
				base_add: current_node.serialize_to_text(),
				power_type: "power",
				power_value: "1",
				power_base: current_node.serialize_to_text(),
				add_term_info: { has_rationals: false, vars: {}, serial_non_poly: current_node.serialize_to_text() },
			};
		}
	}
}

export class QETermBrackets extends QETerm {
	constructor(token: Token) {
		super(token);

		this.type = "BRACKETS";

		if (token.type == "OPENING_BRACKET") {
			this.open_state = true;
		}
	}

	evaluate_to_float(): number {
		return (this.sign < 0 ? -1 : 1) * this.children[0].evaluate_to_float();
	}

	// TODO: apply this.sign to returned Q
	evaluate_to_Q(): QEQ | number {
		return this.children[0].evaluate_to_Q();
	}

	serialize_to_text(options: SerializeToTextOptions = {}): string {
		// check options.hide_implied_brackets
		if (options.hide_implied_brackets) {
			let serial = "";
			if (!this.tokens[0].implied)
				serial += "(";
			serial += this.children[0].serialize_to_text(options);

			// can't assume term has closing token, since it may have been instantiated by a SolverStep
			if (!this.tokens[1])
				serial += ")";
			else if (!this.tokens[1].implied)
				serial += ")";

			return (this.sign < 0 ? '-' : '') + serial;
		}
		return (this.sign < 0 ? '-' : '') + "(" + this.children[0].serialize_to_text(options) + ")";
	}

	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		let ml = '';

		if (this.isCursorAtStart(options))
			ml += cursor;

		// use different token for implied brackets, so we can find in the resulting markup and replace with a semi-transparent bracket
		if (options.show_implied_faded && this.tokens[0].implied)
			ml += '\\left\\lang ';
		else
			ml += '\\left(';

		ml += this.children[0].serialize_to_latex(options);

		// use different token for implied brackets, so we can find in the resulting markup and replace with a semi-transparent bracket
		// can't assume term has closing token, since it may have been instantiated by a SolverStep
		if (options.show_implied_faded && this.tokens[1] && this.tokens[1].implied)
			ml += '\\right\\rang ';
		else
			ml += '\\right)';

		if (this.isCursorAtEnd(options))
			ml += cursor;

		return renderTermSign(this) + addHighlights(this, ml);
	}

	characterizeNode(): void {
		this.characterizeNodeChildren();

		this.characterization = {
			base_mult: this.children[0].serialize_to_text(),
			base_add: this.serialize_to_text(),
			power_type: "power",
			power_value: "1",
			power_base: this.children[0].serialize_to_text(),
			add_term_info: { has_rationals: false, vars: {}, serial_non_poly: this.serialize_to_text() },
		};
	}
}

export class QETermExponent extends QETerm {
	evaluate_to_float(): number {
		const base: number = this.children[0].evaluate_to_float();
		const exp: number = this.children[1].evaluate_to_float();

		if (!isNaN(base) && !isNaN(exp)) {
			return (this.sign < 0 ? -1 : 1) * Math.pow(base, exp);
		}
		return NaN;
	}

	serialize_to_text(options: SerializeToTextOptions = {}): string {
		return (this.sign < 0 ? '-' : '') + this.children.map(function (x) {
			return x.serialize_to_text(options);
		}).join(this.value);
	}

	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		let ml = '{' + this.children[0].serialize_to_latex(options) + '}';
		if (this.isCursorAtStart(options))
			ml += cursor;
		ml += '^{';
		ml += this.children[1].serialize_to_latex(options);
		ml += '}';
		if (this.isCursorAtEnd(options))
			ml += cursor;

		return renderTermSign(this) + addHighlights(this, ml);
	}

	characterizeNode(): void {
		// TODO: \pow{} uses the same logic. The duplicated code could be pulled into a helper function,
		//  but I'd like to instead have QETermExponent inherit from QETermFunctionPow, but calling code in
		//  the solvers would first need to be updated to check instance type instead of type field value
		const current_node = this;

		current_node.characterizeNodeChildren();

		// base and exponent of a power are already characterized. No need to do so further here
		const base = current_node.children[0];

		// for power_base, check if children[0] is BRACKETS and positive sign, and if so, use its child
		let power_base;
		if (current_node.children[0].type == "BRACKETS" && current_node.children[0].sign != -1) {
			power_base = current_node.children[0].characterization.power_base;
		} else {
			power_base = current_node.children[0].serialize_to_text();
		}

		current_node.characterization = {
			base_mult: base.serialize_to_text(),
			base_add: current_node.serialize_to_text(),
			power_type: "power",
			power_value: current_node.children[1].serialize_to_text(),
			power_base: power_base,
			add_term_info: { has_rationals: false, vars: {}, serial_non_poly: "" },
		};

		// set add_term_info, using power add_term_info
		if (current_node.children[1].type == "RATIONAL" &&
			/^-?(0|[1-9]\d*)$/.test(current_node.children[1].value)) { // integer test
			const base_add_term_info = current_node.children[0].characterization.add_term_info;
			if (Object.keys(base_add_term_info.vars).length) {
				// having variables is the primary sorting criterium, so we can ignore serial_non_poly and has_rationals
				Object.keys(base_add_term_info.vars).forEach(function (var_name) {
					// combine exponents
					current_node.characterization.add_term_info.vars[var_name] = base_add_term_info.vars[var_name] * parseInt(current_node.children[1].value);
				});
			} else if (base_add_term_info.serial_non_poly.length) {
				// non-poly base. Serialize the whole thing
				current_node.characterization.add_term_info.serial_non_poly = current_node.serialize_to_text();
			} else {
				// TODO: should be rational - confirm that!
				current_node.characterization.add_term_info.has_rationals = true;
			}
		} else {
			// non-integer exponent. Serialize the whole thing
			current_node.characterization.add_term_info.serial_non_poly = current_node.serialize_to_text();
		}
	}
}

export class QETermFactorial extends QETerm {
//	evaluate_to_float(): number {
//	}

	serialize_to_text(options: SerializeToTextOptions = {}): string {
		return (this.sign < 0 ? '-' : '') + this.children[0].serialize_to_text(options) + this.value;
	}

	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		let ml = this.children[0].serialize_to_latex(options);

		if (this.isCursorAtStart(options))
			ml += cursor;
		ml += this.value;
		if (this.isCursorAtEnd(options))
			ml += cursor;

		return renderTermSign(this) + addHighlights(this, ml);
	}

	characterizeNode(): void {
		this.characterization = {
			base_mult: this.serialize_to_text(),
			base_add: this.serialize_to_text(),
			power_type: "power",
			power_value: "1",
			power_base: this.serialize_to_text(),
			add_term_info: { has_rationals: false, vars: {}, serial_non_poly: this.serialize_to_text() },
		};
	}
}

////////

export class QETermChain extends QETerm {
	constructor(token: Token) {
		super(token);

		this.precedence = token.precedence;
	}

	// iterate over child terms and operator and build running total float
	evaluate_to_float(): number {
		let running_total: number = this.children[0].evaluate_to_float();
		if (isNaN(running_total)) {
			return NaN;
		}
		for (let i = 1; i < this.children.length; i += 2) {
			const op = this.children[i];
			const val: number = this.children[i + 1].evaluate_to_float();
			if (isNaN(val)) {
				return NaN;
			}

			if (op.type == "ADD") {
				running_total += val;
			} else if (op.type == "SUBTRACT") {
				running_total -= val;
			} else if (op.type == "MULTIPLY") {
				running_total *= val;
			} else if (op.type == "DIVIDE") {
				if (val == 0) {
					return NaN;
				}
				running_total /= val;
			} else {
				console.log("Unhandled operator in evaluate_to_float: ", op);
				return NaN;
			}
		}
		return (this.sign < 0 ? -1 : 1) * running_total;
	}

	// TODO: iterate over child terms and operator and build running total Q
//	evaluate_to_Q(): QEQ | number {
//	}

	serialize_to_text(options: SerializeToTextOptions = {}): string {
		let txt = this.children.map(function (x) {
			return x.serialize_to_text(options);
		}).join("");

		// prepend sign to CHAIN after any required brackets are added
		txt = (this.sign < 0 ? '-' : '') + txt;

		// check if sign < 0 and we're in an EXPONENT base
		if (this.sign < 0 && this.parent && this.parent.type == "EXPONENT" && this.parent.children.indexOf(this) == 0) {
			// wrap AGAIN
			txt = "(" + txt + ")";
		}

		return txt;
	}

	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		let ml = this.children.map(function (x) { return x.serialize_to_latex(options); }).join('');

		ml = renderTermSign(this) + ml;

		// TODO: move this check into EXPONENT / \pow
		// check if sign < 0 and we're in an EXPONENT base
		if (this.sign < 0 && this.parent.type == "EXPONENT" && this.parent.children.indexOf(this) == 0) {
			// wrap AGAIN
			ml = "\\left(" + ml + "\\right)";
		}

		// avoid double-styling, or brackets
		return addHighlights(this, ml);
	}
}

export class QETermChainAdd extends QETermChain {
	// NOTE: the chain may contain SUBTRACT operators (which are "additive")
	isAddChain(): boolean { return true; }

	characterizeNode(): void {
		this.characterizeNodeChildren();

		// TODO: use getIntersectionOfCharacterizedTermListFactors from QE.Solver for common term factors
		// NOTE: could aggregate various info about children
		const serialized = this.serialize_to_text();
		this.characterization = {
			base_mult: serialized,
			power_type: "power",
			power_value: "1",
			power_base: this.serialize_to_text(),
			add_term_info: { has_rationals: false, vars: {}, serial_non_poly: this.serialize_to_text(), },
		};

		// NOTE: no base_add, since we never add full add chain directly
	}
}

export class QETermChainMult extends QETermChain {
	// NOTE: the chain may contain DIVIDE operators (which are "multiplicative")
	isMultiplyChain(): boolean { return true; }

	characterizeNode(): void {
		const current_node = this;

		current_node.characterizeNodeChildren();

		// NOTE: should generate multiplicative-term-specific info, such as sign, coeffs, base, and sorted serialization
		current_node.characterization = {
			factors: { num: {}, den: {} },
			coeffs: [],
			base_add: '',
			power_type: "power",
			power_value: "1",
			power_base: current_node.serialize_to_text(),
			add_term_info: { has_rationals: false, vars: {}, serial_non_poly: "" },
		};

		// TODO: by the time we're characterizing a tree, we should have no DIVIDE operators
		// build coeff value-or-expression and base_add value-or-expression
		let op = "MULTIPLY";
		for (let i = 0; i < current_node.children.length; i += 2) {
			var child = current_node.children[i];
			if (i > 0) {
				op = current_node.children[i - 1].type;
			}

			if (child.type == "RATIONAL") {
				current_node.characterization.coeffs.push(child);
			} else {
				current_node.characterization.base_add += child.characterization.base_add;
			}

			// combine add_term_info of children
			var child_add_term_info = child.characterization.add_term_info;
			Object.keys(child_add_term_info.vars).forEach(function (var_name) {
				// take max exponent
				current_node.characterization.add_term_info.vars[var_name] = Math.max(
					current_node.characterization.add_term_info.vars[var_name] || 0,
					child_add_term_info.vars[var_name]
				);
			});
			// append serial_non_poly
			if (child_add_term_info.serial_non_poly.length) {
				if (current_node.characterization.add_term_info.serial_non_poly.length) {
					current_node.characterization.add_term_info.serial_non_poly += '*';
				}
				current_node.characterization.add_term_info.serial_non_poly += child_add_term_info.serial_non_poly;
			}
			// update has_rationals
			current_node.characterization.add_term_info.has_rationals ||= child_add_term_info.has_rationals;

			// TODO: use getSumOfCharacterizedTermListFactors from QE.Solver
			// for each component of child characterization, either add to chain or update exponent count for existing
			['num', 'den'].forEach(function (grouping) {
				if (child.characterization.factors && child.characterization.factors[grouping]) {
					for (const factor_key in child.characterization.factors[grouping]) {
						const factor_exponent = child.characterization.factors[grouping][factor_key];

						// TODO: check if factor_exponent positive, and apply to flipped num/den instead
						if (!current_node.characterization.factors[grouping][factor_key]) {
							current_node.characterization.factors[grouping][factor_key] = 0;
						}

						current_node.characterization.factors[grouping][factor_key] += factor_exponent;
					}
				}
			});
		}

		// NOTE: no base_mult, since we never multiply by full mult chain directly
	}
}

export class QETermChainCmp extends QETermChain {
	isComparatorChain(): boolean { return true; }

	serialize_to_text(options: SerializeToTextOptions = {}): string {
		const txt = this.children.map(function (x) {
			return x.serialize_to_text(options);
		}).join("");
		return txt;
	}

	characterizeNode(): void {
		this.characterizeNodeChildren();

		this.characterization = {
			serialized: this.serialize_to_text(),
		};
	}
}

////////

export class QETermSign extends QETerm {
	serialize_to_text(options: SerializeToTextOptions = {}): string {
		return this.value + this.children[0].serialize_to_text(options);
	}

	serialize_to_latex(options: SerializeToLatexOptions = {}): string {
		let ml = '';

		// MINUS and PLUS don't apply their styling to their child
		if (this.isCursorAtStart(options))
			ml += cursor;
		ml += this.value;
		if (this.isCursorAtEnd(options))
			ml += cursor;
		ml = addHighlights(this, ml);
		ml += this.children[0].serialize_to_latex(options);

		return ml;
	}

	characterizeNode(): void {
		this.characterizeNodeChildren();

		// MINUS and PLUS take on child characterization
		this.characterization = Object.assign({}, this.children[0].characterization);
	}
}

export class QETermSignMinus extends QETermSign {
	evaluate_to_float(): number {
		return -this.children[0].evaluate_to_float();
	}

	evaluate_to_Q(): QEQ | number {
		const q = this.children[0].evaluate_to_Q();
		if (typeof q === "number") return NaN;
		return q.opposite();
	}
}

export class QETermSignPlus extends QETermSign {
	evaluate_to_float(): number {
		return this.children[0].evaluate_to_float();
	}

	evaluate_to_Q(): QEQ | number {
		return this.children[0].evaluate_to_Q();
	}
}

////////

function createQETerm(token: Token): QETerm {
	// TODO: instantiate appropriate subclassed term based on type and value
	// TODO: validate type
	let term;
	switch (token.type){
		case "ROOT":
			term = new QETermRoot(token);
			break;
		case "EMPTY":
			term = new QETermEmpty(token);
			break;
		case "INPUT":
			term = new QETermInput(token);
			break;
		case "PARAMETER":
			term = new QETermParameter(token);
			break;
		case "CONSTANT":
			term = new QETermConstant(token);
			break;
		case "RATIONAL":
			term = new QETermRational(token);
			break;
		case "VARIABLE":
			term = new QETermVariable(token);
			break;

		case "ADD":
			term = new QETermAdd(token);
			break;
		case "SUBTRACT":
			term = new QETermSubtract(token);
			break;
		case "MULTIPLY":
			term = new QETermMultiply(token);
			break;
		case "DIVIDE":
			term = new QETermDivide(token);
			break;

		case "EQUAL":
			term = new QETermEqual(token);
			break;
		case "LESS":
			term = new QETermLess(token);
			break;
		case "LESS_OR_EQUAL":
			term = new QETermLessOrEqual(token);
			break;
		case "GREATER":
			term = new QETermGreater(token);
			break;
		case "GREATER_OR_EQUAL":
			term = new QETermGreaterOrEqual(token);
			break;

		case "FUNCTION_OPEN":
			term = new QETermFunction(token);
			break;
		case "FUNCTION":
			term = new QETermFunction(token);
			break;
		case "OPENING_BRACKET":
			term = new QETermBrackets(token);
			break;
		case "BRACKETS":
			term = new QETermBrackets(token);
			break;

		case "CHAIN":
			if (token.precedence === findGrammar('ADD').precedence) {
				term = new QETermChainAdd(token);
			} else if (token.precedence ===findGrammar('MULTIPLY').precedence) {
				term = new QETermChainMult(token);
			} else if (token.precedence === findGrammar("EQUAL").precedence) {
				term = new QETermChainCmp(token);
			} else {
				console.log("ERROR: CHAIN with unhandled precedence: ", token);
			}
			break;
		case "CHAIN_ADD":
			term = new QETermChainAdd(token);
			break;
		case "CHAIN_MULT":
			term = new QETermChainMult(token);
			break;
		case "CHAIN_CMP":
			term = new QETermChainCmp(token);
			break;

		case "MINUS":
			term = new QETermSignMinus(token);
			break;
		case "PLUS":
			term = new QETermSignPlus(token);
			break;
		case "EXPONENT":
			term = new QETermExponent(token);
			break;
		case "FACTORIAL":
			term = new QETermFactorial(token);
			break;
		default:
	}

	if (!term) {
			console.log('unhandled token type: ', token);
		term = new QETerm(token);
	}
	return term;
}

// getIntegerDecimalDigitLengths - helper to get the integer and decimal places for each child RATIONAL
function getIntegerDecimalDigitLengths(node: QETerm): [number, number][] {
	const integer_decimal_length_pairs: [number, number][] = node.findAllChildren('type', 'RATIONAL').map(x => {
		const parts = x.Q.serialize_to_decimal_parts();
		const integer = parts[0];
		let decimal = parts[1];

		// handle trailing zeros in decimal
		if (x.value.match(/\./) && x.value.match(/0$/)) {
			decimal += x.value.match(/0*$/)[0]; // re-attach stripped zeros
		}

		const integer_length = integer.length;
		let decimal_length = decimal.length;
		if (decimal_length || x.value.match(/\./)) { // decimal or trailing "."
			decimal_length++; // include column for "."
		}

		return [integer_length, decimal_length];
	});
	return integer_decimal_length_pairs;
}

function mixed_frac_katex(num: string, den: string): string {
	const whole: number = Math.floor(parseInt(num) / parseInt(den));
	const remainder: number = Math.abs(parseInt(num) % parseInt(den));

	// if numerator begins with "-", pull it out in front of the fraction
	if (num.slice(0, 1) == '-') {
		// handle non-zero whole number portion
		if (whole) {
			if (remainder) {
				return whole.toString() + '\\frac{' + remainder.toString() + '}{' + den + '}';
			} else {
				return whole.toString();
			}
		} else if (remainder) {
			return '-\\frac{' + num.slice(1) + '}{' + den + '}';
		} else {
			return '0';
		}
	} else {
		// handle non-zero whole number portion
		if (whole) {
			if (remainder) {
				return whole.toString() + '\\frac{' + remainder.toString() + '}{' + den + '}';
			} else {
				return whole.toString();
			}
		} else if (remainder) {
			return '\\frac{' + num + '}{' + den + '}';
		} else {
			return '0';
		}
	}
}

// wrap styles around markup based on node css classes
function addHighlights(node: QETerm, markup: string): string {
	const classes = node.getClasses() || [];
	for (let i = 0; i < classes.length; i++) {
		if (classes[i] == 'highlight_input')
			markup = '\\textcolor{#ff8c00}{' + markup + '}';
		else if (classes[i] == 'highlight_output')
			markup = '\\textcolor{#00b000}{' + markup + '}';
		else if (classes[i] == 'strikethrough_input')
			markup = '\\textcolor{#f00}{\\cancel{\\textcolor{#000}{' + markup + '}}}';
		else if (classes[i] == 'implied')
			markup = '\\textcolor{#c0c0c0}{' + markup + '}';
	}
	return markup;
}

function renderTermSign(node: QETerm): string {
	// helper function to display the sign of a term, if any, and any associated styling
	if (node.sign < 0 && node.value !== "0") {
		let markup = '-';

		// includes sign-specific classes
		const classes = node.getClasses() || [];
		for (let i = 0; i < classes.length; i++) {
			if (classes[i] == 'highlight_input')
				markup = '\\textcolor{#ff8c00}{' + markup + '}';
			else if (classes[i] == 'highlight_input_sign')
				markup = '\\textcolor{#ff8c00}{' + markup + '}';
			else if (classes[i] == 'highlight_output')
				markup = '\\textcolor{#00b000}{' + markup + '}';
			else if (classes[i] == 'highlight_output_sign')
				markup = '\\textcolor{#00b000}{' + markup + '}';
			else if (classes[i] == 'strikethrough_input')
				markup = '\\textcolor{#f00}{\\cancel{\\textcolor{#000}{' + markup + '}}}';
			else if (classes[i] == 'strikethrough_input_sign')
				markup = '\\textcolor{#f00}{\\cancel{\\textcolor{#000}{' + markup + '}}}';
			else if (classes[i] == 'implied')
				markup = '\\textcolor{#c0c0c0}{' + markup + '}';
		}
		return markup;
	}
	return '';
}

// columnAlignDigits - helper to align and pad columns in vertically stacked equation format
function columnAlignDigits(value_str: string, Q: QEQ, options: SerializeToLatexOptions): string {
	const parts = Q.serialize_to_decimal_parts();
	let integer = parts[0];
	let decimal = parts[1];
	const repeating = parts[2];
	const ellipsis = parts[3];

	// handle "empty" integer portion
	if (integer == '0' && value_str.match(/^\./)) {
		integer = '';
	}

	// handle trailing zeros in decimal
	if (value_str.match(/\./) && value_str.match(/0$/)) {
		decimal += value_str.match(/0*$/)[0]; // re-attach stripped zeros
	}

	// integer portion
	let aligned_chars = integer.split('');
	for (let char_idx = aligned_chars.length - 1; char_idx >= 3; char_idx -= 3) {
		// append grouping separator
		aligned_chars[char_idx - 3] += options.digit_group_char;
	}

	// decimal portion
	if (decimal || repeating
		|| value_str.match(/\./)) { // trailing "."
		aligned_chars.push('.');
	}
	aligned_chars = aligned_chars.concat(decimal.split(''));

	// place column-breaks before each digit / decimal-point
	let aligned_val = aligned_chars.map(x => {return '&'+x;}).join('');

	const integer_length = integer.length;
	let decimal_length = decimal.length;
	if (decimal_length || value_str.match(/\./)) { // decimal or trailing "."
		decimal_length++; // include column for "."
	}

	// use options.max_child_integer_digits to left pad grid columns
	for (let i = options.max_child_integer_digits - integer.length; i > 0; i--) {
		aligned_val = '&'+ aligned_val;
	}

	// use options.max_child_decimal_digits to right pad grid columns
	for (let i = options.max_child_decimal_digits - decimal_length; i > 0; i--) {
		aligned_val = aligned_val +'&';
	}

	return aligned_val;
}

