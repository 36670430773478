import { QETerm, Token } from "./QETerm";
import { QEAssert } from './QEAssert.js';

const ArityTypes = ["VALUE", "POSTFIX", "BINARY", "PREFIX"] as const;
export type ArityType = typeof ArityTypes[number];

interface Grammar {
    precedence: number;
    arity?: ArityType;
    value?: string;
}

const GrammarTypes = ["ROOT", "EMPTY",  "INPUT", "PARAMETER", "CONSTANT",  "RATIONAL",  "VARIABLE",

    "ADD", "SUBTRACT",  "MULTIPLY", "DIVIDE",

    "EQUAL", "LESS", "LESS_OR_EQUAL", "GREATER", "GREATER_OR_EQUAL",

    "FUNCTION_OPEN", "FUNCTION_CLOSE", "FUNCTION", "OPENING_BRACKET", "CLOSING_BRACKET", "BRACKETS", 

    "CHAIN", "CHAIN_ADD", "CHAIN_MULT", "CHAIN_CMP",

    "MINUS", "PLUS", "EXPONENT", "FACTORIAL", "PERCENT", "COMMA", "END",

    "DISCARD", ""] as const;

export type GrammarType = typeof GrammarTypes[number];

export const findGrammarType = (value: string): GrammarType | undefined => {
    return GrammarTypes.find(type => type === value);
}

export function findGrammar(type: GrammarType | undefined ): Grammar | null {
    switch (type) {
        case "CONSTANT":
        case "INPUT": 
        case "PARAMETER":
        case "RATIONAL":
        case "VARIABLE":
        {
            return { precedence: 0, arity: "VALUE" };
        }
        case "EMPTY":
            return { precedence: 0, arity: "VALUE", value: "??" };
        case "FUNCTION":
            return { precedence: 1, arity: "PREFIX" };
        case "FACTORIAL":
            return { precedence: 2, arity: "POSTFIX", value: "!" };
        case "PERCENT":
            return { precedence: 2, arity: "POSTFIX", value: "%" };
        case "EXPONENT":
            return { precedence: 3, arity: "BINARY", value: "^" };
        case "MINUS":
            return { precedence: 4, arity: "PREFIX", value: "-" };
        case "PLUS":
            return { precedence: 4, arity: "PREFIX", value: "+" };
        case "MULTIPLY":
            return { precedence: 10, arity: "BINARY", value: "*" };
        case "DIVIDE":
            return { precedence: 10, arity: "BINARY", value: "/" };
        case "SUBTRACT":
            return { precedence: 11, arity: "BINARY", value: "-" };
        case "ADD":
            return { precedence: 11, arity: "BINARY", value: "+" };
        case "BRACKETS":
        case "OPENING_BRACKET":
            return { precedence: 20, arity: "PREFIX", value: "(" };
        case "CLOSING_BRACKET":
            return { precedence: 20, arity: "POSTFIX", value: ")" };
        case "LESS_OR_EQUAL":
            return { precedence: 30, arity: "BINARY", value: "<=" };
        case "LESS":
            return { precedence: 30, arity: "BINARY", value: "<" };
        case "GREATER_OR_EQUAL":
            return { precedence: 30, arity: "BINARY", value: ">=" };
        case "GREATER":
            return { precedence: 30, arity: "BINARY", value: ">" };
        case "EQUAL":
            return { precedence: 30, arity: "BINARY", value: "=" };

        case "FUNCTION_OPEN":
            return { precedence: 35, arity: "PREFIX" };
        case "FUNCTION_CLOSE":
            return { precedence: 35, arity: "POSTFIX" };
        case "COMMA":
            return { precedence: 34, arity: "BINARY", value: "," };

        case "ROOT":
            return { precedence: 42, arity: "PREFIX" };
        case "END":
            return { precedence: 42, arity: "POSTFIX" };

        case "CHAIN":
            return { precedence: -1 }; // precedence must be set upon instantiation
        case "CHAIN_ADD":
            return { precedence: 11 };
        case "CHAIN_MULT":
            return { precedence: 10 };
        case "CHAIN_CMP":
            return { precedence: 30 };
        default:
            return null;
    }
}

interface Subgrammar {
    type: GrammarType;
    regexp: string;
    match_type?: GrammarType;
    match_value?: string;
    num_args?: number;
    separable?: boolean;
}

// Defines the Lexer's sub-grammar
//  If is an ORDERED array of possible token types with matching regexp sources
// The lexer parses its input string and tries to match tokens in this order
//
//  The order in which those regexps are combined matters. eg: if we were to try
// to match INTEGER before DECIMAL, we would match the whole part of a floating
// point and we wouldn't be able to parrse the leftover ('12.345' --> '.345')
//  Because the order is so important, it is defined as an array to control how
// separate regexps are combined into one big 'QEParser.regexp'
const Subgrammars: Subgrammar[] = [
    { type: "DISCARD", regexp: /[ \t]+/.source },
    { type: "PARAMETER", regexp: /\[\$[a-zA-Z0-9_]+\]/.source },
    { type: "INPUT", regexp: /\[\?[a-zA-Z0-9_]+\]/.source },
    { type: "EMPTY", regexp: /\[\?]{2}/.source },
    // multi-argument functions
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?mfrac[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 3 }, // mixed number
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?frac[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 2 },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?list[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}" },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?set[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}" },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?min[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}" },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?max[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}" },
	{ type: "FUNCTION_OPEN", regexp: /(?:\\)?time_hm[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 2 },
	{ type: "FUNCTION_OPEN", regexp: /(?:\\)?time_hms[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 3 },
    // single-argument functions
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?abs[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 1 },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?ceil[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 1 },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?floor[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 1 },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?nroot[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 1 },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?pow[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 1 },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?round[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 2 },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?random[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 1 },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?sign[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 1 },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?sqrt[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 1 },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?sin[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 1 },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?cos[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 1 },
    { type: "FUNCTION_OPEN", regexp: /(?:\\)?tan[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}", num_args: 1 },
    // single-argument functions
    { type: "FUNCTION", regexp: /(?:\\)?sin/.source },
    { type: "FUNCTION", regexp: /(?:\\)?cos/.source },
    { type: "FUNCTION", regexp: /(?:\\)?tan/.source },
    // function catch-all
    { type: "FUNCTION_OPEN", regexp: /[a-zA-Z]+[\{]/.source, match_type: "FUNCTION_CLOSE", match_value: "}" },
    { type: "COMMA", regexp: /[,]/.source },
    { type: "FUNCTION_CLOSE", regexp: /[\}]/.source, match_type: "" }, // no match_type, since FUNCTION_CLOSE can't determine opening token
    { type: "OPENING_BRACKET", regexp: /[\(]/.source, match_type: "CLOSING_BRACKET", match_value: ")" },
    { type: "CLOSING_BRACKET", regexp: /[\)]/.source, match_type: "OPENING_BRACKET", match_value: "(" },
    { type: "PLUS", regexp: /[+]/.source },
    { type: "MINUS", regexp: /[-]/.source },
    { type: "MINUS", regexp: /[\u2212]/.source }, // windows minus
    { type: "MINUS", regexp: /[\u2013]/.source }, // en dash
    { type: "MULTIPLY", regexp: /[*]/.source },
    { type: "DIVIDE", regexp: /[/]/.source },
    { type: "EXPONENT", regexp: /[\^]/.source },
    { type: "FACTORIAL", regexp: /[\!]/.source },
    { type: "LESS_OR_EQUAL", regexp: /<=/.source },
    { type: "LESS", regexp: /</.source },
    { type: "GREATER_OR_EQUAL", regexp: />=/.source },
    { type: "GREATER", regexp: />/.source },
    { type: "EQUAL", regexp: /=/.source },
    { type: "PERCENT", regexp: /[%]/.source },
    { type: "RATIONAL", regexp: /[0-9]*[.][0-9]+[eE][+-]?[0-9]+%/.source, separable: true },
    { type: "RATIONAL", regexp: /[0-9]*[.][0-9]+[eE][+-]?[0-9]+/.source, separable: true },
    { type: "RATIONAL", regexp: /[0-9]*[.][0-9]*\\repeat[\{][0-9]+[\}]%/.source, separable: true },
    { type: "RATIONAL", regexp: /[0-9]*[.][0-9]*\\repeat[\{][0-9]+[\}]/.source, separable: true },
    { type: "RATIONAL", regexp: /[0-9]*[.][0-9]+[.][.][.]%/.source, separable: true },
    { type: "RATIONAL", regexp: /[0-9]*[.][0-9]+[.][.][.]/.source, separable: true },
    { type: "RATIONAL", regexp: /[0-9]*[.][0-9]*%/.source, separable: true },
    { type: "RATIONAL", regexp: /[0-9]*[.][0-9]*/.source, separable: true },
    { type: "RATIONAL", regexp: /[0-9]+%/.source, separable: true },
    { type: "RATIONAL", regexp: /[0-9]+/.source, separable: true },
    { type: "CONSTANT", regexp: /\\pi/.source },
    { type: "VARIABLE", regexp: /[a-zA-Z]/.source },
];

const Regexp = new RegExp( "("+Subgrammars.map(function(x){return x.regexp}).join(")|(")+")" );

interface GrammarToken extends Token {
    match_type?: GrammarType;
    match_value?: string;
    num_args?: number;
}

export function tokenize_string(input: string): GrammarToken[] | undefined {
    let str = input;
    let offset = 0;
    const output: GrammarToken[] = [];
    let match;

    while ((match = Regexp.exec(str)) !== null) {
        if (match.index != 0) {
            break; // abort as soon as we get something unexpected
        }
        // Find index of the matching regexp within QEParser.subgrammar array
        // (When a group didn't capture, it can be either "" or undefined)
        //var def_index = match.findIndex( (x,i) => (i>0) && (x) ) - 1;
        const def_index =
            match.findIndex(function (x, i) {
                return i > 0 && x;
            }) - 1;
        const type = Subgrammars[def_index].type;
        if (type != "DISCARD") {
            const token: GrammarToken = { type: type, value: match[0], offset: offset };
            const subgrammar = Subgrammars[def_index];
            if (typeof subgrammar.match_type != undefined ) token.match_type = subgrammar.match_type;
            if (typeof subgrammar.match_value != undefined ) token.match_value = subgrammar.match_value;
            if (typeof subgrammar.num_args != undefined ) token.num_args = subgrammar.num_args;
            if (subgrammar.separable) token.separable = subgrammar.separable;
            output.push(token);
        }
        str = str.slice(match[0].length);
        offset += match[0].length;
    }

    if (str.length != 0) {
        const index = input.length - str.length;
        const error_msg = `ERROR:\tLexer couldn't tokenize ${input}, \n, \tFailed at index ${index} with leftover ${str}\n`;
        console.log(error_msg);
        return undefined;
    }

    //	console.log( output.map(function(x){return x.type+": "+x.value}).join("\n") );
    //	console.log( "["+output.map(function(x){return x.type+": "+x.value}).join(", ")+"]" );
    return output;
}

export function check_tokens_syntax(tokens: GrammarToken[], original: string): GrammarToken[] {
	if (!tokens) {
		console.log('Error: empty token array');
		return [];
	}

    let awaiting_value = true;
    let array = tokens.slice(0);

    let offset = 0;

    for (let index = 0; index < array.length; index++) {
        const token = array[index];
        let grammarType = findGrammarType(token.type);
        const grammar = findGrammar(grammarType);

        // ignore unknown (or DISCARD) token
        if (grammarType == undefined) {
            array.splice(index, 1);
        }

        if (index > 0) {
            const last_token = array[index - 1];
            //			console.log( last_token );
            offset = last_token.offset + last_token.value.length;
        }

        if (awaiting_value) {
            const arity = grammar.arity;
            if (grammar !== null && arity !== null) {
                if (arity == "VALUE") {
                    awaiting_value = false;
                    continue;
                } else if (arity == "PREFIX") {
                    continue;
                }
            }
            // insert "EMPTY" placeholder for missing value
            array.splice(index, 0, { type: "EMPTY", value: "", offset: offset });
            awaiting_value = false;
            continue;
        }

        // Convert MINUS to SUBTRACT
        if (grammarType == "MINUS") {
            grammarType = "SUBTRACT";
            token.type = "SUBTRACT";
        }
        // Convert PLUS to ADD
        if (grammarType == "PLUS") {
            grammarType = "ADD";
            token.type = "ADD";
        }

        // awaiting_value == false
        if (grammarType != "CLOSING_BRACKET" && grammarType != "FUNCTION_CLOSE") {
            // NOTE: could change to exclude POSTFIX tokens with match_type != 'undefined'
            const arity = findGrammar(grammarType).arity;
            if (arity == "VALUE" || arity == "PREFIX") {
                array.splice(index, 0, { type: "MULTIPLY", value: "", offset: offset, implied: true });
                awaiting_value = true;
            } else if (arity == "BINARY") {
                awaiting_value = true;
            }
        }
    }

    // update offset to end of last array item
    if (array.length > 0) {
        const last_token: GrammarToken = array[array.length - 1];
        offset = last_token.offset + last_token.value.length;
    }

    if (awaiting_value) {
        // insert "EMPTY" placeholder for missing value
        array.push({ type: "EMPTY", value: "", offset: offset });
        awaiting_value = false;
    }

    // We might still have unmatched opening/closing tokens.
    // We add implied matching tokens to close them.
    const root_token: GrammarToken = { type: "ROOT", value: "", offset: 0 };
    array.unshift(root_token);
    const context_stack = [root_token];
    // var token, token_grammar, context_token, context_token_grammar;

    // push END token onto array. Let's us close all contexts at end without extra logic, apart from stripping END from cpy
    array.push({ type: "END", value: "", offset: array[array.length - 1].offset + array[array.length - 1].value.length });

    for (let i = 0; i < array.length; i++) {
        const token = array[i];
        const grammarType = findGrammarType(token.type);
        const token_grammar = findGrammar(grammarType);
        if (grammarType == undefined || token_grammar == null || token_grammar.arity == null) {
            continue;
        }
        if (token_grammar.arity == "PREFIX" && token.match_type) {
            // open context
            context_stack.push(token);
        } else if (token_grammar.arity == "BINARY") {
            // close all open contexts of equal or lower precedence
            let context_token_grammar: Grammar;
            let context_token: GrammarToken;
            do {
                context_token = context_stack[context_stack.length - 1];
                context_token_grammar = findGrammar(findGrammarType(context_token.type));
                if (context_token_grammar != null && context_token_grammar.precedence <= token_grammar.precedence) {
                    // check arity
                    if (context_token_grammar.arity == "PREFIX") {
                        // insert matching closing token
                        const offset = token.offset;
                        array.splice(i, 0, { type: context_token.match_type, offset: offset, value: context_token.match_value, implied: true });
                        i++;

                        // increment offset of subsequent tokens
                        for (let j = i; j < array.length; j++) {
                            array[j].offset += context_token.match_value.length;
                        }
                    }
                    context_stack.pop();
                }
            } while (context_token_grammar.precedence <= token_grammar.precedence);

            // if token.type is COMMA and context_token.type != FUNCTION_OPEN, fatal error
            if (grammarType == "COMMA" && context_token.type != "FUNCTION_OPEN") {
                // fatal error
                console.log('Error: unmatched separator token, cannot determine matching opening token: "' + original + '"');
                debugger;
            }

            // add token to context stack
            context_stack.push(token);
        } else if (token_grammar.arity == "POSTFIX") {
            // close all open contexts of lower precedence
            let context_token_grammar: Grammar;
            let context_token: GrammarToken;

            do {
                context_token = context_stack[context_stack.length - 1];
                context_token_grammar = findGrammar(findGrammarType(context_token.type));
                if (context_token_grammar.precedence < token_grammar.precedence) {
                    // check arity
                    if (context_token_grammar.arity == "PREFIX") {
                        // insert matching closing token
                        const offset = token.offset;
                        array.splice(i, 0, { type: context_token.match_type, offset: offset, value: context_token.match_value, implied: true });
                        i++;

                        // increment offset of subsequent tokens
                        for (let j = i; j < array.length; j++) {
                            array[j].offset += context_token.match_value.length;
                        }
                    }
                    context_stack.pop();
                }
            } while (context_token_grammar.precedence < token_grammar.precedence);

            // close open context, close itself, or fail
            if (context_token.match_type == grammarType) {
                // this is a matched closing token
                context_stack.pop();
            } else if (token.match_type) {
                // unmatched closing token, but can insert matching opening token
                // insert matching opening token
                const offset = context_token.offset + context_token.value.length;
                array.splice(array.indexOf(context_token) + 1, 0, { type: token.match_type, offset: offset, value: token.match_value, implied: true });
                i++;

                // increment offset of subsequent tokens
                for (let j = array.indexOf(context_token) + 2; j < array.length; j++) {
                    array[j].offset += token.match_value.length;
                }
            } else if (typeof token.match_type != "undefined") {
                // fatal error
                console.log('Error: unmatched closing token, but cannot determine matching opening token: "' + original + '"');
                debugger;
            }
        }
    }

    // strip ROOT and END tokens
    array = array.slice(1, -1);

    return array;
}

interface TokenTree {
    tree: QETerm;
    last_token: Token;
    error_str: string;
    token_array: GrammarToken[];
    original_string: string;
}

export function parse_tokens(tokens: GrammarToken[], original: string, options?: any): TokenTree {
    options = Object.assign({ recover_from_error: false }, options);
    const state = new ParserState(options);
    let node;
    let error_str = "";
    let token = undefined;

    if (!tokens.length)
        return {
            tree: state.root,
            last_token: null,
            error_str: error_str,
            token_array: tokens,
            original_string: original,
        };

    for (let i = 0; i < tokens.length; i++) {
        token = tokens[i];
        const grammarType = findGrammarType(token.type);
        const grammar = findGrammar(grammarType);

        // we may either want to break or recover_from_error
        if (error_str) {
            if (!state.recover_from_error) {
                break;
            }
            error_str = "";
            if (state.awaiting_value()) {
                state.insert(QETerm.create({ type: "EMPTY" }));
            } else if (token.type == "CLOSING_BRACKET") {
                // insert opening bracket, as child of "ROOT" because predecence
                state.insert(QETerm.create({ type: "OPENING_BRACKET" }));
            } else if ( findGrammarType(token.type) == undefined ) {
                continue;
            } else {
                QEAssert(grammar.arity == "VALUE" || grammar.arity == "PREFIX");
                // implicit multiply get an empty string as value
                state.insert(QETerm.create({ type: "MULTIPLY", value: "" }));
            }
        }

        // Closing brackets are a special case: find maching opening bracket,
        // remove it from the tree, and select the new 'current' node
        if (token.type == "CLOSING_BRACKET" || token.type == "FUNCTION_CLOSE") {
            error_str = state.close_bracket(token);
            if (error_str) {
                i--; // we may either want to break or recover_from_error
                continue;
            }
            continue;
        }

        // Commas are a special case: find maching open function,
        // push empty onto last child position, and select the new 'current' node
        if (token.type == "COMMA") {
            error_str = state.addFunctionArgument(token);
            if (error_str) {
                i--; // we may either want to break or recover_from_error
                continue;
            }
            continue;
        }

        // Minus token can be either a sign or a subtraction
        if (token.type == "MINUS" && !state.awaiting_value()) {
            //var subtract = {type:"SUBTRACT", value:"-", offset:token.offset };
            // shallow copy of the token to change its type without modifying
            // input token_array, while keeping any extra property
            token = Object.assign({}, token); // don't modify input array
            token.type = "SUBTRACT";
        }
        if (token.type == "PLUS" && !state.awaiting_value()) {
            token = Object.assign({}, token); // don't modify input array
            token.type = "ADD";
        }
        node = QETerm.create(token);

        if (token.type == undefined) {
            error_str = "got a token of unknown type '" + token.type + "'";
            i--; // we may either want to break or recover_from_error
            continue;
        }

        error_str = state.accepts(node);
        if (error_str) {
            i--; // we may either want to break or recover_from_error
            continue;
        }
        state.insert(node);
    }

    // check validity of final state
    if (state.recover_from_error) {
        if (state.awaiting_value()) {
            // state.empty is a placeholder that marks we avait a value. It
            // won't be replaced by an actual value, so replace it by another
            // non-special EMPTY node to be able to use 'state.close_bracket()'
            const empty = QETerm.create({ type: "EMPTY" });
            state.empty.replaceWith(empty);
        }
        while (state.root.findFirstChild("type", "OPENING_BRACKET")) {
            // set current state to the first child of the open bracket
            state.current = state.root.findFirstChild("type", "OPENING_BRACKET").children[0];
            state.close_bracket();
        }
    } else if (!error_str) {
        if (state.awaiting_value()) {
            error_str = "ran short of tokens, awaiting a value to be inserted";
        } else if (state.root.findFirstChild("type", "OPENING_BRACKET")) {
            error_str = "missing closing bracket";
        }
    }

    if (state.root.findFirstChild("type", "COMMA") !== undefined) {
        error_str = "COMMA operator outside of a function call";
    }

    // log error
    if (error_str) {
        error_str = "ERROR:\tQEParser.parse_tokens() " + error_str + "";
        if (original !== undefined) {
            error_str += "\n       \tinput: '" + original + "'";
            if (token) {
                //TODO
                //				var underline = Array(token.offset+1).join(" ")
                //						+ Array(token.value.length+1).join("#");
                //				error_str += "\n       \t        "+ underline;
            }
        }
        console.error(error_str);
    }

    // convert BINARY nodes to CHAINs
    state.root.convertBinaryOpsToChains();

	// convert EXPONENT nodes to pow{}
    state.root.convertExponentsToPowers();

    const output = {
        tree: state.root,
        last_token: token,
        error_str: error_str,
        token_array: tokens,
        original_string: original,
    };

    return output;
}

export function tokenize_and_parse(original: string, options: any = {}): TokenTree {
    const tokens = tokenize_string(original);
    const checkedTokens = check_tokens_syntax(tokens, original);
    const checked = parse_tokens(checkedTokens, original, options);

	if (options.merge_sign_operators) {
		// combine MINUS and PLUS unary operators, and merge with terms
		checked.tree.convertUnarySignOperatorsToTermSign();
	}

    return checked;
}

class ParserState {
    root: QETerm;
    empty: QETerm;
    current: QETerm;
    recover_from_error: boolean;

    // hold state and helper functions for the Parser
    constructor(options) {
        // special term: unary operator with highest possible precedance
        // allows a function to modify the actual root the the tree
        // (better use undefined to catch misuses than get silent errors)
        this.root = QETerm.create({ type: "ROOT" });
        // placeholder to specify the tree is expecting a value or a prefix operator
        this.empty = QETerm.create({ type: "EMPTY" });
        this.root.pushChild(this.empty);
        // 'current' node is the last inserted operator, therefore:
        // - Inserting a "VALUE" doesn't change 'current' node.
        // - 'current' is parent of the last inserted "VALUE" (includes "EMPTY")
        this.current = this.root;
        this.recover_from_error = false;
        // bool 'recover_from_error'
        for (const i in options) {
            this[i] = options[i];
        }
    }

    // returns true is the last inserted value is an "EMPTY" placeholder
    // true means the tree is expecting either a value or a prefix operator
    awaiting_value() {
        const last_index = this.current.children.length - 1;
        return this.current.children[last_index] == this.empty;
    }

    // close_bracket: close the nearest open matching BRACKETS or FUNCTION
    close_bracket(token?: Token | undefined) {
        if (this.awaiting_value()) {
            return "got a closing bracket when expecting a value";
        }

        let matching_term;
        if (token.type == "CLOSING_BRACKET") {
            matching_term = this.current.findParent(function (x) {
                return x.type == "BRACKETS" && x.open_state;
            });
        } else if (token.type == "FUNCTION_CLOSE") {
            matching_term = this.current.findParent(function (x) {
                return x.type == "FUNCTION" && x.open_state;
            });
        }
        if (!matching_term) {
            return "got a closing token with no opening token to match it";
        }

        // close the matching term
        matching_term.open_state = false;

        // push closing token onto matching_term.tokens
        matching_term.tokens.push(token);

        // save reference to term on token, so keyboard editor can have access to related tokens
        token.term = matching_term;

        this.current = matching_term.parent;

        return "";
    }

    // addFunctionArgument: COMMA handling: push EMPTY as last child of current FUNCTION
    addFunctionArgument(token) {
        if (this.awaiting_value()) {
            return "got a comma when expecting a value";
        }

        const matching_term = this.current.findParent(function (x) {
            return x.type == "FUNCTION" && x.open_state;
        });
        if (!matching_term) {
            return "got a comma token with no opening token left to match it";
        }

        // push comma token onto matching_term.tokens
        matching_term.tokens.push(token);

        // save reference to term on token, so keyboard editor can have access to related tokens
        token.term = matching_term;

        // set function as current context
        this.current = matching_term;

        // push empty onto function args
        this.current.pushChild(this.empty);

        return "";
    }

    // return a non-empty error string if 'node' cannot be inserted
    accepts(node) {
        let accepted = false;
        if (this.awaiting_value()) {
            accepted = node.arity == "VALUE" || node.arity == "PREFIX";
        } else {
            accepted = node.arity == "POSTFIX" || node.arity == "BINARY";
        }
        let error_str = "";
        if (!accepted) {
            const awaiting = this.awaiting_value() ? "awaiting" : "not awaiting";
            error_str = "cannot accept '" + node.type + "' ('" + node.arity + "') if " + awaiting + " a value";
        }
        return error_str;
    }

    // insert a node
    insert(node) {
        if (this.awaiting_value()) {
            // 11+ 2
            const empty = this.current.children.pop(); // this.empty
            this.current.children.push(node);
            node.parent = this.current;
            if (node.arity == "PREFIX") {
                this.current = node;
                this.current.pushChild(this.empty);
            }
        } else {
            let parent = this.current;
            // find where we need to insert the new operator
            // find closest parent with higher precedence
            // left-to-right associativity: node.precedence < parent.precedence
            while (node.precedence >= parent.precedence) {
                parent = parent.parent;
            }
            // insert new node as chosen parent's new child
            const child = parent.children.pop();
            parent.children.push(node);
            node.parent = parent;
            // insert old child to new node
            node.children.push(child);
            child.parent = node;
            // newly inserted operator is the new current
            this.current = node;
            if (node.arity == "BINARY") {
                this.current.pushChild(this.empty);
            }
        }
    }
}
