import { QETerm } from "../../../common/QETerm";
import { tokenize_and_parse, findGrammar } from "../../../common/QEGrammar";
import { QESolver, SolverStepOutput } from "../../QESolver";
import {
	additiveChainToList,
	multiplicativeTermToList,
	listToMultiplicativeTerm,
	getCharacterizedTermFactors
} from "../SolverUtils";
import { QEValueTree } from '../../../common/QEHelper';
import { QESolverStep } from '../QESolverStep';

/*
 * Analyze: solver steps that identify or characterize aspects of an input expression or data set
 */
export class Analyze {
	static digitAtPlace(input_value, options): SolverStepOutput {
		if (input_value.type != "map") return undefined;
		var params = input_value.value;

		if (!params["number"] || !params["place"]) {
			return undefined;
		}

		// TODO: validate type of number and place
		var number = params["number"].serialize_to_text();
		var place = params["place"].serialize_to_text();

		// TODO: support place_exponent, e.g. "3" -> 1000s place
		// TODO: handle decimal numbers
		// TODO: bounds checking, etc.
		var digit_at_place = ((number - (number % place)) % (place * 10)) / place;

		// TODO: use passed StringLookup widget?
		var place_strings = {
			1: "ones",
			10: "tens",
			100: "hundreds",
			1000: "thousands",
			10000: "ten-thousands",
		};

		var digits = (number + "").split("");

		var desc = "";
		desc += '<table style="margin-bottom: 10px;">';
		desc += "<tr>";
		// digits
		desc += '<tr style="text-align: center; font-weight: bold;">';
		desc += digits
			.map(function (x, i) {
				return (
					'<td style="border: 1px solid #888;' +
					(i == digits.length - Math.log10(place) - 1
						? "background: #8d4;"
						: "") +
					'">' +
					x +
					"</td>"
				);
			})
			.join("");
		desc += "</tr>";
		// places
		desc += '<tr style="text-align: center;">';
		desc += digits
			.map(function (x, i) {
				return (
					'<td style="border: 1px solid #888;' +
					(i == digits.length - Math.log10(place) - 1
						? "background: #8d4;"
						: "") +
					' padding: 0 10px;">' +
					place_strings[Math.pow(10, digits.length - i - 1)] +
					"</td>"
				);
			})
			.join("");
		desc += "</tr>";
		desc += "</table>";
		desc +=
			'<span style="font-weight: bold">' +
			digit_at_place +
			'</span> is in the <span style="font-weight: bold">' +
			place_strings[place] +
			"</span> place";

		var result: SolverStepOutput = {
			desc: desc,
			value: QETerm.create({ type: "RATIONAL", value: digit_at_place.toString() }),
			type: "tree",
		};

		return result;
	}

	static primeOrComposite(input_value, options) {
		if (input_value.type != "tree") return undefined;
		var eq = input_value.value;

		// validate number
		//	if (!QESolverStep.validateTermIsInteger(input_value)) return undefined;
		var serialized = input_value.value.serialize_to_text();
		var number = parseInt(serialized);
		if (number != serialized) return undefined;

		var desc =
			"Definition of a prime number: a positive whole number greater than 1 that has only two factors: 1 and itself.<br>";
		desc +=
			"Definition of a composite number: a positive whole number greater than 1 that is the product of two or more primes.<br><br>";

		var prime_composite = "neither";
		if (number < 0)
			desc +=
				'<span style="font-weight: bold;">' +
				number +
				'</span> is negative, so is <span style="font-weight: bold;">neither</span> prime nor composite';
		else if (number < 2)
			desc +=
				'<span style="font-weight: bold;">' +
				number +
				'</span> is not greater than 1, so is <span style="font-weight: bold;">neither</span> prime nor composite';
		else {
			var prime_factors = QESolverStep.getPrimeFactorList(number);

			desc +=
				'Factors of <span style="font-weight: bold;">' +
				number +
				"</span>: 1 * " +
				prime_factors.join(" * ") +
				"<br>";
			if (prime_factors.length == 1) prime_composite = "prime";
			else prime_composite = "composite";

			desc +=
				'<span style="font-weight: bold;">' +
				number +
				'</span> is <span style="font-weight: bold;">' +
				prime_composite +
				"</span>.";
		}

		var result = {
			desc: desc,
			value: prime_composite,
			type: "string",
		};
		return result;
	}

	/**
	 * lookupTableValue	Iterates over table rows in specified column to search for the specified value
	 */
	static lookupTableValue(input_value, options) {
		if (input_value.type != "map") return undefined;
		var params = input_value.value;

		// NOTE: this is a bit ugly: any "input" values are currently cloned by solveUsing
		//	so that they can be modified by steps without the original value being changed.
		// This is overkill when we don't want to clone the value
		//	 (e.g. we're passing a value in purely as a data source, or as a display widget).
		// The current workaround to pass a value by reference without cloning is to pass it in the solution display options.
		//	 This works, but it feels hokey.
		// A cleaner approach could be to define parameters in the Solver config.
		//	 If we did so we could also provide that information in the editor tool UI.
		if (
			!params["lookup_value"] ||
			!params["lookup_col_index"] ||
			!params["output_col_index"] ||
			!params["table"]
		) {
			console.log("Missing expected parameter");
			return undefined;
		}

		var table = params["table"].value;
		var lookup_value = params["lookup_value"].serialize_to_text();
		var lookup_col_index = parseInt(params["lookup_col_index"].serialize_to_text());
		var output_col_index = parseInt(params["output_col_index"].serialize_to_text());

		var headers = table.values.headers;
		var rows = table.values.rows;
		var min_col = 0;
		var max_col = headers.length - 1;

		// validate column indices
		if (lookup_col_index < min_col || lookup_col_index > max_col) {
			console.log("Error: lookup_col_index out of bounds.");
			return undefined;
		}
		if (output_col_index < min_col || output_col_index > max_col) {
			console.log("Error: output_col_index out of bounds.");
			return undefined;
		}

		// find each match
		var matches = [];

		var row, col, cell;
		for (row = 0; row < rows.length; row++) {
			for (col = min_col; col <= max_col; col++) {
				cell = rows[row][col];

				// serialize cell value
				var cell_value = "";
				if (cell.value.type == "string") {
					cell_value = cell.value.value;
				} else if (cell.value.type == "tree") {
					cell_value = cell.value.value.serialize_to_text();
				} else if (cell.type == "widget") {
					// TODO: serialize widget based on widget type
					//				cell_value = cell.value.serialize_to_text();
				} else {
					console.log("Error. Unknown table cell type: ", cell);
				}

				if (cell_value === lookup_value) {
					matches.push({ row: row, col: col });
				}
			}
		}

		var desc = "";
		var found_value;
		var value_type;

		if (matches.length) {
			// highlight lookup and output cells and display Table
			var first_match = matches[0];
			var lookup_cell = rows[first_match.row][first_match.col];
			var output_cell = rows[first_match.row][output_col_index];

			found_value = output_cell.value.value;
			value_type = output_cell.value.type;

			var lookup_col_name;
			if (headers[lookup_col_index].value.type == "string") {
				lookup_col_name = headers[lookup_col_index].value.value;
			} else {
				lookup_col_name = headers[
					lookup_col_index
				].value.value.display();
			}
			var output_col_name;
			if (headers[output_col_index].value.type == "string") {
				output_col_name = headers[output_col_index].value.value;
			} else {
				output_col_name = headers[
					output_col_index
				].value.value.display();
			}

			desc += "";
			desc +=
				'To find the value, first look in the <span style="font-weight: bold">' +
				lookup_col_name +
				"</span> column.";
			desc +=
				'The correct value is on the same row in the <span style="font-weight: bold">' +
				output_col_name +
				"</span> column.";
			desc += table.display({
				highlight_input_cell: {
					row: first_match.row,
					col: first_match.col,
				},
				highlight_output_cell: {
					row: first_match.row,
					col: output_col_index,
				},
			});
		} else {
			return undefined;
		}

		var result = {
			desc: desc,
			value: found_value,
			type: value_type,
		};
		return result;
	}

	// characterizeNumber: classify which number systems the number belongs to
	// Note: does shallow characterization. E.g. "4" is_number, is_rational, is_integer, etc, but "sqrt{frac{48,3}}" 
	static characterizeNumber(input_value, options): SolverStepOutput {
		if (input_value.type != "tree") return undefined;
		const val = input_value.value.children[0];

		// TODO: also check is_real, is_imaginary

		// TODO: move out of characterizeNumber so it can be called by other functions

		// helper we can call to characterize values, such as the base of a root or power
		function helpCharacterizeNumber(subval){
			let num_systems = {
				is_number: false,
				is_rational: false,
				is_irrational: false,
				is_integer: false,
				is_whole: false,
				is_natural: false,
//				is_real: false,
//				is_imaginary: false,
			};

			if (subval.type == "RATIONAL") {
				num_systems.is_number = true;
				num_systems.is_rational = true;
				if (subval.Q.den == 1) {
					num_systems.is_integer = true;
					if (subval.sign != -1) {
						num_systems.is_whole = true;

						if (subval.Q.num != 0) {
							num_systems.is_natural = true;
						}
					}
				}
			} else if (subval.type == "CONSTANT") {
				// all of the constants we currently support (just pi) are irrational
				num_systems.is_number = true;
				num_systems.is_irrational = true;
			} else if (subval.type == "FUNCTION") {
				// only deal with fractions here - treat nested roots as non-numbers
				if (subval.value == "frac") {
					const num = subval.children[0];
					const den = subval.children[1];
					const num_characterization = helpCharacterizeNumber(num);
					const den_characterization = helpCharacterizeNumber(den);

					num_systems.is_number = num_characterization.is_number && den_characterization.is_number;
					num_systems.is_rational = num_characterization.is_rational && den_characterization.is_rational;
					num_systems.is_irrational = num_characterization.is_irrational || den_characterization.is_irrational;
				}
			}

			return num_systems;
		}

		// identify which number systems the number belongs to
		let number_systems = {
			is_number: false,
			is_rational: false,
			is_irrational: false,
			is_integer: false,
			is_whole: false,
			is_natural: false,
		};

		if (val.type == "RATIONAL") {
			number_systems = Object.assign(number_systems, helpCharacterizeNumber(val));
		} else if (val.type == "CONSTANT") {
			// all of the constants we currently support (just pi) are irrational
			number_systems = Object.assign(number_systems, helpCharacterizeNumber(val));
		} else if (val.type == "FUNCTION") {
			if (val.value == "frac") {
				// NOTE: not checking if the fraction is evenly divisible, so "frac{6,2}" will not return is_integer true
				const num = val.children[0];
				const den = val.children[1];
				const num_characterization = helpCharacterizeNumber(num);
				const den_characterization = helpCharacterizeNumber(den);

				number_systems.is_number = num_characterization.is_number && den_characterization.is_number;
				number_systems.is_rational = num_characterization.is_rational && den_characterization.is_rational;
				number_systems.is_irrational = num_characterization.is_irrational || den_characterization.is_irrational;
			} else if (val.value == "sqrt" || val.value == "nroot") {
				let base, root_index;
				if (val.value == "sqrt") {
					root_index = QETerm.create({ type: "RATIONAL", value: "2"});
					base = val.children[0];
				} else {
					root_index = val.children[0];
					base = val.children[1];
				}
				const root_index_characterization = helpCharacterizeNumber(root_index);
				const base_characterization = helpCharacterizeNumber(base);

				number_systems.is_number = root_index_characterization.is_number && base_characterization.is_number;
				number_systems.is_irrational = root_index_characterization.is_irrational || base_characterization.is_irrational;

				// ensure root_index is an integer
				if (root_index_characterization.is_integer && base_characterization.is_rational) {
					// factor the base and check whether all of the factor exponents are divisible by root_index
					const base_factors = getCharacterizedTermFactors(base);

					let reducible = true;
					for (let factor in (base_factors.num || {})) {
						// check that non-one factor exponents are evenly divisible by root_index
						if (factor != "1" && base_factors.num[factor] % root_index.Q.num) {
							reducible = false;
						}
					}
					for (let factor in (base_factors.den || {})) {
						// check that non-one factor exponents are evenly divisible by root_index
						if (factor != "1" && base_factors.den[factor] % root_index.Q.num) {
							reducible = false;
						}
					}
					if (reducible) {
						number_systems.is_rational = true;
					} else {
						number_systems.is_irrational = true;
					}
				}
			} else if (val.value == "pow") {
				const base = val.children[0];
				const exp = val.children[1];
				const base_characterization = helpCharacterizeNumber(base);
				const exp_characterization = helpCharacterizeNumber(exp);

				number_systems.is_number = base_characterization.is_number && exp_characterization.is_number;
				number_systems.is_irrational = base_characterization.is_irrational || exp_characterization.is_irrational;

				// ensure exponent is an integer
				if (exp_characterization.is_integer && base_characterization.is_rational) {
					// base is rational and exponent is integer
					number_systems.is_rational = base_characterization.is_rational;
					number_systems.is_integer = base_characterization.is_integer;
				}
			}
		}
		// TODO: mfrac, factorial, brackets

		// return as JSON map of "true"|"false" values
		var result = {
			desc: "",
			value: number_systems,
			type: "json",
		};
		return result;
	}

	static characterizeTerm(input_value, options): SolverStepOutput {
		if (input_value.type != "tree") return undefined;
		const term = input_value.value.children[0];

		// check that term is not a comparison chain or additive chain
		if (term.isComparatorChain() || term.isAddChain()) {
			return;
		}

		const characterization = helpCharacterizeTerm(term);

		// combine coefficient terms
		const coeff_num = listToMultiplicativeTerm(characterization.coefficient_num_terms);
		const coeff_den = listToMultiplicativeTerm(characterization.coefficient_den_terms);

		let coeff;
		if (coeff_den.serialize_to_text() == "1") {
			// no denominator
			coeff = coeff_num;
		} else {
			coeff = QETerm.create({ type: "FUNCTION", value: "frac" });
			coeff.pushChild(coeff_num);
			coeff.pushChild(coeff_den);
		}
		coeff.sign = characterization.coefficient_sign;
		characterization.coefficient = coeff.serialize_to_text();

		delete characterization.coefficient_num_terms;
		delete characterization.coefficient_den_terms;
		delete characterization.coefficient_sign;

		var result = {
			desc: "",
			value: characterization,
			type: "json",
		};
		return result;
	}

	static characterizeExpression(input_value, options): SolverStepOutput {
		if (input_value.type != "tree") return undefined;
		const expr = input_value.value.children[0];

		// check that expr is not a comparison chain
		if (expr.isComparatorChain()) {
			return;
		}

		const characterization = helpCharacterizeExpression(expr);

		// combine coefficient terms
		const coeff_num = listToMultiplicativeTerm(characterization.coefficient_num_terms);
		const coeff_den = listToMultiplicativeTerm(characterization.coefficient_den_terms);

		let coeff;
		if (coeff_den.serialize_to_text() == "1") {
			// no denominator
			coeff = coeff_num;
		} else {
			coeff = QETerm.create({ type: "FUNCTION", value: "frac" });
			coeff.pushChild(coeff_num);
			coeff.pushChild(coeff_den);
		}
		coeff.sign = characterization.coefficient_sign;
		characterization.coefficient = coeff.serialize_to_text();

		delete characterization.coefficient_num_terms;
		delete characterization.coefficient_den_terms;
		delete characterization.coefficient_sign;

		var result = {
			desc: "",
			value: characterization,
			type: "json",
		};
		return result;
	}
}

/////////////
// helpers

function helpCharacterizeExpression(expr) {
	// NOTE: characterizeTerm and characterizeExpression call each other for characterizing child add or multiply chains, even if the child is a single term rather than a chain
	//    As a result, they need to pass characterized child properties onwards unless they are actually incompatible
	//    // E.g. if the expression is "4x", then the coefficient should be returned, but if it is "4x + 3", then coefficient should NOT be returned

	// return as JSON map of string values
	const characterization = {
		is_polynomial: true,
		is_numeric: true,
		num_terms: 0,
		num_vars: 0,
		leading_term: null,
		max_var_degrees: {},
		degree: 0,
		coefficient_num_terms: [],
		coefficient_den_terms: [],
		coefficient_sign: 1,
		coefficient: "",
	};

	// convert expression to list of terms
	const add_chain_terms = additiveChainToList(expr);

	characterization.num_terms = add_chain_terms.length;
	characterization.leading_term = add_chain_terms[0].serialize_to_text(); // sign of first term automatically retained

	// iterate over term list and characterize each
	for (let i = 0; i < add_chain_terms.length; i++) {
		const term = add_chain_terms[i];
		const term_characterization = helpCharacterizeTerm(term);

		characterization.is_polynomial &&= term_characterization.is_polynomial;
		characterization.is_numeric &&= term_characterization.is_numeric;

		// get the maximum degree of each variable in the expression
		for (let var_name in term_characterization.var_degrees) {
			const variable_exp = term_characterization.var_degrees[var_name];
			characterization.max_var_degrees[var_name] = Math.max((characterization.max_var_degrees[var_name] || 0), variable_exp);
		}

		characterization.degree = Math.max(characterization.degree, term_characterization.degree);

		// merge coefficients from child term 
		characterization.coefficient_num_terms = characterization.coefficient_num_terms.concat(term_characterization.coefficient_num_terms);
		characterization.coefficient_den_terms = characterization.coefficient_den_terms.concat(term_characterization.coefficient_den_terms);
		characterization.coefficient_sign *= term_characterization.coefficient_sign;
	}

	// set num_vars
	characterization.num_vars = Object.keys(characterization.max_var_degrees).length;

	if (add_chain_terms.length > 1) {
		// additive chain, drop coefficients
		characterization.coefficient_num_terms = [];
		characterization.coefficient_den_terms = [];
		characterization.coefficient_sign = 1;
	}

	return characterization;
}

function helpCharacterizeTerm(term) {
	// check if each sub-term is:
	// - numeric: anything numeric. Any term/function with ONLY numeric (non-variable) children/descendants is numeric.
	//    If a value is numeric, it is also polynomial (it's a constant)
	//    e.g. "5", "1.2", "frac{4,7}", "sqrt{21}", "(1+sin{\pi})"
	// - polynomial: anything numeric, OR a variable with an integer exponent
	//    e.g. "3", "x", "pow{y,3}"

	// TODO: un-expanded? Could use a flag to indicate the term contains nested additive expressions. E.g. "pow{x-1,2}"

	// NOTE: what about nested expressions, like "(1+x)", or "pow{x-1,2}"?
	// - technically, we should expand out (multiply) and sum any terms (not least because they can cancel out),
	//   but it's "good enough" to stick to the following:
	//   If the nested expression is not polynomial, then don't proceed with any further checks
	//   Else, multiply the degree of any nested expression vars by pow exponent

	const characterization = {
		is_polynomial: true,
		is_numeric: true,
		degree: 0,
		num_vars: 0,
		var_degrees: {},
		coefficient_num_terms: [],
		coefficient_den_terms: [],
		coefficient_sign: 1,
		coefficient: "",
	}

	// iterate over terms
	const terms_list = multiplicativeTermToList(term);
	for (let i = 0; i < terms_list.length; i++) {
		const term = terms_list[i];

		if (term.type == "RATIONAL") {
			// track as coefficient, but otherwise no need to do anything - is_numeric and is_polynomial
			characterization.coefficient_num_terms.push(term);
		} else if (term.type == "CONSTANT") {
			// track as coefficient, but otherwise no need to do anything - is_numeric and is_polynomial
			characterization.coefficient_num_terms.push(term);
		} else if (term.type == "VARIABLE") {
			characterization.is_numeric = false;

			// increase degree of this variable
			characterization.var_degrees[term.value] = (characterization.var_degrees[term.value] || 0) + 1;
		} else if (term.type == "FUNCTION") {
			// must check if it is a frac/root/pow number
			if (term.value == "frac") {
				const num = term.children[0];
				const den = term.children[1];
				const num_characterization = helpCharacterizeExpression(num);
				const den_characterization = helpCharacterizeExpression(den);

				// check if num and den are numeric and polynomial
				characterization.is_numeric &&= num_characterization.is_numeric;
				characterization.is_numeric &&= den_characterization.is_numeric;
				characterization.is_polynomial &&= num_characterization.is_polynomial;
				characterization.is_polynomial &&= den_characterization.is_polynomial;

				// denominator must NOT have variables, else not polynomial
				if (Object.keys(den_characterization.max_var_degrees).length) {
					characterization.is_polynomial = false;
				}

				// merge coefficients from fraction numerator
				characterization.coefficient_num_terms = characterization.coefficient_num_terms.concat(num_characterization.coefficient_num_terms);
				characterization.coefficient_den_terms = characterization.coefficient_den_terms.concat(num_characterization.coefficient_den_terms);

				// merge coefficients from denominator, but flip coefficients
				characterization.coefficient_num_terms = characterization.coefficient_num_terms.concat(den_characterization.coefficient_den_terms);
				characterization.coefficient_den_terms = characterization.coefficient_den_terms.concat(den_characterization.coefficient_num_terms);
			} else if (term.value == "sqrt") {
				const base = term.children[0];
				const base_characterization = helpCharacterizeExpression(base);

				// check if base is numeric and polynomial
				characterization.is_numeric &&= base_characterization.is_numeric;
				characterization.is_polynomial &&= base_characterization.is_polynomial;

				// root base must NOT have variables, else not polynomial
				const nested_base_var_terms = base.findAllChildren("type", "VARIABLE");
				if (nested_base_var_terms.length) {
					characterization.is_polynomial = false;
				}

				// track numeric terms as coefficients
				if (base_characterization.is_numeric) {
					characterization.coefficient_num_terms.push(term);
				}
			} else if (term.value == "nroot") {
				const root_index = term.children[0];
				const base = term.children[1];
				const root_index_characterization = helpCharacterizeExpression(root_index);
				const base_characterization = helpCharacterizeExpression(base);

				// check if root_index is non-numeric. If not, no need to go further
				if (!root_index_characterization.is_numeric) {
					characterization.is_numeric = false;
					characterization.is_polynomial = false;
					continue;
				}

				// check if base is numeric and polynomial
				characterization.is_numeric &&= base_characterization.is_numeric;
				characterization.is_polynomial &&= base_characterization.is_polynomial;

				// root base must NOT have variables, else not polynomial
				const nested_base_var_terms = base.findAllChildren("type", "VARIABLE");
				if (nested_base_var_terms.length) {
					characterization.is_polynomial = false;
				}

				// track numeric terms as coefficients
				if (base_characterization.is_numeric) {
					characterization.coefficient_num_terms.push(term);
				}
			} else if (term.value == "pow") {
				const base = term.children[0];
				const exp = term.children[1];
				const base_characterization = helpCharacterizeExpression(base);
				const exp_characterization = helpCharacterizeExpression(exp);

				// check if exponent is non-numeric, or base is non-polynomial. If not, no need to go further
				if (!exp_characterization.is_numeric ||
					!base_characterization.is_polynomial
				) {
					characterization.is_numeric = false;
					characterization.is_polynomial = false;
					continue;
				}

				// if base is numeric, then the power is numeric. E.g. "6"
				if (base_characterization.is_numeric) {
					continue;
				}

				// else base is polynomial, with variable(s). Need to check if exponent is positive integer
				if (exp.type != "RATIONAL" || exp.sign < 0 || exp.Q.den != 1) {
					characterization.is_polynomial = false;
					continue;
				}

				// base must be polynomial, with variable(s), e.g. "x", or "2y". Multiply by max degree of all nested vars
				for (let var_name in base_characterization.max_var_degrees) {
					const variable_exp = base_characterization.max_var_degrees[var_name] * exp.Q.num;

					// increase degree of this variable
					characterization.var_degrees[var_name] = (characterization.var_degrees[var_name] || 0) + variable_exp;
				}

				// track numeric terms as coefficients
				if (base_characterization.is_numeric) {
					characterization.coefficient_num_terms.push(term);
				}
			} else {
				// everything else, e.g. "sin{3}"
				// check for any nested variables. If any found, is_numeric = false and is_polynomial = false
				const nested_var_terms = term.findAllChildren("type", "VARIABLE");
				if (nested_var_terms.length) {
					characterization.is_numeric = false;
					characterization.is_polynomial = false;
				} else {
					// if function has no variable children it is numeric, and is tracked as a coefficient
					characterization.coefficient_num_terms.push(term);
				}
			}
		// TODO: EXPONENT?
		} else if (term.type == "BRACKETS") {
			// characterize the nested expression
			const base_characterization = helpCharacterizeExpression(term.children[0]);
			characterization.is_numeric &&= base_characterization.is_numeric;
			characterization.is_polynomial &&= base_characterization.is_polynomial;

			// track numeric terms as coefficients
			if (base_characterization.is_numeric) {
				characterization.coefficient_num_terms.push(term);
			}
		}
	}

	// calculate degree of the term - it is the sum of the variable degrees
	for (let var_name in characterization.var_degrees) {
		characterization.degree += characterization.var_degrees[var_name];
	}

	// set num_vars
	characterization.num_vars = Object.keys(characterization.var_degrees).length;

	// retain sign
	characterization.coefficient_sign *= (term.sign || 1);

	return characterization;
}

