import { QETerm } from '../QETerm';
import { QEHelper } from '../QEHelper';
import { QEWidget, DisplayOptions } from '../QEWidget';
import * as jQuery from 'jquery';

export class QEWidgetMultiInput extends QEWidget {
	display_options: { [key: string]: any };
	dataset: { label: string, key: string }[];
	user_value: { [key: string]: string };

	constructor(dataset: { label: string, key: string }[], display_options: DisplayOptions = {}) {
		super();

		// MultiInput is used to present the user with a number of input box items
		// - each item has a label and key
		// - input submitted as map of key to "true"|"false"
		this.dataset = dataset;

		// preset this.user_value with false for each field in dataset
		this.user_value = {};
		for (let i = 0; i < dataset.length; i++) {
			this.user_value[dataset[i].key] = "";
		}

		this.display_options = display_options;
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		let dataset;
		try {
			dataset = JSON.parse(deserialized.dataset);
		} catch (err) {
			console.log('Error: unable to parse dataset values: ', deserialized.dataset, err);
			return null;
		}

		// resolve each dataset label
		for (let i = 0; i < dataset.length; i++) {
			let field_value = QEHelper.resolvePlaceholderToRefs(dataset[i].label, resolved_data);

			// check for unresolved dependencies
			if (!field_value) return null;

			dataset[i].label = field_value.display();
		}

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetMultiInput(dataset, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		var display_options = Object.assign({}, this.display_options, options);
		var values = display_options.value || { value: {} };

		let ml = '<ul class="multi_input">';
		for (let i = 0; i < this.dataset.length; i++) {
			let item = this.dataset[i];
			// use passed values to set input boxes
			if (values.value[item.key] !== undefined) {
				ml += '<li><input type="text" name="'+ item.key +'" value="'+ values.value[item.key] +'"> '+ item.label +'</li>';
			} else {
				ml += '<li><input type="text" name="'+ item.key +'" value=""> '+ item.label +'</li>';
			}
		}
		ml += '</ul>';

		return ml;
	}

	bindEventHandlers(widget_container) {
		const self = this;

		widget_container.on('keyup', 'input', function(){
			if (widget_container.is('.disable_input')) return false; //skip if disabled

			// get value of each input and update user_value
			const new_value = {};
			widget_container.find('li input').each(function(i, elem){
				const item = jQuery(elem);
				new_value[item.attr('name')] = item.val();
			});
			self.setInputValue(new_value);
		});
	}

	setInputValue(value: { [key: string]: string }) {
		this.user_value = value;
	}

	getInputValue(input_widgets?): string { return JSON.stringify(this.user_value); }

	exportValue(options?){
		return {
			type: 'multi_input',
			dataset: JSON.stringify(this.dataset),
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

